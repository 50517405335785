import { ShelvesViewToggle } from '@components/molecules';
import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { FilterButton } from '@components/molecules/filterButton/filterButton';
import { GoParentDirectoryButton } from '@components/molecules/goParentDirectoryButtonIcon/goParentDirectoryButton';
import {
  AppBar,
  EditPlanogramModalProps,
  PlanogramDeleteDialog,
  PlanogramsTable,
} from '@components/organisms';
import { ClonePlanogramModalProps } from '@components/organisms/clonePlanogramForm/clonePlanogramForm';
import {
  GridModeSort,
  ListOrder,
} from '@components/organisms/gridModeSort/gridModeSort';
import { PlanogramsConditions } from '@components/organisms/planogramsConditions/planogramsConditions';
import { PlanogramsGrid } from '@components/organisms/planogramsGrid/planogramsGrid';
import { PlonogramsSidebar } from '@components/organisms/planogramsSidebar/planogramsSidebar';
import { useRerenderingPlanogram } from '@hooks/rerenderingComponents';
import { useCreatePlanogram } from '@hooks/useCreatePlanogram';
import { useDirectoryManagement } from '@hooks/useDirectoryManagement';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { useModal } from '@hooks/useModal';
import { usePageTitle } from '@hooks/usePageTitle';
import { useSessionStorage } from '@hooks/useSessionStorage';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { Box, Typography } from '@mui/material';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal } from '@reducers/modal';
import {
  setLastVisitedPlanogramListURL,
  setSelectedDirectoryId,
  setSelectedItemIndex,
} from '@reducers/planogram';
import {
  setCurrentTypeFile,
  setDirectoryId,
  setDirectoryType,
  setIsViewFolder,
  setPlanogramDirectoryParent,
} from '@reducers/sharePermission';
import {
  GetSearchPlanogramDirectoriesParams,
  useClosePlanogramMutation,
  useDeletePlanogramDirectoryMutation,
  useGetPlanogramDirectoriesQuery,
  useMarkPlanogramDirectoryFavoriteMutation,
  usePutPlanogramMutation,
  useSearchPlanogramDirectoriesQuery,
  useUnmarkPlanogramDirectoryFavoriteMutation,
  useUpdatePlanogramDirectoryMutation,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  SidebarValue,
  drawerWidth,
  fullWidth,
  getListPageTitle,
  getSortOptions,
  isApiResponse,
  pageTitle,
  paths,
  rowsPerPage,
  toastMessages,
} from '@utils/const';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { theme } from 'theme';
import { DirectoryType } from 'types/common';
import {
  PlanogramDirectory,
  PlanogramDirectoryOrder,
  PlanogramListOrder,
} from 'types/planogram';
import { PlanogramSharePermissionProps } from 'types/sharePermission';
import { SearchText } from './fragments/searchText';

const tablePaddingR = 24;
const tablePaddingL = 24;
const defaultSearchedOffsetByTab = {
  all: 0,
  favorite: 0,
  viewed: 0,
};

const getDirectoryPageTitle = (
  sidebarValue: SidebarValue,
  isRoot: boolean,
  directoryParentName: string
) => {
  if (sidebarValue === 'favorite') {
    return 'スター付き';
  } else if (isRoot) {
    return '棚割計画';
  } else {
    return directoryParentName;
  }
};

export const Planograms: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, setSessionLastVisitedPlanogramListURL] = useSessionStorage<string>(
    'sessionLastVisitedPlanogramListURL'
  );
  const { selectedDirectoryId } = useAppSelector((state) => state.Planogram);
  const { isViewFolder } = useAppSelector((state) => state.SharePermission);
  const handleCreatePlanogram = useCreatePlanogram();
  const [clonePlanogram] = useClosePlanogramMutation();
  const [updateDirectory] = useUpdatePlanogramDirectoryMutation();
  const [putPlanogram] = usePutPlanogramMutation();
  const [deletePlanogramDirectory] = useDeletePlanogramDirectoryMutation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isClearSearch, setIsClearSearch] = useState<boolean>(false);
  const [searchedOffsetByTab, setSearchedOffsetByTab] = useState<
    Partial<Record<SidebarValue, number>> // used to maintain tab offset when switching tabs using back browser button
  >(defaultSearchedOffsetByTab);
  const [directoryOffset, setDirectoryOffset] = useState<number>(0);
  const [selectPlanogramDirectory, setSelectPlanogramDirectory] =
    useState<PlanogramDirectory>();
  const params = useParams();
  const directoryId = params.id;
  const { pathname, search } = location;
  const currentUrl = pathname + search;

  const {
    isInitRendering,
    condition,
    isShowFilters,
    viewMode,

    setCondition,
    setIsShowFilters,
    setViewMode,

    conditionData: {
      bayPlanCodes,
      users,
      organizationStatuses,
      me,
      userData,
      userDataLoading,
    },
    sidebarData: {
      sidebarValue,
      isFilteredByFavorite,
      isFilteredByViewed,
      defaultFirstOrderBySideBar,
    },
  } = useRerenderingPlanogram();
  const [isForceGetPlanogramDirectories, setIsForceGetPlanogramDirectories] =
    useState(false); // Used to refetch dictionaries after opening the license modal

  const { firstOrder } = condition ?? {};
  const searchedOffset = searchedOffsetByTab[sidebarValue] || 0;

  const setSearchedOffset = (value: number) => {
    setSearchedOffsetByTab({
      ...searchedOffsetByTab,
      [sidebarValue]: value,
    });
  };
  const clearOffset = () => {
    setDirectoryOffset(0);
    setSearchedOffsetByTab(defaultSearchedOffsetByTab);
  };

  const isSkipListPlanogramDirectoriesQuery =
    isFilteredByFavorite ||
    isFilteredByViewed ||
    !!condition?.name ||
    // Make sure all condition is completely parsed from query URL when initializing page
    !isInitRendering;

  const {
    data: directory,
    isLoading: isDirectoryLoading,
    isFetching: isDirectoryFetching,
    refetch: refetchDirectories,
  } = useGetPlanogramDirectoriesQuery(
    {
      id: directoryId,
      ...{
        ...condition,
        offset: directoryOffset,
        limit: rowsPerPage,
        firstOrder: firstOrder as PlanogramDirectoryOrder,
        name: undefined,
        ownerId: undefined,
        bayPlanCodeId: undefined,
        organizationStatusId: undefined,
      },
    },
    {
      skip:
        isSkipListPlanogramDirectoriesQuery && !isForceGetPlanogramDirectories,
      refetchOnMountOrArgChange: true,
    }
  );
  const isSkipSearchPlanogramDirectoriesQuery =
    (!isSkipListPlanogramDirectoriesQuery &&
      !condition?.bayPlanCodeId &&
      !condition?.ownerId &&
      !condition?.organizationStatusId &&
      !condition?.bayPlanCodeId) ||
    // Prevent favoriteOwnerId is undefined when initializing starred page without userData
    (isFilteredByFavorite && !userData?.user.id) ||
    // Make sure all condition is completely parsed from query URL when initializing page
    !isInitRendering;

  const {
    data: searchedData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
    refetch: refetchSearchDirectories,
  } = useSearchPlanogramDirectoriesQuery(
    {
      ...condition,
      offset: searchedOffset,
      limit: rowsPerPage,
      firstOrder: condition?.firstOrder
        ? condition.firstOrder
        : 'created_at_desc',
      favoriteOwnerId:
        isFilteredByFavorite && userData?.user.id
          ? [userData.user.id]
          : undefined,
      directoryAncestorId: directoryId ? directoryId : undefined,
    },
    {
      skip: isSkipSearchPlanogramDirectoriesQuery,
      refetchOnMountOrArgChange: true,
    }
  );

  const isLoadingData =
    isSearchLoading ||
    isDirectoryLoading ||
    (isFilteredByFavorite && userDataLoading) ||
    !isInitRendering;
  const isFetchingData = isSearchFetching || isDirectoryFetching;

  const isRoot = directory?.parent.type === 'root';
  const isShowBreadcrumbList = !!directory && !!directoryId;

  const offset = isSkipSearchPlanogramDirectoriesQuery
    ? directoryOffset
    : searchedOffset;

  const planogramDirectories = useMemo(() => {
    if (isLoadingData) return [];
    return isSkipSearchPlanogramDirectoriesQuery
      ? directory?.planogram_directories
      : searchedData?.planogram_directories;
  }, [
    directory?.planogram_directories,
    isLoadingData,
    isSkipSearchPlanogramDirectoriesQuery,
    searchedData?.planogram_directories,
  ]);

  const tableWidth = `calc(${fullWidth} - ${drawerWidth}px - ${tablePaddingR}px - ${tablePaddingL}px)`;

  useEffect(() => {
    if (directory?.parent?.type === 'directory') {
      dispatch(setIsViewFolder(true));
      dispatch(setPlanogramDirectoryParent(directory.parent));
    } else {
      dispatch(setIsViewFolder(false));
      dispatch(setPlanogramDirectoryParent(undefined));
    }
  }, [directory, dispatch]);

  useEffect(() => {
    if (
      !selectedDirectoryId &&
      !isViewFolder &&
      directory &&
      directory.parent.type !== 'root'
    ) {
      dispatch(setIsViewFolder(true));
      dispatch(setSelectedDirectoryId(directory.parent.id));
    }
  }, [directory, selectedDirectoryId, dispatch, isViewFolder]);

  const handleShowFilters = () => {
    setIsShowFilters(!isShowFilters);
  };

  const handleClickDirectory = (item: PlanogramDirectory) => {
    const directoryId = item.id;
    clearOffset();
    setIsClearSearch(true);
    dispatch(setSelectedDirectoryId(directoryId));
    setCondition(
      {
        ...condition,
        name: undefined,
      },
      { directoryId }
    );
  };

  const handleRowClick = (item: PlanogramDirectory) => {
    if (item.type === 'directory') {
      handleClickDirectory(item);
    }
    if (item.type === 'file' && item.planogram_id) {
      dispatch(setLastVisitedPlanogramListURL(currentUrl));
      setSessionLastVisitedPlanogramListURL(currentUrl);
      navigate(paths.plans.planogramId(Number(item.planogram_id)));
    }
  };

  const handleClickMoveParentDirectory = (parentId?: string) => {
    const isBackToRootDirectory =
      directory?.breadcrumb?.length === 1 || !parentId;
    const directoryId = isBackToRootDirectory ? '' : parentId;
    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
  };

  const handleClickBreadcrumbs = (directory: DirectoryType) => {
    const isBackToRootDirectory = directory.type === 'root';
    const directoryId = isBackToRootDirectory ? '' : directory.id;
    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
  };

  const afterHandleChangeBreadCrumb = (data: {
    isBackToRootDirectory: boolean;
    hasCondition: boolean;
    directoryId: string;
  }) => {
    const { directoryId, isBackToRootDirectory } = data;
    clearOffset();
    setIsClearSearch(true);
    dispatch(setSelectedDirectoryId(directoryId));
    const newCondition = {
      ...condition,
      name: undefined,
    };
    // sync new conditions to query parameters
    if (isBackToRootDirectory) {
      setCondition(newCondition, { isToRootDirectory: true });
    } else {
      setCondition(newCondition, { directoryId });
    }
  };

  const { showModal: showClonePlanogramModal } =
    useModal<ClonePlanogramModalProps>('clonePlanogram', '棚割を複製');

  const handleOpenDeleteDialog = (planogramDirecotry?: PlanogramDirectory) => {
    if (!planogramDirecotry) return;
    setDeleteDialogOpen(true);
    setSelectPlanogramDirectory(planogramDirecotry);
  };

  const handleDeleteShelfEditorItem = async () => {
    setDeleteDialogOpen(false);
    dispatch(updateLoadingIndicatorState(true));
    const typeName =
      selectPlanogramDirectory?.type === 'directory' ? 'フォルダ' : '棚割';
    try {
      await deletePlanogramDirectory({
        id: selectPlanogramDirectory?.id ?? '',
      }).unwrap();

      if (offset !== 0) {
        clearOffset();
      } else {
        if (isSkipListPlanogramDirectoriesQuery) {
          await refetchSearchDirectories().unwrap();
        } else {
          await refetchDirectories().unwrap();
        }
      }

      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteShelfEditorItem(typeName),
        })
      );
    } catch (error) {
      if (isApiResponse(error)) {
        if (error.data.detail === 'Directory is not empty') {
          dispatch(
            openToast({
              type: 'error',
              message: toastMessages.error.directoryIsNotEmpty,
            })
          );
          return;
        } else if (error.data.detail === 'Operation not permitted') {
          dispatch(
            openToast({
              type: 'error',
              message: toastMessages.error.directoryNotPermitted,
            })
          );
          return;
        }
      }
      console.error(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteShelfEditorItem(typeName),
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const handleClonePlanogram = (planogramDirecotry: PlanogramDirectory) => {
    const { planogram, parent_id } = planogramDirecotry;
    if (!planogram) return;
    showClonePlanogramModal({
      name: `${planogram.name}（コピー）`,
      bayPlanId: planogram.bay_plan_id,
      onSubmit: async ({ name, bayPlanId }) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          await clonePlanogram({
            name,
            originPlanogramId: planogram.id,
            bayPlanId,
            directoryParentId: parent_id,
          }).unwrap();
          dispatch(setSelectedItemIndex(0));
          dispatch(
            openToast({
              type: 'success',
              message: toastMessages.success.clonePlanogram,
            })
          );
          dispatch(closeModal());
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              type: 'error',
              message: toastMessages.error.clonePlanogram,
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  const { showModal: showMoveDirectoryModal } = useModal('moveDirectory', '');

  const handleMoveDirectory = (targetDirectory?: PlanogramDirectory) => {
    if (!targetDirectory) return;
    showMoveDirectoryModal({
      sidebarValue,
      targetDirectory,
      displayFolder: () => {
        handleClickSidebar('all'); // reset all conditions and moving to the all tab
      },
    });
  };

  usePageTitle('棚割計画一覧');

  const handleChangeSelectedBayPlanCodes = (bayPlanCodeId?: number[]) => {
    handleChangeFilter({
      isClear: !bayPlanCodeId?.length,
      conditionKey: 'bayPlanCodeId',
      conditionValue: bayPlanCodeId,
    });
  };

  const handleChangeSelectedOwnerId = (ownerId?: number[]) => {
    const isClear =
      condition?.ownerId?.at(0) === ownerId?.at(0) || !ownerId?.length;

    handleChangeFilter({
      isClear,
      conditionKey: 'ownerId',
      conditionValue: ownerId,
    });
  };

  const handleChangeSelectedOrganizationStatus = (
    organizationStatusId?: number[]
  ) => {
    const isClear =
      condition?.organizationStatusId?.at(0) === organizationStatusId?.at(0) ||
      !organizationStatusId?.length;
    handleChangeFilter({
      isClear,
      conditionKey: 'organizationStatusId',
      conditionValue: organizationStatusId,
    });
  };

  const handleChangeFilter = (data: {
    isClear: boolean;
    conditionKey: string;
    conditionValue?: number[];
  }) => {
    clearOffset();
    const { isClear, conditionKey, conditionValue } = data;
    if (isClear) {
      const newCondition = { ...condition, [conditionKey]: undefined };
      if (isClearAllFilter(newCondition)) {
        clearFilters();
      } else {
        setCondition(newCondition);
      }
    } else {
      setCondition({
        ...condition,
        [conditionKey]: conditionValue,
      });
    }
  };

  const clearFilters = () => {
    clearOffset();
    setCondition({
      ...condition,
      ownerId: undefined,
      bayPlanCodeId: undefined,
      organizationStatusId: undefined,
    });
  };

  const { showModal: showRenamePlanogramModal } =
    useModal<EditPlanogramModalProps>(
      'editPlanogram',
      'ゴンドラ名・ゴンドラ番号変更'
    );

  const handleUpdatePlanogramName = (
    planogramDirectory?: PlanogramDirectory
  ) => {
    if (!planogramDirectory?.planogram) return;
    const { planogram } = planogramDirectory;
    showRenamePlanogramModal({
      name: planogram.name,
      bayPlanCodeId: planogram.bay_plan_code_id,
      onSubmit: async ({ name, bayPlanCodeId }) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          await putPlanogram({
            id: planogram.id,
            body: {
              name,
              bay_plan_id: planogram.bay_plan_id,
              bay_plan_code_id: bayPlanCodeId,
            },
          }).unwrap();

          // NOTE: planogramが持っている棚割名は更新されるが、ディレクトリが持っている棚割名は更新されないため、フロントで意図的にディレクトリの更新を実行している。
          // TODO: 本来planogramが持つ棚割名が更新されたら、ディレクトリが持つ棚割名も更新される必要があるため、バックエンドに相談する
          await updateDirectory({
            name,
            id: planogramDirectory.id,
          }).unwrap();
          dispatch(
            openToast({
              type: 'success',
              message: toastMessages.success.updatePlanogramName,
            })
          );
          dispatch(closeModal());
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              message: toastMessages.error.updatePlanogramName,
              type: 'error',
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };
  // these 3 fields only used to determine hightlight filter button or not
  const planogramConiditionFilters = {
    bayPlanCodeId: condition?.bayPlanCodeId,
    organizationStatusId: condition?.organizationStatusId,
    ownerId: condition?.ownerId,
  };
  const hasCondition = Object.values(planogramConiditionFilters).some((el) => {
    if (Array.isArray(el)) {
      return !!el.length;
    }
    return !!el;
  });
  const isClearAllFilter = (
    newCondition: GetSearchPlanogramDirectoriesParams
  ) => {
    return (
      !newCondition?.ownerId?.length &&
      !newCondition?.organizationStatusId?.length &&
      !newCondition?.bayPlanCodeId?.length
    );
  };

  const shouldChangeColor = hasCondition && !isShowFilters;
  const filterButtonTextColor = shouldChangeColor
    ? theme.palette.primary
    : theme.palette.textBlack.primary;
  const filterButtonIconColor = shouldChangeColor
    ? theme.palette.primary
    : theme.palette.icons.primary;

  const { isEnable: isCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: directory?.parent,
    isPlanogram: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetPlanogramPermission({
    action: 'read',
    planogram: directory?.parent,
    isPlanogram: false,
    isCan: true,
  });

  const handleChangeOrder = (firstOrder?: ListOrder) => {
    clearOffset();
    setCondition({
      ...condition,
      firstOrder: firstOrder as PlanogramListOrder,
    });
  };

  const [markFavoritePlanogram] = useMarkPlanogramDirectoryFavoriteMutation();
  const [unmarkFavoritePlanogram] =
    useUnmarkPlanogramDirectoryFavoriteMutation();

  const handleFavoriteClick = async (id: string, isFavorite: boolean) => {
    clearOffset();
    try {
      if (isFavorite) {
        await unmarkFavoritePlanogram({ directoryId: id }).unwrap();
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.removeStar,
          })
        );
      } else {
        await markFavoritePlanogram({ directoryId: id }).unwrap();
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.addStar,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchText = (name: string) => {
    clearOffset();
    if (name === '') return;
    dispatch(setSelectedDirectoryId(''));
    const firstOrder = defaultFirstOrderBySideBar['all'];
    setCondition(
      {
        ...condition,
        firstOrder,
        name,
      },
      { isToRootDirectory: true }
    );
  };

  const clearSearchText = () => {
    clearOffset();
    setIsClearSearch(true);
    setCondition(
      {
        ...condition,
        name: undefined,
      },
      { isToRootDirectory: true }
    );
  };

  const setConditionBeforeChangeSidebar = (value: SidebarValue) => {
    clearOffset();
    setIsClearSearch(true);
    const firstOrder = defaultFirstOrderBySideBar[value];
    const newCondition = {
      ...condition,
      firstOrder,
      name: undefined,
      bayPlanCodeId: undefined,
      ownerId: undefined,
      organizationStatusId: undefined,
    };
    return newCondition;
  };

  const handleClickSidebar = (value: SidebarValue) => {
    if (value === sidebarValue) return;
    const newCondition = setConditionBeforeChangeSidebar(value);
    dispatch(setSelectedDirectoryId(''));
    setIsShowFilters(false);
    setCondition(newCondition, { sideBarValue: value });
  };

  // Reset all conditions and moving to the new created directory
  const goToCreatedDirectory = (newDirectoryId: string) => {
    const newCondition = setConditionBeforeChangeSidebar(sidebarValue);
    dispatch(setSelectedDirectoryId(newDirectoryId));
    setCondition(newCondition, { directoryId: newDirectoryId });
  };

  const afterCreateDirectorySuccessfully = (newDirectoryId: string) => {
    dispatch(
      openToast({
        type: 'success',
        message: toastMessages.success.createDirectory,
        renderProps: (
          <Typography
            variant="buttonText2"
            color={theme.palette.primary.main}
            mx="12px"
            onClick={() => {
              if (newDirectoryId) {
                goToCreatedDirectory(newDirectoryId);
              }
            }}
            sx={{ cursor: 'pointer' }}
          >
            フォルダを開く
          </Typography>
        ),
      })
    );
  };

  const { handleCreateDirectory, handleUpdateDirectoryName } =
    useDirectoryManagement({
      afterCreateDirectorySuccessfully,
    });

  const handleEndReached = () => {
    if (isLoadingData || isFetchingData) return;
    if (
      isSkipSearchPlanogramDirectoriesQuery &&
      directoryOffset + rowsPerPage < (directory?.pager.total || 0)
    ) {
      setDirectoryOffset(directoryOffset + rowsPerPage);
      return;
    }

    if (searchedOffset + rowsPerPage < (searchedData?.pager.total || 0)) {
      setSearchedOffset(searchedOffset + rowsPerPage);
    }
  };

  const { showLicenseModal } = useSharePermissionModal();

  // Get new parent & list data from API: getDictionaries
  const refetchDirectoriesAfterOpenLicenseModal = useCallback(async () => {
    const results = await refetchDirectories().unwrap();
    dispatch(setPlanogramDirectoryParent(results.parent));
  }, [dispatch, refetchDirectories]);

  const handleSharePermission = async (item: PlanogramSharePermissionProps) => {
    if (!item.planogramDirectory.id) return;
    dispatch(setDirectoryId(`${item.planogramDirectory.id}`));
    dispatch(setDirectoryType('planogram'));
    dispatch(setCurrentTypeFile(item.planogramDirectory.type));
    showLicenseModal({ type: item.type });

    if (isSkipListPlanogramDirectoriesQuery) {
      // Get new parent & list data from API: getDictionaries & searchDictionaries
      setIsForceGetPlanogramDirectories(true);
    } else {
      await refetchDirectoriesAfterOpenLicenseModal();
    }
  };

  useEffect(() => {
    // Get new parent & list data from API: getDictionaries & searchDictionaries
    // Run after opening license modal
    const getNewParentAndListData = async () => {
      if (
        isSkipListPlanogramDirectoriesQuery &&
        isForceGetPlanogramDirectories
      ) {
        const promises = [
          refetchDirectoriesAfterOpenLicenseModal(),
          refetchSearchDirectories().unwrap(),
        ];
        await Promise.all(promises);
        setIsForceGetPlanogramDirectories(false);
      }
    };

    void getNewParentAndListData();
  }, [
    dispatch,
    isForceGetPlanogramDirectories,
    isSkipListPlanogramDirectoriesQuery,
    refetchDirectories,
    refetchDirectoriesAfterOpenLicenseModal,
    refetchSearchDirectories,
  ]);

  return (
    <>
      <Box component="div" sx={{ overflowY: 'hidden' }}>
        <AppBar title="棚割計画（棚エディタ）" />
        <Box component="div" display="flex">
          <PlonogramsSidebar
            handleCreatePlanogram={handleCreatePlanogram}
            handleCreateDirectory={handleCreateDirectory}
            sidebarValue={sidebarValue}
            onClickTabAll={() => handleClickSidebar('all')}
            onClickTabFavorite={() => handleClickSidebar('favorite')}
            onClickTabViewed={() => handleClickSidebar('viewed')}
          />
          <Box component="div" mt={2} px={3} flex={1}>
            {/*56px = 40 AppBar + 16px margin*/}
            <Box
              component="div"
              height="calc(100vh - 56px)"
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <Box component="div">
                <Box
                  width="100%"
                  component="div"
                  mb={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SearchText
                    searchText={condition?.name}
                    isClearSearch={isClearSearch}
                    setIsClearSearch={setIsClearSearch}
                    handleSearch={handleSearchText}
                    clearSearch={clearSearchText}
                  />
                  <ShelvesViewToggle
                    mode={viewMode}
                    onChange={(mode) => {
                      setViewMode(mode);
                    }}
                  />
                </Box>
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {isShowBreadcrumbList ? (
                    <Box component="div" flex={1}>
                      {!isRoot && (
                        <Box component="div" marginLeft={5} mb={0.5}>
                          <BreadcrumbList
                            breadcrumbs={directory.breadcrumb}
                            parentDirectory={directory.parent}
                            screenType="Main"
                            fontSize="12px"
                            handleClickBreadcrumbs={handleClickBreadcrumbs}
                            directoryRootName="棚割計画"
                          />
                        </Box>
                      )}
                      <Box component="div" display="flex" alignItems="center">
                        {!isRoot && (
                          <GoParentDirectoryButton
                            width={32}
                            height={32}
                            handleClick={() => {
                              handleClickMoveParentDirectory(
                                directory.parent?.parent_id
                              );
                            }}
                          />
                        )}
                        <Typography
                          fontSize="20px"
                          fontWeight="bold"
                          display="flex"
                          alignItems="center"
                          mr={!isRoot ? '6px' : ''}
                        >
                          {getDirectoryPageTitle(
                            sidebarValue,
                            isRoot,
                            directory.parent.name
                          )}
                          {/* TODO: フォルダを作成したユーザーのみ編集を可能にすること */}
                          {/* NOTE: フォルダ名変更するためのユーザー情報が取得できないため、誰もフォルダ名の変更を許可しません。そのためペンアイコンは非表示とします。 */}
                          {/* {directory.parent.type === 'directory' && (
                              <IconButton
                                component="button"
                                onClick={() =>
                                  handleUpdateDirectoryName(directory.parent)
                                }
                              >
                                <CreateIcon fontSize="small" />
                              </IconButton>
                            )} */}
                        </Typography>
                        {!isRoot && (
                          <ActionVisible
                            isCanUpdate={isCanUpdate}
                            isCanRead={isCanRead}
                          />
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Typography fontSize="20px" fontWeight="bold">
                      {getListPageTitle({
                        defaultTitle: pageTitle.planogram,
                        sidebarValue,
                        isSearched: !!condition?.name,
                      })}
                    </Typography>
                  )}
                  <Box
                    component="div"
                    display="flex"
                    gap={1}
                    justifyContent="space-between"
                  >
                    <FilterButton
                      onClick={handleShowFilters}
                      textColor={filterButtonTextColor}
                      iconColor={filterButtonIconColor}
                    />
                  </Box>
                </Box>
              </Box>

              {isShowFilters && (
                <Box component="div" mt={1}>
                  <PlanogramsConditions
                    bayPlanCodes={bayPlanCodes}
                    me={me}
                    users={users?.filter((user) => user.id !== me?.id)}
                    organizationStatuses={
                      organizationStatuses?.planogram_organization_statuses
                    }
                    selectedBayPlanCodes={condition?.bayPlanCodeId ?? []}
                    handleChangeSelectedBayPlanCodes={
                      handleChangeSelectedBayPlanCodes
                    }
                    selectedOwnerId={condition?.ownerId?.toString() ?? ''}
                    handleChangeSelectedOwnerId={handleChangeSelectedOwnerId}
                    selectedOrganizationStatus={
                      condition?.organizationStatusId?.toString() ?? ''
                    }
                    handleChangeSelectedOrganizationStatus={
                      handleChangeSelectedOrganizationStatus
                    }
                    clearConditions={clearFilters}
                  />
                </Box>
              )}
              {viewMode === 'grid' && (
                <GridModeSort
                  sortOptions={getSortOptions(
                    'planogram',
                    sidebarValue === 'viewed'
                  )}
                  isLoadingData={isFetchingData || isLoadingData}
                  firstOrder={firstOrder}
                  handleChangeOrder={handleChangeOrder}
                />
              )}
              <Box component="div" mb={2} mt={1} height="100%">
                {viewMode === 'table' ? (
                  <PlanogramsTable
                    parent={directory?.parent}
                    handleRowClick={handleRowClick}
                    planogramDirectories={planogramDirectories}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleClonePlanogram={handleClonePlanogram}
                    handleUpdatePlanogramName={handleUpdatePlanogramName}
                    handleUpdateDirectoryName={handleUpdateDirectoryName}
                    handleMoveDirectory={handleMoveDirectory}
                    isLoading={isLoadingData}
                    isRefetching={isFetchingData}
                    offset={offset}
                    handleFavoriteClick={handleFavoriteClick}
                    tableWidth={tableWidth}
                    isFilteredByViewed={isFilteredByViewed}
                    firstOrder={firstOrder}
                    handleChangeOrder={handleChangeOrder}
                    handleSharePermission={handleSharePermission}
                    handleEndReached={handleEndReached}
                  />
                ) : (
                  <PlanogramsGrid
                    parent={directory?.parent}
                    planogramDirectories={planogramDirectories}
                    handleCardClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleClonePlanogram={handleClonePlanogram}
                    handleUpdatePlanogramName={handleUpdatePlanogramName}
                    handleUpdateDirectoryName={handleUpdateDirectoryName}
                    handleMoveDirectory={handleMoveDirectory}
                    isLoading={isLoadingData}
                    isRefetching={isFetchingData}
                    offset={offset}
                    isFilteredByViewed={isFilteredByViewed}
                    handleFavoriteClick={handleFavoriteClick}
                    handleSharePermission={handleSharePermission}
                    handleEndReached={handleEndReached}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <PlanogramDeleteDialog
        open={deleteDialogOpen}
        directoryType={selectPlanogramDirectory?.type}
        handleCloseDialog={() => setDeleteDialogOpen(false)}
        handleDeleteShelfEditorItem={handleDeleteShelfEditorItem}
      />
    </>
  );
};
