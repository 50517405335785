import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { userRoleTags, userStatusTags } from '@utils/const';
import { FC } from 'react';
import { array, string } from 'yup';

type UsersConditionsProps = {
  selectedUserRoleTags: string[];
  selectedUserStatusTags: string[];
  handleChangeSelectedUserStatusTags: (name: string[]) => void;
  handleChangeSelectedUserRoleTags: (name: string[]) => void;
  clearConditions: () => void;
};

export const UserConditions: FC<UsersConditionsProps> = ({
  selectedUserRoleTags,
  selectedUserStatusTags,
  handleChangeSelectedUserStatusTags,
  handleChangeSelectedUserRoleTags,
  clearConditions,
}) => {
  return (
    <Box component="div" display="flex" gap={2}>
      <FormControl sx={{ flex: 1, minWidth: 200 }}>
        <InputLabel size="small">アカウント権限</InputLabel>
        <Select
          size="small"
          label="アカウント権限"
          multiple
          value={selectedUserRoleTags}
          onChange={({ target: { value } }) => {
            handleChangeSelectedUserRoleTags(
              array(string().required()).validateSync(value) ?? []
            );
          }}
          renderValue={(item) =>
            item.length === 1
              ? userRoleTags.find((userRoleTag) => userRoleTag.key === item[0])
                  ?.value
              : `${item.length}件選択中`
          }
        >
          <MenuItem
            divider
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            <Button
              disabled={userRoleTags.length === selectedUserRoleTags.length}
              onClick={() =>
                handleChangeSelectedUserRoleTags(
                  userRoleTags.map((userRoleTag) => userRoleTag.key)
                )
              }
            >
              すべて選択
            </Button>
            <Button
              disabled={selectedUserRoleTags?.length === 0}
              onClick={() => handleChangeSelectedUserRoleTags([])}
            >
              すべて解除
            </Button>
          </MenuItem>
          {userRoleTags.map((userRoleTag) => {
            const checked = selectedUserRoleTags.includes(userRoleTag.key);
            return (
              <MenuItem divider key={userRoleTag.key} value={userRoleTag.key}>
                <Checkbox checked={checked} />
                {userRoleTag.value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ flex: 1, minWidth: 160 }}>
        <InputLabel size="small">サービス利用</InputLabel>
        <Select
          size="small"
          label="サービス利用"
          multiple
          value={selectedUserStatusTags}
          onChange={({ target: { value } }) => {
            handleChangeSelectedUserStatusTags(
              array(string().required()).validateSync(value) ?? []
            );
          }}
          renderValue={(item) =>
            item.length === 1
              ? userStatusTags.find(
                  (userStatusTag) => userStatusTag.key === item[0]
                )?.value
              : `${item.length}件選択中`
          }
        >
          <MenuItem
            divider
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            <Button
              disabled={userStatusTags.length === selectedUserStatusTags.length}
              onClick={() =>
                handleChangeSelectedUserStatusTags(
                  userStatusTags.map((userStatusTag) => userStatusTag.key)
                )
              }
            >
              すべて選択
            </Button>
            <Button
              disabled={selectedUserStatusTags?.length === 0}
              onClick={() => handleChangeSelectedUserStatusTags([])}
            >
              すべて解除
            </Button>
          </MenuItem>
          {userStatusTags.map((userStatusTag) => {
            const checked = selectedUserStatusTags.includes(userStatusTag.key);
            return (
              <MenuItem
                divider
                key={userStatusTag.key}
                value={userStatusTag.key}
              >
                <Checkbox checked={checked} />
                {userStatusTag.value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        disabled={
          !selectedUserStatusTags.length && !selectedUserRoleTags.length
        }
        onClick={clearConditions}
      >
        すべて解除
      </Button>
    </Box>
  );
};
