import { ProductsBay } from '@components/organisms/productsBay/productsBay';
import { Box } from '@mui/material';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isPlanogramPtsDetail } from '@utils/planogram';
import { PlanogramPlan } from 'types/planogram';

type Props = {
  zoomScale: number;
  plan: PlanogramPlan;
};

const zoomScaleMagnifier = 1.5;

export const TwoDPlanogram: FC<Props> = ({ zoomScale, plan }) => {
  if (isPlanogramPtsDetail(plan)) {
    console.error('unsupported planogram type');
    return <></>;
  }

  return (
    //NOTE: DnD判定を有効にするためにDndProviderでwrapする必要がある
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <Box
        component="div"
        sx={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="div"
          sx={{
            pb: 6,
            willChange: 'transform',
            transform: `scale(${zoomScale * zoomScaleMagnifier})`,
            transformOrigin: 'top center ',
          }}
        >
          <ProductsBay plan={plan} />
        </Box>
      </Box>
    </DndProvider>
  );
};
