import { FC } from 'react';
import { theme } from 'theme';
import { SyncAltOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { InferType } from 'yup';
import { schema } from '../realogram.schema';
import { FlatImage } from './flatImage';
import { NoFlatImage } from './noFlatimage';

type Props = {
  file?: File;
  secondFile?: File;
  onSecondFileSelect: (file: File) => void;
  onFirstFileSelect: (file: File) => void;
  onSwapFile: (file: File | undefined, secondFile: File | undefined) => void;
};

export type RealogramFormData = InferType<typeof schema>;

export const FlatRealogramImages: FC<Props> = ({
  file,
  secondFile,
  onSecondFileSelect,
  onFirstFileSelect,
  onSwapFile,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      gap={{ xs: 0, sm: 0, md: 1 }}
      pt="20px"
      pb={2}
      sx={{
        flex: 1,
        backgroundColor: theme.palette.backgroundBlack.black,
      }}
    >
      <Box
        component="div"
        position="relative"
        display="flex"
        flexDirection="row"
        textAlign="center"
        justifyContent="space-between"
        gap={1}
      >
        {file ? (
          <FlatImage file={file} onFirstFileSelect={onFirstFileSelect} isLeft />
        ) : (
          <NoFlatImage onFirstFileSelect={onFirstFileSelect} isLeft />
        )}
      </Box>

      <IconButton
        onClick={() => onSwapFile(file, secondFile)}
        sx={{
          gap: '10px',
          mb: 5,
        }}
      >
        <SyncAltOutlined
          sx={{
            color: theme.palette.textBlack.primary,
            backgroundColor: theme.palette.backgroundBlack.primary,
            borderRadius: 1,
            height: '40px',
            width: '40px',
            border: `1px solid ${theme.palette.dividerBlack.dark}`,
            p: 1,
            boxShadow: 1,
            '&:hover': {
              backgroundColor: theme.palette.backgroundBlack.primaryHover,
            },
          }}
        />
      </IconButton>
      <Box
        component="div"
        position="relative"
        display="flex"
        flexDirection="row"
        textAlign="center"
        justifyContent="space-between"
      >
        {secondFile ? (
          <FlatImage
            file={secondFile}
            onFirstFileSelect={onFirstFileSelect}
            onSecondFileSelect={onSecondFileSelect}
            isLeft={false}
          />
        ) : (
          <NoFlatImage
            onFirstFileSelect={onFirstFileSelect}
            onSecondFileSelect={onSecondFileSelect}
            isLeft={false}
          />
        )}
      </Box>
    </Box>
  );
};
