import {
  ComparisonBayHeader,
  HeaderStyleDirection,
} from '@components/molecules/comparisionBayHeader/comparisionBayHeader';
import { FaceAreaControllerBox } from '@components/molecules/faceAreaControllerBox/faceAreaControllerBox';
import { MoveBucketButtons } from '@components/molecules/moveBucketButtons/moveBucketButtons';
import { PlanogramInfoDialog } from '@components/molecules/planogramInfoDialog/planogramInfoDialog';
import { FlatPlanogram } from '@components/organisms/flatPlanogram/flatPlanogram';
import { StatisticsValueSelector } from '@components/organisms/statisticsValueSelector/statisticsValueSelector';
import { StyledToggleButtonGroup } from '@components/organisms/viewModeToggle/fragments';
import { PlanButtonGroup } from '@components/pages/planogramDetail/fragments/planButtonGroup';
import { useZoomController } from '@hooks/useZoomController';
import { Box, Button, ToggleButton } from '@mui/material';
import { changeEditorMode } from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import {
  EstimateForPlanogramResponse,
  useGetStoreQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { getDisplayValue, pointersDummy, getProfitsMenu } from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import { convertMeterToPixel, isPlanogramPtsDetail } from '@utils/planogram';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import {
  BboxColors,
  BucketMode,
  BucketPlanogramPlan,
  Planogram as PlanogramType,
} from 'types/planogram';
import { StoreArea } from 'types/products';
import { t } from 'i18next';

type Props = {
  planogram?: PlanogramType;
  flatPlan: BucketPlanogramPlan;
  handleOpenSearchModal?: () => void;
  detailView: ShelfDetailView;
  detailMode: ShelfDetailMode;
  diffColor: string;
  bboxColors: BboxColors;
  backgroundColor: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  handleClickAwayPlanogram: () => void;
  handleChangeBucketMode: (value: BucketMode) => void;
  createdAt: string;
  isEditor: boolean;
  plan: BucketPlanogramPlan;
  isCompared?: boolean;
  handleRecalculate: () => void;
  planogramEstimatedData?: EstimateForPlanogramResponse;
  handleChangeProfit: (value: string) => void;
  storeAreaType: StoreArea;
  profit: string;
  headerStyleDirection?: HeaderStyleDirection;
  targetCompareText?: string;
  isLoadingEstimate?: boolean;
  handleChangeFront?: () => void;
  isTenantSalesAnalytics?: boolean;
};

const zoomButtonPosition = 40;
const reserveValue = 50;

export const FlatPlanogramComparison: FC<Props> = ({
  planogram,
  flatPlan,
  handleOpenSearchModal,
  detailView,
  detailMode,
  diffColor,
  bboxColors,
  backgroundColor,
  onClickNext,
  onClickPrev,
  createdAt,
  isEditor = true,
  plan,
  handleClickAwayPlanogram,
  handleChangeBucketMode,
  isCompared = false,
  handleRecalculate,
  planogramEstimatedData,
  handleChangeProfit,
  storeAreaType,
  profit,
  headerStyleDirection,
  targetCompareText,
  isLoadingEstimate,
  handleChangeFront,
  isTenantSalesAnalytics = false,
}) => {
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const {
    zoomScale,
    handleIncrementZoom,
    handleDecrementZoom,
    handleTriggerZoom,
  } = useZoomController();

  const { name } = planogram ?? {};
  const dispatch = useAppDispatch();
  const { content_min: contentMin, content_max: contentMax } =
    flatPlan.frame.detail;
  const {
    bucketMode,
    selectedBucketId,
    selectedBucketIdClone,
    isSwappingBayPartMode,
  } = useAppSelector(selectPlanogramEditorState);
  const contentX = contentMax.x - contentMin.x;
  const ref = useRef<HTMLDivElement>();
  const [positionLeft, setPositionLeft] = useState(0);
  const [isOverflown, setIsOverflown] = useState(false);

  const handleResize = useCallback(() => {
    if (ref.current?.getBoundingClientRect()) {
      const containerRect = ref.current.getBoundingClientRect();
      setPositionLeft(containerRect.left);
      //compare with a reserve
      if (containerRect.width - reserveValue < convertMeterToPixel(contentX)) {
        setIsOverflown(true);
      } else {
        setIsOverflown(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- need to add zoomScale for recalculation
  }, [setPositionLeft, contentX, zoomScale]);

  const { data: storeBayPlan } = useGetStoreQuery(
    {
      storeId: planogram?.store_id ?? 0,
    },
    {
      skip: !planogram?.store_id,
    }
  );

  useEffect(() => {
    if (!ref?.current) return;
    if (!positionLeft && ref.current?.getBoundingClientRect()) {
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, positionLeft]);

  if (!planogram?.plan || isPlanogramPtsDetail(planogram.plan)) {
    console.error('unsupported planogram type');
    return <></>;
  }

  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};

  const term = getTextDateStatistic(startDateFromAPI, endDateFromAPI);

  return (
    <>
      {/*if wrap PlanButtonGroup in position:relative parent,*/}
      {/*Planogram won't be scrollable, because we have to put zIndex to PlanButtonGroup to make it clickable*/}
      {!isSwappingBayPartMode && (
        <Box
          component="div"
          position="absolute"
          top={zoomButtonPosition}
          zIndex={5}
          sx={{ transform: `translate(${positionLeft}px, 38px)` }}
        >
          <PlanButtonGroup
            bboxEnabled={bboxEnabled}
            handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
            isOrientationModalOpen={false}
            isEditor={isEditor}
            zoomScale={zoomScale}
            handleIncrementZoom={handleIncrementZoom}
            handleDecrementZoom={handleDecrementZoom}
            handleTriggerZoom={handleTriggerZoom}
            handleChangeView={() => dispatch(changeEditorMode('preview'))}
            isFlatPlanogram
            isCompared
          />
        </Box>
      )}

      <Box
        ref={ref}
        component="div"
        flex={1}
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="auto"
      >
        <ComparisonBayHeader
          headerStyleDirection={headerStyleDirection}
          name={name ?? ''}
          bayName={storeBayPlan?.store?.name ?? '-'}
          targetCompareText={targetCompareText}
          setOpenInfoDialog={() => setOpenInfoDialog(true)}
          isComparisonSource
          bayHeaderActions={
            handleOpenSearchModal && (
              <Button
                variant="outlined"
                onClick={handleOpenSearchModal}
                sx={{
                  height: '28px',
                  p: '0',
                  minWidth: '28px',
                  fontSize: '12px',
                  width: '40px',
                }}
              >
                変更
              </Button>
            )
          }
        >
          {planogram?.store_id && isTenantSalesAnalytics && (
            <StatisticsValueSelector
              value={getDisplayValue(
                profit,
                planogramEstimatedData?.estimate.summary,
                t('gross_profit')
              )}
              profits={getProfitsMenu(t('gross_profit'))}
              pointers={pointersDummy}
              selectedProfitType={profit}
              selectedPointerType={storeAreaType}
              handleChangeProfitValue={handleChangeProfit}
              handleRefreshValue={handleRecalculate}
              hasRefreshButton={!isCompared}
              category="シミュレーション"
              term={term}
              disabled={isSwappingBayPartMode}
              isLoading={isLoadingEstimate}
            />
          )}
        </ComparisonBayHeader>

        <Box
          //NOTE: for overflow. do not add property except overflow
          component="div"
          flex={1}
          overflow="auto"
          sx={{
            backgroundColor,
          }}
        >
          <Box
            component="div"
            minHeight="100%"
            display="flex"
            alignItems="center"
            justifyContent={isOverflown ? 'flex-start' : 'center'}
            sx={{
              transform: `scale(${zoomScale})`,
              transformOrigin: `top left`,
              pointerEvents: 'all',
              py: 3,
            }}
            onClick={handleClickAwayPlanogram}
          >
            <FlatPlanogram
              plan={isEditor ? plan : flatPlan}
              detailMode={detailMode}
              detailView={detailView}
              zoomScale={zoomScale}
              setZoomScale={() => void 0}
              diffColor={diffColor}
              bboxColors={bboxColors}
              bboxEnabled={bboxEnabled}
              isEditor={isEditor}
              isCompared={isCompared}
              handleChangeFront={handleChangeFront}
            />
          </Box>

          {(selectedBucketId?.toString() ||
            (selectedBucketId === undefined &&
              bucketMode === 'area' &&
              selectedBucketIdClone !== undefined)) &&
            isEditor &&
            !isSwappingBayPartMode && (
              <Box
                component="div"
                position="absolute"
                bottom="70px"
                px={3}
                display="flex"
                sx={{
                  '@media (max-width: 1600px)': {
                    width: '41.5%',
                  },
                  width: '43.5%',
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <MoveBucketButtons
                  handleNavigateBefore={() => console.log('before')}
                  handleNavigateNext={() => console.log('next')}
                />
                <StyledToggleButtonGroup
                  orientation="horizontal"
                  exclusive
                  value={bucketMode}
                  sx={{ background: theme.palette.white.primary }}
                  onChange={(_, value: BucketMode) =>
                    handleChangeBucketMode(value)
                  }
                >
                  <ToggleButton value="compartment">商品</ToggleButton>
                  <ToggleButton value="area">エリア</ToggleButton>
                </StyledToggleButtonGroup>
              </Box>
            )}
        </Box>

        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={0.25}
          px={2}
          height={40}
          sx={{
            backgroundColor: theme.palette.white.primary,
          }}
        >
          <FaceAreaControllerBox
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            createdAt={createdAt}
          />
        </Box>
      </Box>
      <PlanogramInfoDialog
        planogram={planogram}
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
      />
    </>
  );
};
