// TODO: 階層構造完成後、削除する
import { LoadMoreTableFooter } from '@components/molecules';
import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { GramTableFoot } from '@components/organisms';
import { GramTableFooter } from '@components/organisms/gramTableFooter/gramTableFooter';
import { TableCells } from '@components/organisms/realogramsTable/fragments/tableCells';
import { columns } from '@components/organisms/realogramsTable/fragments/tableHeaderData';
import { TableHeader } from '@components/organisms/tableHeader/tableHeader';
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@mui/material';
import { useAppSelector } from '@store/index';
import { CommonColors } from '@utils/colors';
import { rowHeight, rowsPerPage } from '@utils/const';
import { FC, useRef } from 'react';
import { TableVirtuoso, VirtuosoHandle } from 'react-virtuoso';
import { Status } from 'types/common';
import { RealogramCandidate } from 'types/realogram';

type Props = {
  isLoading: boolean;
  realogramCandidates?: RealogramCandidate[];
  handleEndReached: (index: number) => void;
  handleRowClick: (id: number, status: Status, index: number) => void;
  handleOpenDeleteDialog: (id: number) => void;
  tableHeight: string;
  total: number;
};

export const RealogramsTable: FC<Props> = ({
  isLoading,
  realogramCandidates,
  handleEndReached,
  handleRowClick,
  handleOpenDeleteDialog,
  tableHeight,
  total,
}) => {
  const ref = useRef<VirtuosoHandle>(null);
  const { selectedItemIndex } = useAppSelector((state) => state.Scanner);

  const getRowSx = (status?: Status) => {
    if (status !== 'processed') {
      return {
        color: CommonColors.grayLight,
        backgroundColor: CommonColors.light,
      };
    }
    return {
      ':hover': {
        backgroundColor: CommonColors.lightGray,
      },
    };
  };

  const isRealogramDataEmpty = !realogramCandidates?.length;

  const getFooterContent = (
    isLoading: boolean,
    total: number,
    handleEndReached: (index: number) => void,
    realogramCandidates?: RealogramCandidate[]
  ) => {
    if (isLoading) return <GramTableFooter columns={columns} />;
    if (
      !isLoading &&
      realogramCandidates &&
      total !== realogramCandidates.length
    )
      return (
        <LoadMoreTableFooter
          columns={columns}
          handleClick={() => handleEndReached(realogramCandidates.length + 1)}
        />
      );
    return <></>;
  };

  return (
    <TableVirtuoso
      ref={ref}
      style={{
        height: tableHeight,
        border: '1px solid #CCCCCC',
      }}
      data={
        realogramCandidates?.length
          ? realogramCandidates
          : [...(Array(rowsPerPage) as RealogramCandidate[])]
      }
      initialTopMostItemIndex={selectedItemIndex}
      endReached={(index) => handleEndReached(index)}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        Table: (props: TableProps) => (
          <Table
            {...props}
            style={{
              width: '100%',
              height: '100%',
              tableLayout: 'fixed',
              borderCollapse: 'separate',
            }}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableHead,
        // Note: TableRowに一行に対するスタイルや動作を設定し、itemContentに行内の各カラムに対するスタイルや動作設定する。
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableRow: (props: {
          item: RealogramCandidate;
          // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the props's specification
          'data-index': number;
        }) => {
          const status = props?.item?.status;
          const id = props?.item?.id;
          const index = props['data-index'];
          return (
            <>
              {props?.item ? (
                <TableRow
                  sx={{ height: rowHeight, ...getRowSx(status) }}
                  onClick={() => {
                    if (status !== 'processed') return;
                    handleRowClick(id, status, index);
                  }}
                  {...props}
                />
              ) : (
                <TableRow {...props}>
                  {columns.map((_, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton animation="wave" width="75%" />
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
            </>
          );
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableBody:
          isRealogramDataEmpty && !isLoading
            ? () => (
                <Box
                  component="div"
                  sx={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    width: 'calc(100vw - 50px)', // padding 48px, border 2px
                    height: '100%',
                  }}
                >
                  <EmptyResult title="スキャン結果がありません" isErrorIcon />
                </Box>
              )
            : TableBody,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        ScrollSeekPlaceholder: () => (
          <TableRow>
            {columns.map((_, index) => {
              return (
                <TableCell key={index}>
                  <Skeleton animation="wave" width="75%" />
                </TableCell>
              );
            })}
          </TableRow>
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableFoot: GramTableFoot,
      }}
      fixedHeaderContent={() => <TableHeader columns={columns} />}
      fixedFooterContent={() =>
        isRealogramDataEmpty && !isLoading ? (
          <></>
        ) : (
          getFooterContent(
            isLoading,
            total,
            handleEndReached,
            realogramCandidates
          )
        )
      }
      itemContent={(_, realogram) => (
        <TableCells
          realogram={realogram}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
        />
      )}
    />
  );
};
