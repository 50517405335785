import { ProductImage } from '@components/organisms/productImage/productImage';
import { Box, Divider, Typography } from '@mui/material';
import {
  getProductsLayout,
  isPlanogramBucketPlan,
  isPlanogramShelvesDetail,
} from '@utils/planogram';
import { FC } from 'react';
import { Product, ShelfDetailView } from 'types/common';
import {
  CompartmentBucketPosition,
  Planogram,
  PlanogramProductCompartment,
} from 'types/planogram';
import {
  RealogramProductCompartment,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { ComparisonProductOptional } from '../../../../molecules/comparisonProductOptional/comparisonProductOptional';

type Props = {
  product?: Product;
  view: ShelfDetailView;
  positions?: CompartmentBucketPosition[][];
  comparisonPositions?: CompartmentBucketPosition[][];
  comparisonPlanogram?: Planogram;
  comparisonRealogram?: RealogramShelfBoard[];
  selectedRealogramItem?: RealogramSelectedItem;
};

const countFacesInRow = (
  row: PlanogramProductCompartment[],
  productId?: number
) =>
  row.reduce(
    (rowCount, x) =>
      rowCount + (x.product_id === productId ? x?.face_count ?? 0 : 0),
    0
  );

const countFacesInCompartment = (
  compartment: RealogramProductCompartment,
  productId?: number
) =>
  compartment.faces.reduce(
    (faceCount, face) =>
      faceCount +
      (!face.is_unknown && face.primary_candidate?.product_id === productId
        ? 1
        : 0),
    0
  );

const countFacesInCompartments = (
  compartments: RealogramProductCompartment[],
  productId?: number
) =>
  compartments.reduce(
    (compartmentCount, x) =>
      compartmentCount + countFacesInCompartment(x, productId),
    0
  );

export const FlatPlanogramComparisonProduct: FC<Props> = ({
  product,
  view,
  positions,
  comparisonPositions,
  comparisonPlanogram,
  comparisonRealogram,
  selectedRealogramItem,
}) => {
  const isUnknown = selectedRealogramItem?.item.is_unknown;
  const notInStock = !selectedRealogramItem?.item.in_stock;
  const noPrimaryCandidate =
    selectedRealogramItem?.item.primary_candidate === null;
  const isFaceCountHidden = isUnknown || (notInStock && noPrimaryCandidate);

  const flatPlanogramFaceCount = positions?.reduce(
    (count, y) => {
      return (
        count +
        y.reduce((rowCount, x) => {
          return x.compartment.product_id === product?.id
            ? rowCount + (x.compartment?.face_count ?? 0)
            : rowCount;
        }, 0) // x軸計算時の初期値
      );
    },
    0 // y軸計算時の初期値
  );

  const comparisonFaceCount = () => {
    const productId = product?.id;
    if (comparisonPlanogram) {
      if (isPlanogramBucketPlan(comparisonPlanogram.plan)) {
        return (
          comparisonPositions?.reduce(
            (count, y) => {
              return (
                count +
                y.reduce((rowCount, x) => {
                  return x.compartment.product_id === product?.id
                    ? rowCount + (x.compartment?.face_count ?? 0)
                    : rowCount;
                }, 0) // x軸計算時の初期値
              );
            },
            0 // y軸計算時の初期値
          ) ?? 0
        );
      }
      if (isPlanogramShelvesDetail(comparisonPlanogram.plan)) {
        return getProductsLayout(comparisonPlanogram.plan).reduce(
          (count, y) => count + countFacesInRow(y.row, productId),
          0
        );
      }
    }

    if (comparisonRealogram) {
      return (
        comparisonRealogram.reduce(
          (count, y) =>
            count + countFacesInCompartments(y.compartments, productId),
          0
        ) ?? 0
      );
    }

    return '';
  };

  return (
    //should be common with ScannerDetails comparisonProduct
    <Box component="div">
      {product && (
        <Box
          component="div"
          display="flex"
          my={2}
          position="relative"
          minHeight={100}
          maxWidth={254}
          justifyContent="center"
          alignItems="center"
        >
          <ProductImage
            product={product}
            layout="fill"
            objectFit="contain"
            baseSize={254}
            fontSize={24}
          />
        </Box>
      )}
      <Typography variant="subtitle2" my={2}>
        {product?.name ?? ''}
      </Typography>
      {product && (
        <>
          {!isFaceCountHidden && <Divider sx={{ my: 2, mx: -2 }} />}
          <ComparisonProductOptional
            product={product}
            view={view}
            faceCount={flatPlanogramFaceCount ?? 0}
            comparisonFaceCount={comparisonFaceCount()}
            isFaceCountHidden={isFaceCountHidden}
          />
        </>
      )}
    </Box>
  );
};
