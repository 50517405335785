import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  onClickAction: () => void;
  icon: JSX.Element;
  children: React.ReactNode;
};

export const PrimaryNavigationButton: FC<Props> = ({
  onClickAction,
  icon,
  children,
}) => {
  return (
    <Button
      onClick={onClickAction}
      variant="outlined"
      sx={{
        width: 280,
        mb: 2,
        padding: 0,
        backgroundColor: theme.palette.primary.selected,
        borderColor: theme.palette.primary.lightBorder,
        display: { xs: 'none', sm: 'inline-flex' },
      }}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {icon}
        <Typography
          variant="subtitle1"
          color={theme.palette.primary.main}
          component="div"
          mb={3}
          lineHeight={1.5}
          textAlign="center"
        >
          {children}
        </Typography>
      </Box>
    </Button>
  );
};
