import { FaceAreaControllerBox } from '@components/molecules/faceAreaControllerBox/faceAreaControllerBox';
import { PlanogramInfoDialog } from '@components/molecules/planogramInfoDialog/planogramInfoDialog';
import { Planogram } from '@components/organisms/planogram/planogram';
import { StatisticsValueSelector } from '@components/organisms/statisticsValueSelector/statisticsValueSelector';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useZoomController } from '@hooks/useZoomController';
import { Box, Button, SxProps } from '@mui/material';
import { useGetStoreQuery } from '@reducers/shelfAppsApi';
import {
  getDisplayValue,
  paths,
  pointersDummy,
  getProfitsMenu,
} from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import {
  isPlanogramBucketDetail,
  isPlanogramPtsDetail,
} from '@utils/planogram';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import { BboxColors, Planogram as PlanogramType } from 'types/planogram';
import { theme } from '../../../../theme';
import { PlanButtonGroup } from './planButtonGroup';
import {
  ComparisonBayHeader,
  HeaderStyleDirection,
} from '@components/molecules/comparisionBayHeader/comparisionBayHeader';
import { t } from 'i18next';
import { useBreakpoint } from '@hooks/useBreakpoint';

type Props = {
  planogram?: PlanogramType;
  createdAt: string;
  diffColor: string;
  bboxColors: BboxColors;
  backgroundColor: string;
  handleOpenSearchModal?: () => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  detailView: ShelfDetailView;
  detailMode: ShelfDetailMode;
  isEditor?: boolean;
  isCompared?: boolean;
  headerStyleDirection?: HeaderStyleDirection;
  targetCompareText?: string;
  sx?: SxProps;
  isSwappingBayPartMode?: boolean;
  isTenantSalesAnalytics?: boolean;
};

const zoomButtonPosition = 40;
const tableScaleDown = 0.2;
const tableScalePaddingRight = 1.1;
export const PlanogramComparison: FC<Props> = ({
  planogram,
  createdAt,
  diffColor,
  bboxColors,
  backgroundColor,
  handleOpenSearchModal,
  onClickNext,
  onClickPrev,
  detailView,
  detailMode,
  isEditor = false,
  isCompared = false,
  headerStyleDirection,
  targetCompareText,
  sx,
  isSwappingBayPartMode,
  isTenantSalesAnalytics,
}) => {
  const { isLarger } = useBreakpoint();
  const navigate = useNavigate();
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const {
    zoomScale,
    handleIncrementZoom,
    handleDecrementZoom,
    handleTriggerZoom,
  } = useZoomController();
  const { name } = planogram ?? {};

  const handleNavigateView = () => {
    if (!planogram?.id) return;
    navigate(paths.plans.navigateView(planogram.id));
  };
  const {
    planogramEstimatedData,
    handleChangeProfit,
    storeAreaType,
    profit,
    isLoadingEstimate,
  } = useEstimatedProfit({ planogram });

  const ref = useRef<HTMLDivElement>();
  const [positionLeft, setPositionLeft] = useState(0);
  const handleResize = useCallback(() => {
    if (ref.current?.getBoundingClientRect()) {
      setPositionLeft(ref.current.getBoundingClientRect().left);
    }
  }, [setPositionLeft]);

  useEffect(() => {
    if (!positionLeft && ref.current?.getBoundingClientRect()) {
      setPositionLeft(ref.current.getBoundingClientRect().left);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, positionLeft]);

  const { data: storeBayPlan } = useGetStoreQuery(
    {
      storeId: planogram?.store_id ?? 0,
    },
    {
      skip: !planogram?.store_id,
    }
  );

  if (
    !planogram?.plan ||
    isPlanogramPtsDetail(planogram.plan) ||
    // Todo: 平置きゴンドラで比較機能作成時にremove
    isPlanogramBucketDetail(planogram.plan)
  ) {
    console.error('unsupported planogram type');
    return <></>;
  }

  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};

  const term = getTextDateStatistic(startDateFromAPI, endDateFromAPI);

  return (
    <>
      {/*if wrap PlanButtonGroup in position:relative parent,*/}
      {/*Planogram won't be scrollable, because we have to put zIndex to PlanButtonGroup to make it clickable*/}
      {!isSwappingBayPartMode && (
        <Box
          component="div"
          position="absolute"
          top={zoomButtonPosition}
          zIndex={5}
          sx={{ transform: `translate(${positionLeft}px, 60px)` }}
        >
          <PlanButtonGroup
            handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
            bboxEnabled={bboxEnabled}
            isOrientationModalOpen={false}
            isEditor={false}
            isCompared
            zoomScale={zoomScale}
            handleIncrementZoom={handleIncrementZoom}
            handleDecrementZoom={handleDecrementZoom}
            handleTriggerZoom={handleTriggerZoom}
            handleNavigateView={handleNavigateView}
          />
        </Box>
      )}

      <Box
        ref={ref}
        component="div"
        flex={1}
        display="flex"
        flexDirection="column"
        height="100%"
        sx={{ ...sx }}
      >
        <ComparisonBayHeader
          headerStyleDirection={headerStyleDirection}
          name={name ?? ''}
          bayName={storeBayPlan?.store?.name ?? '-'}
          targetCompareText={targetCompareText}
          setOpenInfoDialog={() => setOpenInfoDialog(true)}
          isComparisonSource={!handleOpenSearchModal}
          bayHeaderActions={
            handleOpenSearchModal && (
              <Button
                variant="outlined"
                disabled={isSwappingBayPartMode}
                onClick={handleOpenSearchModal}
                sx={{
                  height: '28px',
                  p: '0',
                  minWidth: '28px',
                  fontSize: '12px',
                  width: '40px',
                }}
              >
                変更
              </Button>
            )
          }
        >
          {planogram?.store_id && isTenantSalesAnalytics && (
            <StatisticsValueSelector
              value={getDisplayValue(
                profit,
                planogramEstimatedData?.estimate.summary,
                t('gross_profit')
              )}
              profits={getProfitsMenu(t('gross_profit'))}
              pointers={pointersDummy}
              selectedProfitType={profit}
              selectedPointerType={storeAreaType}
              handleChangeProfitValue={handleChangeProfit}
              category="シミュレーション"
              term={term}
              disabled={isSwappingBayPartMode}
              isLoading={isLoadingEstimate}
            />
          )}
        </ComparisonBayHeader>

        <Box
          //NOTE: for overflow. do not add property except overflow
          component="div"
          flex={1}
          overflow="auto"
          sx={{
            backgroundColor,
          }}
        >
          <Box
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              transform: isLarger
                ? `scale(${zoomScale},${zoomScale})`
                : `scale(${zoomScale - tableScaleDown},${
                    zoomScale - tableScaleDown
                  })`,
              minHeight: isLarger ? '100%' : '120%', //NOTE: 110% because scale - 0.1
              transformOrigin: `top center`,
              pointerEvents: 'all',
              py: 3,
              // 縦画面の比較でズームすると横が見切れるのでpaddingをたす
              paddingLeft: isLarger
                ? 0
                : zoomScale < tableScalePaddingRight
                ? '30%'
                : '50%',
              paddingRight: isLarger
                ? 0
                : zoomScale < tableScalePaddingRight
                ? 0
                : '30%',
              overflow: zoomScale > 1 ? 'scroll' : 'none',
            }}
          >
            <Planogram
              plan={planogram.plan}
              diffColor={diffColor}
              bboxColors={bboxColors}
              bboxEnabled={bboxEnabled}
              detailMode={detailMode}
              detailView={detailView}
              isEditor={isEditor}
              isCompared={isCompared}
            />
          </Box>
        </Box>
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={0.25}
          px={2}
          height={40}
          sx={{
            backgroundColor: theme.palette.white.primary,
          }}
        >
          <FaceAreaControllerBox
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            createdAt={createdAt}
          />
        </Box>
      </Box>
      <PlanogramInfoDialog
        planogram={planogram}
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
      />
    </>
  );
};
