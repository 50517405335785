import { NotFound } from '@components/NotFound';
import { MoveBucketButtons } from '@components/molecules/moveBucketButtons/moveBucketButtons';
import { NotPermittedModal } from '@components/organisms/NotPermittedModal/NotPermittedModal';
import { headerHeight } from '@components/organisms/header/header';
import { PlanogramHeader } from '@components/organisms/planogramHeader/planogramHeader';
import { StyledToggleButtonGroup } from '@components/organisms/viewModeToggle/fragments';
import { ZoomableFlatPlanogram } from '@components/organisms/zoomableFlatPlanogram/zoomableFlatPlanogram';
import {
  getBucketCompartmentsByView,
  getBucketPosition,
} from '@components/pages/planogramDetail/flatPlanogramDetail/utils/flatPlanogramDetail';
import { PlanButtonGroup } from '@components/pages/planogramDetail/fragments/planButtonGroup';
import { FlatComparison } from '@components/pages/planogramEditor/flatPlanogram/fragments/flatComparison';
import { RightSideArea } from '@components/pages/planogramEditor/fragments/rightSideArea';
import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useBrowserOperate } from '@hooks/useBrowserOperate';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { useZoomController } from '@hooks/useZoomController';
import { Box, ToggleButton } from '@mui/material';
import { updateBottomBucketsNum } from '@reducers/flatPlan';
import {
  changeEditorMode,
  changeIsRotateTransitionFlatPlanogram,
  forget as forgetPlanogramEditorState,
  rotateGondola,
  selectBayPartId,
  setSelectedBucketId,
  setSelectedBucketIdClone,
  updateBucketMode,
  updateDetailMode,
  updateIsShowBayPartDetail,
  updateIsShowProductDetail,
  updateSelectedProductCompartment,
  updateSelectedProductCompartmentClone,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useListProductsBulkQuery } from '@reducers/shelfAppsApi';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  fullHeight,
  fullRotation,
  getDisplayValue,
  pointersDummy,
  getProfitsMenu,
  rotationAngleFlatPlanogram,
  planogramRightSideHeight,
} from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import {
  getBucketsProductIds,
  getFlatPlanogramAreaWidthAndCenter,
  getProductsLayout,
  isPlanogramBucketPlan,
} from '@utils/planogram';
import httpStatus from 'http-status';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'theme';
import { BucketMode, Planogram } from 'types/planogram';
import { PreviewDragLayer } from '../fragments/previewDragLayer';
import { ThreeDPreview } from '../fragments/threeDPreview';
import { useInitSelectedPlanogram } from '@hooks/useInitSelectedPlanogram';
import { t } from 'i18next';
import { StatisticsValueSelector } from '@components/organisms/statisticsValueSelector/statisticsValueSelector';
import { useBreakpoint } from '@hooks/useBreakpoint';

const defaultDelayTouchStart = 50; /* ms */

type Props = {
  planogram?: Planogram;
  planogramId: number;
  isLoading: boolean;
  isFetching: boolean;
  delay?: string;
  error?: FetchBaseQueryError | SerializedError;
  isForbidden?: boolean;
};

export const FlatPlanogram: FC<Props> = ({
  planogram,
  planogramId,
  isLoading,
  isFetching,
  delay,
  error,
  isForbidden,
}) => {
  const dispatch = useAppDispatch();
  const {
    zoomScale,
    handleIncrementZoom,
    handleDecrementZoom,
    setZoomScale,
    handleTriggerZoom,
  } = useZoomController();
  const { removeQueryParameter } = useUrlQueryParams();
  const { isEnable: isCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: planogram,
    isPlanogram: true,
    isCan: true,
  });
  const [initRerenderingSelectedProduct, setInitRerenderingSelectedProduct] =
    useState(false); // 初回描画：選択中のアイテム情報更新フラグ
  const [
    initRerenderingSelectedProductPosition,
    setInitRerenderingSelectedProductPosition,
  ] = useState(false); // 初回描画：選択中のアイテム位置情報更新フラグ

  const [name, setName] = useState('');
  const [bayPlanCodeId, setBayPlanCodeId] = useState(
    planogram?.bay_plan_code_id
  );
  const [organizationStatusId, setOrganizationStatusId] = useState(
    planogram?.organization_status_id
  );
  const [bboxEnabled, setBboxEnabled] = useState(true);

  const { plan, isDirty, mark, updateBucketProductPosition, changeFront } =
    useFlatPlanogramPlan(
      isPlanogramBucketPlan(planogram?.plan) ? planogram?.plan : undefined
    );

  const {
    planogramEstimatedData,
    handleChangeProfit,
    storeAreaType,
    profit,
    handleRecalculate,
    isLoadingEstimate,
  } = useEstimatedProfit({ planogram: planogram, editedPlan: plan });
  const {
    selectedProductCompartment,
    selectedBayPartId,
    productPosition,
    mode,
    detailView: view,
    productTag,
    bucketMode,
    selectedBucketId,
    isTooltipOpen,
    selectedBucketIdClone,
    selectedProductCompartmentClone,
    detailMode,
    isSwappingBayPartMode,
    rotateDeg,
  } = useAppSelector(selectPlanogramEditorState);
  const isComparisonMode = detailMode === 'comparison';
  const {
    operate,
    selector,
    data: { isTenantSalesAnalytics },
  } = useRerenderingDetails();
  const productIds = plan.frame.detail.buckets
    ?.flatMap((bucket) => getBucketsProductIds(bucket.detail.area))
    .sort()
    .join(',');
  const { data: productsBulk, isLoading: isLoadingProductsBulk } =
    useListProductsBulkQuery(
      { productIds: productIds, shape: true, detail: true },
      { skip: !productIds }
    );

  const { productsSelected } = operate.getSelectedItemOfPlanogram(
    productsBulk?.products,
    getProductsLayout(planogram?.plan)
  );

  const positions = useMemo(
    () =>
      getBucketCompartmentsByView(
        view,
        productTag,
        plan,
        productsBulk?.products
      ),
    [plan, view, productTag, productsBulk?.products]
  );

  const bucketPosition = useMemo(
    () => getBucketPosition(productsSelected, positions),
    [productsSelected, positions]
  );

  useEffect(() => {
    // compare
    if (selector.modeQueryParams) {
      dispatch(updateDetailMode(selector.modeQueryParams));
    }
  }, [dispatch, selector]);

  useEffect(() => {
    return () => {
      dispatch(updateIsShowProductDetail(false));
      dispatch(updateIsShowBayPartDetail(false));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updateBottomBucketsNum(0));
      dispatch(forgetPlanogramEditorState());
    };
  }, [dispatch]);

  // 初回描画：選択中のアイテム情報
  useInitSelectedPlanogram({
    planogramPlan: plan,
    initSelectedData: {
      isSkipInit:
        !productsSelected ||
        initRerenderingSelectedProduct ||
        isLoading ||
        isComparisonMode,
      view,
      productTag,
      onInitCompleted: setInitRerenderingSelectedProduct,
    },
  });

  // 初回描画：選択中のアイテム位置情報
  useEffect(() => {
    if (
      initRerenderingSelectedProductPosition ||
      isLoadingProductsBulk ||
      !bucketPosition ||
      selectedBucketId
    ) {
      return;
    }

    dispatch(setSelectedBucketId(bucketPosition.bucketId));
    updateBucketProductPosition(bucketPosition.position);
    setInitRerenderingSelectedProductPosition(true);
  }, [
    dispatch,
    initRerenderingSelectedProductPosition,
    updateBucketProductPosition,
    isLoadingProductsBulk,
    bucketPosition,
    selectedBucketId,
  ]);

  // ブラウザバックなどの操作時に発火する
  const updateStateByBrowserOperated = () => {
    setInitRerenderingSelectedProduct(false);
    setInitRerenderingSelectedProductPosition(false);
  };
  useBrowserOperate(updateStateByBrowserOperated);

  const handleClickAwayPlanogram = () => {
    if ((selectedBucketId || selectedBayPartId) && !isSwappingBayPartMode) {
      dispatch(selectBayPartId(undefined));
      dispatch(updateIsShowBayPartDetail(false));
    }
    if (
      (productPosition || selectedProductCompartment) &&
      selectedBucketId?.toString()
    ) {
      updateBucketProductPosition(undefined);
      if (bucketMode === 'area') dispatch(setSelectedBucketId(undefined));
      dispatch(updateSelectedProductCompartment(undefined));
      dispatch(updateIsShowProductDetail(false));
      dispatch(updateSelectedProductCompartmentClone(undefined));
    }
    {
      /* TOOD: remove toString() method after buckets api is ready. Currently index is used as selectedBucketId */
    }
    if (
      !productPosition &&
      !selectedProductCompartment &&
      selectedBucketId?.toString() &&
      !isTooltipOpen &&
      !isSwappingBayPartMode
    ) {
      dispatch(setSelectedBucketId(undefined));
      dispatch(updateIsShowProductDetail(false));
      dispatch(updateSelectedProductCompartmentClone(undefined));
    }

    if (selectedBucketId === undefined && bucketMode === 'area') {
      dispatch(setSelectedBucketIdClone(undefined));
    }

    removeQueryParameter('item');
    updateBucketProductPosition(undefined);
  };

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleChangeBayPlanCodeId = (value?: number) => {
    setBayPlanCodeId(value);
  };

  const handleChangeOrganizationStatusId = (value?: number) => {
    setOrganizationStatusId(value);
  };

  const handleChangeBucketMode = (value: BucketMode) => {
    if (value !== null) dispatch(updateBucketMode(value));
    if (value === 'area') {
      if (selectedProductCompartment) {
        dispatch(
          updateSelectedProductCompartmentClone(selectedProductCompartment)
        );
        dispatch(updateIsShowProductDetail(false));
        dispatch(updateSelectedProductCompartment(undefined));
      } else {
        dispatch(setSelectedBucketIdClone(undefined));
      }
    } else {
      if (selectedBucketIdClone !== undefined) {
        dispatch(setSelectedBucketId(selectedBucketIdClone));
        dispatch(setSelectedBucketIdClone(undefined));
      }

      if (selectedProductCompartmentClone) {
        dispatch(updateIsShowProductDetail(true));
        dispatch(
          updateSelectedProductCompartment(selectedProductCompartmentClone)
        );
      }

      if (selectedProductCompartment) dispatch(updateIsShowProductDetail(true));

      if (
        !productPosition &&
        !selectedProductCompartment &&
        selectedBucketId?.toString() &&
        !isTooltipOpen &&
        !selectedProductCompartmentClone
      ) {
        updateBucketProductPosition(undefined);
      }
    }
  };

  useEffect(() => {
    if (!planogram) return;
    setName(planogram.name);
    setBayPlanCodeId(planogram.bay_plan_code_id);
    setOrganizationStatusId(planogram.organization_status_id);
  }, [planogram]);

  const { areaWidth, displayPosition } = useMemo(() => {
    return getFlatPlanogramAreaWidthAndCenter(plan.frame.detail);
  }, [plan.frame.detail]);

  const [width, setWidth] = useState(0);

  const scrollRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(displayPosition, 0);
    }
  }, [scrollRef, displayPosition]);

  useEffect(() => {
    const handleResize = () => {
      if (scrollRef?.current) {
        const newWidth = scrollRef.current.offsetWidth;
        setWidth(newWidth);
      }
    };

    // コンポーネントがマウントされた時に幅を取得
    handleResize();

    // ウィンドウのリサイズ時に幅を取得
    window.addEventListener('resize', handleResize);

    // クリーンアップ関数でイベントリスナーを削除
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isLarger } = useBreakpoint();

  // todo: shelfと共通化できるか検討。（共通化すると表示不備が起こるため原因調査)
  if (!!error && 'status' in error && error.status === httpStatus.NOT_FOUND) {
    return <NotFound title="棚割計画（棚エディタ）" />;
  }
  if (mode === 'preview' && planogram) {
    return <ThreeDPreview planogram={planogram} plan={plan} />;
  }

  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};

  const term = getTextDateStatistic(startDateFromAPI, endDateFromAPI);

  const changeFrontTime = 500;
  const handleChangeFront = () => {
    dispatch(updateLoadingIndicatorState(true));
    dispatch(changeIsRotateTransitionFlatPlanogram(false));
    changeFront();
    dispatch(
      rotateGondola(
        rotateDeg % fullRotation === 0 ? rotationAngleFlatPlanogram : 0
      )
    );
    setTimeout(() => {
      dispatch(updateLoadingIndicatorState(false));
    }, changeFrontTime);
  };

  return (
    <Box
      component="div"
      sx={{
        height: fullHeight,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      }}
    >
      <PlanogramHeader
        name={name}
        bayPlanCodeId={bayPlanCodeId}
        organizationStatusId={organizationStatusId}
        planogram={planogram}
        planogramIsLoading={isLoading}
        handleChangeName={handleChangeName}
        handleChangeBayPlanCodeId={handleChangeBayPlanCodeId}
        handleChangeOrganizationStatusId={handleChangeOrganizationStatusId}
        planogramIsFetching={isFetching}
        plan={plan}
        isDirty={isDirty}
        mark={mark}
        setZoomScale={setZoomScale}
      />
      <DndProvider
        backend={isMobile ? TouchBackend : HTML5Backend}
        options={{ delayTouchStart: Number(delay ?? defaultDelayTouchStart) }}
      >
        {detailMode === 'comparison' ? (
          <FlatComparison
            handleChangeBucketMode={handleChangeBucketMode}
            handleClickAwayPlanogram={handleClickAwayPlanogram}
            planogram={planogram}
            plan={plan}
            profit={profit}
            planogramEstimatedData={planogramEstimatedData}
            storeAreaType={storeAreaType}
            handleRecalculate={handleRecalculate}
            handleChangeProfit={handleChangeProfit}
            handleChangeFront={handleChangeFront}
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            initRerenderingSelectedProduct={initRerenderingSelectedProduct}
            setInitRerenderingSelectedProduct={
              setInitRerenderingSelectedProduct
            }
            compareQueryParams={selector.compareQueryParams}
          />
        ) : (
          <>
            <Box
              component="div"
              position="relative"
              sx={{
                display: 'flex',
                height: `calc(${fullHeight} - ${headerHeight}px)`,
                flexDirection: { xs: 'column', breakpoint: 'row' },
              }}
            >
              <Box
                data-planogram-container="data-planogram-container"
                ref={scrollRef}
                component="div"
                height={{
                  xs: `calc(${fullHeight} - ${headerHeight}px - ${planogramRightSideHeight}px)`,
                  md: '100%',
                }}
                width="100%"
                display="flex"
                flexDirection="column"
                sx={{
                  overflowX: 'scroll',
                  background: theme.palette.shelf.backgroundTana,
                }}
              >
                <Box
                  component="div"
                  height="100%"
                  width={`${areaWidth * zoomScale}px`}
                  position="relative"
                  display="flex"
                  flexDirection="column"
                >
                  {planogram?.store_id && isTenantSalesAnalytics && (
                    <Box
                      component="div"
                      p="8px 16px"
                      position="fixed"
                      width={`${width}px`}
                      sx={{
                        backgroundColor: theme.palette.white.primary,
                      }}
                    >
                      <StatisticsValueSelector
                        value={getDisplayValue(
                          profit,
                          planogramEstimatedData?.estimate.summary,
                          t('gross_profit')
                        )}
                        profits={getProfitsMenu(t('gross_profit'))}
                        pointers={pointersDummy}
                        selectedProfitType={profit}
                        selectedPointerType={storeAreaType}
                        handleChangeProfitValue={handleChangeProfit}
                        handleRefreshValue={handleRecalculate}
                        hasRefreshButton
                        category="シミュレーション"
                        term={term}
                        disabled={isSwappingBayPartMode}
                        isLoading={isLoadingEstimate}
                        detailMode={detailMode}
                      />
                    </Box>
                  )}
                  <ZoomableFlatPlanogram
                    bboxEnabled={bboxEnabled}
                    plan={plan}
                    scale={zoomScale}
                    handleClickAway={handleClickAwayPlanogram}
                    zoomScale={zoomScale}
                    setZoomScale={setZoomScale}
                    handleChangeFront={handleChangeFront}
                  />
                </Box>
              </Box>
              {/* TOOD: remove toString() method after buckets api is ready. Currently index is used as selectedBucketId */}
              {(selectedBucketId?.toString() ||
                (selectedBucketId === undefined &&
                  bucketMode === 'area' &&
                  selectedBucketIdClone !== undefined)) &&
                !isSwappingBayPartMode && (
                  <Box
                    component="div"
                    position="absolute"
                    bottom={48}
                    width={width}
                    px={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MoveBucketButtons
                      handleNavigateBefore={() => console.log('before')}
                      handleNavigateNext={() => console.log('next')}
                    />
                    <StyledToggleButtonGroup
                      orientation="horizontal"
                      value={bucketMode}
                      exclusive
                      sx={{ background: theme.palette.white.primary }}
                      onChange={(_, value: BucketMode) =>
                        handleChangeBucketMode(value)
                      }
                    >
                      <ToggleButton value="compartment">商品</ToggleButton>
                      <ToggleButton value="area">エリア</ToggleButton>
                    </StyledToggleButtonGroup>
                  </Box>
                )}
              <Box
                component="div"
                sx={{
                  maxWidth: { xs: '100%', breakpoint: '480px' },
                  width: '100%',
                  boxSizing: 'border-box',
                  padding: '8px',
                  background: '#FFF',
                  borderLeft: '1px solid #EDEDED',
                  position: 'relative',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: {
                    xs: planogramRightSideHeight,
                    breakpoint: '100%',
                  },
                  height: '100%',
                }}
              >
                <RightSideArea
                  bayPlanId={planogram?.bay_plan_id ?? 0}
                  isProductDetailRow={!isLarger}
                />
              </Box>
            </Box>
            <PreviewDragLayer scale={zoomScale} />
          </>
        )}
      </DndProvider>
      {detailMode !== 'comparison' && !isSwappingBayPartMode && (
        <Box
          component="div"
          position="absolute"
          top={24}
          zIndex={5}
          sx={{ transform: `translate(0px, 24px)` }}
        >
          <PlanButtonGroup
            bboxEnabled={bboxEnabled}
            handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
            handleChangeView={() => dispatch(changeEditorMode('preview'))}
            isOrientationModalOpen={false}
            handleIncrementZoom={handleIncrementZoom}
            handleDecrementZoom={handleDecrementZoom}
            handleTriggerZoom={handleTriggerZoom}
            zoomScale={zoomScale}
            isEditor
            isFlatPlanogram
          />
        </Box>
      )}
      <NotPermittedModal
        id={planogramId}
        open={!(isLoading || (planogram && isCanUpdate)) || !!isForbidden}
        errorMessage="この棚割の編集権限がありません"
        buttonMessage="棚割詳細へ戻る"
      />
    </Box>
  );
};
