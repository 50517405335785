import { formatNumberToYen } from '@utils/const';
import { theme } from '../../../../theme';

export const fakeTotalProfit = formatNumberToYen(321112);
export const fakeLowestProfit = formatNumberToYen(1921);
export const fakeHighestProfit = formatNumberToYen(153112);
export const fakeTotalSales = '201';

export const heatMapSales = [
  { value: 3, color: theme.palette.heatmap['05_line'], min: '0', max: '10' },
  { value: 12, color: theme.palette.heatmap['04_line'], min: '10', max: '50' },
  { value: 3, color: theme.palette.heatmap['03_line'], min: '50', max: '100' },
  { value: 5, color: theme.palette.heatmap['02_line'], min: '100', max: '150' },
  {
    value: 10,
    color: theme.palette.heatmap['01_line'],
    min: '200',
    max: '250',
  },
];

export const heatMapProfit = [
  {
    value: 3,
    color: '#8CDE24',
    min: formatNumberToYen(0),
    max: formatNumberToYen(4000),
  },
  {
    value: 12,
    color: '#D0D413',
    min: formatNumberToYen(4000),
    max: formatNumberToYen(8000),
  },
  {
    value: 3,
    color: '#DFB01D',
    min: formatNumberToYen(8000),
    max: formatNumberToYen(12000),
  },
  {
    value: 5,
    color: '#E48731',
    min: formatNumberToYen(12000),
    max: formatNumberToYen(16000),
  },
  {
    value: 10,
    color: '#E25538',
    min: formatNumberToYen(16000),
    max: formatNumberToYen(20000),
  },
];
