import { getCompartmentSize, judgeOverflow } from '@utils/planogram';
import { useMemo } from 'react';
import { Product } from 'types/common';
import { PlanogramProductCompartmentList } from 'types/planogram';

/**
 * 各コンパートメントがはみ出しているか確認する
 **/
export const useOverflows = (
  row: PlanogramProductCompartmentList['row'],
  shelfHeight: number,
  shelfWidth: number,
  products?: Product[]
) => {
  //回転を考慮したコンパートメントのサイズを計算する
  const compartmentSizes = row.map((productCompartment) => {
    const product = products?.find(
      (product) => product.id === productCompartment.product_id
    );
    const { width, height } = getCompartmentSize(productCompartment, product);

    return { width, height };
  });

  const compartmentOverflows = useMemo(
    () =>
      judgeOverflow(
        { width: shelfWidth, height: shelfHeight },
        compartmentSizes
      ),
    [shelfWidth, shelfHeight, compartmentSizes]
  );

  return { compartmentOverflows };
};
