import { Item } from '@components/organisms/bucketArea/bucketArea';
import { BucketBayPart } from '@components/organisms/bucketBayPart/bucketBayPart';
import { DisplayBuckets } from '@components/organisms/displayBuckets/displayBuckets';
import { AddIconVertical } from '@components/organisms/displayBuckets/fragments/addIconVertical';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { ErrorOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { updateBucketsExceed } from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useGetBayPartQuery } from '@reducers/shelfAppsApi/injections/bayPartCategoriesApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { withOpacity } from '@utils/colors';
import { rotationAngleFlatPlanogram } from '@utils/const';
import { convertMeterToPixel } from '@utils/planogram';
import { FC, useEffect, useRef, useState } from 'react';
import { ConnectableElement, useDrop } from 'react-dnd';
import { Mode } from 'types/common';
import { Bucket } from 'types/planogram';
import { ItemTypes } from 'types/rack';
import { theme } from '../../../../theme';

type Props = {
  index: number;
  bucket: Bucket;
  zoomScale: number;
  setZoomScale: (zoomScale: number) => void;
  setTranslatePosition: (value: number) => void;
  translatePosition: number;
  isEditor?: boolean;
  bboxEnabled: boolean;
  isCompared?: boolean;
  isExceedSize?: boolean;
  hasBottomBuckets?: boolean;
  comparedBottomBucketsNum?: number;
};

const getBackgroundColor = (
  isSelected: boolean,
  isDisabled: boolean,
  mode: Mode,
  isDragProduct?: boolean
) => {
  if (mode !== 'CompartmentEditor') return 'transparent';
  if (isSelected && !isDragProduct) return theme.palette.white.primary;
  else if (isDisabled) return theme.palette.backgroundBlack.disabled;
  return 'transparent';
};

const textMargin = 4;

export const Buckets: FC<Props> = ({
  bucket,
  index,
  zoomScale,
  setZoomScale,
  setTranslatePosition,
  translatePosition,
  isEditor = true,
  bboxEnabled,
  isCompared = false,
  isExceedSize = false,
  hasBottomBuckets = false,
  comparedBottomBucketsNum = 0,
}) => {
  const dispatch = useAppDispatch();
  const { addBayPart, bottomBucketsNum, moveBayPart } = useFlatPlanogramPlan();
  const {
    selectedBucketId,
    rotateDeg,
    mode,
    rotateDegInCompare,
    selectedBayPartId,
    isDragProduct,
    isSwappingBayPartMode,
  } = useAppSelector(selectPlanogramEditorState);
  const dropTargetRef = useRef<HTMLDivElement | null>(null);
  const isSelected =
    index === selectedBucketId && mode === 'CompartmentEditor' && !isCompared;
  const isSelectedInEditor =
    index === selectedBucketId && mode === 'BayEditor' && !isCompared;
  const { padding } = bucket.detail;
  const contentX = bucket.detail.content_max.x - bucket.detail.content_min.x;
  const contentZ = bucket.detail.content_max.z - bucket.detail.content_min.z;
  const { data, isLoading, isFetching } = useGetBayPartQuery(
    // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: 既存の問題なのであとで修正する
    { bay_part_id: selectedBayPartId ?? 0 },
    { skip: selectedBayPartId === undefined }
  );
  const bottomBucketsLength = isCompared
    ? comparedBottomBucketsNum
    : bottomBucketsNum;
  // eslint-disable-next-line no-magic-numbers -- 偶数か奇数かを (回転度数%2)=== 0 で求める一般的な記述なため
  const isFront = (rotateDeg / rotationAngleFlatPlanogram) % 2 === 0;
  const initUpperRowIndexThreshold = index >= bottomBucketsLength;

  const isDisabledBuckets = () => {
    if (mode !== 'CompartmentEditor') return false;
    if (isFront) {
      return initUpperRowIndexThreshold;
    }
    return index < bottomBucketsLength;
  };
  const [isOnRightSide, setIsOnRightSide] = useState(true);
  const height =
    convertMeterToPixel(contentZ) +
    convertMeterToPixel(padding.front) +
    convertMeterToPixel(padding.behind);
  const width =
    convertMeterToPixel(contentX) +
    convertMeterToPixel(padding.left) +
    convertMeterToPixel(padding.right);
  const rotateDegree = isEditor ? rotateDeg : rotateDegInCompare;
  const [{ isOver, item }, drop] = useDrop<
    Item,
    unknown,
    { item?: Item; isOver?: boolean }
  >(
    () => ({
      accept: ItemTypes.BUCKET_BAY_PART,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        item: monitor.getItem(),
      }),
      drop: (item) => {
        if (item.index === index) return;
        let dropIndex;
        if (
          isOnRightSide ||
          (index === 0 && !isOnRightSide) ||
          (index === bottomBucketsLength && !isOnRightSide)
        ) {
          dropIndex = index;
        } else {
          dropIndex = index - 1;
        }
        if (!dropIndex.toString()) return;
        moveBayPart({
          currentIndex: item.index,
          dropIndex,
          isLeftEdgeBottomRow: index === 0 && !isOnRightSide,
          isLeftEdgeTopRow: index === bottomBucketsLength && !isOnRightSide,
        });
      },
      hover: (item, monitor) => {
        if (item.index === index) return;
        const targetBoundingRect =
          dropTargetRef?.current?.getBoundingClientRect();
        if (!targetBoundingRect) return;
        const horizontalMiddle =
          (targetBoundingRect.right - targetBoundingRect.left) / 2;
        const clientOffset = monitor.getClientOffset();
        if (!clientOffset) return;
        const hoverClientX = clientOffset.x - targetBoundingRect.left;
        const onRightSide = hoverClientX >= horizontalMiddle;
        setIsOnRightSide(onRightSide);
      },
    }),
    [isOnRightSide]
  );
  const isSameItem = item?.index === index;
  const paddingValue = convertMeterToPixel(
    bucket.detail.padding.right + bucket.detail.padding.left
  );
  useEffect(() => {
    dispatch(updateBucketsExceed({ bucketIndex: index, isExceedSize }));
  }, [isExceedSize, mode, index, dispatch]);

  return (
    <Box
      ref={(node) => {
        dropTargetRef.current = node as HTMLDivElement;
        return drop(node as ConnectableElement);
      }}
      component="div"
      sx={{
        height,
        width,
        //Need to specify padding bottom when displaying exterior UI
        display: 'flex',
        background:
          isEditor && !isCompared
            ? getBackgroundColor(
                isSelected,
                isDisabledBuckets(),
                mode,
                isDragProduct
              )
            : 'transparent',
        position: 'relative',
        '&::before':
          mode === 'BayEditor' &&
          ((!isSwappingBayPartMode && index !== selectedBucketId) ||
            (isSwappingBayPartMode && index === selectedBucketId))
            ? {
                content: `'${bucket.name}'`,
                position: 'absolute',
                top:
                  index >= bottomBucketsLength ? 'unset' : height + textMargin,
                bottom:
                  index >= bottomBucketsLength ? height + textMargin : 'unset',
                left: 0,
                width: '100%',
                fontSize: '10px',
                color: theme.palette.textBlack.secondary,
                transform: `translateY(${
                  selectedBayPartId && !selectedBucketId?.toString()
                    ? index >= bottomBucketsLength
                      ? -40
                      : 40
                    : 0
                }px) rotate(${rotateDegree}deg)`,
              }
            : '',
        '&::after':
          selectedBayPartId && !selectedBucketId?.toString()
            ? {
                content: "''",
                position: 'absolute',
                top:
                  index >= bottomBucketsLength ? 'unset' : height + textMargin,
                bottom:
                  index >= bottomBucketsLength ? height + textMargin : 'unset',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '1px',
                height: '30px',
                background: theme.palette.dividerBlack.dark,
                marginTop: index >= bottomBucketsLength ? 0 : '4px',
                marginBottom: index >= bottomBucketsLength ? '4px' : 0,
              }
            : '',
      }}
    >
      {isOver && !isSameItem && (
        <Box
          component="div"
          sx={{
            position: 'absolute',
            right: isOnRightSide ? `-${paddingValue}px` : `100%`,
            width: 2,
            height: '100%',
            background: theme.palette.primary.main,
            borderRadius: '1px',
          }}
        />
      )}
      {isExceedSize && (
        <Box
          component="div"
          sx={{
            width: 11,
            height: 11,
            backgroundColor: withOpacity(theme.palette.system.errorMain, 0.2),
            position: 'absolute',
            top: index >= bottomBucketsLength ? 'calc(100% - 5px)' : '-5px',
            right: index >= bottomBucketsLength ? 'calc(100% - 5px)' : '-7px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '20px',
            zIndex: 2,
          }}
        >
          <ErrorOutlined
            sx={{ fill: theme.palette.system.errorMain, width: 15, height: 15 }}
          />
        </Box>
      )}
      {mode === 'BayEditor' && !isSwappingBayPartMode && (
        <Box
          component="div"
          position="absolute"
          top="50%"
          left="50%"
          sx={{
            transform: 'translate(-50%,-50%)',
            width: '100%',
            height: '100%',
            background: 'url(/hundle.svg) no-repeat center center',
          }}
        />
      )}

      {!isSwappingBayPartMode && (
        <>
          {selectedBayPartId &&
            !isCompared &&
            !selectedBucketId?.toString() &&
            // left edges of top and botoom rows
            (index === 0 || bottomBucketsLength === index) && (
              <Box
                component="div"
                position="absolute"
                sx={{
                  top:
                    index >= bottomBucketsLength || !hasBottomBuckets
                      ? 'unset'
                      : height,
                  bottom:
                    index >= bottomBucketsLength || !hasBottomBuckets
                      ? height
                      : 'unset',
                  left: 0,
                  transform: `translate(-50%,${
                    bottomBucketsLength === index || !hasBottomBuckets
                      ? 30
                      : -30
                  }%)`,
                }}
              >
                <AddIconVertical
                  disabled={isLoading || isFetching}
                  sx={{
                    transform: `rotate(${
                      index >= bottomBucketsLength || !hasBottomBuckets
                        ? '180'
                        : 0
                    }deg)`,
                  }}
                  handleClick={() => {
                    addBayPart({
                      bayPart: data?.bay_part,
                      index,
                      isLeftEdgeBottomRow: index === 0,
                    });
                  }}
                />
              </Box>
            )}

          {selectedBayPartId &&
            !isCompared &&
            !selectedBucketId?.toString() && (
              <Box
                component="div"
                position="absolute"
                sx={{
                  top:
                    index >= bottomBucketsLength || !hasBottomBuckets
                      ? 'unset'
                      : height,
                  bottom:
                    index >= bottomBucketsLength || !hasBottomBuckets
                      ? height
                      : 'unset',
                  right: 0,
                  transform: `translate(50%,${
                    index >= bottomBucketsLength || !hasBottomBuckets ? 30 : -30
                  }%)`,
                }}
              >
                <AddIconVertical
                  disabled={isLoading || isFetching}
                  sx={{
                    transform: `rotate(${
                      index >= bottomBucketsLength || !hasBottomBuckets
                        ? '180'
                        : 0
                    }deg)`,
                  }}
                  handleClick={() => {
                    addBayPart({ bayPart: data?.bay_part, index });
                  }}
                />
              </Box>
            )}
        </>
      )}

      <Box
        component="div"
        height="100%"
        width="100%"
        sx={{
          display: 'flex',
          alignItems: 'start',
          backgroundColor: isExceedSize
            ? theme.palette.system.errorBg
            : 'transparent',
        }}
      >
        <BucketBayPart
          bucket={bucket}
          isSelected={isSelected}
          isSelectedInEditor={isSelectedInEditor}
          isExceedSize={isExceedSize}
          isCompared={isCompared}
        />
      </Box>
      <DisplayBuckets
        isEditor={isEditor}
        key={index}
        bucket={bucket}
        contentX={contentX}
        contentY={contentZ}
        index={index}
        bboxEnabled={bboxEnabled}
        zoomScale={zoomScale}
        setZoomScale={setZoomScale}
        initUpperRowIndexThreshold={initUpperRowIndexThreshold}
        isDisabledBuckets={isEditor ? isDisabledBuckets() : false}
        setTranslatePosition={setTranslatePosition}
        translatePosition={translatePosition}
        isCompared={isCompared}
      />
    </Box>
  );
};
