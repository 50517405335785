import { DialogField } from '@components/molecules/dialogField/dialogField';
import { useBayPlanApi } from '@hooks/useBayPlanApi';
import { useBayPlanCodes } from '@hooks/useBayPlanCodes';
import { useOrganizationStatuses } from '@hooks/useOrganizationStatuses';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import { Format, format } from '@utils/date';
import { FC, useEffect, useState } from 'react';
import { Planogram } from 'types/planogram';

type Props = {
  open: boolean;
  onClose: () => void;
  planogram?: Planogram;
};

export const PlanogramInfoDialog: FC<Props> = ({
  open,
  onClose,
  planogram,
}) => {
  const [bayPlanName, setBayPlanName] = useState('');
  const { bayPlanCode } = useBayPlanCodes(planogram?.bay_plan_code_id);
  const { fetchBayPlan } = useBayPlanApi();
  const ownerName = planogram?.owner.fullname ?? '';
  const { organizationStatus } = useOrganizationStatuses(
    planogram?.organization_status_id
  );
  const planogramName = planogram?.name ?? '';

  useEffect(() => {
    const getBayPlanName = async (bayPlanId?: number) => {
      if (bayPlanId) {
        const bayPlan = await fetchBayPlan(bayPlanId);
        setBayPlanName(bayPlan?.name ?? '');
      }
    };
    void getBayPlanName(planogram?.bay_plan_id);
  }, [planogram, fetchBayPlan]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '400px',
          minHeight: '321px',
          borderRadius: 2,
        },
      }}
    >
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 2,
          pt: 3,
          gap: 1,
        }}
      >
        <Typography
          sx={{
            color: '#000000',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '20px',
            mb: 2,
          }}
        >
          棚割情報
        </Typography>
        <DialogField
          title="作成日時"
          value={format(planogram?.created_at, Format.datetimeWithWeekday)}
        />
        <Divider />
        <DialogField
          title="最終更新日時"
          value={format(planogram?.updated_at, Format.datetimeWithWeekday)}
        />
        <Divider />
        <DialogField title="ゴンドラテンプレート" value={bayPlanName} />
        <Divider />
        <DialogField title="ゴンドラ名" value={planogramName} />
        <Divider />
        <DialogField
          title="ゴンドラ番号"
          value={bayPlanCode?.name ?? '-'}
        />
        <Divider />
        <DialogField title="作成者" value={ownerName} />
        <Divider />
        <DialogField
          title="ステータス"
          value={organizationStatus?.label ?? '-'}
        />
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{
            fontWeight: 'bold',
            m: 1,
            mr: 2,
            px: 2,
          }}
          onClick={onClose}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
