import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { useDeleteMfaDeviceByAdminMutation } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { toastMessages } from '@utils/const';
import { useState } from 'react';

export const useDeleteMfa = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [deleteMfaDeviceByAdmin] = useDeleteMfaDeviceByAdminMutation();

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = async (id: number) => {
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteMfaDeviceByAdmin({ id });
      dispatch(
        openToast({
          message: toastMessages.success.deleteMfaByAdmin,
          type: 'success',
        })
      );
      closeDialog();
    } catch (error) {
      console.error(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteMfaByAdmin,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  return {
    isDialogOpen,
    openDialog,
    closeDialog,
    handleDelete,
  };
};
