import {
  EmptyResult,
  planogramDirectoryEmptyMessage,
} from '@components/molecules/emptyResult/emptyResult';
import { GramTableFoot } from '@components/organisms';
import { useQueryParameterPresence } from '@hooks/useQueryParameterPresence';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@mui/material';
import { setSelectedItemIndex } from '@reducers/planogram';
import { useAppSelector } from '@store/index';
import { CommonColors } from '@utils/colors';
import { rowHeight, rowsPerPage } from '@utils/const';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso';
import { theme } from 'theme';
import {
  PlanogramDirectory,
  PlanogramDirectoryParent,
  PlanogramListOrder,
} from 'types/planogram';
import { PlanogramSharePermissionProps } from 'types/sharePermission';
import { GramTableFooter } from '../gramTableFooter/gramTableFooter';
import { TableHeader } from '../tableHeader/tableHeader';
import { TableCells } from './fragments/tableCells';
import { getColumns } from './fragments/tableHeaderData';

type Props = {
  parent?: PlanogramDirectoryParent;
  planogramDirectories?: PlanogramDirectory[];
  handleRowClick: (item: PlanogramDirectory) => void;
  handleOpenDeleteDialog: (planogramDirecotry: PlanogramDirectory) => void;
  handleClonePlanogram: (planogramDirecotry: PlanogramDirectory) => void;
  handleUpdatePlanogramName: (planogramDirectory?: PlanogramDirectory) => void;
  handleUpdateDirectoryName: (planogramDirectory?: PlanogramDirectory) => void;
  handleChangeOrder: (order: PlanogramListOrder) => void;
  handleMoveDirectory: (targetDirectory?: PlanogramDirectory) => void;
  isLoading: boolean;
  isRefetching: boolean;
  offset: number;
  tableWidth: string;
  isFilteredByViewed: boolean;
  firstOrder?: PlanogramListOrder;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleSharePermission: (item: PlanogramSharePermissionProps) => void;
  handleEndReached: () => void;
};

const enterVelocity = 400;
const exitVelocity = 2;
const queryList = ['bay_plan_code_id', 'owner_id', 'organization_status_id'];

export const PlanogramsTable: FC<Props> = ({
  parent,
  planogramDirectories,
  handleRowClick,
  handleOpenDeleteDialog,
  handleClonePlanogram,
  handleUpdatePlanogramName,
  handleUpdateDirectoryName,
  handleMoveDirectory,
  handleChangeOrder,
  isLoading,
  isRefetching,
  offset,
  tableWidth,
  isFilteredByViewed,
  firstOrder,
  handleFavoriteClick,
  handleEndReached,
  handleSharePermission,
}) => {
  const ref = useRef<TableVirtuosoHandle>(null);
  const { selectedItemIndex } = useAppSelector((state) => state.Planogram);
  const dispatch = useDispatch();
  const { hasQueryParameters } = useQueryParameterPresence();
  const isEmpty = !isLoading && !planogramDirectories?.length;
  const isParentTypeDirectory = parent?.type === 'directory';
  const hasQueries = hasQueryParameters(queryList);
  const columns = useMemo(
    () => getColumns(isFilteredByViewed),
    [isFilteredByViewed]
  );

  useEffect(() => {
    if (!isRefetching) return;
    ref.current?.scrollToIndex({ index: 0 });
  });

  return (
    <TableVirtuoso
      style={{
        border: '1px solid #CCCCCC',
        overflow: isEmpty ? 'hidden' : 'auto',
      }}
      data={
        planogramDirectories?.length
          ? planogramDirectories
          : Array.from(
              { length: rowsPerPage },
              () => ({} as PlanogramDirectory)
            )
      }
      initialTopMostItemIndex={selectedItemIndex}
      endReached={handleEndReached}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        Table: (props: TableProps) => (
          <Table
            {...props}
            style={{
              borderCollapse: 'separate',
              height: isEmpty ? '100%' : '',
            }}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableHead,
        // Note: TableRowに一行に対するスタイルや動作を設定し、itemContentに行内の各カラムに対するスタイルや動作設定する。
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableRow: (props: {
          item: PlanogramDirectory;
          // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's default props
          'data-index': number;
        }) => {
          const index = props['data-index'];
          const { isNotEnable: isNotCanRead } = useGetPlanogramPermission({
            action: 'read',
            planogram: props.item,
            isPlanogram: false,
            isCan: false,
          });

          return (
            <>
              {offset === 0 && (isLoading || isRefetching) ? (
                <TableRow {...props}>
                  {columns.map((_, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton animation="wave" width="75%" />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : props?.item ? (
                <TableRow
                  sx={{
                    height: rowHeight,
                    cursor: isNotCanRead ? '' : 'pointer',
                    ':hover': {
                      backgroundColor: CommonColors.lightGray,
                    },
                    '.MuiTableCell-root': {
                      color: isNotCanRead
                        ? theme.palette.textBlack.disabled
                        : theme.palette.textBlack.table,
                    },
                  }}
                  onClick={() => {
                    if (isNotCanRead) return;
                    handleRowClick(props.item);
                    dispatch(setSelectedItemIndex(index));
                  }}
                  {...props}
                />
              ) : (
                <Box component="div" height="100%" width={tableWidth}>
                  <EmptyResult
                    title="棚割・フォルダがありません"
                    message={planogramDirectoryEmptyMessage(
                      hasQueries,
                      isParentTypeDirectory
                    )}
                    isErrorIcon
                  />
                </Box>
              )}
            </>
          );
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableBody:
          isEmpty && !isLoading && !isRefetching
            ? () => (
                <Box component="div" height="100%" width={tableWidth}>
                  <EmptyResult
                    title="棚割・フォルダがありません"
                    message={planogramDirectoryEmptyMessage(
                      hasQueries,
                      isParentTypeDirectory
                    )}
                    isErrorIcon
                  />
                </Box>
              )
            : TableBody,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        ScrollSeekPlaceholder: () => (
          <TableRow>
            {columns.map((_, index) => {
              return (
                <TableCell key={index}>
                  <Skeleton animation="wave" width="75%" />
                </TableCell>
              );
            })}
          </TableRow>
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableFoot: GramTableFoot,
      }}
      scrollSeekConfiguration={{
        enter: (velocity) => Math.abs(velocity) > enterVelocity,
        exit: (velocity) => {
          const shouldExit = Math.abs(velocity) < exitVelocity;
          return shouldExit;
        },
      }}
      fixedHeaderContent={() => (
        <TableHeader
          columns={columns}
          firstOrder={firstOrder}
          handleChangeOrder={(order: PlanogramListOrder) =>
            handleChangeOrder(order)
          }
        />
      )}
      fixedFooterContent={() => {
        if (isRefetching) return <GramTableFooter columns={columns} />;
        //TODO: 必要か検討
        // if (planograms?.length && !isLoading && total !== planograms.length)
        //   return (
        //     <LoadMoreTableFooter
        //       columns={columns}
        //       handleClick={() => {
        //         handleEndReached(planograms.length + 1);
        //       }}
        //     />
        //   );
        return <></>;
      }}
      itemContent={(_, planogramDirectory) => (
        <TableCells
          isFilteredByViewed={isFilteredByViewed}
          planogramDirectory={planogramDirectory}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          handleClonePlanogram={handleClonePlanogram}
          handleUpdatePlanogramName={handleUpdatePlanogramName}
          handleUpdateDirectoryName={handleUpdateDirectoryName}
          handleMoveDirectory={handleMoveDirectory}
          handleFavoriteClick={handleFavoriteClick}
          handleSharePermission={handleSharePermission}
        />
      )}
    />
  );
};
