import { FaceAreaControllerBox } from '@components/molecules/faceAreaControllerBox/faceAreaControllerBox';
import { PlanogramInfoDialog } from '@components/molecules/planogramInfoDialog/planogramInfoDialog';
import { Planogram } from '@components/organisms/planogram/planogram';
import { StatisticsValueSelector } from '@components/organisms/statisticsValueSelector/statisticsValueSelector';
import { PlanButtonGroup } from '@components/pages/planogramDetail/fragments/planButtonGroup';
import { BayPartController } from '@components/pages/planogramEditor/fragments/bayPartController';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useZoomController } from '@hooks/useZoomController';
import { Box, Button } from '@mui/material';
import { changeEditorMode } from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import {
  EstimateForPlanogramResponse,
  useGetStoreQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { getDisplayValue, pointersDummy, getProfitsMenu } from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import {
  isPlanogramBucketDetail,
  isPlanogramPtsDetail,
} from '@utils/planogram';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import { PlanogramPlan, Planogram as PlanogramType } from 'types/planogram';
import { theme } from '../../../../theme';
import { ComparisonBayHeader } from '@components/molecules/comparisionBayHeader/comparisionBayHeader';
import { t } from 'i18next';
import { useBreakpoint } from '@hooks/useBreakpoint';

type Props = {
  plan: PlanogramPlan;
  planogram?: PlanogramType;
  createdAt: string;
  backgroundColor: string;
  handleOpenSearchModal?: () => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  detailView: ShelfDetailView;
  detailMode: ShelfDetailMode;
  isEditor: boolean;
  handleMoveUpBayPart: () => void;
  handleMoveDownBayPart: () => void;
  handleClickAway: () => void;
  step?: number;
  isRightSide?: boolean;
  profit?: string;
  handleRecalculate: () => void;
  planogramEstimatedData?: EstimateForPlanogramResponse;
  isBucketType?: boolean;
  isSwappingBayPartMode?: boolean;
  isTenantSalesAnalytics?: boolean;
};

const zoomButtonPosition = 40;

export const PlanogramComparisonDetail: FC<Props> = ({
  plan,
  createdAt,
  backgroundColor,
  handleOpenSearchModal,
  onClickNext,
  onClickPrev,
  detailView,
  detailMode,
  isEditor,
  planogram,
  handleMoveUpBayPart,
  handleMoveDownBayPart,
  step,
  handleClickAway,
  handleRecalculate,
  planogramEstimatedData,
  isBucketType = false,
  isSwappingBayPartMode,
  isTenantSalesAnalytics = false,
}) => {
  const { isLarger } = useBreakpoint();
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const { mode } = useAppSelector(selectPlanogramEditorState);
  const {
    zoomScale,
    handleIncrementZoom,
    handleDecrementZoom,
    handleTriggerZoom,
  } = useZoomController();
  const { handleChangeProfit, storeAreaType, profit, isLoadingEstimate } =
    useEstimatedProfit({
      planogram,
      editedPlan: plan,
    });
  const { name } = planogram ?? {};
  const ref = useRef<HTMLDivElement>();
  const [positionLeft, setPositionLeft] = useState(0);
  const handleResize = useCallback(() => {
    if (ref.current?.getBoundingClientRect()) {
      setPositionLeft(ref.current.getBoundingClientRect().left);
    }
  }, [setPositionLeft]);

  const { data: storeBayPlan } = useGetStoreQuery(
    {
      storeId: planogram?.store_id ?? 0,
    },
    {
      skip: !planogram?.store_id,
    }
  );
  console.log({ positionLeft, ref: ref.current });

  useEffect(() => {
    if (!positionLeft && ref.current) {
      setPositionLeft(ref.current.getBoundingClientRect().left);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, positionLeft]);
  const dispatch = useAppDispatch();

  if (
    !planogram?.plan ||
    isPlanogramPtsDetail(planogram?.plan) ||
    // Todo: 平置きゴンドラで比較機能作成時にremove
    isPlanogramBucketDetail(planogram?.plan)
  ) {
    console.error('unsupported planogram type');
    return <></>;
  }

  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};

  const term = getTextDateStatistic(startDateFromAPI, endDateFromAPI);
  return (
    <>
      {/*if wrap PlanButtonGroup in position:relative parent,*/}
      {/*Planogram won't be scrollable, because we have to put zIndex to PlanButtonGroup to make it clickable*/}
      {!isSwappingBayPartMode && (
        <Box
          component="div"
          position="absolute"
          top={zoomButtonPosition}
          zIndex={5}
          sx={{ transform: `translate(${positionLeft}px, 38px)` }}
        >
          <PlanButtonGroup
            handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
            bboxEnabled={bboxEnabled}
            handleChangeView={() => dispatch(changeEditorMode('preview'))}
            isOrientationModalOpen={false}
            handleIncrementZoom={handleIncrementZoom}
            handleDecrementZoom={handleDecrementZoom}
            handleTriggerZoom={handleTriggerZoom}
            zoomScale={zoomScale}
            isEditor
            isCompared
          />
        </Box>
      )}
      <Box
        ref={ref}
        component="div"
        flex={1}
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <ComparisonBayHeader
          headerStyleDirection={isBucketType ? 'row' : 'column'}
          name={name ?? ''}
          bayName={storeBayPlan?.store?.name ?? '-'}
          isComparisonSource
          bayHeaderActions={
            !isEditor &&
            handleOpenSearchModal && (
              <Button
                variant="outlined"
                onClick={handleOpenSearchModal}
                sx={{
                  height: '28px',
                  p: '0',
                  minWidth: '28px',
                  fontSize: '12px',
                  width: '40px',
                }}
              >
                変更
              </Button>
            )
          }
        >
          {planogram?.store_id && isTenantSalesAnalytics && (
            <StatisticsValueSelector
              value={getDisplayValue(
                profit,
                planogramEstimatedData?.estimate.summary,
                t('gross_profit')
              )}
              profits={getProfitsMenu(t('gross_profit'))}
              pointers={pointersDummy}
              selectedProfitType={profit}
              selectedPointerType={storeAreaType}
              handleChangeProfitValue={handleChangeProfit}
              handleRefreshValue={handleRecalculate}
              hasRefreshButton
              category="シミュレーション"
              term={term}
              disabled={isSwappingBayPartMode}
              isLoading={isLoadingEstimate}
            />
          )}
        </ComparisonBayHeader>

        <Box
          //NOTE: for overflow. do not add property except overflow
          component="div"
          flex={1}
          overflow="auto"
          sx={{
            backgroundColor,
          }}
          onClick={handleClickAway}
        >
          <Box
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              transformOrigin: `top left`,
              py: 3,

              transform: isLarger
                ? `scale(${zoomScale},${zoomScale})`
                : `scale(${zoomScale - 0.1},${zoomScale - 0.1})`,

              minHeight: isLarger ? '100%' : '110%', //NOTE: 110% because scale - 0.1
              paddingLeft: isLarger ? 0 : '10%',
            }}
          >
            <Planogram
              plan={isEditor ? plan : planogram.plan}
              bboxEnabled={bboxEnabled}
              detailMode={detailMode}
              detailView={detailView}
              isEditor={isEditor}
            />
          </Box>
        </Box>
        {mode === 'BayEditor' && step && isEditor && !isSwappingBayPartMode && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              bottom: '45px',
            }}
          >
            <BayPartController
              step={step}
              onUpClick={() => handleMoveUpBayPart()}
              onDownClick={() => handleMoveDownBayPart()}
            />
          </Box>
        )}
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={0.25}
          px={2}
          height={40}
          sx={{
            backgroundColor: theme.palette.white.primary,
          }}
        >
          <FaceAreaControllerBox
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            createdAt={createdAt}
          />
        </Box>
      </Box>
      <PlanogramInfoDialog
        planogram={planogram}
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
      />
    </>
  );
};
