import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@mui/material';
import { GetProductsParams } from '@reducers/shelfAppsApi';
import { rowsPerPage } from '@utils/const';
import { FC } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductOrder } from 'types/products';
import { GramTableFoot } from '../gramTableFoot/gramTableFoot';
import { GramTableFooter } from '../gramTableFooter/gramTableFooter';
import { ProductsTableHeader } from '../productsTableHeader/productsTableHeader';
import { columns } from './fragments/productsTableHeader';
import { TableCells } from './fragments/tableCells';

type ProductsTableProps = {
  products?: Product[];
  isLoading: boolean;
  condition: GetProductsParams;
  handleRowClick: (product: Product) => void;
  handleEndReached: (index: number) => void;
  handleChangeOrder: (order: ProductOrder) => void;
  offset: number;
};

const rowHeight = 64;
const enterVelocityThreshold = 400;
const exitVelocityThreshold = 2;

export const ProductsTable: FC<ProductsTableProps> = ({
  products,
  isLoading,
  condition,
  handleRowClick,
  handleEndReached,
  handleChangeOrder,
  offset,
}) => {
  return (
    <TableVirtuoso
      style={{
        overflow: 'auto',
        border: '1px solid #CCCCCC',
      }}
      data={products?.length ? products : new Array<Product>(rowsPerPage)}
      endReached={(index) => handleEndReached(index)}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        Table: (props: TableProps) => (
          <Table
            {...props}
            style={{
              tableLayout: 'fixed',
              borderCollapse: 'separate',
            }}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableHead,
        // Note: TableRowに一行に対するスタイルや動作を設定し、itemContentに行内の各カラムに対するスタイルや動作設定する。
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableRow: (props: { item: Product; 'data-index': number }) => {
          return (
            <>
              {offset === 0 && isLoading ? (
                <TableRow {...props}>
                  {columns.map((_, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton animation="wave" width="75%" />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : (
                <TableRow
                  sx={{
                    ':hover': {
                      backgroundColor: theme.palette.backgroundBlack.hover,
                    },
                  }}
                  onClick={() => {
                    handleRowClick(props.item);
                  }}
                  {...props}
                />
              )}
            </>
          );
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableBody: TableBody,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        ScrollSeekPlaceholder: () => (
          <TableRow>
            {columns.map((_, index) => {
              return (
                <TableCell key={index}>
                  <Skeleton animation="wave" width="75%" />
                </TableCell>
              );
            })}
          </TableRow>
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableFoot: GramTableFoot,
      }}
      scrollSeekConfiguration={{
        enter: (velocity) => Math.abs(velocity) > enterVelocityThreshold,
        exit: (velocity) => {
          const shouldExit = Math.abs(velocity) < exitVelocityThreshold;
          return shouldExit;
        },
      }}
      fixedHeaderContent={() => (
        <ProductsTableHeader
          columns={columns}
          condition={condition}
          handleChangeOrder={(order: ProductOrder) => handleChangeOrder(order)}
        />
      )}
      fixedFooterContent={() => {
        if (!isLoading || !products) return <></>;
        if (products.length > 0)
          return <GramTableFooter columns={columns} height={rowHeight} />;
        return <></>;
      }}
      itemContent={(_, product) => <TableCells product={product} />}
    />
  );
};
