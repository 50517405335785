import { objectToSnake } from 'ts-case-convert';
import { paths } from 'types/api';
import {
  baseApi,
  KeysToCamelcase,
  PlanogramDirectoryTags,
  PlanogramTags,
  DirectoryRolesTags,
} from '../baseApi';

type GetPlanogramDirectoriesParams = KeysToCamelcase<
  paths['/api/v1/planogram_directories']['get']['parameters']['query']
>;
type GetPlanogramDirectoriesResponse =
  paths['/api/v1/planogram_directories']['get']['responses']['200']['content']['application/json'];

type PostPlanogramDirectoriesParams = KeysToCamelcase<
  paths['/api/v1/planogram_directories']['post']['requestBody']['content']['application/json']
>;
type PostPlanogramDirectoriesResponse =
  paths['/api/v1/planogram_directories']['post']['responses']['200']['content']['application/json'];

type PutPlanogramDirectoryParams = KeysToCamelcase<
  paths['/api/v1/planogram_directories/{id}']['put']['parameters']['path'] &
    paths['/api/v1/planogram_directories/{id}']['put']['requestBody']['content']['application/json']
>;
type PutPlanogramDirectoryResponse =
  paths['/api/v1/planogram_directories/{id}']['put']['responses']['200']['content']['application/json'];

type DeletePlanogramDirectoryParams = KeysToCamelcase<
  paths['/api/v1/planogram_directories/{id}']['delete']['parameters']['path']
>;
type DeletePlanogramDirectoryResponse =
  paths['/api/v1/planogram_directories/{id}']['delete']['responses']['200']['content']['application/json'];

export type GetSearchPlanogramDirectoriesParams = KeysToCamelcase<
  paths['/api/v1/planogram_directories/search']['get']['parameters']['query']
>;
type GetSearchPlanogramDirectoriesResponse =
  paths['/api/v1/planogram_directories/search']['get']['responses']['200']['content']['application/json'];

type GetPlanogramDirectoryParams = KeysToCamelcase<
  paths['/api/v1/planogram_directories/{id}']['get']['parameters']['path']
>;
type GetPlanogramDirectorResponse =
  paths['/api/v1/planogram_directories/{id}']['get']['responses']['200']['content']['application/json'];

export const planogramDirectoriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listPlanogramDirectories: builder.query<
      GetPlanogramDirectoriesResponse,
      GetPlanogramDirectoriesParams
    >({
      query: (params) => ({
        url: 'planogram_directories',
        params: params ? objectToSnake(params) : undefined,
      }),
      providesTags: [
        PlanogramDirectoryTags.CreatePlanogramDirectory,
        PlanogramDirectoryTags.UpdatePlanogramDirectory,
        PlanogramTags.CreatePlanogram,
        PlanogramTags.UpdatePlanogram,
        PlanogramDirectoryTags.ListPlanogramDirectory,
        DirectoryRolesTags.UpdateDirectoryRole,
        DirectoryRolesTags.DeleteDirectoryRole,
      ],
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        // check is filters changed or not
        if (currentCache.pager.offset === newItems.pager.offset) {
          currentCache.parent = newItems.parent;
          currentCache.pager = newItems.pager;
          currentCache.breadcrumb = newItems.breadcrumb;
          currentCache.planogram_directories = [
            ...newItems.planogram_directories,
          ];
        } else {
          currentCache.planogram_directories.push(
            ...newItems.planogram_directories
          );
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getPlanogramDirectories: builder.query<
      GetPlanogramDirectoriesResponse,
      GetPlanogramDirectoriesParams
    >({
      query: (params) => ({
        url: 'planogram_directories',
        params: params ? objectToSnake(params) : undefined,
      }),
      providesTags: [
        PlanogramDirectoryTags.CreatePlanogramDirectory,
        PlanogramDirectoryTags.UpdatePlanogramDirectory,
        PlanogramTags.CreatePlanogram,
        PlanogramTags.UpdatePlanogram,
        PlanogramDirectoryTags.ListPlanogramDirectory,
        DirectoryRolesTags.UpdateDirectoryRole,
        DirectoryRolesTags.DeleteDirectoryRole,
      ],
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache.pager.offset === newItems.pager.offset) {
          currentCache.breadcrumb = [...newItems.breadcrumb];
          currentCache.parent = newItems.parent;
          currentCache.pager = newItems.pager;
          currentCache.planogram_directories = [
            ...newItems.planogram_directories,
          ];
        } else {
          currentCache.planogram_directories.push(
            ...newItems.planogram_directories
          );
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    createPlanogramDirectory: builder.mutation<
      PostPlanogramDirectoriesResponse,
      PostPlanogramDirectoriesParams
    >({
      query: (params) => ({
        url: 'planogram_directories',
        method: 'POST',
        body: objectToSnake(params),
      }),
      invalidatesTags: [PlanogramDirectoryTags.CreatePlanogramDirectory],
    }),
    updatePlanogramDirectory: builder.mutation<
      PutPlanogramDirectoryResponse,
      PutPlanogramDirectoryParams
    >({
      query: ({ id, ...params }) => ({
        url: `planogram_directories/${id}`,
        method: 'PUT',
        body: params ? objectToSnake(params) : undefined,
      }),
      invalidatesTags: [
        PlanogramDirectoryTags.ListPlanogramDirectory,
        PlanogramDirectoryTags.GetPlanogramDirectory,
      ],
    }),
    deletePlanogramDirectory: builder.mutation<
      DeletePlanogramDirectoryResponse,
      DeletePlanogramDirectoryParams
    >({
      query: ({ id }) => ({
        url: `planogram_directories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [PlanogramTags.DeletePlanogram],
    }),
    searchPlanogramDirectories: builder.query<
      GetSearchPlanogramDirectoriesResponse,
      GetSearchPlanogramDirectoriesParams
    >({
      query: (params) => ({
        url: '/planogram_directories/search',
        params: params ? objectToSnake(params) : undefined,
      }),
      providesTags: [
        PlanogramDirectoryTags.ListPlanogramDirectory,
        PlanogramDirectoryTags.CreatePlanogramDirectory,
        DirectoryRolesTags.UpdateDirectoryRole,
        DirectoryRolesTags.DeleteDirectoryRole,
      ],
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        // check is filters changed or not
        if (currentCache.pager.offset === newItems.pager.offset) {
          currentCache.pager = newItems.pager;
          currentCache.planogram_directories = [
            ...newItems.planogram_directories,
          ];
        } else {
          currentCache.planogram_directories.push(
            ...newItems.planogram_directories
          );
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getPlanogramDirectory: builder.query<
      GetPlanogramDirectorResponse,
      GetPlanogramDirectoryParams
    >({
      query: ({ id }) => ({
        url: `/planogram_directories/${id}`,
        method: 'GET',
      }),
      providesTags: [
        PlanogramDirectoryTags.UpdatePlanogramDirectory,
        PlanogramDirectoryTags.GetPlanogramDirectory,
      ],
    }),
    // NOTE: This endpoint is used to avoid using same cache as searchPlanogramDirectories
    searchPlanogram: builder.query<
      GetSearchPlanogramDirectoriesResponse,
      GetSearchPlanogramDirectoriesParams
    >({
      query: (params) => ({
        url: '/planogram_directories/search',
        params: params ? objectToSnake(params) : undefined,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        // check is filters changed or not
        if (currentCache.pager.offset === newItems.pager.offset) {
          currentCache.pager = newItems.pager;
          currentCache.planogram_directories = [
            ...newItems.planogram_directories,
          ];
        } else {
          currentCache.planogram_directories.push(
            ...newItems.planogram_directories
          );
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const {
  useListPlanogramDirectoriesQuery,
  useGetPlanogramDirectoriesQuery,
  useCreatePlanogramDirectoryMutation,
  useUpdatePlanogramDirectoryMutation,
  useDeletePlanogramDirectoryMutation,
  useSearchPlanogramDirectoriesQuery,
  useGetPlanogramDirectoryQuery,
  useSearchPlanogramQuery,
  useLazyListPlanogramDirectoriesQuery,
} = planogramDirectoriesApi;
