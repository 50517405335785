import { BayButton } from '@components/molecules';
import { StyledButtonGroup, ZoomController } from '@components/organisms';
import { headerHeight } from '@components/organisms/header/header';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { Redo, RotateRight, Undo } from '@mui/icons-material';
import { Box, Button, ClickAwayListener } from '@mui/material';
import {
  changeIsRotateTransitionFlatPlanogram,
  rotateGondola,
  rotateGondolaInCompare,
  setIsTooltipOpen,
  setSelectedBucketId,
  updateIsShowProductDetail,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { fullRotation, rotationAngleFlatPlanogram } from '@utils/const';
import { FC, useEffect } from 'react';
import { CustomWidthTooltip } from './customWidthTooltip';
import { PreviewIcon } from '@components/molecules/previewIcon/previewIcon';
import { FaceAreaButton } from '@components/molecules/faceAreaControllerBox/fragments/faceAreaButton';

type Props = {
  zoomScale: number;
  handleNavigateView?: () => void;
  handleIncrementZoom: () => void;
  handleDecrementZoom: () => void;
  handleTriggerZoom?: () => void;
  isOrientationModalOpen: boolean;
  isEditor?: boolean;
  handleChangeView?: () => void;
  isFlatPlanogram?: boolean;
  bboxEnabled?: boolean;
  handleChangeBboxEnabled?: () => void;
  isCompared?: boolean;
};

const bayButtonPosition = 24;

export const PlanButtonGroup: FC<Props> = ({
  handleNavigateView,
  handleIncrementZoom,
  handleDecrementZoom,
  handleTriggerZoom,
  zoomScale,
  isOrientationModalOpen,
  isEditor = true,
  handleChangeView,
  isFlatPlanogram = false,
  bboxEnabled = true,
  handleChangeBboxEnabled = () => {
    console.log('Enable');
  },
  isCompared = false,
}) => {
  const dispatch = useAppDispatch();
  const { undo, redo, hasNext, hasPrev } = usePlanogramPlan();
  const {
    undoFlat,
    redoFlat,
    hasNextFlat,
    hasPrevFlat,
    isRotated,
    plan,
    resetIsRotated,
    updateBucketProductPosition,
  } = useFlatPlanogramPlan();
  const { rotateDeg, isTooltipOpen, rotateDegInCompare } = useAppSelector(
    selectPlanogramEditorState
  );

  const handleClickRotate = () => {
    dispatch(changeIsRotateTransitionFlatPlanogram(true));
    if (isEditor || !isCompared) {
      dispatch(
        rotateGondola(
          rotateDeg === 0
            ? rotationAngleFlatPlanogram
            : rotationAngleFlatPlanogram + rotateDeg
        )
      );
    } else {
      dispatch(
        rotateGondolaInCompare(
          rotateDegInCompare === 0
            ? rotationAngleFlatPlanogram
            : rotationAngleFlatPlanogram + rotateDegInCompare
        )
      );
    }

    // NOTE: 回転したら、上段の選択状態は全て解除される必要があるため、ここでリセットを行う
    dispatch(setSelectedBucketId(undefined));
    updateBucketProductPosition(undefined);
    dispatch(updateSelectedProductCompartment(undefined));
    dispatch(updateIsShowProductDetail(false));
  };

  const handlePreview = () => {
    dispatch(rotateGondola(0));
    dispatch(rotateGondolaInCompare(0));
    isEditor
      ? handleChangeView && handleChangeView()
      : handleNavigateView && handleNavigateView();
  };

  // 平置きのundo, redoの際にrotateDegを変更させるための処理
  useEffect(() => {
    if (isRotated) {
      dispatch(
        rotateGondola(
          rotateDeg % fullRotation === 0 ? rotationAngleFlatPlanogram : 0
        )
      );
      resetIsRotated();
    }
  }, [plan, isRotated, dispatch, resetIsRotated, rotateDeg]);

  const handleUndo = () => {
    if (isFlatPlanogram) {
      undoFlat();
      dispatch(changeIsRotateTransitionFlatPlanogram(false));
    } else {
      undo();
    }
  };
  const handleRedo = () => {
    if (isFlatPlanogram) {
      redoFlat();
      dispatch(changeIsRotateTransitionFlatPlanogram(false));
    } else {
      redo();
    }
  };

  return (
    <Box
      component="div"
      position="absolute"
      top={bayButtonPosition + headerHeight}
      left={bayButtonPosition}
      display="flex"
      flexDirection="column"
      gap={1}
      mt={`${isCompared ? '24px' : '0px'}`}
    >
      {!isCompared && (
        <BayButton onClick={handlePreview} variant="contained">
          <PreviewIcon />
        </BayButton>
      )}
      {isEditor && !isCompared && (
        <StyledButtonGroup orientation="vertical">
          <Button
            onClick={handleUndo}
            disabled={
              (isFlatPlanogram ? !hasPrevFlat : !hasPrev) ||
              isOrientationModalOpen
            }
          >
            <Undo data-testid="undo" />
          </Button>
          <Button
            onClick={handleRedo}
            disabled={
              (isFlatPlanogram ? !hasNextFlat : !hasNext) ||
              isOrientationModalOpen
            }
          >
            <Redo data-testid="redo" />
          </Button>
        </StyledButtonGroup>
      )}

      <ZoomController
        zoomScale={zoomScale}
        handleIncrementZoom={handleIncrementZoom}
        handleDecrementZoom={handleDecrementZoom}
        handleTriggerZoom={handleTriggerZoom}
      />
      {isFlatPlanogram && (
        <ClickAwayListener
          onClickAway={() => {
            if (isTooltipOpen) {
              dispatch(setIsTooltipOpen(false));
            }
          }}
        >
          <Box component="div">
            <CustomWidthTooltip
              open={isTooltipOpen && !isCompared}
              title="反対側のカゴを編集するにはゴンドラを回転させてください"
              placement="right"
              arrow
              disableHoverListener
              disableTouchListener
            >
              <StyledButtonGroup orientation="vertical">
                <Button onClick={handleClickRotate}>
                  <RotateRight />
                </Button>
              </StyledButtonGroup>
            </CustomWidthTooltip>
          </Box>
        </ClickAwayListener>
      )}
      <Box component="div" display="flex" gap={1} alignItems="center">
        <FaceAreaButton
          enabled={bboxEnabled}
          onClick={handleChangeBboxEnabled}
        />
      </Box>
    </Box>
  );
};
