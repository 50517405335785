import { Box } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { getFlatPlanogramAreaWidthAndCenter } from '@utils/planogram';
import { FC, useMemo } from 'react';
import { BucketPlanogramPlan } from 'types/planogram';
import { FlatPlanogram } from '../flatPlanogram/flatPlanogram';

type Props = {
  plan: BucketPlanogramPlan;
  scale: number;
  handleClickAway: () => void;
  zoomScale: number;
  setZoomScale: (zoomScale: number) => void;
  bboxEnabled?: boolean;
  isEditor?: boolean;
  handleChangeFront?: () => void;
};

export const ZoomableFlatPlanogram: FC<Props> = ({
  plan,
  scale,
  handleClickAway,
  zoomScale,
  setZoomScale,
  bboxEnabled,
  isEditor = true,
  handleChangeFront,
}) => {
  const { detailMode, detailView } = useAppSelector(selectPlanogramEditorState);
  const { areaWidth, left } = useMemo(() => {
    return getFlatPlanogramAreaWidthAndCenter(plan.frame.detail);
  }, [plan.frame.detail]);

  return (
    <Box
      component="div"
      sx={{
        flex: 1,
        overflow: 'auto',
        width: `${areaWidth * zoomScale}px`,
      }}
    >
      <Box
        component="div"
        sx={{
          minHeight: '100%',
          py: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            transform: `scale(${scale},${scale})`,
            transformOrigin: 'top center',
            alignItems: 'center',
            height: '100%',
            marginLeft: `${left * zoomScale}px`,
          }}
          onClick={handleClickAway}
        >
          <FlatPlanogram
            plan={plan}
            detailMode={detailMode}
            detailView={detailView}
            zoomScale={zoomScale}
            setZoomScale={setZoomScale}
            bboxEnabled={bboxEnabled}
            isEditor={isEditor}
            handleChangeFront={handleChangeFront}
          />
        </Box>
      </Box>
    </Box>
  );
};
