import { FC } from 'react';
import { theme } from 'theme';
import { Box, Typography } from '@mui/material';
import { InferType } from 'yup';
import { schema } from '../realogram.schema';
import { FlatRealogramRightEmpty } from '@components/molecules/flatRealogramRightEmpty/flatRealogramRightEmpty';
import { OtherImageUploadButton } from '../../../buttons/otherImageUploadButton';

type Props = {
  file?: File;
  isLeft: boolean;
  onFirstFileSelect: (file: File) => void;
  onSecondFileSelect?: (file: File) => void;
};

export type RealogramFormData = InferType<typeof schema>;

export const NoFlatImage: FC<Props> = ({
  isLeft,
  onFirstFileSelect,
  onSecondFileSelect,
}) => {
  return (
    <Box component="div" gap={1}>
      <Box
        component="div"
        borderRadius="4px"
        height="270px"
        width="360px"
        textAlign="center"
        gap={1}
        pt={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.primary.selected,
        }}
      >
        <FlatRealogramRightEmpty
          sx={{
            transform: `${isLeft ? 'rotate(180deg)' : 'rotate(0deg)'}`,
            width: 300,
            fontSize: 100,
            pb: 1,
          }}
        />
        <OtherImageUploadButton
          onFileSelect={onFirstFileSelect}
          leftOrRight={isLeft ? 'left' : 'right'}
          onSecondFileSelect={onSecondFileSelect}
        />
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        pt={2}
      >
        <Typography variant="buttonText1" color={theme.palette.white.primary}>
          {isLeft ? '左半分' : '右半分'}
        </Typography>
      </Box>
    </Box>
  );
};
