import { useState } from 'react';
import httpStatus from 'http-status';
import { useCreateRealogramCandidateImageMutation } from '@reducers/shelfAppsApi';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { useAppDispatch } from '@store/index';
import { RealogramFormData } from '../fragments/stepFour/realogramForm/forms/realogramForm';
import { isApiResponse } from '@utils/const';
import { ChangeStep, RealogramCandidate, StepFiveInfo } from 'types/realogram';
import { BayPlanCode } from 'types/bayPlan';

export const useRealogramFileUpload = (
  changeStep: ChangeStep,
  handleSetSkipRealogramStepThree: (isSkip: boolean) => void,
  isSkipStepThree: boolean,
  handleSetSkipFlatRealogramStepThree: (isSkip: boolean) => void,
  isFlatSkipStepThree: boolean,
  bayPlanCodes?: BayPlanCode[],
  setFormValue?: (type: 'storeId' | 'storeBayId', id: number) => void
) => {
  const dispatch = useAppDispatch();
  const [firstFile, setFirstFile] = useState<File>();
  const [secondFile, setSecondFile] = useState<File>();
  const [failedDialogOpen, setFailedDialogOpen] = useState(false);
  const [stepFiveInfo, setStepFiveInfo] = useState<StepFiveInfo>();
  const [failedDialogOpenForbidden, setFailedDialogOpenForbidden] =
    useState(false);
  const [createRealogramCandidateImage] =
    useCreateRealogramCandidateImageMutation();

  const onFirstFileSelect = (file?: File) => {
    setFirstFile(file);
  };
  const onSecondFileSelect = (file?: File) => {
    setSecondFile(file);
  };

  const onSwapFile = (file?: File, secondFile?: File) => {
    onFirstFileSelect(secondFile);
    onSecondFileSelect(file);
  };

  const onStepFiveInfo = (
    realogramCandidate: RealogramCandidate,
    data: RealogramFormData
  ) => {
    const bayPlan = bayPlanCodes?.find((d) => d.id === data.bayPlanId);
    setStepFiveInfo({
      id: realogramCandidate.id,
      storeName: realogramCandidate.store_bay.store.name,
      storeBayName: realogramCandidate.store_bay.name,
      bayPlanName: bayPlan?.name,
      firstFile: firstFile,
      secondFile: secondFile,
    });
  };

  const onSingleRealogramSubmit = async (data: RealogramFormData) => {
    setFailedDialogOpen(false);
    setFailedDialogOpenForbidden(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      const { upload_url: uploadUrl, realogram_candidate: realogramCandidate } =
        await createRealogramCandidateImage({
          storeBayId: Number(data.storeBayId),
          shotType: 'single_shelve',
          shotCount: 1,
        }).unwrap();
      await fetch(uploadUrl, {
        mode: 'cors',
        method: 'PUT',
        headers: { 'Content-Type': firstFile?.type ?? '' },
        body: firstFile,
      });
      onStepFiveInfo(realogramCandidate, data);
      setFormValue && setFormValue('storeBayId', 0);
      changeStep('four', true);
      handleSetSkipRealogramStepThree(isSkipStepThree);
    } catch (error) {
      console.log({ error });
      if (isApiResponse(error) && error?.status === httpStatus.FORBIDDEN)
        setFailedDialogOpenForbidden(true);
      else setFailedDialogOpen(true);
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  const onFlatRealogramSubmit = async (data: RealogramFormData) => {
    setFailedDialogOpen(false);
    setFailedDialogOpenForbidden(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      const {
        upload_urls: uploadUrls,
        realogram_candidate: realogramCandidate,
      } = await createRealogramCandidateImage({
        storeBayId: Number(data.storeBayId),
        shotType: 'split_bucket',
        shotCount: 2,
      }).unwrap();
      await Promise.all([
        fetch(uploadUrls[0], {
          mode: 'cors',
          method: 'PUT',
          headers: { 'Content-Type': firstFile?.type ?? '' },
          body: firstFile,
        }),
        fetch(uploadUrls[1], {
          mode: 'cors',
          method: 'PUT',
          headers: { 'Content-Type': secondFile?.type ?? '' },
          body: secondFile,
        }),
      ]);

      onStepFiveInfo(realogramCandidate, data);
      changeStep('four', true);
      handleSetSkipFlatRealogramStepThree(isFlatSkipStepThree);
    } catch (error) {
      console.log({ error });
      if (isApiResponse(error) && error?.status === httpStatus.FORBIDDEN)
        setFailedDialogOpenForbidden(true);
      else setFailedDialogOpen(true);
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  return {
    firstFile,
    onFirstFileSelect,
    secondFile,
    onSecondFileSelect,
    onSwapFile,
    onSingleRealogramSubmit,
    onFlatRealogramSubmit,
    stepFiveInfo,
    // ↓ダイアログ関連
    failedDialogOpen,
    failedDialogOpenForbidden,
    setFailedDialogOpen,
    setFailedDialogOpenForbidden,
  };
};
