import { ProductDetail } from '@components/organisms/productDetail/productDetail';
import { ArrowBackIos } from '@mui/icons-material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Button, IconButton } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { FaceFrontId, Product, ProductImageSize } from '../../../types/common';

type Props = {
  handleClick: () => void;
  selectedProduct: Product;
  imageSize: ProductImageSize;
  showDetail?: boolean;
  hasEditButton?: boolean;
  hasBackButton?: boolean;
  handleEditOpen?: () => void;
  onProductImageClick?: () => void;
  faceFrontId?: FaceFrontId;
  onFaceFrontIdChange?: (faceFrontId: FaceFrontId) => void;
  hasFaceFrontSwitcher?: boolean;
  hasMakerInfo?: boolean;
  isProductDetailRow?: boolean;
};

export const ProductDetailDrawer: FC<Props> = ({
  handleClick,
  selectedProduct,
  imageSize,
  hasEditButton,
  hasBackButton,
  showDetail,
  handleEditOpen,
  onProductImageClick,
  faceFrontId,
  onFaceFrontIdChange,
  hasFaceFrontSwitcher = false,
  hasMakerInfo = false,
  // 編集時の商品詳細をタブレットでは横並びにする
  isProductDetailRow = false,
}) => {
  return (
    <Box
      component="div"
      height={{ xs: '100%', breakpoint: 'auto' }}
      overflow={{ xs: 'auto', breakpoint: 'visible' }}
    >
      {hasEditButton && (
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="40px"
          width="100%"
          mt={2}
          pr={2}
          pl={1}
        >
          <IconButton onClick={handleClick}>
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
          <Button
            variant="outlined"
            onClick={handleEditOpen}
            sx={{
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
            }}
          >
            編集
          </Button>
        </Box>
      )}
      {hasBackButton && (
        <Button
          variant="outlined"
          startIcon={
            <ArrowBackIos
              sx={{
                color: theme.palette.primary.main,
                width: '1rem',
                height: '1rem',
              }}
            />
          }
          onClick={handleClick}
          sx={{
            mt: 2,
            ml: 3,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            width: '72px',
            height: '32px',
            padding: '0 12px',
            '& .MuiButton-startIcon': { margin: '0' },
          }}
        >
          戻る
        </Button>
      )}
      <ProductDetail
        product={selectedProduct}
        showDetail={showDetail}
        imageSize={imageSize}
        onProductImageClick={onProductImageClick}
        faceFrontId={faceFrontId}
        onFaceFrontIdChange={onFaceFrontIdChange}
        hasFaceFrontSwitcher={hasFaceFrontSwitcher}
        hasMakerInfo={hasMakerInfo}
        isProductDetailRow={isProductDetailRow}
      />
    </Box>
  );
};
