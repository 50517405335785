import { Draggable } from '@components/organisms/draggable/draggable';
import { useOverflows } from '@hooks/useOverflows';
import { Box } from '@mui/material';
import { updateOverflowState } from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { calculateJustifyMargin, convertMeterToPixel } from '@utils/planogram';
import { getProductDisplaySize } from '@utils/product';
import { FC, MouseEvent, useEffect } from 'react';
import {
  DndData,
  Product,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import {
  BboxColors,
  JustifyContent,
  PlanogramProductCompartment,
  ProductPosition,
} from 'types/planogram';
import { RealogramSelectedItem } from 'types/realogram';
import { ItemTypes } from '../../../types/rack';
import { DisplayableHighlight } from '../displayShelf/fragments/displayableHighlight';
import { ProductsGroup } from '../productsGroup/productsGroup';

type Item = {
  data: DndData | undefined;
  index: number;
  rowIndex: number;
};

type Props = {
  products?: Product[];
  handleClickGroup: (
    e: MouseEvent<HTMLElement>,
    position: ProductPosition,
    product: Product
  ) => void;
  row: PlanogramProductCompartment[];
  rowIndex: number;
  selectedPosition?: ProductPosition;
  selectedProductCompartment?: Product;
  selectedRealogramItem?: RealogramSelectedItem;
  shelfHeight: number;
  shelfWidth: number;
  diffColor?: string;
  bboxColors: BboxColors;
  bboxEnabled?: boolean;
  detailView?: ShelfDetailView;
  detailMode?: ShelfDetailMode;
  justifyContent: JustifyContent;
  item?: Item;
  isEditor?: boolean;
  isCompared?: boolean;
  setIsAllChildRenndered?: (value: boolean) => void;
};

export const ProductCompartments: FC<Props> = ({
  products,
  rowIndex,
  row,
  handleClickGroup,
  shelfHeight,
  shelfWidth,
  selectedPosition,
  selectedProductCompartment,
  selectedRealogramItem,
  diffColor,
  bboxColors,
  bboxEnabled = true,
  detailView,
  detailMode,
  justifyContent,
  item,
  isEditor,
  isCompared = false,
  setIsAllChildRenndered,
}) => {
  const dispatch = useAppDispatch();
  const { mode } = useAppSelector(selectPlanogramEditorState);
  const { compartmentOverflows } = useOverflows(
    row,
    shelfHeight,
    shelfWidth,
    products
  );
  const isEditingBay = mode === 'BayEditor';

  const justifyMargin =
    justifyContent === 'space_evenly'
      ? calculateJustifyMargin(shelfWidth, row, products ?? [])
      : 0;
  const left = row.reduce((acc, c) => {
    const product = products?.find((p) => p.id === c.product_id);
    if (!product) return acc;

    const { width: w } = getProductDisplaySize(
      product,
      c.face_front,
      c.orientation
    );
    const x = c.count?.x ?? 0;
    return acc + (w + justifyMargin) * x;
  }, justifyMargin);

  useEffect(() => {
    if (!(mode === 'CompartmentEditor' && detailMode !== 'comparison')) return;
    dispatch(
      updateOverflowState({
        rowIndex,
        overflow: compartmentOverflows.some((o) => o),
      })
    );
  }, [dispatch, compartmentOverflows, rowIndex, mode, detailMode]);

  const isModeCompartmentEditor =
    detailMode === 'comparison' ? isEditor : mode === 'CompartmentEditor';
  return (
    <>
      {row.map((compartment, index) => {
        const isPlanogramSelected =
          selectedProductCompartment &&
          selectedProductCompartment.id === compartment.product_id;
        const isRealogramSelected =
          selectedRealogramItem &&
          selectedRealogramItem?.item?.primary_candidate?.product_id ===
            compartment.product_id;
        const isPositionSelected =
          selectedPosition?.indexY === rowIndex &&
          selectedPosition.subPosition.indexX === index;
        const isSelected = !!(detailMode === 'comparison'
          ? !selectedRealogramItem?.item.is_unknown &&
            (isPlanogramSelected || isRealogramSelected)
          : isPlanogramSelected && isPositionSelected);
        const product = products?.find(
          (product) => product.id === compartment.product_id
        );

        const left = row.slice(0, index).reduce((acc, c) => {
          const product = products?.find((p) => p.id === c.product_id);
          if (!product) return acc;

          const { width: w } = getProductDisplaySize(
            product,
            c.face_front,
            c.orientation
          );
          const x = c.count?.x ?? 0;
          return acc + (w + justifyMargin) * x;
        }, justifyMargin);

        const { width: w } = product
          ? getProductDisplaySize(
              product,
              compartment.face_front,
              compartment.orientation
            )
          : { width: 0 };
        const width =
          w * (compartment.count?.x ?? 0) +
          justifyMargin * ((compartment.count?.x ?? 1) - 1);

        return (
          // TODO: 重複する可能性があるので、keyを変更する
          <Box
            component="div"
            key={`${compartment.product_id}-${index}`}
            sx={{ position: 'absolute' }}
            left={convertMeterToPixel(left)}
            width={convertMeterToPixel(width)}
          >
            {product && (
              <Draggable
                itemType={ItemTypes.ITEM_GROUP}
                data={{ compartment, product }}
                index={index}
                rowIndex={rowIndex}
                canDrag={isModeCompartmentEditor && !isCompared}
              >
                <Box
                  component="div"
                  width="100%"
                  height="100%"
                  position="relative"
                >
                  <ProductsGroup
                    justifyMargin={justifyMargin}
                    rowIndex={rowIndex}
                    index={index}
                    isSelected={isSelected}
                    product={product}
                    lastIndex={row?.length - 1}
                    setIsAllChildRenndered={setIsAllChildRenndered}
                    {...compartment}
                    isOverflown={
                      !isEditor
                        ? false
                        : compartmentOverflows.at(index) ?? false
                    }
                    handleClickGroup={(e) => {
                      handleClickGroup(
                        e,
                        {
                          indexX: 0,
                          indexY: rowIndex,
                          subPosition: {
                            indexX: index,
                            indexY: 0,
                          },
                        },
                        product
                      );
                    }}
                    diffColor={diffColor}
                    bboxColors={bboxColors}
                    bboxEnabled={bboxEnabled}
                    view={detailView}
                    mode={detailMode}
                    isCompared={isCompared}
                  />
                </Box>
              </Draggable>
            )}
          </Box>
        );
      })}
      <Box
        component="div"
        sx={{ position: 'absolute', left: convertMeterToPixel(left) }}
      >
        {(detailMode === 'comparison'
          ? isEditor && !isEditingBay
          : !isEditingBay) &&
          item?.data &&
          !isCompared && <DisplayableHighlight dndData={item.data} />}
      </Box>
    </>
  );
};
