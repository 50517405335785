import { FC } from 'react';
import { theme } from 'theme';
import { Box, Typography } from '@mui/material';
import { InferType } from 'yup';
import { schema } from '../realogram.schema';
import { ImageUploadButton } from '../../../buttons/imageUploadButton';
import { ImagePreview } from './imagePreview';

type Props = {
  file?: File;
  isLeft: boolean;
  onFirstFileSelect: (file: File) => void;
  onSecondFileSelect?: (file: File) => void;
};

export type RealogramFormData = InferType<typeof schema>;

export const FlatImage: FC<Props> = ({
  file,
  isLeft,
  onFirstFileSelect,
  onSecondFileSelect,
}) => {
  return (
    <Box component="div" gap={1}>
      <Box component="div" width="360px" height="270px">
        <ImagePreview file={file} selectedRealogramType="flat" />
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
        pt={1}
      >
        <Typography variant="buttonText1" color={theme.palette.white.primary}>
          {isLeft ? '左半分' : '右半分'}
        </Typography>
        <ImageUploadButton
          onFileSelect={onFirstFileSelect}
          onSecondFileSelect={onSecondFileSelect}
        />
      </Box>
    </Box>
  );
};
