import { CircularSpinner } from '@components/molecules';
import { Box, Button, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { FC, MutableRefObject, useEffect } from 'react';
import { ProductCategory } from 'types/productCategories';
import { Product } from '../../../types/common';
import { ProductsGrid } from '../productsGrid/productsGrid';
import { ProductsSearchEmptyResults } from './fragments/productsSearchEmptyResults';

type Props = {
  products?: Product[];
  reset: () => void;
  filteredCategories?: ProductCategory[];
  handleClickProductCategory: (category: ProductCategory) => void;
  isLoading: boolean;
  productsRef: MutableRefObject<HTMLDivElement | null>;
  isDraggable?: boolean;
  handleSelectProductClick?: (productId: Product['id']) => void; // Note: actuals配下で利用
  isCompareMode?: boolean;
  handleEndReached: (index: number) => void;
  total?: number;
  handleClickProduct: (product: Product) => void;
  offset: number;
  selectedItemId?: number;
};

const layoutPadding = { pl: 2, pr: 1 };

export const ProductsSearchResults: FC<Props> = ({
  products,
  reset,
  filteredCategories,
  handleClickProductCategory,
  isLoading,
  productsRef,
  isDraggable = true,
  isCompareMode = false,
  handleEndReached,
  handleClickProduct,
  total,
  offset,
  selectedItemId,
}) => {
  const { productsListScrollPos } = useAppSelector(selectPlanogramEditorState);
  useEffect(() => {
    if (productsListScrollPos && productsRef.current) {
      productsRef.current.scrollTo({ top: productsListScrollPos });
    }
  }, [products?.length, productsListScrollPos, productsRef]);
  if (isLoading && offset === 0)
    return (
      <Box component="div" p={2}>
        <CircularSpinner />
      </Box>
    );
  if (products?.length === 0 && filteredCategories?.length === 0 && !isLoading)
    return <ProductsSearchEmptyResults />;

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        pt: 2,
        pb: `${filteredCategories && selectedItemId ? '120px' : '40px'}`,
        ...(isDraggable ? {} : layoutPadding),
      }}
      ref={productsRef}
    >
      <Box
        component="div"
        width="100%"
        ml={1}
        color="#757575" // set color directly at this moment
        gap={2}
        display="flex"
      >
        <Typography>商品</Typography>
        <Typography>{total}件</Typography>
      </Box>
      <ProductsGrid
        products={products}
        handleClick={handleClickProduct}
        isLoading={isLoading}
        handleEndReached={handleEndReached}
        isDraggable={isDraggable}
        isCompareMode={isCompareMode}
        selectedItemId={selectedItemId}
      />
      {filteredCategories && (
        <>
          <Box
            component="div"
            width="100%"
            ml={1}
            pt={2}
            pb={1}
            gap={2}
            display="flex"
            color="#757575" // set color directly at this moment
          >
            <Typography>カテゴリ</Typography>
            <Typography>{filteredCategories.length}件</Typography>
          </Box>
          <Box
            component="div"
            gap={1}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              overflow: 'auto',
              alignContent: 'flex-start',
              // ml: 1,
              // mb: 3,
            }}
          >
            {filteredCategories.map((category) => {
              return (
                <Button
                  key={`${category.name}_${
                    category.product_division_code ?? ''
                  }`}
                  component="div"
                  onClick={() => {
                    reset();
                    handleClickProductCategory(category);
                  }}
                  sx={{
                    color: '#000000',
                    borderColor: '#CCCCCC',
                    whiteSpace: 'nowrap',
                    padding: '4px 10px',
                    fontWeight: 400,
                  }}
                  variant="outlined"
                >
                  {category.name}
                </Button>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
