import { TextPaper } from '@components/molecules';
import { CategoryIconDivider } from '@components/molecules/categoryIconDivider/categoryIconDivider';
import { RealogramCandidateLabel } from '@components/organisms/productCandidate/fragments/realogramCandidateLabel';
import { ProductFlagLabel } from '@components/organisms/realogramCandidatesList/fragments';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { ArrowRight, Edit } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useGetRealogramAnalyticsQuery } from '@reducers/shelfAppsApi';
import {
  formatNumberToYen,
  getProfitTabsValueByKey,
  profitTabGrossProfit,
  profitTabQuantity,
  profitTabSales,
} from '@utils/const';
import { Format, format } from '@utils/date';
import { calculateReportTotal } from '@utils/product';
import { isShowFaceUp } from '@utils/realogram';
import { t } from 'i18next';
import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'theme';
import { ProductSalesReport } from 'types/products';
import { Product, ProfitTab, ShelfDetailView } from '../../../../types/common';
import {
  ProductCandidate,
  RealogramSelectedItem,
} from '../../../../types/realogram';
import { ConfidentProductCard } from './confidentProductCard';
import { EvaluationScore } from './evaluationScore';
import { ProfitAnalysisTable } from './profitAnalysisTable';
import { StatisticCard } from './statisticCard';

type Props = {
  realogramDetailView: ShelfDetailView;
  product?: Product;
  selectedItem: RealogramSelectedItem;
  productSalesReport?: ProductSalesReport[];
  profitTab?: ProfitTab;
  realogramCandidateId?: number;
  isCanNotEditProductAndCompartment?: boolean;
  isTenantSalesAnalytics?: boolean;
  mostHighScoreProduct?: Product;
  mostHighScore?: ProductCandidate;
  handleModalOpen: () => void;
  handleOutOfStockModalOpen: () => void;
  handleChangeCandidate?: (mostHighScoreProduct: ProductCandidate) => void;
  isUnrevisedUnknownProduct?: boolean;
  slideButtons?: ReactNode;
  displayAnalyticsDataIndex: number;
};

const profitComparisonTab = 'area_lv2';
const secondWeekIndex = 1;
const thirdWeekIndex = 2;
const fourthWeekIndex = 3;
const maxReportIndex = 4;
export const ProductCandidateOptionalItem: FC<Props> = ({
  realogramDetailView,
  product,
  selectedItem,
  productSalesReport,
  profitTab,
  realogramCandidateId,
  isCanNotEditProductAndCompartment,
  isTenantSalesAnalytics,
  mostHighScoreProduct,
  mostHighScore,
  handleModalOpen,
  handleOutOfStockModalOpen,
  handleChangeCandidate,
  isUnrevisedUnknownProduct = false,
  slideButtons,
  displayAnalyticsDataIndex,
}) => {
  const { isLarger } = useBreakpoint();
  const { data: analyticsData } = useGetRealogramAnalyticsQuery(
    {
      timeWindow: 'weekly',
      limit: 4,
      realogramCandidateId: realogramCandidateId ?? 0,
      storeAreaType: profitComparisonTab,
    },
    {
      skip:
        !profitComparisonTab ||
        !realogramCandidateId ||
        !isTenantSalesAnalytics,
    }
  );

  const comparedProductSalesReport = analyticsData?.reports
    ?.at(0)
    ?.products.find(
      (el) =>
        el.product_id === selectedItem?.item?.primary_candidate?.product_id
    );

  const comparedGrossSalesPrice =
    comparedProductSalesReport?.gross_sales_price != null
      ? formatNumberToYen(comparedProductSalesReport.gross_sales_price)
      : '-';

  const comparedGrossProfitPrice =
    comparedProductSalesReport?.gross_profit_price != null
      ? formatNumberToYen(comparedProductSalesReport.gross_profit_price)
      : '-';

  const comparedSalesCount =
    comparedProductSalesReport?.sales_count?.toString() ?? '-';

  const { pathname } = useLocation();

  const selectedProductSalesReport = productSalesReport
    ?.at(displayAnalyticsDataIndex)
    ?.products.find(
      (el) => el.product_id === selectedItem?.item.primary_candidate?.product_id
    );

  const grossSalesPrice =
    selectedProductSalesReport?.gross_sales_price != null
      ? formatNumberToYen(selectedProductSalesReport.gross_sales_price)
      : '-';

  const grossProfitPrice =
    selectedProductSalesReport?.gross_profit_price != null
      ? formatNumberToYen(selectedProductSalesReport.gross_profit_price)
      : '-';

  const salesCount = selectedProductSalesReport?.sales_count?.toString() ?? '-';

  const title = `${
    // profitTab could be 0, so check by string
    profitTab?.toString()
      ? getProfitTabsValueByKey(profitTab, t('gross_profit'))
      : ''
  }(4週間)`;

  const differenceGrossSalesPrice =
    selectedProductSalesReport?.gross_sales_price != null &&
    comparedProductSalesReport?.gross_sales_price != null
      ? formatNumberToYen(
          selectedProductSalesReport.gross_sales_price -
            comparedProductSalesReport.gross_sales_price
        )
      : '-';

  const differenceGrossProfitPrice =
    selectedProductSalesReport?.gross_profit_price != null &&
    comparedProductSalesReport?.gross_profit_price != null
      ? formatNumberToYen(
          selectedProductSalesReport.gross_profit_price -
            comparedProductSalesReport.gross_profit_price
        )
      : '-';

  const diffSalesCount =
    selectedProductSalesReport?.sales_count != null &&
    comparedProductSalesReport?.sales_count != null
      ? (
          selectedProductSalesReport.sales_count -
          comparedProductSalesReport.sales_count
        ).toFixed(1)
      : '-';

  const profitTableHeads = [
    { name: '' },
    { name: '店舗' },
    { name: getComparisonLabel(profitComparisonTab) },
    { name: '差分' },
  ];

  const profitAnalysisTableData = [
    {
      name: '導入率',
      value: '-',
      targetValue: '-',
      diffValue: '-',
    },
    {
      name: '売上',
      value: grossSalesPrice,
      targetValue: comparedGrossSalesPrice,
      diffValue: differenceGrossSalesPrice,
    },
    {
      name: t('gross_profit'),
      value: grossProfitPrice,
      targetValue: comparedGrossProfitPrice,
      diffValue: differenceGrossProfitPrice,
    },
    {
      name: '販売数',
      value: salesCount,
      targetValue: comparedSalesCount,
      diffValue: diffSalesCount,
    },
  ];

  //TODO: 判定式のリファクタリング
  const isActual = pathname.includes('actuals');
  if (isActual) {
    switch (realogramDetailView) {
      case 'default':
        return (
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {selectedItem && (
              <>
                {/* 確信度の高い商品 */}
                {isUnrevisedUnknownProduct ? (
                  <ConfidentProductCard
                    selectedItem={selectedItem}
                    mostHighScoreProduct={mostHighScoreProduct}
                    mostHighScore={mostHighScore}
                    isCanNotEditProductAndCompartment={
                      isCanNotEditProductAndCompartment
                    }
                    handleModalOpen={handleModalOpen}
                    handleOutOfStockModalOpen={handleOutOfStockModalOpen}
                    handleChangeCandidate={handleChangeCandidate}
                    slideButtons={slideButtons}
                  />
                ) : (
                  <>
                    {/* 訂正済ラベル */}
                    <RealogramCandidateLabel selectedItem={selectedItem} />
                    <Button
                      disabled={isCanNotEditProductAndCompartment}
                      variant="text"
                      onClick={
                        !selectedItem.item.in_stock ||
                        selectedItem.item.product_candidates.length === 0
                          ? handleOutOfStockModalOpen
                          : handleModalOpen
                      }
                      sx={{
                        marginLeft: selectedItem?.item.is_unknown ? 0 : '16px',
                      }}
                      startIcon={<Edit fontSize="small" />}
                    >
                      <Typography variant="buttonText2">訂正</Typography>
                    </Button>
                  </>
                )}
              </>
            )}
          </Box>
        );
      case 'productFlag':
        return (
          <>
            <Stack direction="row" spacing={0.5} my={1}>
              {product?.detail?.tags?.map((tag) => (
                <ProductFlagLabel key={tag} name={tag} />
              ))}
            </Stack>
            <Typography
              sx={{ fontSize: 12, color: theme.palette.textBlack.secondary }}
            >
              推奨売価：-
            </Typography>
            <Typography
              sx={{ fontSize: 12, color: theme.palette.textBlack.secondary }}
            >
              商品カテゴリ：
              <CategoryIconDivider categories={product?.detail?.categories} />
            </Typography>
          </>
        );
      case 'profit':
        return (
          <>
            <Box component="div" mb={1} mt={isLarger ? 1 : 0}>
              <Box component="div" mt={1} />
              <ProfitAnalysisTable
                tableHeads={profitTableHeads}
                tableRows={profitAnalysisTableData}
              />
            </Box>
            <Box
              component="div"
              display="flex"
              borderRadius="4px"
              border={`1px solid ${theme.palette.divider}`}
              py={0.5}
            >
              <Stack
                direction="row"
                divider={
                  <ArrowRight sx={{ color: theme.palette.dividerBlack.dark }} />
                }
                alignItems="center"
                justifyContent="space-between"
                flex={1}
                px={{ xs: 1, breakpoint: 3 }}
              >
                <StatisticCard
                  title={salesDataDateFrom(
                    productSalesReport?.at(
                      displayAnalyticsDataIndex + fourthWeekIndex
                    )
                  )}
                  value={
                    getSalesDataByWeek(
                      displayAnalyticsDataIndex + fourthWeekIndex,
                      productSalesReport,
                      profitTab,
                      selectedItem?.item.primary_candidate?.product_id
                    ) ?? '-'
                  }
                />
                <StatisticCard
                  title={salesDataDateFrom(
                    productSalesReport?.at(
                      displayAnalyticsDataIndex + thirdWeekIndex
                    )
                  )}
                  value={
                    getSalesDataByWeek(
                      displayAnalyticsDataIndex + thirdWeekIndex,
                      productSalesReport,
                      profitTab,
                      selectedItem?.item.primary_candidate?.product_id
                    ) ?? '-'
                  }
                />
                <StatisticCard
                  title={salesDataDateFrom(
                    productSalesReport?.at(
                      displayAnalyticsDataIndex + secondWeekIndex
                    )
                  )}
                  value={
                    getSalesDataByWeek(
                      displayAnalyticsDataIndex + secondWeekIndex,
                      productSalesReport,
                      profitTab,
                      selectedItem?.item.primary_candidate?.product_id
                    ) ?? '-'
                  }
                />
                <StatisticCard
                  title={salesDataDateFrom(
                    productSalesReport?.at(displayAnalyticsDataIndex)
                  )}
                  value={
                    getSalesDataByWeek(
                      displayAnalyticsDataIndex,
                      productSalesReport,
                      profitTab,
                      selectedItem?.item.primary_candidate?.product_id
                    ) ?? '-'
                  }
                />
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Box component="div" px={{ xs: 1, breakpoint: 3 }}>
                <StatisticCard
                  title={title}
                  value={
                    getSalesDataSummary(
                      productSalesReport?.slice(
                        displayAnalyticsDataIndex,
                        displayAnalyticsDataIndex + maxReportIndex
                      ),
                      profitTab,
                      selectedItem?.item.primary_candidate?.product_id
                    ) ?? '-'
                  }
                />
              </Box>
            </Box>
          </>
        );
      case 'rate':
        return (
          <>
            {isShowFaceUp(selectedItem.item) &&
              selectedItem.item.evaluation?.face_front_score &&
              selectedItem.item.evaluation.foreground_score && (
                <EvaluationScore
                  //NOTE: 型定義上selectedItem.ite.evaluationを渡すとそれ以下のtypeがoptionalとなるため個別に値を渡す
                  evaluation={{
                    face_front_score:
                      selectedItem.item.evaluation.face_front_score,
                    foreground_score:
                      selectedItem.item.evaluation.foreground_score,
                  }}
                />
              )}
          </>
        );
      default:
        return <></>;
    }
  }
  // エディタとそれ以外の表示
  switch (realogramDetailView) {
    case 'default':
      return <></>;
    case 'productFlag':
      return (
        <Stack direction="row" spacing={0.5}>
          {product?.detail?.tags?.map((tag) => (
            <ProductFlagLabel key={tag} name={tag} />
          ))}
        </Stack>
      );
    case 'profit':
      return (
        <Stack direction="row" spacing={1} minHeight="44px">
          <TextPaper title="売上" text="" />
          <TextPaper title="販売個数" text="" />
          <TextPaper title="商品の導入率" text="" />
        </Stack>
      );
    default:
      return <></>;
  }
};

// TODO: ローカル関数のリファクタリング
const getSalesDataByWeek = (
  weekNumber: number,
  reports?: ProductSalesReport[],
  profitTab?: ProfitTab,
  productId?: number
) => {
  // profitTab can be 0 so we check by string
  if (!reports || !profitTab?.toString() || !productId) return;
  const product = reports[weekNumber]?.products.find(
    (product) => product.product_id === productId
  );
  switch (profitTab) {
    case profitTabSales:
      return product?.gross_sales_price != null
        ? formatNumberToYen(product?.gross_sales_price)
        : '-';
    case profitTabQuantity:
      return product?.sales_count != null
        ? product?.sales_count.toString()
        : '-';
    case profitTabGrossProfit:
      return product?.gross_profit_price != null
        ? formatNumberToYen(product?.gross_profit_price)
        : '-';
    default:
      break;
  }
};

const getSalesDataSummary = (
  reports?: ProductSalesReport[],
  profitTab?: ProfitTab,
  productId?: number
) => {
  // profitTab can be 0 so we check by string
  if (!reports || !profitTab?.toString() || !productId) return;
  switch (profitTab) {
    case profitTabSales: {
      const grossSalesPrice = calculateReportTotal(
        'gross_sales_price',
        reports,
        productId
      );
      return grossSalesPrice !== undefined
        ? formatNumberToYen(grossSalesPrice)
        : '-';
    }
    case profitTabQuantity: {
      const salesCount = calculateReportTotal(
        'sales_count',
        reports,
        productId
      );
      return salesCount !== undefined ? salesCount.toString() : '-';
    }
    case profitTabGrossProfit: {
      const grossProfitPrice = calculateReportTotal(
        'gross_profit_price',
        reports,
        productId
      );
      return grossProfitPrice !== undefined
        ? formatNumberToYen(grossProfitPrice)
        : '-';
    }
    default:
      break;
  }
};

const salesDataDateFrom = (report?: ProductSalesReport) => {
  if (!report) return '';
  return `${format(
    report.summary.aggregation_period.start_date,
    Format.dateWithoutYear
  )}~`;
};

const getComparisonLabel = (profitComparisonTab: string) => {
  switch (profitComparisonTab) {
    case 'area_lv2':
      return 'DO平均';
    case 'area_lv1':
      return 'ZO平均';
    default:
      return '全国';
  }
};
