import { ModalInnerProps } from '@components/modal';
import { Box, Button, TextField, Typography } from '@mui/material';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { setPlanogramsPageNumber } from '@reducers/planogram';
import { useUploadPlanogramMutation } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { CommonColors } from '@utils/colors';
import { FC } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useForm,
  useWatch,
} from 'react-hook-form';
import { ModalActions } from '../modalActions/modalActions';
import { toastMessages } from '@utils/const';

type PtsFormData = { name: string; ptsFile: File };

export const PtsForm: FC<ModalInnerProps> = ({ handleClose }) => {
  const { control, handleSubmit } = useForm<PtsFormData>();
  const dispatch = useAppDispatch();
  const { name, ptsFile } = useWatch({ control });
  const [uploadPlanogram] = useUploadPlanogramMutation();
  const { isLoading } = useAppSelector((state) => state.LoadingIndicator);

  const onSubmit = async (data: PtsFormData) => {
    dispatch(updateLoadingIndicatorState(true));
    try {
      await uploadPlanogram(data).unwrap();
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.successImport,
        })
      );
      dispatch(setPlanogramsPageNumber(1));
      handleClose();
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.successImport,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  return (
    <Box component="form" width={400}>
      <Box component="div" mb={3}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField {...field} fullWidth label="ゴンドラ名" />
          )}
        />
      </Box>
      <Controller
        name="ptsFile"
        control={control}
        render={({ field: { onChange } }) => (
          <FileUploadButton onChange={onChange} />
        )}
      />
      {!!ptsFile && (
        <Box
          component="div"
          sx={{ display: 'flex', alignItems: 'center', mt: 2 }}
        >
          <AttachmentSvg />
          <Typography>{ptsFile.name}</Typography>
        </Box>
      )}
      <ModalActions
        handleClose={handleClose}
        handleClick={handleSubmit(onSubmit)}
        actionBtnTitle="インポート"
        disabled={!name || !ptsFile || isLoading}
      />
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const FileUploadButton: FC<{
  onChange: ControllerRenderProps<PtsFormData, 'ptsFile'>['onChange'];
}> = ({ onChange }) => (
  <Button
    component="label"
    sx={{
      p: 0,
      fontWeight: 'bold',
      border: `1px solid ${CommonColors.grayLight}`,
      height: 40,
      px: 1.5,
      color: CommonColors.black,
      ':hover': {
        backgroundColor: CommonColors.lightGray,
      },
    }}
  >
    <input
      hidden
      type="file"
      accept=".csv"
      onChange={({ target }) => {
        if (!target.files) {
          console.log('pts-file upload failed');
          return;
        }
        onChange(target.files[0]);
      }}
    />
    ファイルを選択
  </Button>
);

// eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
const AttachmentSvg = () => (
  <svg
    style={{ margin: '0 10px 0 0' }}
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1763 2.07461C8.70446 0.602734 6.30758 0.602734 4.83727 2.07461L0.759145 6.14961C0.732583 6.17617 0.71852 6.21211 0.71852 6.24961C0.71852 6.28711 0.732583 6.32305 0.759145 6.34961L1.33571 6.92617C1.36206 6.95241 1.39774 6.96714 1.43493 6.96714C1.47212 6.96714 1.50779 6.95241 1.53415 6.92617L5.61227 2.85117C6.11852 2.34492 6.79196 2.0668 7.50758 2.0668C8.22321 2.0668 8.89665 2.34492 9.40133 2.85117C9.90758 3.35742 10.1857 4.03086 10.1857 4.74492C10.1857 5.46055 9.90758 6.13242 9.40133 6.63867L5.24508 10.7934L4.57165 11.4668C3.94196 12.0965 2.91852 12.0965 2.28883 11.4668C1.98415 11.1621 1.81696 10.7574 1.81696 10.3262C1.81696 9.89492 1.98415 9.49024 2.28883 9.18555L6.41227 5.06367C6.51696 4.96055 6.65446 4.90273 6.80133 4.90273H6.8029C6.94977 4.90273 7.08571 4.96055 7.18883 5.06367C7.29352 5.16836 7.34977 5.30586 7.34977 5.45273C7.34977 5.59805 7.29196 5.73555 7.18883 5.83867L3.81852 9.20586C3.79196 9.23242 3.7779 9.26836 3.7779 9.30586C3.7779 9.34336 3.79196 9.3793 3.81852 9.40586L4.39508 9.98242C4.42144 10.0087 4.45711 10.0234 4.4943 10.0234C4.53149 10.0234 4.56717 10.0087 4.59352 9.98242L7.96227 6.61367C8.27321 6.30273 8.44352 5.89023 8.44352 5.45117C8.44352 5.01211 8.27165 4.59805 7.96227 4.28867C7.32008 3.64648 6.27633 3.64805 5.63415 4.28867L5.23415 4.69023L1.51227 8.41055C1.25966 8.66167 1.05942 8.96045 0.923169 9.28956C0.786914 9.61866 0.717353 9.97154 0.71852 10.3277C0.71852 11.0512 1.00133 11.7309 1.51227 12.2418C2.04196 12.7699 2.73571 13.034 3.42946 13.034C4.12321 13.034 4.81696 12.7699 5.34508 12.2418L10.1763 7.41367C10.8873 6.70117 11.281 5.75273 11.281 4.74492C11.2826 3.73555 10.8888 2.78711 10.1763 2.07461Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);
