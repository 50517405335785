import { RequiredText } from '@components/molecules/requiredText/requiredText';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { productTags } from '@utils/const';
import { convertMeterToMilliMeter } from '@utils/planogram';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductFormData } from '../productInformationModal';
import { SizeTextField } from './sizeTextField';

type Props = {
  product: Product;
  isValidSizeFormat: {
    width: boolean;
    height: boolean;
    depth: boolean;
  };
};
type SizeProductType = 'width' | 'height' | 'depth';
export const ProductInfoDetailForm: FC<Props> = ({
  product,
  isValidSizeFormat,
}) => {
  const { control, watch, setValue } = useFormContext<ProductFormData>();
  const productSize =
    product.shape?.size?.display_size ?? product.shape?.size.actual;
  const size = {
    width: productSize && convertMeterToMilliMeter(productSize.width),
    height: productSize && convertMeterToMilliMeter(productSize.height),
    depth: productSize && convertMeterToMilliMeter(productSize.depth),
  };
  const [isHookOn, productTagsArray] = watch(['isHookOn', 'productTagsArray']);

  const onChange = (index: number) => {
    const newArray = [...productTagsArray];
    newArray[index] = !productTags[index];
    setValue('productTagsArray', newArray);
  };
  const handleClickReset = () => {
    if (size.width) setValue('width', size.width);
    if (size.height) setValue('height', size.height);
    if (size.depth) setValue('depth', size.depth);
  };
  const onClickOutSideSize = () => {
    Object.keys(size)?.forEach((dimension) => {
      const dimensionKey = dimension as SizeProductType;
      const dimensionValue = size[dimensionKey];

      if (!isValidSizeFormat[dimensionKey] && dimensionValue) {
        setValue(dimensionKey, dimensionValue);
      }
    });
  };

  return (
    <Stack gap={4} flex={4} color={theme.palette.textBlack.secondary} mb={3}>
      <Stack gap={2} py={1.25}>
        <Typography variant="body1">商品属性</Typography>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
          {productTags.map((tag, index) => (
            <Controller
              key={tag}
              name={`productTagsArray.${index}`}
              control={control}
              render={({ field }) => (
                <FormControl {...field}>
                  <FormControlLabel
                    key={tag}
                    control={
                      <Checkbox checked={productTagsArray[index]} disabled />
                    }
                    onChange={() => onChange(index)}
                    value={tag}
                    label={tag}
                    sx={{
                      color: theme.palette.textBlack.primary,
                    }}
                  />
                </FormControl>
              )}
            />
          ))}
        </FormGroup>
      </Stack>
      <Stack gap={2} display="none">
        <Typography variant="body1">フック陳列</Typography>
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          height="24px"
        >
          <Controller
            name="isHookOn"
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                checked={isHookOn}
                onChange={() => setValue('isHookOn', !isHookOn)}
              />
            )}
          />
          <Typography variant="body1" color={theme.palette.text.primary}>
            {isHookOn ? 'ON' : 'OFF'}
          </Typography>
        </Box>
      </Stack>
      <Stack gap={2}>
        <Box component="div" display="flex" alignItems="center" gap={1}>
          <Typography variant="body1">サイズ</Typography>
          {/* variant="body3" */}
          <RequiredText fontSize="12px" />
        </Box>
        <Box
          component="div"
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Stack gap={2}>
            <ClickAwayListener onClickAway={onClickOutSideSize}>
              <Box component="div">
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Controller
                    name="width"
                    control={control}
                    render={({ field }) => (
                      <SizeTextField
                        error={!isValidSizeFormat.width}
                        label="幅"
                        field={field}
                        isFocused={!isValidSizeFormat.width}
                        sxTextField={{
                          '& .Mui-error': {
                            padding: 0,
                          },
                          '& .Mui-error .MuiOutlinedInput-input': {
                            background: theme.palette.system.errorBg,
                          },
                          '& .Mui-error .MuiInputAdornment-root': {
                            display: 'none',
                          },
                          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px !important',
                          },
                        }}
                      />
                    )}
                  />
                  <Typography mx={1}>×</Typography>
                  <Controller
                    name="height"
                    control={control}
                    render={({ field }) => (
                      <SizeTextField
                        error={!isValidSizeFormat.height}
                        label="高さ"
                        field={field}
                        isFocused={!isValidSizeFormat.height}
                        sxTextField={{
                          '& .Mui-error': {
                            padding: 0,
                          },
                          '& .Mui-error .MuiOutlinedInput-input': {
                            background: theme.palette.system.errorBg,
                          },
                          '& .Mui-error .MuiInputAdornment-root': {
                            display: 'none',
                          },
                          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px !important',
                          },
                        }}
                      />
                    )}
                  />
                  <Typography mx={1}>×</Typography>
                  <Controller
                    name="depth"
                    control={control}
                    render={({ field }) => (
                      <SizeTextField
                        error={!isValidSizeFormat.depth}
                        label="奥行き"
                        field={field}
                        isFocused={!isValidSizeFormat.depth}
                        sxTextField={{
                          '& .Mui-error': {
                            padding: 0,
                          },
                          '& .Mui-error .MuiOutlinedInput-input': {
                            background: theme.palette.system.errorBg,
                          },
                          '& .Mui-error .MuiInputAdornment-root': {
                            display: 'none',
                          },
                          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px !important',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                {/* TODO: エラーの挙動について検討 */}
                {(!isValidSizeFormat.width ||
                  !isValidSizeFormat.height ||
                  !isValidSizeFormat.depth) && (
                  <Typography color="error" variant="caption">
                    半角数字を入力してください。
                  </Typography>
                )}
              </Box>
            </ClickAwayListener>
            <Typography
              variant="body2"
              color={theme.palette.textBlack.secondary}
            >
              編集前サイズ：幅{size.width}mm × 高さ
              {size.height}mm × 奥行き
              {size.depth}mm
            </Typography>
          </Stack>
          <Button
            sx={{
              fontWeight: 'bold',
              color: theme.palette.textBlack.disabled,
            }}
            onClick={() => handleClickReset()}
          >
            リセット
          </Button>
        </Box>
      </Stack>
      <Controller
        name="memo"
        control={control}
        render={({ field }) => (
          <TextField
            rows={5}
            multiline
            {...field}
            label="メモ"
            sx={{ display: 'none' }}
          />
        )}
      />
    </Stack>
  );
};
