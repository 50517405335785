import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Button, Typography } from '@mui/material';
import { faceFrontJpValues, faceIdFront, faceIdTop } from '@utils/const';
import { getFaceFrontById } from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import { FC } from 'react';
import { theme } from 'theme';
import { FaceFrontId, Product, ProductImageSize } from 'types/common';
import { UploadedFiles } from 'types/products';
import { ArrowNextIcon } from '../../molecules/arrowNextIcon/arrowNextIcon';
import { ArrowPrevIcon } from '../../molecules/arrowPrevIcon/arrowPrevIcon';
import { DummyProduct } from '../productImage/fragments/dummyProduct';
import { ProductImage } from '../productImage/productImage';

type Props = {
  product: Product;
  mockupImages: UploadedFiles;
  showDetail?: boolean;
  imageSize: ProductImageSize;
  onProductImageClick: () => void;
  faceFrontId: FaceFrontId;
  onFaceFrontIdChange: (faceFrontId: FaceFrontId) => void;
};

const countOfImages = 6;
const smallImageSize = 184;
const mediumImageSize = 337;
const fontSize = 24;
const hexahedronSize = 32;
export const FaceFrontSwitcher: FC<Props> = ({
  product,
  mockupImages,
  imageSize,
  onProductImageClick,
  faceFrontId,
  onFaceFrontIdChange,
}) => {
  const size = imageSize === 's' ? smallImageSize : mediumImageSize;
  const isSelectedItemLast = faceFrontId >= countOfImages;
  const isSelectedItemFirst = faceFrontId <= 1;

  const nextProductImage = () => {
    if (isSelectedItemLast) {
      return;
    } else {
      onFaceFrontIdChange((faceFrontId + 1) as FaceFrontId);
    }
  };

  const prevProductImage = () => {
    if (isSelectedItemFirst) {
      return;
    } else {
      onFaceFrontIdChange((faceFrontId - 1) as FaceFrontId);
    }
  };

  const faceDirection = product.detail?.face_direction;
  const isFaceTop = faceDirection === 'top' && faceFrontId === faceIdTop;
  const isFaceFront = faceDirection === 'front' && faceFrontId === faceIdFront;
  const isNot3DScanned = hasProductTag('未3Dスキャン', product.detail?.tags);
  const selectedMockupImage =
    faceFrontId && isNot3DScanned
      ? mockupImages[getFaceFrontById(faceFrontId)]
      : undefined;
  return (
    <Box
      component="div"
      width="100%"
      display="flex"
      position="relative"
      flexDirection="column"
      gap={1}
    >
      <Box
        component="div"
        width="100%"
        minHeight={size}
        display="flex"
        justifyContent="center"
        position="relative"
      >
        <Box
          component="div"
          position="relative"
          minWidth="184px"
          minHeight={size}
          sx={{
            cursor: 'zoom-in',
          }}
          onClick={onProductImageClick}
        >
          {selectedMockupImage?.hasOriginalFile || !isNot3DScanned ? (
            <ProductImage
              product={product}
              faceFrontId={faceFrontId}
              imageSizeType="medium"
              layout="fill"
              objectFit="contain"
              baseSize={size}
              fontSize={fontSize}
            />
          ) : (
            <DummyProduct
              product={product}
              width={size}
              height={size}
              fontSize={fontSize}
            />
          )}
          <ZoomInIcon
            sx={{
              zIndex: 999,
              fontSize: 32,
              position: 'absolute',
              color: '#838583',
              right: '4px',
              bottom: '4px',
            }}
          />
        </Box>
        <Button
          variant="outlined"
          disabled={isSelectedItemFirst}
          sx={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            padding: '5px',
            minWidth: '32px',
            minHeight: '32px',
            borderColor: theme.palette.dividerBlack.dark,
            ':disabled': {
              background: theme.palette.backgroundBlack.disabled,
              border: 'unset',
            },
          }}
          onClick={prevProductImage}
        >
          <ArrowPrevIcon
            sx={{
              fill: !isSelectedItemFirst
                ? theme.palette.icons.primary
                : theme.palette.icons.disabled,
            }}
          />
        </Button>
        <Button
          variant="outlined"
          disabled={isSelectedItemLast}
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 0,
            padding: '5px',
            minWidth: '32px',
            minHeight: '32px',
            borderColor: theme.palette.dividerBlack.dark,
            ':disabled': {
              background: theme.palette.backgroundBlack.disabled,
              border: 'unset',
            },
          }}
          onClick={nextProductImage}
        >
          <ArrowNextIcon
            sx={{
              fill: !isSelectedItemLast
                ? theme.palette.icons.primary
                : theme.palette.icons.disabled,
            }}
          />
        </Button>
      </Box>
      <Typography textAlign="center" variant="body2">
        {`${faceFrontJpValues[faceFrontId - 1]}`}
        {(isFaceTop || isFaceFront) && ' (フェイス面)'}
      </Typography>
      <Box component="div" display="flex" gap={1} justifyContent="center">
        {Array.from(Array(countOfImages).keys()).map((_, index) => {
          const numOfImage = index + 1;
          const isSelected = numOfImage === faceFrontId;
          const mockupImage = isNot3DScanned
            ? mockupImages[getFaceFrontById(numOfImage as FaceFrontId)]
            : undefined;
          return (
            <Box
              key={numOfImage}
              component="div"
              minWidth="32px"
              minHeight="32px"
              position="relative"
              border={
                isSelected ? `1px solid ${theme.palette.primary.main}` : 'unset'
              }
              sx={{ cursor: 'pointer' }}
              onClick={() => onFaceFrontIdChange(numOfImage as FaceFrontId)}
            >
              {mockupImage?.hasOriginalFile || !isNot3DScanned ? (
                <ProductImage
                  product={product}
                  faceFrontId={numOfImage as FaceFrontId}
                  imageSizeType="medium"
                  layout="fill"
                  objectFit="contain"
                  baseSize={hexahedronSize}
                  fontSize={fontSize}
                />
              ) : (
                <DummyProduct
                  product={product}
                  width={hexahedronSize}
                  height={hexahedronSize}
                  fontSize={4}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
