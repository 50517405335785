import { MainProductChip } from '@components/molecules/mainProductChip/mainProductChip';
import { CategoryIconDivider } from '@components/molecules/categoryIconDivider/categoryIconDivider';
import { ProductDetailBody } from '@components/molecules/productDetailBody/productDetailBody';
import { useProductMockupImages } from '@hooks/useProductMockupImages';
import { Box, Divider } from '@mui/material';
import { selectToken } from '@reducers/auth/selectors';
import { useGetProductAdjustersQuery } from '@reducers/shelfAppsApi';
import { useAppSelector } from '@store/index';
import { Format, format } from '@utils/date';
import { convertMeterToMilliMeter } from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import { FC } from 'react';
import { theme } from 'theme';
import { FaceFrontId, Product, ProductImageSize } from 'types/common';
import { FaceFrontSwitcher } from '../faceFrontSwitcher/faceFrontSwitcher';
import { ProductImage } from '../productImage/productImage';
type Props = {
  product: Product;
  showDetail?: boolean;
  imageSize: ProductImageSize;
  onProductImageClick?: () => void;
  faceFrontId?: FaceFrontId;
  onFaceFrontIdChange?: (faceFrontId: FaceFrontId) => void;
  hasFaceFrontSwitcher: boolean;
  hasMakerInfo: boolean;
  isProductDetailRow?: boolean;
};

const smallImageSize = 160;
const mediumImageSize = 337;
const mediumImageTabletSize = 240;
export const ProductDetail: FC<Props> = ({
  product,
  showDetail,
  imageSize,
  onProductImageClick,
  faceFrontId,
  onFaceFrontIdChange,
  hasFaceFrontSwitcher,
  hasMakerInfo,
  // 編集時の商品詳細をタブレットでは横並びにする
  isProductDetailRow = false,
}) => {
  const size =
    imageSize === 's'
      ? smallImageSize
      : isProductDetailRow
      ? mediumImageTabletSize
      : mediumImageSize;

  const productSizes =
    product.shape?.size.display_size ?? product.shape?.size.actual;
  const width = productSizes?.width
    ? convertMeterToMilliMeter(productSizes.width)
    : '';
  const height = productSizes?.height
    ? convertMeterToMilliMeter(productSizes.height)
    : '';
  const depth = productSizes?.depth
    ? convertMeterToMilliMeter(productSizes.depth)
    : '';

  const { data } = useGetProductAdjustersQuery(
    {
      product_id: product.id,
    },
    {
      skip: !product.id,
    }
  );

  const isEdited = !!data?.product_adjuster;

  // TODO：productを取得するAPIの修正が完了後detailを更新する
  const displayedProductList = [
    {
      label: '商品名',
      detail: product?.name ?? '-',
    },
    {
      label: 'カテゴリ',
      detail: <CategoryIconDivider categories={product.detail?.categories} />,
    },
    {
      label: 'メーカー',
      detail: product.detail?.product_manufacturer?.name ?? '-',
    },
    {
      label: 'JAN',
      detail: product.detail?.ean_code ?? '-',
    },
    {
      label: '商品CD',
      detail: product.detail?.organization_product_id ?? '-',
    },
    {
      label: '推奨売価',
      detail: '-',
    },
    {
      label: 'サイズ',
      detail: (
        <>
          {`W${width} x H${height} x D${depth}`}
          {isEdited && (
            <Box component="span" color={theme.palette.textBlack.label}>
              （編集済み）
            </Box>
          )}
        </>
      ),
    },
    {
      label: '商品属性',
      detail: product.detail?.tags?.map((tag, index) => (
        <MainProductChip key={index} label={tag} isOverflowShown />
      )),
    },
  ].filter(
    (item) => (!hasMakerInfo && item.label !== 'メーカー') || hasMakerInfo
  );

  const displayedProductDetailList = [
    {
      label: 'フック陳列',
      detail: '-',
    },
    {
      label: '横倒し',
      detail: '-',
    },
    {
      label: '登録日時',
      detail: format(product.created_at, Format.datetimeWithWeekday),
    },
    {
      label: '更新日時',
      detail: format(product.updated_at, Format.datetimeWithWeekday),
    },
    {
      label: '最終更新者',
      detail: '-',
    },
  ];

  const handleFaceFrontIdChange = (faceFrontId: FaceFrontId) => {
    if (onFaceFrontIdChange) {
      onFaceFrontIdChange(faceFrontId);
    }
  };

  const handleProductImageClick = () => {
    if (onProductImageClick) {
      onProductImageClick();
    }
  };

  const isNot3DScanned = hasProductTag('未3Dスキャン', product.detail?.tags);
  const token = useAppSelector(selectToken) ?? '';
  const { uploadedFiles } = useProductMockupImages(
    product.id,
    token,
    isNot3DScanned
  );

  return (
    <Box
      component="div"
      display="flex"
      flexDirection={isProductDetailRow ? 'row' : 'column'}
      sx={{
        maxHeight: hasFaceFrontSwitcher
          ? 'calc(100vh - 96px)'
          : 'calc(100vh - 118px)',
      }}
      alignItems={isProductDetailRow ? 'center' : 'stretch'}
    >
      {/* 上段：商品画像 */}

      {hasFaceFrontSwitcher ? (
        <Box
          component="div"
          display="flex"
          mx={1}
          my={3}
          flex={1}
          position="relative"
          minHeight="252px"
          justifyContent="center"
          alignItems="center"
        >
          <FaceFrontSwitcher
            product={product}
            mockupImages={uploadedFiles}
            imageSize="s"
            onProductImageClick={handleProductImageClick}
            faceFrontId={faceFrontId ?? 1}
            onFaceFrontIdChange={handleFaceFrontIdChange}
          />
        </Box>
      ) : (
        <Box
          component="div"
          display="flex"
          mx={3}
          my={3}
          flex={1}
          position="relative"
          minHeight={size}
          justifyContent="center"
          alignItems="center"
        >
          <ProductImage
            product={product}
            imageSizeType="medium"
            layout="fill"
            objectFit="contain"
            baseSize={size}
            fontSize={24}
          />
        </Box>
      )}

      <Divider sx={{ mx: '-8px' }} />
      {/* 中段：商品詳細情報 */}
      {/* TODO：商品詳細のUIが更新されたらparentの分岐をなくし中段を共通化する */}
      <ProductDetailBody productDetailList={displayedProductList} />
      {/* 下段：商品設定情報 */}
      {showDetail && (
        <>
          <Divider />
          <ProductDetailBody productDetailList={displayedProductDetailList} />
        </>
      )}
    </Box>
  );
};
