import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { GramTableFoot } from '@components/organisms';
import { GramTableFooter } from '@components/organisms/gramTableFooter/gramTableFooter';
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@mui/material';
import { GetUsersQueryParams } from '@reducers/shelfAppsApi';
import { rowsPerPage } from '@utils/const';
import { FC } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { theme } from 'theme';
import { User, UserOrder } from 'types/user';
import { TableCells } from './tableCells';
import { UsersTableHeader, columns } from './usersTableHeader';

type UsersTableProps = {
  users?: User[];
  tableHeight: string;
  isLoading: boolean;
  condition: GetUsersQueryParams;
  selectedUser?: User;
  offset: number;
  tableWidth: string;
  handleClickRow: (user: User) => void;
  handleEndReached: (index: number) => void;
  handleChangeOrder: (order: UserOrder) => void;
};

const rowHeight = 64;
const enterVelocityThreshold = 400;
const exitVelocityThreshold = 2;

export const UsersTable: FC<UsersTableProps> = ({
  users,
  tableHeight,
  isLoading,
  condition,
  selectedUser,
  offset,
  tableWidth,
  handleClickRow,
  handleEndReached,
  handleChangeOrder,
}) => {
  const isEmpty = !users?.length && !isLoading;
  return (
    <TableVirtuoso
      style={{
        height: tableHeight,
        border: '1px solid #CCCCCC',
        overflow: isEmpty ? 'hidden' : 'auto',
      }}
      initialScrollTop={offset}
      data={users?.length ? users : new Array<User>(rowsPerPage)}
      endReached={(index) => handleEndReached(index)}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        Table: (props: TableProps) => (
          <Table
            {...props}
            style={{
              tableLayout: 'fixed',
              borderCollapse: 'separate',
              height: isEmpty ? '100%' : '',
            }}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableHead,
        // Note: TableRowに一行に対するスタイルや動作を設定し、itemContentに行内の各カラムに対するスタイルや動作設定する。
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableRow: (props: { item: User; 'data-index': number }) => {
          const sx =
            props?.item && selectedUser?.id === props?.item.id
              ? {
                  backgroundColor: theme.palette.primary.selected,
                  '.MuiTableCell-root': {
                    borderTop: `1px solid ${theme.palette.primary.main}`,
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                  },
                }
              : {};

          return (
            <>
              {offset === 0 && isLoading ? (
                <TableRow {...props}>
                  {columns.map((_, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton animation="wave" width="75%" />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : props?.item ? (
                <TableRow
                  sx={{
                    height: '48px',
                    ':hover': {
                      backgroundColor: theme.palette.backgroundBlack.hover,
                      cursor: 'pointer',
                    },
                    ...sx,
                  }}
                  onClick={() => {
                    handleClickRow(props.item);
                  }}
                  {...props}
                />
              ) : (
                <Box
                  component="div"
                  sx={{
                    height: '100%',
                    width: `${tableWidth}px`,
                  }}
                >
                  <EmptyResult title="ユーザーが存在しません" isErrorIcon />
                </Box>
              )}
            </>
          );
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableBody:
          users?.length === 0 && !isLoading
            ? () => (
                <Box component="div" height="100%" width={tableWidth}>
                  <EmptyResult title="ユーザーが存在しません" isErrorIcon />
                </Box>
              )
            : TableBody,

        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        ScrollSeekPlaceholder: () => (
          <TableRow>
            {columns.map((_, index) => {
              return (
                <TableCell key={index}>
                  <Skeleton animation="wave" width="75%" />
                </TableCell>
              );
            })}
          </TableRow>
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableFoot: GramTableFoot,
      }}
      scrollSeekConfiguration={{
        enter: (velocity) => Math.abs(velocity) > enterVelocityThreshold,
        exit: (velocity) => {
          const shouldExit = Math.abs(velocity) < exitVelocityThreshold;
          return shouldExit;
        },
      }}
      fixedHeaderContent={() => (
        <UsersTableHeader
          condition={condition}
          handleChangeOrder={(order: UserOrder) => handleChangeOrder(order)}
        />
      )}
      fixedFooterContent={() => {
        if (!isLoading || !users) return <></>;
        if (users.length > 0)
          return <GramTableFooter columns={columns} height={rowHeight} />;
        return <></>;
      }}
      itemContent={(_, user) => <TableCells user={user} />}
    />
  );
};
