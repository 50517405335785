import { useModal } from '@hooks/useModal';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal, updateErrorMessage } from '@reducers/modal';
import { useCreateUserMutation } from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch } from '@store/index';
import { isApiResponse, toastMessages } from '@utils/const';
import { CreateUserFormProps } from '../fragments/createUserForm';

const statusCode = 409;

export const useCreateUser = () => {
  const dispatch = useAppDispatch();

  const [createUser] = useCreateUserMutation();

  const { showModal: showCreateUserModal } = useModal<CreateUserFormProps>(
    'createUserModal',
    '新しいユーザーを追加'
  );

  const handleCreateUser = () => {
    showCreateUserModal({
      onSubmit: async ({ fullname, email, role }) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          await createUser({ fullname, email, role }).unwrap();
          dispatch(
            openToast({
              message: toastMessages.success.createUser,
              type: 'success',
            })
          );
          dispatch(closeModal());
        } catch (error) {
          console.error(error);
          if (isApiResponse(error)) {
            if (error.status === statusCode) {
              dispatch(updateErrorMessage('このユーザーは追加済です。'));
            } else {
              dispatch(
                openToast({
                  message: toastMessages.error.createUser,
                  type: 'error',
                })
              );
            }
          }
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  return { handleCreateUser };
};
