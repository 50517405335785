import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rowsPerPage } from '@utils/const';

type ScannerState = {
  page: number;
  limit: number;
  selectedItemIndex: number;
  selectedDirectoryId: string;
  lastVisitedRealogramListURL: string;
};

const initialState: ScannerState = {
  page: 1,
  limit: rowsPerPage,
  selectedItemIndex: 0,
  selectedDirectoryId: '',
  lastVisitedRealogramListURL: '',
};

const scannerSlice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {
    setRealogramsPageNumber: (
      state: ScannerState,
      action: PayloadAction<number>
    ) => {
      state.page = action.payload;
    },
    reset: () => initialState,
    setSelectedItemIndex: (
      state: ScannerState,
      action: PayloadAction<number>
    ) => {
      state.selectedItemIndex = action.payload;
    },
    setSelectedDirectoryId: (
      state: ScannerState,
      action: PayloadAction<string>
    ) => {
      state.selectedDirectoryId = action.payload;
    },
    setLastVisitedRealogramListURL: (
      state: ScannerState,
      action: PayloadAction<string>
    ) => {
      state.lastVisitedRealogramListURL = action.payload;
    },
  },
});

export const {
  setRealogramsPageNumber,
  reset,
  setSelectedItemIndex,
  setSelectedDirectoryId,
  setLastVisitedRealogramListURL,
} = scannerSlice.actions;

export const ScannerReducer = scannerSlice.reducer;
