import { objectToSnake } from 'ts-case-convert';
import { paths } from 'types/api';
import {
  baseApi,
  KeysToCamelcase,
  RealogramDirectoryTags,
  DirectoryRolesTags,
  RealogramsTags,
} from '../baseApi';

export type GetRealogramDirectoriesParams = KeysToCamelcase<
  paths['/api/v1/realogram_directories']['get']['parameters']['query']
>;
type GetRealogramDirectoriesResponse =
  paths['/api/v1/realogram_directories']['get']['responses']['200']['content']['application/json'];

type GetSearchRealogramDirectoriesParams = KeysToCamelcase<
  paths['/api/v1/realogram_directories/search']['get']['parameters']['query']
>;
type GetSearchRealogramDirectoriesResponse =
  paths['/api/v1/realogram_directories/search']['get']['responses']['200']['content']['application/json'];

type AddFavoriteRealogramResponse =
  paths['/api/v1/realogram_directory_favorites/{directory_id}']['post']['responses']['200']['content']['application/json'];
type AddFavoriteRealogramParams = KeysToCamelcase<
  paths['/api/v1/realogram_directory_favorites/{directory_id}']['post']['parameters']['path']
>;

type DeleteFavoriteRealogramResponse =
  paths['/api/v1/realogram_directory_favorites/{directory_id}']['delete']['responses']['200']['content']['application/json'];
type DeleteFavoriteRealogramParams = KeysToCamelcase<
  paths['/api/v1/realogram_directory_favorites/{directory_id}']['delete']['parameters']['path']
>;

type GetRealogramDirectoryParams = KeysToCamelcase<
  paths['/api/v1/realogram_directories/{id}']['get']['parameters']['path']
>;
type GetRealogramDirectoryResponse =
  paths['/api/v1/realogram_directories/{id}']['get']['responses']['200']['content']['application/json'];

export const realogramDirectoriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listRealogramDirectories: builder.query<
      GetRealogramDirectoriesResponse,
      GetRealogramDirectoriesParams
    >({
      query: (params) => ({
        url: 'realogram_directories',
        params: params ? objectToSnake(params) : undefined,
      }),
      providesTags: [
        RealogramDirectoryTags.ListRealogramDirectory,
        DirectoryRolesTags.UpdateDirectoryRole,
        DirectoryRolesTags.DeleteDirectoryRole,
      ],
    }),
    searchRealogramDirectories: builder.query<
      GetSearchRealogramDirectoriesResponse,
      GetSearchRealogramDirectoriesParams
    >({
      query: (params) => ({
        url: 'realogram_directories/search',
        params: params ? objectToSnake(params) : undefined,
      }),
      providesTags: [
        RealogramDirectoryTags.ListRealogramDirectory,
        DirectoryRolesTags.UpdateDirectoryRole,
        DirectoryRolesTags.DeleteDirectoryRole,
      ],
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        // check is filters changed or not
        if (currentCache.pager.offset === newItems.pager.offset) {
          currentCache.pager = newItems.pager;
          currentCache.realogram_directories = [
            ...newItems.realogram_directories,
          ];
        } else {
          currentCache.realogram_directories.push(
            ...newItems.realogram_directories
          );
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    addFavoriteRealogram: builder.mutation<
      AddFavoriteRealogramResponse,
      AddFavoriteRealogramParams
    >({
      query: (params) => ({
        url: `realogram_directory_favorites/${params.directoryId}`,
        method: 'POST',
      }),
      invalidatesTags: [
        RealogramDirectoryTags.ListRealogramDirectory,
        RealogramsTags.ListRealogram,
      ],
    }),
    deleteFavoriteRealogram: builder.mutation<
      DeleteFavoriteRealogramResponse,
      DeleteFavoriteRealogramParams
    >({
      query: (params) => ({
        url: `realogram_directory_favorites/${params.directoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        RealogramDirectoryTags.ListRealogramDirectory,
        RealogramsTags.ListRealogram,
      ],
    }),
    getRealogramDirectory: builder.query<
      GetRealogramDirectoryResponse,
      GetRealogramDirectoryParams
    >({
      query: ({ id }) => ({
        url: `realogram_directories/${id}`,
        method: 'GET',
      }),
      providesTags: [
        RealogramDirectoryTags.ListRealogramDirectory,
        DirectoryRolesTags.UpdateDirectoryRole,
        DirectoryRolesTags.DeleteDirectoryRole,
      ],
    }),
  }),
});

export const {
  useListRealogramDirectoriesQuery,
  useSearchRealogramDirectoriesQuery,
  useAddFavoriteRealogramMutation,
  useDeleteFavoriteRealogramMutation,
  useGetRealogramDirectoryQuery,
} = realogramDirectoriesApi;
