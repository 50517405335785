// TODO: planに集約できるようにリファクタ
import { PayloadAction, createSlice, original } from '@reduxjs/toolkit';
import { maxChildrenOfArea } from '@utils/const';
import { bucketPosition } from '@utils/planogram';
import { cloneDeep } from 'lodash';
import { BayPlanBayPart, BucketsBucketBayPart } from 'types/bayPlan';
import { FaceFrontId, FaceOrientationId, Product } from 'types/common';
import {
  Bucket,
  BucketArea,
  BucketCompartment,
  BucketPlanogramPlan,
  BucketProductPosition,
  BucketsBucketDetail,
  BucketsPoint,
  Position,
  RemoveArea,
  RotationValues,
  SplitArea,
} from 'types/planogram';

/**
 * 棚に配置された商品の位置 / Position of products on shelves
 * 配列のインデックスを指定する / set the array index
 * subPositionがないケースが存在するため一旦分離
 */
export type ProductPosition = Position & {
  subPosition?: Position;
};

type AddBayPartParams = {
  bayPart?: BayPlanBayPart;
  index: number;
  isLeftEdgeBottomRow?: boolean;
  isBottomOnlyOne?: boolean;
  isTopOnlyOne?: boolean;
};

type UndoableState<T> = {
  past: T[];
  present: T;
  future: T[];
  // サーバーに保存されている履歴を指し示す。undefined: 履歴に存在しない。0: presentが保存されている。 1以上: cursor - 1の履歴が保存されている
  cursor?: number;
  bucketProductPosition?: Position[];
  canSplitHorizontal?: boolean;
  canSplitVertical?: boolean;
  bottomBucketsNum: number;
  isRotated?: boolean;
};

type PlanState = UndoableState<BucketPlanogramPlan>;

const initialState: PlanState = {
  past: [],
  present: {
    format: 'buckets_v1',
    frame: {
      type: 'buckets_frame',
      name: '',
      bay_part_id: 0,
      detail: {
        content_min: { x: 0, y: 0, z: 0 },
        content_max: { x: 0, y: 0, z: 0 },
        padding: { left: 0, right: 0, front: 0, behind: 0, bottom: 0, top: 0 },
        buckets: [],
      },
    },
  },
  future: [],
  bottomBucketsNum: 0,
  isRotated: false,
};

const flatPlanSlice = createSlice({
  name: 'flatPlan',
  initialState,
  reducers: {
    // TODO: shelfと共通化する
    undo: (state: PlanState) => {
      const previous = state.past.pop();
      const current = original(state.present);
      if (!previous || !current) return;
      const previousBucket = original(previous);
      if (
        !previousBucket ||
        !current.frame.detail.buckets ||
        !previousBucket.frame.detail.buckets
      )
        return;
      const isRotated = checkIsRotated(
        current.frame.detail.buckets,
        previousBucket.frame.detail.buckets
      );
      state.isRotated = isRotated;
      state.future.unshift(current);
      state.present = previous;
    },
    redo: (state: PlanState) => {
      const next = state.future.shift();
      const current = original(state.present);
      if (!current || !next) return;
      const nextBucket = original(next);
      if (
        !nextBucket ||
        !current.frame.detail.buckets ||
        !nextBucket.frame.detail.buckets
      )
        return;
      const isRotated = checkIsRotated(
        current.frame.detail.buckets,
        nextBucket.frame.detail.buckets
      );
      state.isRotated = isRotated;
      state.present = next;
      state.past.push(current);
    },
    mark: (state: PlanState) => {
      state.cursor = state.past.length;
    },
    setPlan: (
      state: PlanState,
      action: PayloadAction<BucketPlanogramPlan | undefined>
    ) => {
      state.past = [];
      state.present = action.payload ?? initialState.present;
      state.future = [];
      state.cursor = 0;
    },
    addProduct: (
      state: PlanState,
      action: PayloadAction<{
        to: ProductPosition[];
        bucketIndex: number;
        product: Product;
        count?: {
          x: number;
          y: number;
        };
        orientation?: FaceOrientationId;
        faceFront?: FaceFrontId;
      }>
    ) => {
      const plan = state.present;
      const { to, bucketIndex, product, count, faceFront, orientation } =
        action.payload;
      const productsLayout = plan?.frame;
      const newItem: BucketCompartment = {
        type: 'compartment',
        product_id: product.id,
        face_front: faceFront ?? 1,
        face_count: 1,
        orientation: orientation ?? 0,
        count: {
          x: count?.x ?? 1,
          y: count?.y ?? 1,
        },
      };
      const targetBucket = productsLayout.detail.buckets?.at(bucketIndex);
      if (!targetBucket) return;
      const targetArea = targetBucket.detail.area;

      const findAndAddCompartment = (area: BucketArea, index: number) => {
        if (area.type === 'area' && area.children?.length === 0) {
          // すでに存在するcompartmentがない場合, 新しいものを追加
          if (
            area.children?.some(
              (child: BucketArea) => child.type === 'compartment'
            )
          ) {
            return false;
          } else {
            area.children = area.children ?? [];
            area.children.push(newItem);
            return true;
          }
        } else if (area.children && to[index]) {
          if (to[index].indexX === 1 || to[index].indexY === 1) {
            findAndAddCompartment(area.children[1], index + 1);
            return true;
          } else if (to[index].indexX === 0 && to[index].indexY === 0) {
            findAndAddCompartment(area.children[0], index + 1);
            return true;
          }
        }
        return false;
      };

      findAndAddCompartment(targetArea, 0);
      makeHistory(state);
    },
    moveProducts: () => {
      return;
    },
    removeProducts: (
      state: PlanState,
      action: PayloadAction<{
        bucketIndex: number;
        areaPath?: Position[];
        isMakeHistory?: boolean;
      }>
    ) => {
      const plan = state.present;
      const { bucketIndex, areaPath } = action.payload;
      let { isMakeHistory } = action.payload;
      isMakeHistory = isMakeHistory ?? true;

      const productsLayout = plan.frame;
      const targetBucket = productsLayout.detail.buckets?.at(bucketIndex);
      if (!targetBucket) return;
      const targetArea = targetBucket.detail.area;
      const findAndDeleteCompartment = (area: BucketArea, index: number) => {
        if (
          area.children?.some(
            (child: BucketArea) => child.type === 'compartment'
          )
        ) {
          // remove products
          area.children = [];
          return true;
        } else if (area.children && areaPath) {
          if (areaPath[index].indexX === 1 || areaPath[index].indexY === 1) {
            findAndDeleteCompartment(area.children[1], index + 1);
            return true;
          } else if (
            areaPath[index].indexX === 0 &&
            areaPath[index].indexY === 0
          ) {
            findAndDeleteCompartment(area.children[0], index + 1);
            return true;
          }
        }
        return false;
      };
      findAndDeleteCompartment(targetArea, 0);

      if (isMakeHistory) makeHistory(state);
    },
    rotateProducts: (
      state: PlanState,
      action: PayloadAction<{
        bucketIndex: number;
        areaPath?: Position[];
        values: RotationValues;
      }>
    ) => {
      const plan = state.present;
      const { bucketIndex, areaPath } = action.payload;
      const productsLayout = plan.frame;
      const targetBucket = productsLayout.detail.buckets?.at(bucketIndex);
      if (!targetBucket) return;
      const targetArea = targetBucket.detail.area;
      const [faceFrontId, faceOrientationId] = action.payload.values;
      const findCompartmentAndCalculateProducts = (
        area: BucketArea,
        index: number,
        faceFrontId: FaceFrontId,
        faceOrientationId: FaceOrientationId
      ) => {
        if (
          area.children?.some(
            (child: BucketArea) => child.type === 'compartment'
          )
        ) {
          if (area.children[0]) {
            area.children[0].face_front = faceFrontId;
            area.children[0].orientation = faceOrientationId;
          }
          return true;
        } else if (area.children && areaPath) {
          if (areaPath[index].indexX === 1 || areaPath[index].indexY === 1) {
            findCompartmentAndCalculateProducts(
              area.children[1],
              index + 1,
              faceFrontId,
              faceOrientationId
            );
            return true;
          } else if (
            areaPath[index].indexX === 0 &&
            areaPath[index].indexY === 0
          ) {
            findCompartmentAndCalculateProducts(
              area.children[0],
              index + 1,
              faceFrontId,
              faceOrientationId
            );
            return true;
          }
        }
        return false;
      };

      findCompartmentAndCalculateProducts(
        targetArea,
        0,
        faceFrontId,
        faceOrientationId
      );
      makeHistory(state);
    },
    changeProductsAmount: (
      state: PlanState,
      action: PayloadAction<{
        bucketIndex: number;
        areaPath?: Position[];
        amount: { x: number; y: number };
      }>
    ) => {
      const plan = state.present;
      const { bucketIndex, areaPath, amount } = action.payload;
      const productsLayout = plan.frame;
      const targetBucket = productsLayout?.detail?.buckets?.at(bucketIndex);
      if (!targetBucket) return;
      const targetArea = targetBucket.detail.area;
      const findCompartmentAndCalculateProducts = (
        area: BucketArea,
        index: number,
        amount: { x: number; y: number }
      ) => {
        if (
          area.children?.some(
            (child: BucketArea) => child.type === 'compartment'
          )
        ) {
          // change amount products
          if (area.children[0] && area.children[0].count) {
            area.children[0].count = {
              x: area?.children[0]?.count.x + amount.x,
              y: area?.children[0]?.count.y + amount.y,
            };
          }
          return true;
        } else if (area.children && areaPath) {
          if (areaPath[index].indexX === 1 || areaPath[index].indexY === 1) {
            findCompartmentAndCalculateProducts(
              area.children[1],
              index + 1,
              amount
            );
            return true;
          } else if (
            areaPath[index].indexX === 0 &&
            areaPath[index].indexY === 0
          ) {
            findCompartmentAndCalculateProducts(
              area.children[0],
              index + 1,
              amount
            );
            return true;
          }
        }
        return false;
      };
      findCompartmentAndCalculateProducts(targetArea, 0, amount);
      makeHistory(state);
    },
    addBayPart: (state: PlanState, action: PayloadAction<AddBayPartParams>) => {
      const plan = state.present;
      const {
        index,
        bayPart: bayPartParam,
        isLeftEdgeBottomRow,
        isBottomOnlyOne,
        isTopOnlyOne,
      } = action.payload;
      if (!bayPartParam) return;
      const bayPart = bayPartParam as BucketsBucketBayPart;
      const buckets = plan.frame.detail.buckets?.slice() ?? [];

      const isBottomRow = index < state.bottomBucketsNum || isBottomOnlyOne;
      const lastBottomRowIndex = isBottomOnlyOne ? 0 : state.bottomBucketsNum;
      const lastTopRowIndex = buckets?.length && buckets.length - 1;
      let bottomBuckets = buckets?.slice(0, lastBottomRowIndex);
      let topBuckets = buckets?.slice(lastBottomRowIndex);

      if (!bayPart?.detail.content_max || !bayPart.detail.content_min) return;

      const {
        content_max: newBayPartContentMax,
        content_min: newBayPartContentMin,
        padding: newBayPartPadding,
      } = bayPart.detail;

      const newBayPartWidth =
        newBayPartContentMax.x -
        newBayPartContentMin.x +
        newBayPartPadding.right +
        newBayPartPadding.left;
      const newBayPartInnerWidth =
        newBayPartContentMax.x - newBayPartContentMin.x;
      const item = buckets?.at(index);
      if (isBottomOnlyOne) {
        const newBayPart: Bucket = createNewBayPart({
          bayPart,
          content_max: {
            x:
              newBayPartContentMax.x -
              newBayPartContentMin.x +
              newBayPartPadding.left,
            y: newBayPartContentMax.y,
            z:
              plan.frame.detail.content_max.z -
              plan.frame.detail.padding.behind -
              newBayPartPadding.behind,
          },
          content_min: {
            x: newBayPartPadding.left,
            y: newBayPartContentMin.y,
            z:
              plan.frame.detail.content_max.z -
              plan.frame.detail.padding.behind -
              (newBayPartContentMax.z - newBayPartContentMin.z) -
              newBayPartPadding.behind,
          },
        });
        bottomBuckets = addBottomItem({
          bucketIndex: 0,
          buckets: bottomBuckets,
          newBayPartWidth,
          newBayPart,
          isLeftEdgeBottomRow: false,
        });
        state.bottomBucketsNum = 1;
      } else if (isTopOnlyOne) {
        const newBayPart: Bucket = createNewBayPart({
          bayPart,
          content_max: {
            x:
              plan.frame.detail.content_max.x -
              plan.frame.detail.padding.right -
              newBayPartPadding.right,
            y: newBayPartContentMax.y,
            z:
              newBayPartContentMax.z -
              newBayPartContentMin.z +
              newBayPartPadding.front,
          },
          content_min: {
            x:
              plan.frame.detail.content_max.x -
              plan.frame.detail.padding.right -
              newBayPartInnerWidth -
              newBayPartPadding.right,
            y: newBayPartContentMin.y,
            z: newBayPartPadding.front,
          },
        });
        topBuckets = addBottomItem({
          bucketIndex: index,
          buckets: topBuckets,
          newBayPartWidth,
          newBayPart,
          isLeftEdgeBottomRow: false,
        });
      } else if (isBottomRow) {
        if (!item) return;
        const {
          content_max: contentMax,
          content_min: contentMin,
          padding,
        } = item.detail;
        const newContentMinX =
          contentMax.x + padding.right + bayPart?.detail.padding.left;
        const newBayPart: Bucket = createNewBayPart({
          bayPart,
          content_max: {
            ...contentMax,
            x: newBayPartInnerWidth + newContentMinX,
          },
          content_min: {
            ...contentMin,
            x: newContentMinX,
          },
        });
        if (isLeftEdgeBottomRow) {
          const newBayPart: Bucket = createNewBayPart({
            bayPart,
            content_max: { ...contentMax, x: bayPart?.detail.content_max.x },
            content_min: {
              ...contentMin,
              x: bayPart?.detail.content_min.x,
            },
          });
          bottomBuckets = addBottomItem({
            bucketIndex: index,
            buckets: bottomBuckets,
            newBayPartWidth,
            newBayPart,
            isLeftEdgeBottomRow: true,
          });
        } else {
          bottomBuckets = addBottomItem({
            bucketIndex: index,
            buckets: bottomBuckets,
            newBayPartWidth,
            newBayPart,
          });
        }
        state.bottomBucketsNum = lastBottomRowIndex + 1;
      } else {
        if (!item) return;
        const { content_max: contentMax, content_min: contentMin } =
          item.detail;
        const newIndex = index - lastBottomRowIndex;
        if (index === lastTopRowIndex) {
          const newBayPart: Bucket = createNewBayPart({
            bayPart,
            content_max: contentMax,
            content_min: {
              ...contentMin,
              x: contentMax.x - newBayPartInnerWidth,
            },
          });
          topBuckets = addTopItem({
            bucketIndex: newIndex,
            buckets: topBuckets,
            newBayPartWidth,
            newBayPart,
          });
        } else {
          const item = buckets?.at(index + 1);
          if (!item) return;
          const newContentMaxX =
            item?.detail.content_min.x -
            item?.detail.padding.left -
            newBayPartPadding.right;
          const newBayPart: Bucket = createNewBayPart({
            bayPart,
            content_max: {
              ...item?.detail.content_max,
              x: newContentMaxX,
            },
            content_min: {
              ...item?.detail.content_min,
              x: newContentMaxX - newBayPartInnerWidth,
            },
          });
          topBuckets = addTopItem({
            bucketIndex: newIndex,
            buckets: topBuckets,
            newBayPartWidth,
            newBayPart,
          });
        }
      }
      plan.frame.detail.buckets = [...bottomBuckets, ...topBuckets];
      makeHistory(state);
    },
    moveBayPart: (
      state: PlanState,
      action: PayloadAction<{
        currentIndex: number;
        dropIndex: number;
        isLeftEdgeBottomRow: boolean;
        isLeftEdgeTopRow: boolean;
      }>
    ) => {
      const { currentIndex, dropIndex, isLeftEdgeBottomRow, isLeftEdgeTopRow } =
        action.payload;

      const plan = state.present;
      const rightEdgePosition =
        state.present.frame.detail.content_max.x -
        state.present.frame.detail.padding.right;
      const buckets = plan.frame.detail.buckets?.slice();
      const isBottomRow = buckets?.length && dropIndex < state.bottomBucketsNum;

      const lastBottomRowIndex = state.bottomBucketsNum;
      const lastTopRowIndex = buckets?.length && buckets.length - 1;
      let bottomBuckets = buckets?.slice(0, lastBottomRowIndex) ?? [];
      let topBuckets = buckets?.slice(lastBottomRowIndex) ?? [];
      const targetBayPart = buckets?.at(currentIndex);

      if (
        !targetBayPart?.detail.content_max ||
        !targetBayPart.detail.content_min
      )
        return;
      const targetBayPartWidth =
        targetBayPart?.detail.content_max.x -
        targetBayPart?.detail.content_min.x +
        targetBayPart?.detail.padding.right +
        targetBayPart?.detail.padding.left;
      const targetBayPartInnerWidth =
        targetBayPart?.detail.content_max.x -
        targetBayPart?.detail.content_min.x;

      if (isBottomRow) {
        const item = buckets?.at(dropIndex);
        if (!item) return;
        const {
          content_max: contentMax,
          content_min: contentMin,
          padding,
        } = item.detail;
        const targetContentMinX =
          contentMax.x + padding.right + targetBayPart.detail.padding.left;
        const newBayPart: Bucket = {
          ...targetBayPart,
          detail: {
            ...targetBayPart?.detail,
            content_max: {
              ...contentMax,
              x: targetContentMinX + targetBayPartInnerWidth,
            },
            content_min: {
              ...contentMin,
              x: targetContentMinX,
            },
          },
        };
        if (isLeftEdgeBottomRow) {
          const item = buckets?.at(0);
          if (!item) return;
          const { content_max: contentMax, content_min: contentMin } =
            item.detail;

          const newBayPart: Bucket = {
            ...targetBayPart,
            detail: {
              ...targetBayPart?.detail,
              content_max: {
                ...contentMax,
                x: contentMin.x + targetBayPartInnerWidth,
              },

              content_min: contentMin,
            },
          };

          bottomBuckets = addBottomItem({
            bucketIndex: 0,
            buckets: bottomBuckets,
            newBayPartWidth: targetBayPartWidth,
            newBayPart,
            isLeftEdgeBottomRow: true,
          });
        } else {
          bottomBuckets = addBottomItem({
            bucketIndex: dropIndex,
            buckets: bottomBuckets,
            newBayPartWidth: targetBayPartWidth,
            newBayPart,
          });
        }
        if (currentIndex >= lastBottomRowIndex) {
          topBuckets = removeTopItem({
            bucketIndex: currentIndex - lastBottomRowIndex,
            buckets: topBuckets,
            bayPartWidth: targetBayPartWidth,
          });
          state.bottomBucketsNum = lastBottomRowIndex + 1;
        } else {
          bottomBuckets = removeBottomItem({
            bucketIndex:
              dropIndex < currentIndex ? currentIndex + 1 : currentIndex,
            buckets: bottomBuckets,
            bayPartWidth: targetBayPartWidth,
          });
        }
      } else {
        const newIndex = dropIndex - lastBottomRowIndex;
        const index =
          dropIndex === lastTopRowIndex ? lastTopRowIndex : dropIndex + 1;
        const item = buckets?.at(index);
        if (!item) return;
        const {
          content_max: contentMax,
          content_min: contentMin,
          padding,
        } = item.detail;
        const lastNewBayPart: Bucket = {
          ...targetBayPart,
          detail: {
            ...targetBayPart?.detail,
            content_max: {
              ...contentMax,
              x: rightEdgePosition - padding.right,
            },
            content_min: {
              ...contentMin,
              x: rightEdgePosition - padding.right - targetBayPartInnerWidth,
            },
          },
        };
        const targetBayPartContentMaxX =
          contentMin.x - padding.left - targetBayPart.detail.padding.right;

        const newBayPart: Bucket = {
          ...targetBayPart,
          detail: {
            ...targetBayPart?.detail,
            content_max: {
              ...contentMax,
              x: targetBayPartContentMaxX,
            },
            content_min: {
              ...contentMin,
              x: targetBayPartContentMaxX - targetBayPartInnerWidth,
            },
          },
        };
        if (isLeftEdgeTopRow) {
          const item = buckets?.at(lastBottomRowIndex);
          if (!item) return;
          const {
            content_max: contentMax,
            content_min: contentMin,
            padding,
          } = item.detail;

          const targetContentMaxX =
            contentMin.x - padding.left - targetBayPart.detail.padding.right;
          const newBayPart: Bucket = {
            ...targetBayPart,
            detail: {
              ...targetBayPart?.detail,
              content_max: {
                ...contentMax,
                x: targetContentMaxX,
              },
              content_min: {
                ...contentMin,
                x: targetContentMaxX - targetBayPartInnerWidth,
              },
            },
          };
          topBuckets.unshift(newBayPart);
        } else {
          topBuckets = addTopItem({
            bucketIndex: newIndex,
            buckets: topBuckets,
            newBayPartWidth: targetBayPartWidth,
            newBayPart:
              dropIndex === lastTopRowIndex ? lastNewBayPart : newBayPart,
          });
        }

        if (currentIndex < lastBottomRowIndex) {
          bottomBuckets = removeBottomItem({
            bucketIndex: currentIndex,
            buckets: bottomBuckets,
            bayPartWidth: targetBayPartWidth,
          });
          state.bottomBucketsNum = lastBottomRowIndex - 1;
        } else {
          const removeIndex = currentIndex - lastBottomRowIndex;
          topBuckets = removeTopItem({
            bucketIndex:
              dropIndex < currentIndex ? removeIndex + 1 : removeIndex,
            buckets: topBuckets,
            bayPartWidth: targetBayPartWidth,
          });
        }
      }
      const newBuckets = [...bottomBuckets, ...topBuckets];
      plan.frame.detail.buckets = newBuckets;
      makeHistory(state);
    },
    removeBayPart: (state: PlanState, action: PayloadAction<number>) => {
      const plan = state.present;
      const bucketIndex = action.payload;
      const buckets = plan.frame.detail.buckets?.slice();
      const isBottomRow =
        buckets?.length && bucketIndex < state.bottomBucketsNum;

      const lastBottomRowIndex = state.bottomBucketsNum;
      let bottomBuckets = buckets?.slice(0, lastBottomRowIndex) ?? [];
      let topBuckets = buckets?.slice(lastBottomRowIndex) ?? [];
      const targetBayPart = buckets?.at(bucketIndex);

      if (
        !targetBayPart?.detail.content_max ||
        !targetBayPart.detail.content_min
      )
        return;
      const bayPartWidth =
        targetBayPart?.detail.content_max.x -
        targetBayPart?.detail.content_min.x +
        targetBayPart?.detail.padding.right +
        targetBayPart?.detail.padding.left;
      if (isBottomRow) {
        bottomBuckets = removeBottomItem({
          bucketIndex,
          buckets: bottomBuckets,
          bayPartWidth,
        });
        state.bottomBucketsNum = lastBottomRowIndex - 1;
      } else {
        const newIndex = bucketIndex - lastBottomRowIndex;
        topBuckets = removeTopItem({
          bucketIndex: newIndex,
          buckets: topBuckets,
          bayPartWidth,
        });
      }
      const newBuckets = [...bottomBuckets, ...topBuckets];
      plan.frame.detail.buckets = newBuckets;
      makeHistory(state);
    },
    replaceBayPart: (
      state: PlanState,
      action: PayloadAction<{
        bucketIndex: number;
        bayPart?: Bucket;
      }>
    ) => {
      const { bucketIndex, bayPart } = action.payload;

      const plan = state.present;
      const rightEdgePosition =
        state.present.frame.detail.content_max.x -
        state.present.frame.detail.padding.right;
      const buckets = state.present.frame.detail.buckets?.slice();
      const isBottomRow =
        buckets?.length && bucketIndex < state.bottomBucketsNum;

      const lastBottomRowIndex = state.bottomBucketsNum;
      const lastTopRowIndex = buckets?.length && buckets.length - 1;
      let bottomBuckets = buckets?.slice(0, lastBottomRowIndex) ?? [];
      let topBuckets = buckets?.slice(lastBottomRowIndex) ?? [];
      const targetBayPart = buckets?.at(bucketIndex);

      if (
        !targetBayPart?.detail.content_max ||
        !targetBayPart.detail.content_min ||
        !bayPart
      )
        return;
      const targetBayPartWidth =
        targetBayPart?.detail.content_max.x -
        targetBayPart?.detail.content_min.x +
        targetBayPart?.detail.padding.right +
        targetBayPart?.detail.padding.left;
      const {
        content_max: newBayPartContentMax,
        content_min: newBayPartContentMin,
        padding: newBayPartPadding,
      } = bayPart.detail;

      const newBayPartWidth =
        newBayPartContentMax.x -
        newBayPartContentMin.x +
        newBayPartPadding.right +
        newBayPartPadding.left;
      const newBayPartInnerWidth =
        newBayPartContentMax.x - newBayPartContentMin.x;
      if (isBottomRow) {
        const item = buckets?.at(bucketIndex);
        if (!item) return;
        const {
          content_max: contentMax,
          content_min: contentMin,
          padding,
        } = item.detail;
        const newContentMinX =
          contentMax.x + padding.right + bayPart?.detail.padding.left;
        const newBayPart: Bucket = {
          ...targetBayPart,
          detail: {
            ...targetBayPart?.detail,
            content_max: {
              ...contentMax,
              x: newBayPartInnerWidth + newContentMinX,
            },
            content_min: {
              ...contentMin,
              x: newContentMinX,
            },
          },
          bay_part_id: bayPart.bay_part_id,
        };
        bottomBuckets = addBottomItem({
          bucketIndex: bucketIndex,
          buckets: bottomBuckets,
          newBayPartWidth,
          newBayPart,
        });
        bottomBuckets = removeBottomItem({
          bucketIndex: bucketIndex,
          buckets: bottomBuckets,
          bayPartWidth: targetBayPartWidth,
        });
      } else {
        const newIndex = bucketIndex - lastBottomRowIndex;
        const index =
          bucketIndex === lastTopRowIndex ? lastTopRowIndex : bucketIndex + 1;
        const item = buckets?.at(index);
        if (!item) return;
        const {
          content_max: contentMax,
          content_min: contentMin,
          padding,
        } = item.detail;
        const lastNewBayPart: Bucket = {
          ...targetBayPart,
          detail: {
            ...targetBayPart?.detail,
            content_max: {
              ...contentMax,
              x: rightEdgePosition - padding.right,
            },
            content_min: {
              ...contentMin,
              x: rightEdgePosition - padding.right - newBayPartInnerWidth,
            },
          },
          bay_part_id: bayPart.bay_part_id,
        };
        const targetBayPartContentMaxX =
          contentMin.x - padding.left - bayPart.detail.padding.right;

        const newBayPart: Bucket = {
          ...targetBayPart,
          detail: {
            ...targetBayPart?.detail,
            content_max: {
              ...contentMax,
              x: targetBayPartContentMaxX,
            },
            content_min: {
              ...contentMin,
              x: targetBayPartContentMaxX - newBayPartInnerWidth,
            },
          },
          bay_part_id: bayPart.bay_part_id,
        };
        topBuckets = addTopItem({
          bucketIndex: newIndex,
          buckets: topBuckets,
          newBayPartWidth: newBayPartWidth,
          newBayPart:
            bucketIndex === lastTopRowIndex ? lastNewBayPart : newBayPart,
        });

        const removeIndex = bucketIndex - lastBottomRowIndex;
        topBuckets = removeTopItem({
          bucketIndex: removeIndex,
          buckets: topBuckets,
          bayPartWidth: targetBayPartWidth,
        });
      }
      const newBuckets = [...bottomBuckets, ...topBuckets];
      plan.frame.detail.buckets = newBuckets;
      makeHistory(state);
    },
    updateCompartmentAttributes: () => {
      return;
    },
    splitArea: (state: PlanState, action: PayloadAction<SplitArea>) => {
      const { type, selectedBucketId, bucketProductPosition } = action.payload;

      const defaultArea: BucketArea = {
        type: 'area',
        split_axis: null,
        children: [] as BucketArea[],
      };

      if (selectedBucketId === undefined) return;
      const updatedPresent = state.present;
      const bucketDetail =
        updatedPresent.frame?.detail?.buckets?.[selectedBucketId].detail ||
        ({} as BucketsBucketDetail);

      let area: BucketArea | undefined = bucketDetail.area;
      if (!area) return;

      if (bucketProductPosition !== undefined) {
        const indexArray = bucketProductPosition?.map((el) => {
          if (el.indexX === 0 && el.indexY === 0) return 0;
          return 1;
        });
        indexArray.forEach((value) => {
          area = area?.children?.[value];
        });
      }

      area.split_axis = type;
      const compartment = area.children?.length ? area.children[0] : undefined;

      area.children = [cloneDeep(defaultArea), cloneDeep(defaultArea)];

      if (compartment) {
        area.children[0].children = [{ ...compartment }];
      }

      state.present = updatedPresent;
      makeHistory(state);
    },
    removeArea: (state: PlanState, action: PayloadAction<RemoveArea>) => {
      const { selectedBucketId, bucketProductPosition } = action.payload;

      if (selectedBucketId === undefined) return;
      const updatedPresent = state.present;
      const area: BucketArea | undefined =
        updatedPresent.frame.detail.buckets?.[selectedBucketId].detail.area;
      if (!area) return;
      let currentParent = area;
      let position: Position[] | undefined = undefined;

      if (bucketProductPosition !== undefined) {
        const indexArray = bucketProductPosition?.map((el) => {
          if (el.indexX === 0 && el.indexY === 0) return 0;
          return 1;
        });
        let i = 0;

        while (i < indexArray.length) {
          const index = indexArray[i];

          if (i === indexArray.length - 1) {
            if (hasMultipleChildren(currentParent)) {
              let nextChild;
              if (index === 0) {
                nextChild = currentParent.children?.[index + 1];
              } else {
                nextChild = currentParent.children?.[index - 1];
              }

              removeDataAndSetNullSplitAxis(currentParent, index);

              if (
                nextChild &&
                (nextChild.children?.length ?? 0) >= maxChildrenOfArea
              ) {
                currentParent.children = [...(nextChild.children ?? [])];
                currentParent.split_axis = nextChild.split_axis;

                const bucketProductPositionCopy =
                  bucketProductPosition.slice(0);
                bucketProductPositionCopy[i] = { indexX: 0, indexY: 0 };
                position = bucketProductPositionCopy;
              } else {
                position = bucketProductPosition.slice(0, -1);
              }
            }
          }
          currentParent =
            currentParent.children?.[index] ?? ([] as unknown as BucketArea);
          i++;
        }
      }

      // update state
      state.present = updatedPresent;
      state.bucketProductPosition = position?.length ? position : undefined;
      makeHistory(state);
    },
    updateBucketProductPosition: (
      state: PlanState,
      action: PayloadAction<BucketProductPosition[] | undefined>
    ) => {
      state.bucketProductPosition = action.payload;
    },
    updateCanSplitHorizontal: (
      state: PlanState,
      action: PayloadAction<boolean>
    ) => {
      state.canSplitHorizontal = action.payload;
    },
    updateCanSplitVertical: (
      state: PlanState,
      action: PayloadAction<boolean>
    ) => {
      state.canSplitVertical = action.payload;
    },
    updateBottomBucketsNum: (
      state: PlanState,
      action: PayloadAction<number>
    ) => {
      state.bottomBucketsNum = action.payload;
    },
    reset: () => {
      initialState;
    },
    changeFront: (state: PlanState) => {
      const plan = state.present;
      const {
        buckets,
        content_max: contentMax,
        content_min: contentMin,
        padding,
      } = plan.frame.detail;

      if (!buckets || buckets.length === 0) return;
      const half = 2;
      const rotatedBuckets = buckets.map((bucket) => {
        // カゴが上下どちらに存在しているかを取得する
        const position = bucketPosition(
          bucket,
          (contentMax.z - contentMin.z) / half
        );

        const {
          content_max: bucketContentMax,
          content_min: bucketContentMin,
          padding: bucketPadding,
        } = bucket.detail;

        const bucketZ = bucketContentMax.z - bucketContentMin.z;

        return {
          ...bucket,
          detail: {
            ...bucket.detail,
            content_max: {
              ...bucketContentMax,
              x: contentMax.x - padding.right - bucketContentMin.x,
              z:
                // 上下のz軸の値を反転させる
                position === 'top'
                  ? contentMax.z - bucketPadding.front - padding.front
                  : contentMin.z +
                    bucketPadding.behind +
                    bucketZ -
                    padding.front,
            },
            content_min: {
              ...bucketContentMin,
              x: contentMax.x - padding.right - bucketContentMax.x,
              z:
                // 上下のz軸の値を反転させる
                position === 'top'
                  ? contentMax.z - bucketPadding.front - bucketZ - padding.front
                  : contentMin.z + bucketPadding.behind - padding.front,
            },
          },
        };
      });

      plan.frame.detail.buckets = [...rotatedBuckets.reverse()];
      makeHistory(state);
    },
    resetIsRotated: (state: PlanState) => {
      state.isRotated = false;
    },
  },
});

// TODO: shelfと共通化する
const makeHistory = (state: PlanState) => {
  // 履歴の保存
  const plan = original(state.present);
  if (plan) {
    if ((state.cursor || 0) > state.past.length) {
      state.cursor = undefined;
    }
    state.past.push(plan);
    state.future = [];
  }
};

const hasMultipleChildren = (parent: BucketArea) => {
  return (
    Array.isArray(parent.children) &&
    parent.children.length >= maxChildrenOfArea
  );
};

const removeDataAndSetNullSplitAxis = (
  parent: BucketArea,
  childIndex: number
) => {
  if (Array.isArray(parent.children)) {
    const hasCompartment = parent.children.every(
      (child) => child.children?.length
    );

    if (hasCompartment) {
      parent.children.splice(childIndex, 1);
      parent.children = parent.children[0]?.children || [];
    } else {
      const compartment = parent.children.find(
        (child) => child.children?.length
      );
      parent.children = compartment?.children || [];
    }

    parent.split_axis = null;
  }
};

const createNewBayPart = ({
  content_max,
  content_min,
  bayPart,
}: {
  content_max: BucketsPoint;
  content_min: BucketsPoint;
  bayPart: BucketsBucketBayPart;
}): Bucket => {
  return {
    bay_part_id: bayPart.id,
    name: bayPart.name,
    type: bayPart.type ?? 'buckets_bucket',
    detail: {
      padding: bayPart.detail.padding,
      area: {
        split_axis: undefined,
        children: [],
        type: 'area',
      },
      content_max,
      content_min,
    },
  };
};

type RemoveItemProps = {
  bucketIndex: number;
  buckets: Bucket[];
  bayPartWidth: number;
};

const removeBottomItem = ({
  bucketIndex,
  buckets: bottomBuckets,
  bayPartWidth,
}: RemoveItemProps) => {
  const leftSideItems = bottomBuckets?.slice(0, bucketIndex);
  const rightSideItems = bottomBuckets?.slice(bucketIndex + 1).map((el) => {
    const { content_max: max, content_min: min } = el.detail;
    return {
      ...el,
      detail: {
        ...el.detail,
        content_max: {
          ...max,
          x: max.x - bayPartWidth,
        },
        content_min: {
          ...min,
          x: min.x - bayPartWidth,
        },
      },
    };
  });
  return [...leftSideItems, ...rightSideItems];
};

const removeTopItem = ({
  bucketIndex,
  buckets: topBuckets,
  bayPartWidth,
}: RemoveItemProps) => {
  const leftSideItems = topBuckets?.slice(0, bucketIndex).map((el) => {
    const { content_max: max, content_min: min } = el.detail;
    return {
      ...el,
      detail: {
        ...el.detail,
        content_max: {
          ...max,
          x: max.x + bayPartWidth,
        },
        content_min: {
          ...min,
          x: min.x + bayPartWidth,
        },
      },
    };
  });
  const rightSideItems = topBuckets?.slice(bucketIndex + 1);
  return [...leftSideItems, ...rightSideItems];
};

type AddItemProps = {
  bucketIndex: number;
  buckets: Bucket[];
  newBayPartWidth: number;
  newBayPart: Bucket;
  isLeftEdgeBottomRow?: boolean;
  isLeftEdgeTopRow?: boolean;
};

const addBottomItem = ({
  bucketIndex,
  buckets: bottomBuckets,
  newBayPartWidth,
  newBayPart,
  isLeftEdgeBottomRow = false,
}: AddItemProps) => {
  if (isLeftEdgeBottomRow) {
    const rightSideItems = bottomBuckets?.map((el) => {
      const { content_max: max, content_min: min } = el.detail;
      return {
        ...el,
        detail: {
          ...el.detail,
          content_max: {
            ...max,
            x: newBayPartWidth + max.x,
          },
          content_min: {
            ...min,
            x: newBayPartWidth + min.x,
          },
        },
      };
    });
    return [newBayPart, ...rightSideItems];
  } else {
    const leftSideItems = bottomBuckets?.slice(0, bucketIndex + 1);
    const rightSideItems = bottomBuckets?.slice(bucketIndex + 1).map((el) => {
      const { content_max: max, content_min: min } = el.detail;
      return {
        ...el,
        detail: {
          ...el.detail,
          content_max: {
            ...max,
            x: newBayPartWidth + max.x,
          },
          content_min: {
            ...min,
            x: newBayPartWidth + min.x,
          },
        },
      };
    });
    return [...leftSideItems, newBayPart, ...rightSideItems];
  }
};

const addTopItem = ({
  bucketIndex,
  buckets: topBuckets,
  newBayPartWidth,
  newBayPart,
}: AddItemProps) => {
  const leftSideItems = topBuckets?.slice(0, bucketIndex + 1).map((el) => {
    const { content_max: max, content_min: min } = el.detail;
    return {
      ...el,
      detail: {
        ...el.detail,
        content_max: {
          ...max,
          x: max.x - newBayPartWidth,
        },
        content_min: {
          ...min,
          x: min.x - newBayPartWidth,
        },
      },
    };
  });
  const rightSideItems = topBuckets?.slice(bucketIndex + 1);
  return [...leftSideItems, newBayPart, ...rightSideItems];
};

const checkIsRotated = (preBucket: Bucket[], newBucket: Bucket[]) => {
  const bucketNum = preBucket.length - 1;
  for (let i = 0; i < bucketNum; i++) {
    if (!preBucket[i]?.detail?.area || !newBucket[bucketNum - i]?.detail?.area)
      return false;
    if (
      JSON.stringify(preBucket[i].detail.area.children) !==
      JSON.stringify(newBucket[bucketNum - i].detail.area.children)
    ) {
      return false;
    }
  }
  return true;
};

export const {
  setPlan,
  addProduct,
  moveProducts,
  removeProducts,
  changeProductsAmount,
  rotateProducts,
  addBayPart,
  moveBayPart,
  removeBayPart,
  undo,
  redo,
  mark,
  replaceBayPart,
  updateCompartmentAttributes,
  splitArea,
  removeArea,
  updateBucketProductPosition,
  updateCanSplitHorizontal,
  updateCanSplitVertical,
  updateBottomBucketsNum,
  reset,
  changeFront,
  resetIsRotated,
} = flatPlanSlice.actions;

export const flatPlanReducer = flatPlanSlice.reducer;
