import { TextPaper } from '@components/molecules';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { emptyText } from '@utils/const';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  totalShelfBoards: string;
  totalUniques: string;
  totalOutOfStocks: string;
  totalUnknowns: string;
  isBucketType?: boolean;
  setIsUnknownProductsReviseMode?: () => void;
};

const unknownTextPaperHight = 54;

export const RealogramStatistics: FC<Props> = ({
  totalShelfBoards,
  totalUniques,
  totalUnknowns,
  totalOutOfStocks,
  isBucketType = false,
  setIsUnknownProductsReviseMode,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap={1}
    >
      <TextPaper
        title={isBucketType ? 'カゴ数' : '段数'}
        text={totalShelfBoards}
        paperSx={{
          height: 54,
          display: 'flex',
          flexDirection: 'column',
        }}
      />
      <TextPaper
        title="アイテム数"
        text={totalUniques}
        paperSx={{
          height: 54,
          display: 'flex',
          flexDirection: 'column',
        }}
      />
      <TextPaper
        title="欠品区画数"
        text={totalOutOfStocks}
        paperSx={{
          height: 54,
          display: 'flex',
          flexDirection: 'column',
        }}
      />
      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme.palette.backgroundBlack.primary,
          p: 2,
          height: {
            xs: isBucketType ? unknownTextPaperHight : 'auto',
            md: 54,
          },
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: {
              xs: 125,
              breakpoint: '80px',
            },
            maxWidth: '144px',
          }}
        >
          <Typography
            variant="caption"
            color={theme.palette.textBlack.secondary}
            lineHeight={1}
          >
            不明な商品フェイス数
          </Typography>
          {totalUnknowns ? (
            <Typography
              textAlign="end"
              fontSize={18}
              fontWeight={500}
              color={theme.palette.textBlack.primary}
            >
              {totalUnknowns}
            </Typography>
          ) : (
            <Typography
              textAlign="start"
              variant="body1"
              color={theme.palette.textBlack.disabled}
              lineHeight={1.5}
            >
              {emptyText}
            </Typography>
          )}
        </Box>
        {totalUnknowns !== '0' && (
          <>
            <Divider
              orientation="vertical"
              sx={{
                borderWidth: '1px',
                color: `${theme.palette.dividerBlack.light}`,
              }}
            />
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                maxWidth: '200px',
                gap: '4px',
                flexWrap: {
                  xs: isBucketType ? 'nowrap' : 'wrap',
                  md: 'nowrap',
                },
              }}
            >
              <Button
                variant="text"
                sx={{
                  fontWeight: 700,
                  fontSize: '12px',
                  lineHeight: '12px',
                  width: '48px',
                  py: 0,
                  px: '6px',
                }}
                onClick={setIsUnknownProductsReviseMode}
              >
                一括訂正
              </Button>
              <Typography variant="caption2">
                「不明な商品」を確信度の高い候補にまとめて訂正
              </Typography>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};
