import { useAbility } from '@casl/react';
import { usePersistStateSearchParam } from '@hooks/usePersistStateSearchParam';
import { CurrentSelectedType } from '@reducers/comparisonItemModal';
import { useGetTenantsQuery } from '@reducers/shelfAppsApi/injections/tenantsApi';
import { AbilityContext } from '@utils/abac';
import {
  profitTabGrossProfit,
  profitTabGrossProfitQueryParamater,
  profitTabQuantity,
  profitTabQuantityQueryParamater,
  profitTabSales,
  profitTabSalesQueryParamater,
} from '@utils/const';
import {
  filteringQueryParams,
  getAttributeQueryParams,
  getModeQueryParams,
  getViewQueryParams,
} from '@utils/urlQueryParams';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Product,
  ProductTag,
  ProfitTab,
  Rate,
  ShelfDetailView,
} from 'types/common';
import { PlanogramPlan } from 'types/planogram';
import { RealogramSelectedItem, RealogramShelfBoard } from 'types/realogram';

export const useRerenderingDetails = () => {
  const ability = useAbility(AbilityContext);
  const canReadDemo = ability.can('read', 'Demo');
  const navigate = useNavigate();
  const location = useLocation();
  const { searchParams, setSearchParamsWithState: setSearchParams } =
    usePersistStateSearchParam({ state: location.state });

  const { data: tenantInfo, isLoading: isLoadingTenant } = useGetTenantsQuery();
  const isTenantSalesAnalytics = tenantInfo?.features.tenant_sales_analytics;

  // URLの更新
  const updateUrlQueryParams = (
    selector: {
      viewQueryParams?: ShelfDetailView;
      isProduct?: boolean;
      attributeQueryParams?: ProductTag;
      profitTab?: ProfitTab;
      evaluationQueryParams?: Rate;
    },
    options = {
      replace: true,
    }
  ) => {
    const {
      viewQueryParams,
      isProduct,
      attributeQueryParams,
      profitTab,
      evaluationQueryParams,
    } = selector;

    const existingParams = Object.fromEntries(searchParams);

    const updateParams = (newParams: Record<string, string>) =>
      setSearchParams(newParams, { replace: !!options.replace });

    const createNewParams = (
      keysToInclude: string[],
      additionalParams = {} as Record<string, string>
    ) => {
      const newParams = filteringQueryParams(keysToInclude, existingParams);
      if (!isProduct) {
        delete newParams.item;
      }
      // delete query key "evaluation" in the planogram screen
      if (Object.values(additionalParams).includes('score')) {
        delete additionalParams.evaluation;
      }
      return { ...newParams, ...additionalParams };
    };

    // viewクエリ自体がない
    if (!viewQueryParams) {
      const newParams = createNewParams(['view', 'compare', 'item'], {
        view: 'layout',
      });
      updateParams(newParams);
      return;
    }

    // layoutの値が想定外の場合
    if (viewQueryParams === 'default') {
      const newParams = createNewParams(['view', 'compare', 'item'], {
        view: 'layout',
      });
      updateParams(newParams);
      return;
    }

    // attributeの値が想定外の場合
    if (viewQueryParams === 'productFlag') {
      const newParams = createNewParams(
        ['view', 'compare', 'item', 'attribute'],
        {
          view: 'attribute',
          attribute: attributeQueryParams || '新商品(1週目)',
        }
      );
      updateParams(newParams);
      return;
    }

    // indexの値が想定外の場合
    if (viewQueryParams === 'profit') {
      if (!isTenantSalesAnalytics) {
        const newParams = createNewParams(['view', 'compare', 'item'], {
          view: 'layout',
        });
        updateParams(newParams);
        return;
      }

      const profitTabMapping: Record<ProfitTab, string> = {
        [profitTabSales]: profitTabSalesQueryParamater,
        [profitTabQuantity]: profitTabQuantityQueryParamater,
        [profitTabGrossProfit]: profitTabGrossProfitQueryParamater,
      };

      const newParams = createNewParams(['view', 'compare', 'item', 'index'], {
        view: 'analytics',
        index: profitTab
          ? profitTabMapping[profitTab]
          : profitTabMapping[profitTabSales],
      });
      updateParams(newParams);
      return;
    }

    // evaluationの値が想定外の場合
    if (viewQueryParams === 'rate') {
      if (!canReadDemo) {
        const newParams = createNewParams(['view', 'compare', 'item'], {
          view: 'layout',
        });
        updateParams(newParams);
        return;
      }

      const evaluationMapping: Record<Rate | string, string> = {
        ['フェイスアップNG']: 'faceup_ng',
        ['欠品区画数']: 'stockout',
        ['不明な商品フェイス数']: 'unknown',
        ['評価OK']: 'ok',
        ['スコア']: 'score',
      };

      const newParams = createNewParams(
        ['view', 'compare', 'item', 'evaluation'],
        {
          view: 'evaluation',
          evaluation: evaluationQueryParams
            ? evaluationMapping[evaluationQueryParams]
            : evaluationMapping['評価OK'],
        }
      );
      updateParams(newParams);
      return;
    }

    navigate(`${location.pathname}?view=layout`, {
      replace: true,
      state: location.state,
    });
  };

  // indexクエリ情報の取得
  const getProfitTabParams = (): ProfitTab => {
    const params = searchParams.get('index');
    switch (params) {
      case profitTabSalesQueryParamater:
        return profitTabSales;

      case profitTabQuantityQueryParamater:
        return profitTabQuantity;

      case profitTabGrossProfitQueryParamater:
        return profitTabGrossProfit;

      default:
        return profitTabSales;
    }
  };

  // evaluationクエリ情報の取得
  const getEvaluationQueryParams = (): Rate => {
    const params = searchParams.get('evaluation');
    switch (params) {
      case 'stockout':
        return '欠品区画数';

      case 'unknown':
        return '不明な商品フェイス数';

      case 'faceup_ng':
        return 'フェイスアップNG';

      case 'ok':
      default:
        return '評価OK';
    }
  };

  const getCompareQueryParams = useCallback(():
    | { id: number; type: CurrentSelectedType }
    | undefined => {
    const existingParams = Object.fromEntries(searchParams);
    const compareString = existingParams['compare'] ?? undefined;
    const queryParams = compareString?.split('-');
    if (!queryParams?.at(0) && !queryParams?.at(1)) return;
    const [type, id] = queryParams;
    return { type: type as CurrentSelectedType, id: parseInt(id) };
  }, [searchParams]);

  // 選択中のアイテム情報を取得する
  const getSelectedItemOfRealogram = useCallback(
    (items?: RealogramShelfBoard[]) => {
      const params = searchParams.get('item');
      const product = {} as RealogramSelectedItem;

      items?.some(({ id: shelfBoardId, compartments }) => {
        const compartment = compartments.find(
          (compartment) => compartment.id === params
        );
        if (compartment) {
          const face = compartment.faces[0];
          product.shelfBoardId = shelfBoardId;
          product.compartmentId = compartment.id;
          product.item = face;
          return true; // Stop iterating through items
        }
        return false;
      });

      const isEmpty = Object.keys(product).length === 0;

      return {
        isEmpty,
        product,
      };
    },
    [searchParams]
  );

  const getSelectedItemOfPlanogram = useCallback(
    (
      products?: Product[],
      productsLayout?: PlanogramPlan['products_layout']
    ) => {
      const params = searchParams.get('item');
      const position: {
        indexY?: number;
        subIndexX?: number;
      } = {};

      const productsSelected = products?.filter(
        (item) => params && item.id === parseInt(params, 10)
      );

      // subPosition > indexY と indexX の座標を取得
      if (productsSelected && productsLayout) {
        for (let indexY = 0; indexY < productsLayout.length; indexY++) {
          const { row } = productsLayout[indexY];
          for (let subIndexX = 0; subIndexX < row.length; subIndexX++) {
            if (row[subIndexX].product_id === productsSelected.at(0)?.id) {
              position.indexY = indexY;
              position.subIndexX = subIndexX;
              break;
            }
          }
        }
      }

      const isEmpty = Object.keys(position).length === 0;

      return {
        isEmpty,
        position,
        productsSelected,
      };
    },
    [searchParams]
  );

  return {
    searchParams,
    isViewQueryParams: searchParams.has('view'),
    selector: {
      viewQueryParams: getViewQueryParams(searchParams),
      modeQueryParams: getModeQueryParams(searchParams),
      attributeQueryParams: getAttributeQueryParams(searchParams),
      profitTab: getProfitTabParams(),
      evaluationQueryParams: getEvaluationQueryParams(),
      compareQueryParams: getCompareQueryParams(),
    },
    operate: {
      getSelectedItemOfRealogram,
      getSelectedItemOfPlanogram,
      updateUrlQueryParams,
    },
    data: {
      isLoadingTenant,
      canReadDemo,
      isTenantSalesAnalytics,
    },
  };
};
