import { StopPropagationButton } from '@components/organisms/stopPropagationButton/stopPropagationButton';
import { useFlatPlanogramPlan } from '@hooks/useFlatPlanogramPlan';
import {
  AddCircle,
  Delete,
  RemoveCircle,
  RotateRight,
} from '@mui/icons-material';
import { Button, ButtonGroup, Divider } from '@mui/material';
import {
  addRecentProduct,
  updateFlatOverflowState,
} from '@reducers/planogramEditor/reducer';
import { useAppDispatch } from '@store/index';
import { CommonColors } from '@utils/colors';
import { FC, SyntheticEvent } from 'react';
import { Product } from 'types/common';
import { BucketArea, Position } from 'types/planogram';

type Props = {
  target: BucketArea;
  handleClose: () => void;
  handleClickRotate: (e: SyntheticEvent) => void;
  bucketIndex: number;
  areaPath?: Position[];
  product: Product;
};

// TODOリファクタ: shelfのコンポーネントと統合する
export const ProductsButtonGroup: FC<Props> = ({
  target,
  handleClose,
  handleClickRotate,
  bucketIndex,
  areaPath,
  product,
}) => {
  const dispatch = useAppDispatch();
  const disableMinus = target?.count?.x === 1;
  const {
    removeProducts,
    incrementHorizontalAmount,
    decrementHorizontalAmount,
  } = useFlatPlanogramPlan();
  return (
    <ButtonGroup
      sx={{
        height: 48,
        '& .MuiButtonGroup-grouped': {
          color: CommonColors.appBlue,
          border: 0,
          '&:hover': {
            border: 0,
          },
        },
      }}
    >
      <StopPropagationButton
        onClick={() => decrementHorizontalAmount(bucketIndex, areaPath)}
        disabled={disableMinus}
      >
        <RemoveCircle
          sx={
            disableMinus
              ? { opacity: 0.26, fill: CommonColors.black }
              : { opacity: 1, fill: CommonColors.appBlue }
          }
        />
      </StopPropagationButton>
      <StopPropagationButton
        onClick={() => incrementHorizontalAmount(bucketIndex, areaPath)}
      >
        <AddCircle />
      </StopPropagationButton>
      <Divider
        orientation="vertical"
        component="div"
        flexItem
        sx={{ margin: '10px 0', color: '#D9D9D9' }}
      />
      <Button
        onClick={(e) => {
          e.stopPropagation(); // popperのcloseイベントを防ぐ
          handleClickRotate(e);
        }}
      >
        <RotateRight />
      </Button>
      <Divider
        orientation="vertical"
        component="div"
        flexItem
        sx={{ margin: '10px 0', color: '#D9D9D9' }}
      />
      <Button
        onClick={(e) => {
          e.stopPropagation();
          removeProducts({ bucketIndex, areaPath });
          if (!areaPath) return;
          dispatch(
            updateFlatOverflowState({
              bucketIndex,
              areaPath,
              overflow: false,
              isDelete: true,
            })
          );
          dispatch(addRecentProduct(product));
          handleClose();
        }}
      >
        <Delete />
      </Button>
    </ButtonGroup>
  );
};
