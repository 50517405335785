import { FC } from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import { theme } from 'theme';
import { ColumnProps } from '@components/organisms/tableHeader/tableHeader';

type Props = {
  columns: ColumnProps[];
  handleClick: () => void;
};

export const LoadMoreTableFooter: FC<Props> = ({ columns, handleClick }) => {
  return (
    <TableRow
      sx={{ backgroundColor: theme.palette.white.primary, height: '72px' }}
    >
      <TableCell
        colSpan={columns.length}
        sx={{
          p: 0,
          color: '#D9D9D9',
          textAlign: 'center',
        }}
      >
        <Button variant="outlined" onClick={handleClick}>
          さらに読み込む
        </Button>
      </TableCell>
    </TableRow>
  );
};
