import { CreateOrRenameDirectoryFormProps } from '@components/organisms/createOrRenameDirectoryForm/createOrRenameDirectoryForm';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import { closeModal } from '@reducers/modal';
import {
  useCreatePlanogramDirectoryMutation,
  useUpdatePlanogramDirectoryMutation,
} from '@reducers/shelfAppsApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import { toastMessages } from '@utils/const';
import { useParams } from 'react-router-dom';
import { PlanogramDirectory } from 'types/planogram';
import { useGetPlanogramPermission } from './useGetPlanogramPermission';
import { useModal } from './useModal';

export const useDirectoryManagement = (data: {
  afterCreateDirectorySuccessfully: (newDirectoryId: string) => void;
}) => {
  const { afterCreateDirectorySuccessfully } = data;
  const dispatch = useAppDispatch();
  const { isViewFolder, planogramDirectoryParent } = useAppSelector(
    (state) => state.SharePermission
  );
  const { isNotEnable: isCanNotCreate } = useGetPlanogramPermission({
    action: 'create',
    planogram: planogramDirectoryParent,
    isPlanogram: false,
    isCan: false,
  });
  const params = useParams();
  const currentDirectoryId = params.id;

  const [createPlanogramDirectory] = useCreatePlanogramDirectoryMutation();
  const [updateDirectory] = useUpdatePlanogramDirectoryMutation();

  const { showModal: showCreateDirectoryModal } =
    useModal<CreateOrRenameDirectoryFormProps>(
      'createOrRenameDirectoryForm',
      '新規フォルダ'
    );

  const { showModal: showRenameDirectoryModal } =
    useModal<CreateOrRenameDirectoryFormProps>(
      'createOrRenameDirectoryForm',
      'フォルダ名変更'
    );

  const handleCreateDirectory = () => {
    if (isViewFolder && isCanNotCreate) {
      dispatch(
        openToast({
          message: toastMessages.error.notPermittedCreateDirectory,
          type: 'error',
        })
      );

      return;
    }

    showCreateDirectoryModal({
      type: 'directory',
      onSubmit: async ({ name, type, planogramId }) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          const { planogram_directory: newDirectory } =
            await createPlanogramDirectory({
              name,
              type,
              parentId: currentDirectoryId,
              planogramId,
            }).unwrap();
          dispatch(closeModal());
          if (newDirectory) {
            afterCreateDirectorySuccessfully(newDirectory.id);
          }
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              message: toastMessages.error.createDirectory,
              type: 'error',
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  const handleUpdateDirectoryName = (
    planogramDirectory?: PlanogramDirectory
  ) => {
    if (!planogramDirectory || planogramDirectory?.type === 'file') return;

    showRenameDirectoryModal({
      name: planogramDirectory.name,
      type: 'directory',
      actionBtnTitle: '変更',
      onSubmit: async (data) => {
        dispatch(updateLoadingIndicatorState(true));
        try {
          await updateDirectory({
            ...data,
            id: planogramDirectory.id,
          }).unwrap();
          dispatch(
            openToast({
              type: 'success',
              message: toastMessages.success.updateDirectoryName,
            })
          );
          dispatch(closeModal());
        } catch (e) {
          console.error(e);
          dispatch(
            openToast({
              message: toastMessages.error.updateDirectoryName,
              type: 'error',
            })
          );
        } finally {
          dispatch(updateLoadingIndicatorState(false));
        }
      },
    });
  };

  return { handleCreateDirectory, handleUpdateDirectoryName };
};
