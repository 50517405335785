import { ArrowUpwardDownwardIcon } from '@components/molecules/arrowUpwardDownwardIcon/arrowUpwardDowneardIcon';
import {
  Box,
  Divider,
  SxProps,
  TableCell,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { PlanogramOrderBy, PlanogramListOrder } from 'types/planogram';

type Props = {
  columns: ColumnProps[];
  firstOrder?: PlanogramListOrder;
  // TODO: RealogramsTableにsort機能を実装後,optional解除
  handleChangeOrder?: (order: PlanogramListOrder) => void;
};
export type ColumnProps = {
  headerName: string;
  orderBy?: PlanogramOrderBy;
  headerSx?: SxProps;
  hasDivider?: boolean;
};

export const TableHeader: FC<Props> = ({
  columns,
  firstOrder,
  handleChangeOrder = () => {
    return;
  },
}) => {
  return (
    <TableRow
      sx={{
        whiteSpace: 'nowrap',
        backgroundColor: '#F6FAFF',
        width: '100%',
        height: '48px',
      }}
    >
      {columns.map((item, index) => {
        const orderBy = item.orderBy;
        const isCurrentOrderBy = firstOrder?.startsWith(orderBy ?? '');
        // 現在のソートカテゴリと異なる時は必ずascとなる。
        const currentOrder =
          isCurrentOrderBy && firstOrder?.endsWith('desc') ? 'desc' : 'asc';
        const nextOrder =
          isCurrentOrderBy && firstOrder?.endsWith('asc') ? 'desc' : 'asc';
        return (
          <TableCell
            key={index}
            sx={item.headerSx}
            sortDirection={currentOrder}
          >
            <Box component="div" display="flex" gap="16px">
              {item.hasDivider && <Divider orientation="vertical" flexItem />}
              {orderBy ? (
                <TableSortLabel
                  hideSortIcon={!isCurrentOrderBy}
                  active={isCurrentOrderBy}
                  direction={currentOrder}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    '& .MuiTableSortLabel-icon': {
                      width: 20,
                      height: 20,
                    },
                  }}
                  onClick={() => {
                    handleChangeOrder(`${orderBy}_${nextOrder}`);
                  }}
                >
                  <Typography color="gray" fontSize="14px">
                    {item.headerName}
                  </Typography>
                  {!isCurrentOrderBy && (
                    <Box component="div" width={20} height={20}>
                      <ArrowUpwardDownwardIcon />
                    </Box>
                  )}
                </TableSortLabel>
              ) : (
                <Typography color="gray" fontSize="14px">
                  {item.headerName}
                </Typography>
              )}
              {item.headerName === '権限' && (
                <Divider orientation="vertical" flexItem />
              )}
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
