import { ModalInnerProps } from '@components/modal';
import { SelectionModalActions } from '@components/molecules/selectionModalActions/selectionModalActions';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { Folder } from '@mui/icons-material';
import {
  setCurrentRealogramDirectoryId,
  updateRealogramSearch,
  updateSelectedItemId,
} from '@reducers/selectionStoreModal';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';
import {
  useGetUserQuery,
  useListRealogramDirectoriesQuery,
  useSearchRealogramDirectoriesQuery,
} from '@reducers/shelfAppsApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { AppAbility } from '@utils/abac';
import { rowsPerPage } from '@utils/const';
import { FC, useEffect, useMemo, useState } from 'react';
import { RealogramDirectory } from 'types/realogram';
import { BaseModal } from '../baseModal/baseModal';
import { SelectionRealograms } from './fragments/selectionRealograms';

const sideBarList = [
  {
    icon: <Folder />,
    label: 'すべて',
    value: 'all',
  },
  {
    icon: <StarIcon />,
    label: 'スター付き',
    value: 'favorite',
  },
];

const sideBarAll = sideBarList[0].value;
const sideBarFavorite = sideBarList[1].value;
type SelectionStoreModalProps = {
  handleChangeSelectedStoreModal: (selectedItem?: RealogramDirectory) => void;
  ability: AppAbility;
};
type Props = ModalInnerProps<SelectionStoreModalProps>;

export const SelectionStoreModal: FC<Props> = ({ handleClose, data }) => {
  const { ability, handleChangeSelectedStoreModal } = data;
  const {
    currentSelectedItem,
    currentRealogramDirectoryId,
    currentSelectedItemId,
  } = useAppSelector(selectionStoreModal);
  const [search, setSearch] = useState('');
  const [sideBarValue, setSideBarValue] = useState('all');
  const isFilteredByFavorite = sideBarValue === sideBarFavorite;
  const isFilterByAll = sideBarValue === sideBarAll;

  const [isClearSearch, setIsClearSearch] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);
  const dispatch = useAppDispatch();
  const [realogramDirectoryId, setRealogramDirectoryId] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<
    RealogramDirectory | undefined
  >(currentSelectedItem);
  const { data: userData } = useGetUserQuery({
    userId: 'me',
  });

  const isSkipListRealogramDirectoriesQuery = !!search || isFilteredByFavorite;
  const {
    data: listRealogramDirectoryData,
    isLoading: isListRealogramDirectoryDataLoading,
    isFetching: isListRealogramDirectoryDataFetching,
  } = useListRealogramDirectoriesQuery(
    {
      id:
        currentRealogramDirectoryId && !realogramDirectoryId
          ? currentRealogramDirectoryId
          : realogramDirectoryId,
    },
    {
      skip: isSkipListRealogramDirectoriesQuery,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: searchedRealogramData,
    isFetching: isSearchRealogramFetching,
    isLoading: isSearchRealogramLoading,
  } = useSearchRealogramDirectoriesQuery(
    {
      name: search,
      offset: offset,
      favoriteOwnerId:
        isFilteredByFavorite && userData?.user.id
          ? [userData.user.id]
          : undefined,
      directoryType: 'directory',
      firstOrder: 'created_at_desc',
    },
    {
      skip: !isSkipListRealogramDirectoriesQuery,
      refetchOnMountOrArgChange: true,
    }
  );

  const isFetching =
    isSearchRealogramFetching || isListRealogramDirectoryDataFetching;

  const isLoading =
    isSearchRealogramLoading || isListRealogramDirectoryDataLoading;

  const realogramDirectories = useMemo(() => {
    const items =
      search || isFilteredByFavorite
        ? searchedRealogramData?.realogram_directories
        : listRealogramDirectoryData?.realogram_directories;
    return (items || []).filter((item) => item.type !== 'file');
  }, [
    search,
    isFilteredByFavorite,
    searchedRealogramData,
    listRealogramDirectoryData,
  ]);

  const handleClickSideBarChip = (value: string) => {
    clearSearchText();
    setSideBarValue(value);
    setOffset(0);
    setRealogramDirectoryId(undefined);
    dispatch(setCurrentRealogramDirectoryId(undefined));
  };

  const handleClickBack = () => {
    if (!listRealogramDirectoryData?.parent?.parent_id) return;
    setRealogramDirectoryId(listRealogramDirectoryData.parent.parent_id);
  };
  const clearSearchText = () => {
    setOffset(0);
    setSearch('');
    setRealogramDirectoryId(undefined);
  };
  const handleSearchText = (text: string) => {
    setOffset(0);
    setSideBarValue('all');
    setSearch(text);
  };

  const displayName = () => {
    if (isFilteredByFavorite) return 'スター付き';
    const { name, type } = listRealogramDirectoryData?.parent || {};
    if (type !== 'root' && name) {
      return name;
    }
    return '店舗一覧';
  };

  const handleClickBreadcrumbs = (item: RealogramDirectory) => {
    if (item.type === 'root' || item.type === 'directory') {
      setOffset(0);
      setRealogramDirectoryId(item.id);
    }
  };

  const handleClickListItem = (item: RealogramDirectory) => {
    if (item.store_id) {
      setSelectedItem(item);
      dispatch(updateSelectedItemId(item.id));
      return;
    }
    setRealogramDirectoryId(item.id);
    setSideBarValue('all');
    setSearch('');
    setOffset(0);
    dispatch(updateRealogramSearch(undefined));
  };

  useEffect(() => {
    const threshold = 10;
    const shouldFetch =
      !!search &&
      realogramDirectories &&
      // continuous fetching if the length of the filtered data is less than 10
      realogramDirectories.length < threshold &&
      offset + rowsPerPage <= (searchedRealogramData?.pager?.total ?? 0) &&
      !isSearchRealogramFetching &&
      !isSearchRealogramLoading;
    if (shouldFetch) {
      setOffset(offset + rowsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should be fire only if realogramSearchText is changed because this effect is use for continuous fetching
  }, [dispatch, realogramDirectories]);

  return (
    <BaseModal
      isUnderRoot={
        listRealogramDirectoryData?.parent &&
        listRealogramDirectoryData?.parent?.type !== 'root' &&
        isFilterByAll
      }
      listHeaderTitle={displayName()}
      setIsClearSearch={setIsClearSearch}
      handleClickSideBarChip={handleClickSideBarChip}
      sideBarHiddenValue=""
      sideBarValues={sideBarList}
      sideBarValue={sideBarValue}
      clearSearchText={clearSearchText}
      modalTitle="店舗"
      modalSubtitle="を選択"
      search={search}
      searchPlaceholder="キーワードで検索"
      handleSearchText={handleSearchText}
      handleClickBack={handleClickBack}
      isClearSearch={isClearSearch}
      list={
        <SelectionRealograms
          isLoading={isLoading}
          isFetching={isFetching}
          searchedTotal={searchedRealogramData?.pager?.total ?? 0}
          realogramOffset={offset}
          realogramDirectories={realogramDirectories}
          realogramBreadcrumbs={
            isFilterByAll ? listRealogramDirectoryData?.breadcrumb : undefined
          }
          realogramDirectoryParent={
            isFilterByAll ? listRealogramDirectoryData?.parent : undefined
          }
          setRealogramOffset={setOffset}
          handleClickBreadcrumbs={handleClickBreadcrumbs}
          handleClickListItem={handleClickListItem}
          userId={userData?.user.id}
          textSearch={search}
          ability={ability}
        />
      }
      footer={
        <SelectionModalActions
          handleClick={() => {
            handleChangeSelectedStoreModal(selectedItem);
          }}
          handleClose={() => {
            dispatch(updateSelectedItemId(currentSelectedItemId));
            if (selectedItem?.parent_id) {
              dispatch(setCurrentRealogramDirectoryId(selectedItem?.parent_id));
            }
            handleClose();
          }}
          disabledSelect={!selectedItem?.store_id}
        />
      }
    />
  );
};
