/* eslint filename-consistency/match: off -- ファイル名変更に伴い、呼び出し元もLintによる修正をする必要があるため */

// Imports
// Custom made components import
import { ShelvesViewToggle } from '@components/molecules';
import { ActionVisible } from '@components/molecules/actionVisible/actionVisible';
import { BreadcrumbList } from '@components/molecules/breadcrumbList/breadcrumbList';
import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { FilterButton } from '@components/molecules/filterButton/filterButton';
import { GoParentDirectoryButton } from '@components/molecules/goParentDirectoryButtonIcon/goParentDirectoryButton';
import { AppBar, DeleteRealogramDialog } from '@components/organisms';
import {
  GridModeSort,
  ListOrder,
} from '@components/organisms/gridModeSort/gridModeSort';
import { RealogramsConditions } from '@components/organisms/realogramsConditions/realogramsConditions';
import { RealogramsSidebar } from '@components/organisms/realogramsSidebar/realogramsSidebar';
import { ScanPermissionDialog } from '@components/organisms/scanPermissionDialog/scanPermissionDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRerenderingScanner } from '@hooks/rerenderingComponents';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { usePageTitle } from '@hooks/usePageTitle';
import { useQueryParameterPresence } from '@hooks/useQueryParameterPresence';
import { useSessionStorage } from '@hooks/useSessionStorage';
import { useSharePermissionModal } from '@hooks/useSharePermissionModal';
import { Close, Search } from '@mui/icons-material';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { updateLoadingIndicatorState } from '@reducers/loadingIndicator';
import {
  setLastVisitedRealogramListURL,
  setRealogramsPageNumber,
  setSelectedDirectoryId,
  setSelectedItemIndex,
} from '@reducers/scanner';
import { setDirectoryId, setDirectoryType } from '@reducers/sharePermission';
import {
  useDeleteRealogramMutation,
  useLazyListStoresQuery,
} from '@reducers/shelfAppsApi';
import {
  useAddFavoriteRealogramMutation,
  useDeleteFavoriteRealogramMutation,
  useListRealogramDirectoriesQuery,
  useSearchRealogramDirectoriesQuery,
} from '@reducers/shelfAppsApi/injections/realogramDirectoriesApi';
import { openToast } from '@reducers/toast';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  SidebarValue,
  getListPageTitle,
  getSortOptions,
  pageTitle,
  paths,
  removeFirstLastSpaces,
  rowsPerPage,
  toastMessages,
} from '@utils/const';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { theme } from 'theme';
import { DirectoryType } from 'types/common';
import {
  RealogramDirectory,
  RealogramListOrder,
  RealogramOrder,
} from 'types/realogram';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { InferType, object, string } from 'yup';
import { RealogramsDirectoryGrid } from './fragments/ralogramsDirectoryGrid/realogramsDirectoryGrid';
import { RealogramsDirectoryTable } from './fragments/realogramsDirectoryTable/realogramsDirectoryTable';

const schema = object({
  searchText: string().required(),
});

const queryList = ['owner_id', 'name'];
const defaultSearchedOffsetByTab = {
  all: 0,
  favorite: 0,
  latest: 0,
  viewed: 0,
};
type FormData = InferType<typeof schema>;

const getDirectoryPageTitle = (
  sidebarValue: SidebarValue,
  isRoot: boolean,
  directoryParentName: string
) => {
  if (sidebarValue === 'favorite') {
    return 'スター付き';
  } else if (sidebarValue === 'latest') {
    return '最近のスキャン';
  } else if (isRoot) {
    return 'スキャン結果';
  } else {
    return directoryParentName;
  }
};

export const Realograms: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, setSessionLastVisitedRealogramListURL] = useSessionStorage<string>(
    'sessionLastVisitedRealogramListURL'
  );
  const { selectedDirectoryId } = useAppSelector((state) => state.Scanner);
  const { isViewFolder } = useAppSelector((state) => state.SharePermission);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isPermissionDialogOpen, setIsPermissionDialogOpen] = useState(false);
  const [currentRealogramId, setCurrentRealogramId] = useState<number>();
  const [deleteRealogram] = useDeleteRealogramMutation();

  const { pathname, search } = location;
  const currentUrl = pathname + search;

  const {
    isInitRendering,
    viewMode,
    condition,
    isShowFilters,

    setViewMode,
    setCondition,
    setIsShowFilters,

    conditionData: { me, userData, userDataLoading, users },
    sidebarData: {
      sidebarValue,
      isFilteredByFavorite,
      isFilteredByLatest,
      isFilteredByViewed,
      defaultFirstOrderBySideBar,
    },
  } = useRerenderingScanner();

  const { hasQueryParameters } = useQueryParameterPresence();
  const hasQueries = hasQueryParameters(queryList);
  const { name } = condition ?? {};
  const params = useParams();
  const directoryId = params.id;
  const [offsetByTab, setOffsetByTab] = useState<Record<SidebarValue, number>>(
    defaultSearchedOffsetByTab
  ); // used to maintain tab offset when switching tabs using back browser button
  const [fetchStoreData] = useLazyListStoresQuery();

  const offset = offsetByTab[sidebarValue];
  const setOffset = (value: number) => {
    setOffsetByTab({
      ...offsetByTab,
      [sidebarValue]: value,
    });
  };

  const clearOffset = () => {
    setOffsetByTab(defaultSearchedOffsetByTab);
  };

  const isSkipListRealogramDirectoriesQuery =
    isFilteredByFavorite ||
    isFilteredByLatest ||
    isFilteredByViewed ||
    !!condition?.name ||
    // Make sure all condition is completely parsed from query URL when initializing page
    !isInitRendering;

  const {
    data: directory,
    isLoading: isDirectoryLoading,
    isFetching: isDirectoryFetching,
    refetch: refetchListRealogramDirectories,
  } = useListRealogramDirectoriesQuery(
    {
      id: directoryId,
      status: condition?.status ? condition.status : undefined,
      firstOrder: condition?.firstOrder as RealogramOrder,
    },
    {
      skip: isSkipListRealogramDirectoriesQuery,
      refetchOnMountOrArgChange: true,
    }
  );

  const isSkipSearchRealogramDirectoriesQuery =
    (!isSkipListRealogramDirectoriesQuery && !condition?.ownerId) ||
    // Prevent favoriteOwnerId is undefined when initializing starred page without userData
    (isFilteredByFavorite && !userData?.user.id) ||
    // Make sure all condition is completely parsed from query URL when initializing page
    !isInitRendering;
  const {
    data: searchedData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
    isUninitialized: isUninitializedSearchRealogramDirectoriesQuery,
    refetch: refetchSearchRealogramDirectories,
  } = useSearchRealogramDirectoriesQuery(
    {
      offset,
      limit: rowsPerPage,
      name: condition?.name,
      ownerId: condition?.ownerId ? condition.ownerId : undefined,
      status: condition?.status ? condition.status : undefined,
      firstOrder: condition?.firstOrder,
      favoriteOwnerId:
        isFilteredByFavorite && userData?.user.id
          ? [userData.user.id]
          : undefined,
      directoryType: isFilteredByLatest ? 'file' : undefined,
      directoryAncestorId: directoryId ? directoryId : undefined,
    },
    {
      skip: isSkipSearchRealogramDirectoriesQuery,
      refetchOnMountOrArgChange: true,
    }
  );

  const { control, handleSubmit, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: '',
    },
  });

  // these 2 fields only used to determine hightlight filter button or not
  const planogramConiditionFilters = {
    statusId: condition?.status,
    ownerId: condition?.ownerId,
  };

  const hasCondition = Object.values(planogramConiditionFilters).some((el) => {
    if (Array.isArray(el)) {
      return !!el.length;
    }
    return !!el;
  });

  const isLoadingData =
    isSearchLoading ||
    isDirectoryLoading ||
    (isFilteredByFavorite && userDataLoading) ||
    !isInitRendering;
  const isFetchingData = isSearchFetching || isDirectoryFetching;
  const shouldChangeColor = hasCondition && !isShowFilters;
  const filterButtonTextColor = shouldChangeColor
    ? theme.palette.primary
    : theme.palette.textBlack.primary;
  const filterButtonIconColor = shouldChangeColor
    ? theme.palette.primary
    : theme.palette.icons.primary;
  const isRoot = directory?.parent.type === 'root';
  const isShowBreadcrumbList = !!directory && !!directoryId;

  const realogramDirectories = useMemo(() => {
    if (isLoadingData) return [];
    return isSkipSearchRealogramDirectoriesQuery
      ? directory?.realogram_directories
      : searchedData?.realogram_directories;
  }, [
    isLoadingData,
    isSkipSearchRealogramDirectoriesQuery,
    searchedData?.realogram_directories,
    directory?.realogram_directories,
  ]);

  const { isEnable: isCanUpdate } = useGetRealogramPermission({
    action: 'update',
    realogram: directory?.parent,
    isCandidate: false,
    isCan: true,
  });
  const { isEnable: isCanRead } = useGetRealogramPermission({
    action: 'read',
    realogram: directory?.parent,
    isCandidate: false,
    isCan: true,
  });

  useEffect(() => {
    if (!isFirstRender && name) {
      setIsFocused(true);
      setValue('searchText', name);
      setIsFirstRender(true);
    } else if (!name && isFirstRender) {
      setIsFocused(false);
      setValue('searchText', '');
    }
  }, [isFirstRender, name, setIsFocused, setValue]);

  useEffect(() => {
    if (
      !selectedDirectoryId &&
      !isViewFolder &&
      directory &&
      directory.parent.type !== 'root'
    ) {
      dispatch(setSelectedDirectoryId(directory.parent.id));
    }
  }, [directory, selectedDirectoryId, dispatch, isViewFolder]);

  const navigateToScanner = async () => {
    dispatch(setRealogramsPageNumber(1));
    dispatch(updateLoadingIndicatorState(true));
    const stores = await fetchStoreData({
      filterByRole: 'editor',
    });
    if (stores.data?.stores?.length) {
      navigate(paths.actuals.scan);
    } else {
      setIsPermissionDialogOpen(true);
    }
    dispatch(updateLoadingIndicatorState(false));
  };

  const handleClickDirectory = (item: RealogramDirectory) => {
    const directoryId = item.id;
    clearOffset();
    setIsFocused(false);
    setValue('searchText', '');
    dispatch(setSelectedDirectoryId(directoryId));
    setCondition(
      {
        ...condition,
        name: undefined,
        firstOrder: undefined,
      },
      {
        directoryId,
      }
    );
  };

  const handleClickFile = (index: number, item: RealogramDirectory) => {
    dispatch(setSelectedItemIndex(index));
    dispatch(setLastVisitedRealogramListURL(currentUrl));
    setSessionLastVisitedRealogramListURL(currentUrl);
    navigate(paths.actuals.id(String(item.realogram_candidate_id)));
  };

  const handleRowClick = (index: number, item: RealogramDirectory) => {
    if (item.type === 'directory') {
      handleClickDirectory(item);
    } else if (item.type === 'file' && item.realogram_candidate_id) {
      handleClickFile(index, item);
    }
  };
  const handleOpenDeleteDialog = (id: number) => {
    setDeleteDialogOpen(true);
    setCurrentRealogramId(id);
  };

  const handleDeleteRealogram = async () => {
    setDeleteDialogOpen(false);
    dispatch(updateLoadingIndicatorState(true));
    try {
      await deleteRealogram({
        realogramCandidateId: currentRealogramId ?? 0,
      }).unwrap();
      if (offset !== 0) {
        clearOffset();
      } else {
        if (isSkipListRealogramDirectoriesQuery) {
          await refetchSearchRealogramDirectories().unwrap();
        } else {
          await refetchListRealogramDirectories().unwrap();
        }
      }
      dispatch(
        openToast({
          type: 'success',
          message: toastMessages.success.deleteScanResult,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        openToast({
          type: 'error',
          message: toastMessages.error.deleteScanResult,
        })
      );
    } finally {
      dispatch(updateLoadingIndicatorState(false));
    }
  };

  usePageTitle('スキャン結果一覧');

  const handleClickMoveParentDirectory = (parentId?: string) => {
    const isBackToRootDirectory =
      directory?.breadcrumb?.length === 1 || !parentId;
    const directoryId = isBackToRootDirectory ? '' : parentId;

    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
  };

  const handleClickBreadcrumbs = (directory: DirectoryType) => {
    const isBackToRootDirectory = directory.type === 'root';
    const directoryId = isBackToRootDirectory ? '' : directory.id;
    // update related states and sync query parameter to url
    afterHandleChangeBreadCrumb({
      isBackToRootDirectory,
      directoryId,
      hasCondition,
    });
  };

  const afterHandleChangeBreadCrumb = (data: {
    isBackToRootDirectory: boolean;
    hasCondition: boolean;
    directoryId: string;
  }) => {
    const { directoryId, isBackToRootDirectory } = data;
    clearOffset();
    setIsFocused(false);
    setValue('searchText', '');
    dispatch(setSelectedDirectoryId(directoryId));
    const newCondition = {
      ...condition,
      name: undefined,
    };
    if (isBackToRootDirectory) {
      setCondition(newCondition, {
        isToRootDirectory: true,
      });
    } else {
      setCondition(newCondition, {
        directoryId,
      });
    }
  };

  const onSubmit = (data: FormData) => {
    const name = removeFirstLastSpaces(data.searchText);
    if (name === '') return;
    // always search in the tab all
    const firstOrder = defaultFirstOrderBySideBar['all'];
    clearOffset();
    dispatch(setSelectedDirectoryId(''));
    setCondition(
      {
        ...condition,
        firstOrder,
        name,
      },
      { isToRootDirectory: true }
    );
  };

  const clearTextSearch = () => {
    clearOffset();
    setIsFocused(false);
    setValue('searchText', '');
    dispatch(setSelectedDirectoryId(''));
    setCondition(
      {
        ...condition,
        name: undefined,
        firstOrder: undefined,
      },
      {
        isToRootDirectory: true,
      }
    );
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleChangeSelectedOwnerId = (ownerId?: number[]) => {
    clearOffset();
    const isClear =
      condition?.ownerId?.at(0) === ownerId?.at(0) || !ownerId?.length;
    if (isClear) {
      // Because the filter has only ownerId -> Clear directory_ancestor_id to refetch ListRealogramDirectoriesQuery again
      setCondition({
        ...condition,
        ownerId: undefined,
      });
    } else {
      setCondition({
        ...condition,
        ownerId,
      });
    }
  };

  const clearFilters = () => {
    clearOffset();
    setCondition({
      ...condition,
      ownerId: undefined,
      status: undefined,
    });
  };

  const handleShowFilters = () => {
    setIsShowFilters(!isShowFilters);
  };

  const handleChangeOrder = (firstOrder?: ListOrder) => {
    clearOffset();
    const newFirstOrder =
      isFilteredByViewed && !firstOrder
        ? defaultFirstOrderBySideBar['viewed']
        : firstOrder;
    setCondition({
      ...condition,
      firstOrder: newFirstOrder as RealogramListOrder,
    });
  };

  const [addFavoriteRealogram] = useAddFavoriteRealogramMutation();
  const [deleteFavoriteRealogram] = useDeleteFavoriteRealogramMutation();
  const handleFavoriteClick = async (id: string, isFavorite: boolean) => {
    clearOffset();
    try {
      if (isFavorite) {
        await deleteFavoriteRealogram({ directoryId: id }).unwrap();
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.removeStar,
          })
        );
      } else {
        await addFavoriteRealogram({ directoryId: id }).unwrap();
        dispatch(
          openToast({
            type: 'success',
            message: toastMessages.success.addStar,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSidebar = (value: SidebarValue) => {
    if (sidebarValue === value) return;
    const firstOrder = defaultFirstOrderBySideBar[value];
    clearOffset();
    setIsFocused(false);
    setValue('searchText', '');
    dispatch(setSelectedDirectoryId(''));
    setIsShowFilters(false);
    setCondition(
      {
        ...condition,
        name: undefined,
        ownerId: undefined,
        status: undefined,
        firstOrder: firstOrder,
      },
      {
        sideBarValue: value,
      }
    );
  };

  const handleEndReached = (index: number) => {
    if (isLoadingData || isFetchingData) return;
    const offset = index + 1;
    if (searchedData?.pager.total && offset >= searchedData?.pager.total)
      return; // NOTE: 全件取得済みのときは何もしない
    // prevent searching items by wrong offsets when initializing page having query parameters
    if (isUninitializedSearchRealogramDirectoriesQuery) {
      clearOffset();
    } else {
      setOffset(offset);
    }
  };

  const { showLicenseModal } = useSharePermissionModal();

  const handleSharePermission = (item: RealogramSharePermissionProps) => {
    if (!item.directoryId) return;
    dispatch(setDirectoryId(`${item?.directoryId}`));
    dispatch(setDirectoryType('realogram'));
    showLicenseModal({
      type: item.type,
    });
  };

  return (
    <>
      <AppBar title="棚割実績（棚スキャナ）" />
      <Box component="div" display="flex">
        <RealogramsSidebar
          navigateToScanner={navigateToScanner}
          sidebarValue={sidebarValue}
          onClickTabAll={() => handleClickSidebar('all')}
          onClickTabFavorite={() => handleClickSidebar('favorite')}
          onClickTabLatest={() => handleClickSidebar('latest')}
          onClickTabViewed={() => handleClickSidebar('viewed')}
        />
        <Box component="div" mt={2} px={3} flex={1}>
          {/*56px = 40 AppBar + 16px margin*/}
          <Box
            component="div"
            width="100%"
            height="calc(100vh - 57px)"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <Box component="div" display="flex" justifyContent="space-between">
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                display="flex"
              >
                <Controller
                  name="searchText"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="棚割・フォルダを検索"
                      onFocus={handleFocus}
                      size="small"
                      sx={{
                        width: '320px',
                        'input::placeholder': {
                          fontSize: '14px',
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: '40px',
                          backgroundColor: '#F7F7F7',
                        },
                        startAdornment: (
                          <Search
                            sx={{
                              color: theme.palette.icons.primary,
                              mr: '4px',
                            }}
                          />
                        ),
                        endAdornment: isFocused && (
                          <IconButton onClick={clearTextSearch}>
                            <Close
                              sx={{
                                width: '18px',
                                height: '18px',
                                cursor: 'pointer',
                                color: theme.palette.icons.primary,
                              }}
                            />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <ShelvesViewToggle
                mode={viewMode}
                onChange={(mode) => {
                  setViewMode(mode);
                }}
              />
            </Box>

            <Box
              component="div"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={-1}
            >
              {isShowBreadcrumbList ? (
                <Box component="div">
                  {!isRoot && (
                    <Box component="div" marginLeft={5}>
                      <BreadcrumbList
                        breadcrumbs={directory.breadcrumb}
                        parentDirectory={directory.parent}
                        screenType="Main"
                        fontSize="12px"
                        handleClickBreadcrumbs={handleClickBreadcrumbs}
                        directoryRootName="スキャン結果"
                      />
                    </Box>
                  )}
                  <Box component="div" display="flex" alignItems="center">
                    {!isRoot && (
                      <GoParentDirectoryButton
                        width={32}
                        height={32}
                        handleClick={() => {
                          handleClickMoveParentDirectory(
                            directory.parent.parent_id
                          );
                        }}
                      />
                    )}
                    <Typography
                      fontSize="20px"
                      fontWeight="bold"
                      display="flex"
                      alignItems="center"
                      mr={!isRoot ? '6px' : ''}
                    >
                      {getDirectoryPageTitle(
                        sidebarValue,
                        isRoot,
                        directory.parent.name
                      )}
                    </Typography>
                    {!isRoot && (
                      <ActionVisible
                        isCanUpdate={isCanUpdate}
                        isCanRead={isCanRead}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <Typography fontWeight="bold" fontSize="20px">
                  {getListPageTitle({
                    defaultTitle: pageTitle.realogram,
                    sidebarValue,
                    isSearched: !!condition?.name,
                  })}
                </Typography>
              )}
              <Box component="div" display="flex" gap={1}>
                <FilterButton
                  onClick={handleShowFilters}
                  textColor={filterButtonTextColor}
                  iconColor={filterButtonIconColor}
                />
              </Box>
            </Box>

            {isShowFilters && (
              <RealogramsConditions
                me={me}
                users={users?.filter((user) => user.id !== me?.id)}
                selectedOwnerId={condition?.ownerId?.toString() ?? ''}
                handleChangeSelectedOwnerId={handleChangeSelectedOwnerId}
                selectedStatus={condition?.status?.toString() ?? ''}
                clearConditions={clearFilters}
              />
            )}
            {viewMode === 'grid' && (
              <GridModeSort
                sortOptions={getSortOptions(
                  'realogram',
                  sidebarValue === 'viewed'
                )}
                isLoadingData={isFetchingData || isLoadingData}
                firstOrder={condition?.firstOrder}
                handleChangeOrder={handleChangeOrder}
              />
            )}

            {!(
              directory?.realogram_directories.length ||
              searchedData?.realogram_directories.length
            ) &&
            !isLoadingData &&
            !isFetchingData &&
            viewMode === 'grid' ? (
              <Box component="div" height="calc(100% - 90px)">
                <EmptyResult
                  title="スキャン結果がありません"
                  message={
                    hasQueries
                      ? '条件を変更してください。'
                      : '左上の「新規スキャン」ボタンから追加してください。'
                  }
                  isErrorIcon
                />
              </Box>
            ) : (
              <Box component="div" mb={2} height="100%">
                {viewMode === 'table' ? (
                  <RealogramsDirectoryTable
                    isLoading={isLoadingData}
                    isRefetching={isFetchingData}
                    offset={offset}
                    realogramDirectories={realogramDirectories}
                    isFilteredByViewed={isFilteredByViewed}
                    firstOrder={condition.firstOrder}
                    viewMode={viewMode}
                    handleRowClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleChangeOrder={handleChangeOrder}
                    handleFavoriteClick={handleFavoriteClick}
                    handleEndReached={handleEndReached}
                    handleSharePermission={handleSharePermission}
                  />
                ) : (
                  <RealogramsDirectoryGrid
                    isLoading={isLoadingData}
                    isRefetching={isFetchingData}
                    offset={offset}
                    realogramDirectories={realogramDirectories}
                    viewMode={viewMode}
                    isFilteredByViewed={isFilteredByViewed}
                    handleCardClick={handleRowClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleFavoriteClick={handleFavoriteClick}
                    handleEndReached={handleEndReached}
                    handleSharePermission={handleSharePermission}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <DeleteRealogramDialog
        open={deleteDialogOpen}
        handleClickCancelButton={() => setDeleteDialogOpen(false)}
        handleClickConfirmButton={handleDeleteRealogram}
      />
      <ScanPermissionDialog
        open={isPermissionDialogOpen}
        handleCancel={() => setIsPermissionDialogOpen(false)}
      />
    </>
  );
};
