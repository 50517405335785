import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from 'store';
import { theme } from 'theme';
import { AppContainer } from './fragments/appContainer';

export const ShelfApp = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppContainer />
    </ThemeProvider>
  </Provider>
);
