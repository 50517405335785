/* eslint-disable @typescript-eslint/naming-convention -- API定義を利用するので snake_case を許容する必要がある */
// TODO: コード量が多いため分離できるか検討
import {
  faceFrontValues,
  faceOrientationValues,
  planogramScale,
} from '@utils/const';
import { Format, format } from '@utils/date';
import { isEqual } from 'lodash';
import {
  BayPlan,
  BayPlanBucketsDetail,
  BayPlanPlan,
  BayPlanPtsDetail,
  BayPlanShelvesDetail,
} from 'types/bayPlan';
import {
  FaceFrontId,
  FaceFrontType,
  FaceOrientationId,
  Product,
  ProductTag,
  ShelfDetailView,
} from 'types/common';
import {
  Bucket,
  BucketArea,
  BucketAreaDimensions,
  BucketPlanogramPlan,
  BucketProductPosition,
  OrientationDirectionId,
  Planogram,
  PlanogramBayPart,
  PlanogramBucketsDetail,
  PlanogramHookBarPart,
  PlanogramPlan,
  PlanogramProductCompartment,
  PlanogramProductCompartmentCount,
  PlanogramProductCompartmentList,
  PlanogramPtsDetail,
  PlanogramPtsShelfBoard,
  PlanogramRow,
  PlanogramScope,
  PlanogramShelfBoardPart,
  PlanogramShelvesDetail,
  PlanogramShelvesV2Compartment,
  PlanogramShelvesV2Detail,
  Position,
  ProductOrigin,
  ProductPosition,
} from 'types/planogram';
import { RealogramShelfBoard } from 'types/realogram';
import { ProductReportProduct, ProductSize } from '../types/products';
import { getProductDisplaySize, hasProductTag } from './product';

export const isBayPlanShelvesDetail = (
  plan: BayPlan['plan']
): plan is BayPlanShelvesDetail => {
  return plan?.format === 'shelves_v1' || plan?.format === 'shelves_v2';
};

export const isBayPlanBucketsDetail = (
  plan: BayPlan['plan']
): plan is BayPlanBucketsDetail => {
  return plan?.format === 'buckets_v1';
};
export const isBayPlanPtsDetail = (
  plan: BayPlan['plan']
): plan is BayPlanPtsDetail => {
  return plan?.format === 'pts_v3';
};

// TODO: common.tsに移動
export const convertMeterToMilliMeter = (value: number) => {
  // eslint-disable-next-line no-magic-numbers -- 単位変換に必要
  return Math.round(value * 1000);
};

/**
 * meterをアプリで表現するためにpxに変換する / Convert meter to px for representation in planogram
 */
export const convertMeterToPixel = (value: number) => {
  return value * planogramScale;
};

export const getNextToLeftProductsGroupPosition = (at: ProductPosition) => {
  return {
    ...at,
    subPosition: {
      ...at.subPosition,
      indexX: at.subPosition.indexX - 1, //NOTE: nextToLeft index = -1
    },
  };
};

export const shelfElevationToStepElevation = (
  shelf?: PlanogramHookBarPart | PlanogramShelfBoardPart
) => {
  if (!shelf) {
    return;
  }
  const bottom =
    shelf.detail.exterior.padding.top != null
      ? shelf.elevation +
        (shelf.type === 'hook_bar'
          ? shelf.detail.ui_helper?.display_space_2d.elevation ?? 0
          : 0) +
        shelf.detail.exterior.padding.top -
        shelf.detail.exterior.height
      : shelf.elevation +
        (shelf.type === 'hook_bar'
          ? shelf.detail.ui_helper?.display_space_2d.elevation ?? 0
          : 0) -
        (shelf.detail.exterior.padding.bottom ?? 0) -
        shelf.height;
  return bottom + shelf.detail.handle.padding.bottom;
};

export const stepElevationToShelfElevation = (
  elevation: number,
  shelf?: PlanogramHookBarPart | PlanogramShelfBoardPart
) => {
  if (!shelf) {
    return;
  }
  const bottom = elevation - shelf.detail.handle.padding.bottom;
  return shelf.detail.exterior.padding.top != null
    ? bottom + shelf.detail.exterior.height - shelf.detail.exterior.padding.top
    : bottom + (shelf.detail.exterior.padding.bottom ?? 0) + shelf.height;
};

export type ShelfStep = {
  name: string;
  elevation: number;
  shelfHeight: number;
  stepTop: number;
  stepBottom: number;
};
/**
 * indicatorsUsedShelffSteps
 * 特定の位置を除外して残りの棚についての指標を計算する関数
 */
export const indicatorsUsedShelfSteps = (
  plan: PlanogramPlan,
  selectPositionIndex: number,
  steps: number[]
) =>
  plan.shelves
    .map((shelf, index) => {
      if (selectPositionIndex === index) return null;

      const isHookbar = shelf.type === 'hook_bar';
      const exterior = shelf.detail.exterior;
      const elevation = shelf.elevation;
      const e1 = shelfElevationToStepElevation(shelf) ?? -1;

      const shelfHeight = isHookbar
        ? exterior.height + (exterior.padding.top ?? 0)
        : exterior.height + (exterior.padding.bottom ?? 0);

      const stepTop = isHookbar
        ? stepIndex(e1 + exterior.height, steps)
        : stepIndex(e1, steps);

      const stepBottom = isHookbar
        ? stepIndex(e1, steps)
        : stepIndex(e1 - exterior.height, steps);

      return {
        name: shelf.name,
        elevation,
        shelfHeight,
        stepTop,
        stepBottom,
      };
    })
    .filter((step) => step !== null) as ShelfStep[];

const shelfNewElevationToStepElevation = (
  shelf: PlanogramHookBarPart | PlanogramShelfBoardPart,
  newElevation: number
) => {
  const exterior = shelf.detail.exterior;
  const bottom =
    exterior.padding.top != null
      ? newElevation +
        (shelf.type === 'hook_bar' ? 0 : exterior.padding.top - exterior.height)
      : newElevation - (exterior.padding.bottom ?? 0) - shelf.height;
  return bottom + shelf.detail.handle.padding.bottom;
};

/**
 * newElevationShelfStepsRange
 * shelves Step上で選択したShelfが利用されてるStep
 */
export const newElevationShelfStepsRange = (
  shelf: PlanogramHookBarPart | PlanogramShelfBoardPart,
  newElevation: number,
  steps: number[]
): ShelfStep => {
  const e1 = shelfNewElevationToStepElevation(shelf, newElevation) ?? -1;
  const isHookbar = shelf.type === 'hook_bar';
  const exterior = shelf.detail.exterior;

  const shelfHeight = isHookbar
    ? exterior.height + (exterior.padding.top ?? 0)
    : exterior.height + (exterior.padding.bottom ?? 0);

  const stepTop = isHookbar
    ? stepIndex(e1 + exterior.height, steps)
    : stepIndex(e1, steps);

  const stepBottom = isHookbar
    ? stepIndex(e1, steps)
    : stepIndex(e1 - exterior.height, steps);

  return {
    name: shelf.name,
    elevation: newElevation,
    shelfHeight,
    stepTop,
    stepBottom,
  };
};
/**
 * isWithinShelfStepsRange
 * 新しい棚のステップが使用されている
 * 棚のステップの範囲内にあるかどうかを判断する関数
 * @param newElevationStep
 * @param usedShelfSteps
 * @returns Boolean
 */
export const isWithinShelfStepsRange = (
  newElevationStep: ShelfStep,
  usedShelfSteps: ShelfStep[]
): boolean => {
  return usedShelfSteps.some(
    (usedStep) =>
      newElevationStep.stepTop >= usedStep.stepBottom &&
      newElevationStep.stepBottom <= usedStep.stepTop
  );
};

export const tidyCompartments = (
  compartments: PlanogramProductCompartment[]
): PlanogramProductCompartment[] =>
  compartments.reduce<PlanogramProductCompartment[]>((acc, curr) => {
    const prev = acc.pop();
    if (!prev) {
      return [curr];
    }
    if (prev.count && prev.product_id === curr.product_id) {
      prev.count.x += curr.count?.x ?? 0;
      prev.face_count = prev.count.x * prev.count.y;
      return [...acc, prev];
    }
    return [...acc, prev, curr];
  }, []);

export const tidyCompartmetsList = (
  compartmentListArray: PlanogramProductCompartmentList[]
): PlanogramProductCompartmentList[] =>
  compartmentListArray.map((compartmentList) => ({
    ...compartmentList,
    row: tidyCompartments(compartmentList.row),
  }));

export const createCompartment = (
  product: Product,
  count: PlanogramProductCompartmentCount = { x: 1, y: 1 }
): PlanogramProductCompartment => ({
  product_id: product.id,
  face_count: count.x * count.y * (count.z ?? 1),
  count,
  face_front: faceFrontValues[product.detail?.face_direction ?? 'front'],
  orientation: 0,
});

export const isPlanogramPlan = (
  plan: Planogram['plan']
): plan is PlanogramPlan =>
  plan?.format === 'shelves_v1' || plan?.format === 'shelves_v2';

export const isPlanogramBucketPlan = (
  plan: Planogram['plan']
): plan is BucketPlanogramPlan => plan?.format === 'buckets_v1';

export const isPlanogramShelvesDetail = (
  plan: Planogram['plan']
): plan is PlanogramShelvesDetail | PlanogramShelvesV2Detail =>
  isPlanogramShelvesV1Detail(plan) || isPlanogramShelvesV2Detail(plan);
export const isPlanogramShelvesV1Detail = (
  plan: Planogram['plan']
): plan is PlanogramShelvesDetail => plan?.format === 'shelves_v1';
export const isPlanogramShelvesV2Detail = (
  plan: Planogram['plan']
): plan is PlanogramShelvesV2Detail => plan?.format === 'shelves_v2';
export const isPlanogramPtsDetail = (
  plan: Planogram['plan']
): plan is PlanogramPtsDetail => plan?.format === 'pts_v3';
export const isPlanogramBucketDetail = (
  plan: Planogram['plan']
): plan is PlanogramBucketsDetail => plan?.format === 'buckets_v1';

export const isPlanogramPtsShelfBoard = (
  bayPart: PlanogramBayPart
): bayPart is PlanogramPtsShelfBoard => bayPart.type === 'board';
export const isPlanogramShelfBoardPart = (
  bayPart: PlanogramBayPart
): bayPart is PlanogramShelfBoardPart => bayPart.type === 'shelf_board';
export const isPlanogramHookBarPart = (
  bayPart: PlanogramBayPart
): bayPart is PlanogramHookBarPart => bayPart.type === 'hook_bar';

/**
 * elevationの等価性比較
 * 浮動小数点であるため、誤差により単純に等価演算子が使用できない。
 */
const threshold = 0.001; // 1mm
export const isSameElevation = (
  e1: number,
  e2: number,
  t: number = threshold
) => Math.abs(e1 - e2) < t;

/**
 * 3D回転のマップ
 * key: 回転前の面番号（FaceFrontType）, 回転前の回転方向（FaceOrientationID）, 回転する方向（DirectionID）
 * FaceFrontType
 *     1:正面  2:上面  3:右側面 4:左側面  5:背面  6:底面
 * FaceOrientationID
 *     0:回転なし  1:左90度  2:180度  3:右90度
 * OrientationDirectionID
 *     0:左ロール 1:右ロール 2:右ヨー 3:左ヨー 4:上ピッチ 5:下ピッチ
 *
 * value: 回転後の面（FaceFrontType）, 回転後の向き（FaceOrientationID）
 * FaceFrontType
 *     1:正面  2:上面  3:右側面 4:左側面  5:背面  6:底面
 * FaceOrientationID
 *     0:回転なし  1:左90度  2:180度  3:右90度
 */

export const rotationMap: {
  [key in `${FaceFrontId},${FaceOrientationId},${OrientationDirectionId}`]: {
    faceFrontId: FaceFrontId;
    orientation: FaceOrientationId;
  };
} = {
  '1,0,0': { faceFrontId: 1, orientation: 1 },
  '1,0,1': { faceFrontId: 1, orientation: 3 },
  '1,0,2': { faceFrontId: 3, orientation: 0 },
  '1,0,3': { faceFrontId: 4, orientation: 0 },
  '1,0,4': { faceFrontId: 6, orientation: 0 },
  '1,0,5': { faceFrontId: 2, orientation: 0 },

  '1,1,0': { faceFrontId: 1, orientation: 2 },
  '1,1,1': { faceFrontId: 1, orientation: 0 },
  '1,1,2': { faceFrontId: 6, orientation: 1 },
  '1,1,3': { faceFrontId: 2, orientation: 1 },
  '1,1,4': { faceFrontId: 4, orientation: 1 },
  '1,1,5': { faceFrontId: 3, orientation: 1 },

  '1,2,0': { faceFrontId: 1, orientation: 3 },
  '1,2,1': { faceFrontId: 1, orientation: 1 },
  '1,2,2': { faceFrontId: 4, orientation: 2 },
  '1,2,3': { faceFrontId: 3, orientation: 2 },
  '1,2,4': { faceFrontId: 2, orientation: 2 },
  '1,2,5': { faceFrontId: 6, orientation: 2 },

  '1,3,0': { faceFrontId: 1, orientation: 0 },
  '1,3,1': { faceFrontId: 1, orientation: 2 },
  '1,3,2': { faceFrontId: 2, orientation: 3 },
  '1,3,3': { faceFrontId: 6, orientation: 3 },
  '1,3,4': { faceFrontId: 3, orientation: 3 },
  '1,3,5': { faceFrontId: 4, orientation: 3 },

  '2,0,0': { faceFrontId: 2, orientation: 1 },
  '2,0,1': { faceFrontId: 2, orientation: 3 },
  '2,0,2': { faceFrontId: 3, orientation: 1 },
  '2,0,3': { faceFrontId: 4, orientation: 3 },
  '2,0,4': { faceFrontId: 1, orientation: 0 },
  '2,0,5': { faceFrontId: 5, orientation: 2 },

  '2,1,0': { faceFrontId: 2, orientation: 2 },
  '2,1,1': { faceFrontId: 2, orientation: 0 },
  '2,1,2': { faceFrontId: 1, orientation: 1 },
  '2,1,3': { faceFrontId: 5, orientation: 3 },
  '2,1,4': { faceFrontId: 4, orientation: 0 },
  '2,1,5': { faceFrontId: 3, orientation: 2 },

  '2,2,0': { faceFrontId: 2, orientation: 3 },
  '2,2,1': { faceFrontId: 2, orientation: 1 },
  '2,2,2': { faceFrontId: 4, orientation: 1 },
  '2,2,3': { faceFrontId: 3, orientation: 3 },
  '2,2,4': { faceFrontId: 5, orientation: 0 },
  '2,2,5': { faceFrontId: 1, orientation: 2 },

  '2,3,0': { faceFrontId: 2, orientation: 0 },
  '2,3,1': { faceFrontId: 2, orientation: 2 },
  '2,3,2': { faceFrontId: 5, orientation: 1 },
  '2,3,3': { faceFrontId: 1, orientation: 3 },
  '2,3,4': { faceFrontId: 3, orientation: 0 },
  '2,3,5': { faceFrontId: 4, orientation: 2 },

  '3,0,0': { faceFrontId: 3, orientation: 1 },
  '3,0,1': { faceFrontId: 3, orientation: 3 },
  '3,0,2': { faceFrontId: 5, orientation: 0 },
  '3,0,3': { faceFrontId: 1, orientation: 0 },
  '3,0,4': { faceFrontId: 6, orientation: 1 },
  '3,0,5': { faceFrontId: 2, orientation: 3 },

  '3,1,0': { faceFrontId: 3, orientation: 2 },
  '3,1,1': { faceFrontId: 3, orientation: 0 },
  '3,1,2': { faceFrontId: 6, orientation: 2 },
  '3,1,3': { faceFrontId: 2, orientation: 0 },
  '3,1,4': { faceFrontId: 1, orientation: 1 },
  '3,1,5': { faceFrontId: 5, orientation: 1 },

  '3,2,0': { faceFrontId: 3, orientation: 3 },
  '3,2,1': { faceFrontId: 3, orientation: 1 },
  '3,2,2': { faceFrontId: 1, orientation: 2 },
  '3,2,3': { faceFrontId: 5, orientation: 2 },
  '3,2,4': { faceFrontId: 2, orientation: 1 },
  '3,2,5': { faceFrontId: 6, orientation: 3 },

  '3,3,0': { faceFrontId: 3, orientation: 0 },
  '3,3,1': { faceFrontId: 3, orientation: 2 },
  '3,3,2': { faceFrontId: 2, orientation: 2 },
  '3,3,3': { faceFrontId: 6, orientation: 0 },
  '3,3,4': { faceFrontId: 5, orientation: 3 },
  '3,3,5': { faceFrontId: 1, orientation: 3 },

  '4,0,0': { faceFrontId: 4, orientation: 1 },
  '4,0,1': { faceFrontId: 4, orientation: 3 },
  '4,0,2': { faceFrontId: 1, orientation: 0 },
  '4,0,3': { faceFrontId: 5, orientation: 0 },
  '4,0,4': { faceFrontId: 6, orientation: 3 },
  '4,0,5': { faceFrontId: 2, orientation: 1 },

  '4,1,0': { faceFrontId: 4, orientation: 2 },
  '4,1,1': { faceFrontId: 4, orientation: 0 },
  '4,1,2': { faceFrontId: 6, orientation: 0 },
  '4,1,3': { faceFrontId: 2, orientation: 2 },
  '4,1,4': { faceFrontId: 5, orientation: 1 },
  '4,1,5': { faceFrontId: 1, orientation: 1 },

  '4,2,0': { faceFrontId: 4, orientation: 3 },
  '4,2,1': { faceFrontId: 4, orientation: 1 },
  '4,2,2': { faceFrontId: 5, orientation: 2 },
  '4,2,3': { faceFrontId: 1, orientation: 2 },
  '4,2,4': { faceFrontId: 2, orientation: 3 },
  '4,2,5': { faceFrontId: 6, orientation: 1 },

  '4,3,0': { faceFrontId: 4, orientation: 0 },
  '4,3,1': { faceFrontId: 4, orientation: 2 },
  '4,3,2': { faceFrontId: 2, orientation: 0 },
  '4,3,3': { faceFrontId: 6, orientation: 2 },
  '4,3,4': { faceFrontId: 1, orientation: 3 },
  '4,3,5': { faceFrontId: 5, orientation: 3 },

  '5,0,0': { faceFrontId: 5, orientation: 1 },
  '5,0,1': { faceFrontId: 5, orientation: 3 },
  '5,0,2': { faceFrontId: 4, orientation: 0 },
  '5,0,3': { faceFrontId: 3, orientation: 0 },
  '5,0,4': { faceFrontId: 6, orientation: 2 },
  '5,0,5': { faceFrontId: 2, orientation: 2 },

  '5,1,0': { faceFrontId: 5, orientation: 2 },
  '5,1,1': { faceFrontId: 5, orientation: 0 },
  '5,1,2': { faceFrontId: 6, orientation: 3 },
  '5,1,3': { faceFrontId: 2, orientation: 3 },
  '5,1,4': { faceFrontId: 3, orientation: 1 },
  '5,1,5': { faceFrontId: 4, orientation: 1 },

  '5,2,0': { faceFrontId: 5, orientation: 3 },
  '5,2,1': { faceFrontId: 5, orientation: 1 },
  '5,2,2': { faceFrontId: 3, orientation: 2 },
  '5,2,3': { faceFrontId: 4, orientation: 2 },
  '5,2,4': { faceFrontId: 2, orientation: 0 },
  '5,2,5': { faceFrontId: 6, orientation: 0 },

  '5,3,0': { faceFrontId: 5, orientation: 0 },
  '5,3,1': { faceFrontId: 5, orientation: 2 },
  '5,3,2': { faceFrontId: 2, orientation: 1 },
  '5,3,3': { faceFrontId: 6, orientation: 1 },
  '5,3,4': { faceFrontId: 4, orientation: 3 },
  '5,3,5': { faceFrontId: 3, orientation: 3 },

  '6,0,0': { faceFrontId: 6, orientation: 1 },
  '6,0,1': { faceFrontId: 6, orientation: 3 },
  '6,0,2': { faceFrontId: 3, orientation: 3 },
  '6,0,3': { faceFrontId: 4, orientation: 1 },
  '6,0,4': { faceFrontId: 5, orientation: 2 },
  '6,0,5': { faceFrontId: 1, orientation: 0 },

  '6,1,0': { faceFrontId: 6, orientation: 2 },
  '6,1,1': { faceFrontId: 6, orientation: 0 },
  '6,1,2': { faceFrontId: 5, orientation: 3 },
  '6,1,3': { faceFrontId: 1, orientation: 1 },
  '6,1,4': { faceFrontId: 4, orientation: 2 },
  '6,1,5': { faceFrontId: 3, orientation: 0 },

  '6,2,0': { faceFrontId: 6, orientation: 3 },
  '6,2,1': { faceFrontId: 6, orientation: 1 },
  '6,2,2': { faceFrontId: 4, orientation: 3 },
  '6,2,3': { faceFrontId: 3, orientation: 1 },
  '6,2,4': { faceFrontId: 1, orientation: 2 },
  '6,2,5': { faceFrontId: 5, orientation: 0 },

  '6,3,0': { faceFrontId: 6, orientation: 0 },
  '6,3,1': { faceFrontId: 6, orientation: 2 },
  '6,3,2': { faceFrontId: 1, orientation: 3 },
  '6,3,3': { faceFrontId: 5, orientation: 1 },
  '6,3,4': { faceFrontId: 3, orientation: 2 },
  '6,3,5': { faceFrontId: 4, orientation: 0 },
};

export const createPlanogram = (
  bayPlan: BayPlan,
  planogramName: string,
  bayPlanCodeId?: number
): Planogram | undefined => {
  const plan = bayPlan.plan;
  if (
    !plan ||
    (!isBayPlanShelvesDetail(plan) && !isBayPlanBucketsDetail(plan))
  ) {
    return;
  }
  const planogramPlan = createPlanogramPlan(plan);
  return {
    id: 0,
    name: planogramName,
    owner: {
      id: 0,
      fullname: '',
      role: 'admin',
      email: '',
      created_at: '',
      updated_at: '',
    },
    bay_plan_id: bayPlan.id,
    bay_plan_code_id: bayPlanCodeId,
    created_at: '',
    plan: planogramPlan,
    organization_status_id: 0,
    updated_at: '',
    status: 'initialized',
  };
};

export const createPlanogramPlan = (
  plan: BayPlanPlan | BayPlanBucketsDetail
): PlanogramPlan | PlanogramBucketsDetail => {
  switch (plan.format) {
    case 'shelves_v1':
      return {
        bay_size: plan.bay_size?.actual,
        products_layout: plan.shelves.map(() => ({
          row: [],
        })),
        format: plan.format,
        shelves_frame: plan.shelves_frame,
        shelves: plan.shelves,
      };
    case 'shelves_v2':
      return {
        products_layout: plan.shelves.map(() => ({
          justify_content: 'start',
          row: [],
        })),
        format: plan.format,
        shelves_frame: plan.shelves_frame,
        shelves: plan.shelves,
      };
    case 'buckets_v1':
      return {
        format: plan.format,
        frame: plan.frame as PlanogramBucketsDetail['frame'],
      };
  }
};

export const getProductOrigin = (bayPart: PlanogramBayPart) => {
  if (!('detail' in bayPart)) {
    //pts(board) don't have detail
    return 'bottom';
  }
  if (!('product_origin' in bayPart.detail.exterior)) {
    return 'bottom';
  }
  return bayPart.detail.exterior.product_origin as ProductOrigin;
};

/**
 * フェイスフロントによって画像の幅と高さを変更する
 */
export const changeSidesByFaceFront = (
  productSize: ProductSize | undefined,
  faceFront: FaceFrontId
): ProductSize => {
  if (!productSize)
    return {
      width: 0,
      height: 0,
      depth: 0,
    };

  if (faceFront === faceFrontValues.front || faceFront === faceFrontValues.back)
    return productSize;

  if (faceFront === faceFrontValues.top || faceFront === faceFrontValues.bottom)
    return {
      width: productSize.width,
      height: productSize.depth,
      depth: productSize.height,
    };

  return {
    width: productSize.depth,
    height: productSize.height,
    depth: productSize.width,
  };
};

/**
 * 商品の回転方向によって画像の幅と高さを変更する
 */
export const changeSidesByOrientation = (
  productSize: ProductSize | undefined,
  rotated: boolean
): ProductSize => {
  if (!productSize)
    return {
      width: 0,
      height: 0,
      depth: 0,
    };

  if (rotated)
    return {
      height: productSize.width,
      width: productSize.height,
      depth: productSize.depth,
    };

  return productSize;
};

export const determineNinetyOrTwoSeventyDegrees = (
  orientation: FaceOrientationId
) =>
  orientation === faceOrientationValues.leftNinetyDegree.id ||
  orientation === faceOrientationValues.rightNinetyDegree.id;

export const changeCountByOrientation = (
  rotated: boolean,
  count?: PlanogramProductCompartmentCount
) => {
  if (!count) {
    return { countX: 0, countY: 0 };
  }
  if (rotated) {
    return { countX: count.y, countY: count.x };
  }
  return { countX: count.x, countY: count.y };
};

export const getCompartmentContainerSize = (
  countX: number,
  countY: number,
  width: number,
  height: number,
  isNinetyOrTwoSeventydDegrees: boolean
) => {
  /*
    If bottles have rotation 0,180,360 than container and group have same dimensions.
    if bottles have rotation 90, 270 than container and group have vice virse dimensions.
    it need because place of bottles not rotated.
    Here in name i use odd and even, it means odd == [90, 270] degree, even == [0, 180]
  */
  if (isNinetyOrTwoSeventydDegrees) {
    return {
      containerWidth: countY * width,
      containerHeight: countX * height,
    };
  }
  return {
    containerWidth: countX * width,
    containerHeight: countY * height,
  };
};

type Size = {
  width: number;
  height: number;
};
type DisplayShelfSize = Size;
type CompartmentSizes = Size[];
type JudgeOverflowFn = (
  displayShelfSize: DisplayShelfSize,
  compartmentSizes: CompartmentSizes
) => boolean[];

export const judgeOverflow: JudgeOverflowFn = (
  displayShelfSize,
  compartmentSizes
) => {
  return compartmentSizes
    .map((compartmentSize, i, sizes) => ({
      top: compartmentSize.height,
      right: sizes.slice(0, i + 1).reduce((acc, cur) => acc + cur.width, 0),
    }))
    .map(
      ({ top, right }) =>
        right > displayShelfSize.width || top > displayShelfSize.height
    );
};

/**
 * objectのvalueからkeyを取得する
 */
export const getKeyByValue = (
  object: Record<string, unknown>,
  value: unknown
) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getFaceFrontById = (faceFrontId: FaceFrontId) => {
  return getKeyByValue(faceFrontValues, faceFrontId) as FaceFrontType;
};

/**
 * width,height → compartment sizes considering count, rotation, and face front.
 * compartmentProductSize → size of the product considering rotation and face front.
 */
export const getCompartmentSize = (
  productCompartment?: PlanogramProductCompartment,
  product?: Product
) => {
  if (!product) {
    return { width: 0, height: 0, productCompartment: { width: 0, height: 0 } };
  }

  if (!productCompartment) {
    return {
      width:
        product.shape?.size.display_size?.width ??
        product.shape?.size.actual.width ??
        0,
      height:
        product.shape?.size.display_size?.height ??
        product.shape?.size.actual.height ??
        0,
      productCompartment: { width: 0, height: 0 },
    };
  }

  const isNinetyOrTwoSeventydDegrees = determineNinetyOrTwoSeventyDegrees(
    productCompartment.orientation
  );

  const productSizeByFaceFront = changeSidesByFaceFront(
    product.shape?.size.display_size ?? product.shape?.size.actual,
    productCompartment.face_front
  );

  const {
    width: productWidthByOrientation,
    height: productHeightByOrientation,
  } = changeSidesByOrientation(
    productSizeByFaceFront,
    isNinetyOrTwoSeventydDegrees
  );

  const { countX, countY } = changeCountByOrientation(
    isNinetyOrTwoSeventydDegrees,
    productCompartment.count
  );

  const { containerWidth: width, containerHeight: height } =
    getCompartmentContainerSize(
      countX,
      countY,
      productWidthByOrientation,
      productHeightByOrientation,
      isNinetyOrTwoSeventydDegrees
    );

  return {
    width,
    height,
    productCompartment: {
      width: productWidthByOrientation,
      height: productHeightByOrientation,
    },
  };
};

export const calcFlatPlanStatistics = (
  plan: PlanogramPlan | Planogram['plan'] | undefined,
  view: ShelfDetailView,
  productTag: ProductTag,
  products?: Product[]
) => {
  if (!isPlanogramBucketPlan(plan)) return;
  const buckets = plan.frame.detail?.buckets ?? [];
  const totalUniques = Array.from(
    new Set(
      buckets
        .map((bucket) => getBucketsProductIds(bucket.detail.area))
        .flatMap((productId) => productId)
    )
  );
  const {
    totalFaces,
    totalMainFaces,
    totalNewFacesFirstWeek,
    totalNewFacesSecondWeek,
    totalRecomendedToCancelationFaces,
    totalNot3DScannedFaces,
  } = buckets.reduce(
    (acc, bucket) => ({
      totalFaces:
        acc.totalFaces +
        calcBucketsProductsTotalFaces(
          bucket.detail.area,
          view,
          productTag,
          products
        ),
      totalMainFaces:
        acc.totalMainFaces +
        calcBucketsProductsTagFaces(bucket.detail.area, '基本商品', products),
      totalRecomendedToCancelationFaces:
        acc.totalRecomendedToCancelationFaces +
        calcBucketsProductsTagFaces(bucket.detail.area, '推奨取消', products),
      totalNewFacesFirstWeek:
        acc.totalNewFacesFirstWeek +
        calcBucketsProductsTagFaces(
          bucket.detail.area,
          '新商品(1週目)',
          products
        ),
      totalNewFacesSecondWeek:
        acc.totalNewFacesSecondWeek +
        calcBucketsProductsTagFaces(
          bucket.detail.area,
          '新商品(2週目)',
          products
        ),
      totalNot3DScannedFaces:
        acc.totalNot3DScannedFaces +
        calcBucketsProductsTagFaces(
          bucket.detail.area,
          '未3Dスキャン',
          products
        ),
    }),
    {
      totalFaces: 0,
      totalNewFacesFirstWeek: 0,
      totalNewFacesSecondWeek: 0,
      totalRecomendedToCancelationFaces: 0,
      totalMainFaces: 0,
      totalNot3DScannedFaces: 0,
    }
  );
  return {
    totalShelves: String(plan.frame.detail.buckets?.length ?? 0),
    totalFaces: String(totalFaces),
    totalUniques: String(totalUniques.length),
    productFlag: {
      totalMainFaces: String(totalMainFaces),
      totalNewFacesFirstWeek: String(totalNewFacesFirstWeek),
      totalNewFacesSecondWeek: String(totalNewFacesSecondWeek),
      totalRecomendedToCancelationFaces: String(
        totalRecomendedToCancelationFaces
      ),
      totalNot3DScannedFaces: String(totalNot3DScannedFaces),
    },
  };
};

export const calcPlanStatistics = (
  plan: PlanogramPlan | Planogram['plan'] | undefined,
  view: ShelfDetailView,
  productTag: ProductTag,
  products?: Product[]
) => {
  if (!plan || !('products_layout' in plan)) return;
  const totalUniques = extractPlanogramProductIds(plan);
  const productsLayout: PlanogramProductCompartmentList[] =
    plan.products_layout;
  const {
    totalFaces,
    totalMainFaces,
    totalNewFacesFirstWeek,
    totalNewFacesSecondWeek,
    totalRecomendedToCancelationFaces,
    totalNot3DScannedFaces,
    totalOutOfStocks,
    totalUnknowns,
    totalFaceUp,
    totalNotFaceUp,
  } = productsLayout.reduce(
    (acc, { row }) => ({
      totalFaces: row
        .flatMap(({ face_count, product_id }) => ({ face_count, product_id }))
        .reduce((accFaceCounts, cur) => {
          if (view === 'productFlag') {
            const product = products?.find((p) => p.id === cur.product_id);
            return hasProductTag(productTag, product?.detail?.tags)
              ? accFaceCounts + (cur?.face_count ?? 0)
              : accFaceCounts;
          }
          return accFaceCounts + (cur?.face_count ?? 0);
        }, acc.totalFaces),
      totalMainFaces: calcProductTagFaces(
        acc.totalMainFaces,
        row,
        '基本商品',
        products
      ),
      totalRecomendedToCancelationFaces: calcProductTagFaces(
        acc.totalRecomendedToCancelationFaces,
        row,
        '推奨取消',
        products
      ),
      totalNewFacesFirstWeek: calcProductTagFaces(
        acc.totalNewFacesFirstWeek,
        row,
        '新商品(1週目)',
        products
      ),
      totalNewFacesSecondWeek: calcProductTagFaces(
        acc.totalNewFacesSecondWeek,
        row,
        '新商品(2週目)',
        products
      ),
      totalNot3DScannedFaces: calcProductTagFaces(
        acc.totalNot3DScannedFaces,
        row,
        '未3Dスキャン',
        products
      ),
      // add calculation logic after api is ready
      totalOutOfStocks: 0,
      totalUnknowns: 0,
      totalFaceUp: 0,
      totalNotFaceUp: 0,
    }),
    {
      totalFaces: 0,
      totalNewFacesFirstWeek: 0,
      totalNewFacesSecondWeek: 0,
      totalRecomendedToCancelationFaces: 0,
      totalMainFaces: 0,
      totalNot3DScannedFaces: 0,
      totalOutOfStocks: 0,
      totalUnknowns: 0,
      totalFaceUp: 0,
      totalNotFaceUp: 0,
    }
  );
  return {
    totalShelves: String(plan.shelves.length),
    totalFaces: String(totalFaces),
    totalUniques: String(totalUniques.length),
    productFlag: {
      totalMainFaces: String(totalMainFaces),
      totalNewFacesFirstWeek: String(totalNewFacesFirstWeek),
      totalNewFacesSecondWeek: String(totalNewFacesSecondWeek),
      totalRecomendedToCancelationFaces: String(
        totalRecomendedToCancelationFaces
      ),
      totalNot3DScannedFaces: String(totalNot3DScannedFaces),
    },
    totalOutOfStocks: String(totalOutOfStocks),
    totalUnknowns: String(totalUnknowns),
    totalFaceUp: String(totalFaceUp),
    totalNotFaceUp: String(totalNotFaceUp),
  };
};

const calcProductTagFaces = (
  initValue: number,
  row: PlanogramRow,
  productTag: ProductTag,
  products?: Product[]
) => {
  return row
    .flatMap(({ face_count, product_id }) => ({ face_count, product_id }))
    .reduce((accFaceCounts, cur) => {
      const product = products?.find((p) => p.id === cur.product_id);
      return hasProductTag(productTag, product?.detail?.tags)
        ? accFaceCounts + (cur?.face_count ?? 0)
        : accFaceCounts;
    }, initValue);
};

const calcBucketsProductsTagFaces = (
  area: BucketArea,
  productTag: ProductTag,
  products?: Product[]
) => {
  let faceCounts = 0;
  if (area.count) {
    const product = products?.find((p) => p.id === area.product_id);
    return hasProductTag(productTag, product?.detail?.tags)
      ? (faceCounts += area.count.x * area.count.y)
      : faceCounts;
  }

  if (area.children) {
    for (const child of area.children) {
      faceCounts += calcBucketsProductsTagFaces(child, productTag, products);
    }
  }

  return faceCounts;
};
export const convertProductsLayoutIntoShelfBoards = (
  productsLayout: PlanogramPlan['products_layout']
): RealogramShelfBoard[] => {
  return productsLayout.map(({ row }) => {
    return {
      compartments: row.map(({ product_id, face_count }) => ({
        faces: [...Array<number>(face_count ?? 0)].map(() => ({
          in_stock: true,
          primary_candidate: { product_id },
        })),
      })),
    };
  }) as RealogramShelfBoard[];
};

export const isSelectedCompartment = (
  target: ProductPosition,
  selected?: ProductPosition
) => isEqual(target, selected);

export const isClickableCompartment = (
  view: ShelfDetailView,
  product: Product,
  productTag: ProductTag
) => {
  switch (view) {
    case 'productFlag':
      return hasProductTag(productTag, product.detail?.tags);
    // TODO: remove after analytics api is ready
    case 'profit':
      return false;
    default:
      return true;
  }
};
export const getNextPlanogramItem = (
  productsLayout: PlanogramPlan['products_layout'],
  position: ProductPosition,
  index = -1
): ProductPosition => {
  if (productsLayout.at(position.indexY + index)?.row.length) {
    return {
      indexX: 0,
      indexY: position.indexY + index,
      subPosition: { indexX: 0, indexY: 0 },
    };
  }
  return getNextPlanogramItem(productsLayout, position, index - 1);
};

export const getPrevPlanogramItem = (
  productsLayout: PlanogramPlan['products_layout'],
  position: ProductPosition,
  index = 1
): ProductPosition => {
  if (productsLayout.at(position.indexY + index)?.row.length) {
    const prevLastShelfBoard = productsLayout[position.indexY + index];
    const prevLastCompartmentIndex = prevLastShelfBoard.row.length - 1;
    return {
      indexX: 0,
      indexY: position.indexY + index,
      subPosition: { indexX: prevLastCompartmentIndex, indexY: 0 },
    };
  }
  return getPrevPlanogramItem(productsLayout, position, index + 1);
};

export const isEditor = (pathname: string) => pathname.includes('edit');
export const isPlans = (pathname: string) => pathname.includes('plans');

/**
 * @deprecated
 */
export const toLocalDatetime = (d: string) => format(d, Format.datetime);

export const extractPlanogramProductIds = (
  plan: NonNullable<Planogram['plan']>
): number[] => {
  let productIds: number[] = [];
  if (isPlanogramBucketPlan(plan)) {
    productIds = (plan.frame.detail.buckets ?? []).flatMap((bucket) =>
      getBucketsProductIds(bucket.detail.area)
    );
  }
  if (isPlanogramShelvesDetail(plan)) {
    productIds = getProductsLayout(plan).flatMap(({ row }) =>
      row.map(({ product_id }) => product_id)
    );
  }

  return Array.from(new Set(productIds));
};

const turnUnit = -90;
export const orientationToDegree = (orientation: FaceOrientationId): number => {
  return turnUnit * orientation;
};

export const isPlanogramShelvesV2Compartment = (
  compartment?: PlanogramPlan['products_layout'][number]
): compartment is PlanogramShelvesV2Compartment => {
  if (!compartment) return false;
  return 'justify_content' in compartment;
};

export const isPlanogramProductCompartment = (
  compartment?: PlanogramPlan['products_layout'][number]
): compartment is PlanogramProductCompartmentList => {
  if (!compartment) return false;
  return !('justify_content' in compartment);
};

export const calculateJustifyMargin = (
  shelfWidth: number,
  compartments: PlanogramProductCompartment[],
  products: Product[]
) => {
  const { width, total } = compartments.reduce(
    (acc, cur) => {
      const p = products.find((p) => p.id === cur.product_id);
      if (!p) return acc;

      const { width: w } = getProductDisplaySize(
        p,
        cur.face_front,
        cur.orientation
      );

      return {
        width: acc.width + w * (cur.count?.x ?? 0),
        total: acc.total + (cur.count?.x ?? 0),
      };
    },
    {
      width: 0,
      total: 0,
    }
  );
  return (shelfWidth - width) / (total + 1);
};

export const setBucketSubPosition = (
  path: BucketProductPosition,
  newPosition: Position
) => {
  if (path.subPosition) {
    // すでに subPosition が設定されている場合、再帰的に setBucketSubPosition を呼び出す
    setBucketSubPosition(path.subPosition, newPosition);
  } else {
    // subPosition が未設定の場合、新しい Position を作成
    path.subPosition = newPosition;
  }
  return path;
};

export const getProductsLayout = (plan: Planogram['plan']) =>
  plan && 'products_layout' in plan ? plan.products_layout : [];

export const getBucketsProductIds = (bucketArea: BucketArea): number[] => {
  const productIds: number[] = [];

  if (bucketArea.product_id) {
    productIds.push(bucketArea.product_id);
  }

  if (bucketArea.children) {
    for (const child of bucketArea.children) {
      productIds.push(...getBucketsProductIds(child));
    }
  }

  return productIds;
};

export const convertMeterToCantimeter = (value: number) => {
  // eslint-disable-next-line no-magic-numbers -- Required for unit conversion
  return Math.floor(value * 100);
};

export const getAreaDimensions = (
  height: number,
  width: number,
  bucketArea?: BucketArea,
  bucketProductPosition?: Position[],
  index = 0
): BucketAreaDimensions => {
  if (bucketArea?.children?.length && bucketProductPosition?.length) {
    const indexArray = bucketProductPosition.map((el) =>
      el.indexX === 0 && el.indexY === 0 ? 0 : 1
    );

    const area = bucketArea.children.at(indexArray[index]);
    const half = 2;
    if (area) {
      if (bucketArea.split_axis === 'compartment_y') {
        return getAreaDimensions(
          height / half,
          width,
          area,
          bucketProductPosition,
          index + 1
        );
      } else if (bucketArea.split_axis === 'compartment_x') {
        return getAreaDimensions(
          height,
          width / half,
          area,
          bucketProductPosition,
          index + 1
        );
      } else {
        return getAreaDimensions(
          height,
          width,
          area,
          bucketProductPosition,
          index + 1
        );
      }
    }
  }

  return {
    height: height,
    width: width,
  };
};

/**
 * Search closest elevation index of standard elevation steps
 * @param e1 - elevation number
 * @param steps - array of standart elevations number[]
 * @returns - index of array number
 */
export const stepIndex = (e1: number, steps: number[]) => {
  if (e1 <= 0) return steps.length;
  const stepsClosestIndex = steps.reduce(
    (closestIndex: number, current: number, index: number, array: number[]) => {
      return Math.abs(current - e1) <= Math.abs(array[closestIndex] - e1)
        ? index
        : closestIndex;
    },
    0
  );
  return stepsClosestIndex;
};

export const calcBucketsProductsTotalFaces = (
  bucketArea: BucketArea,
  view: ShelfDetailView,
  productTag: ProductTag,
  products?: Product[]
): number => {
  let totalFace = 0;
  if (bucketArea.count) {
    if (view === 'productFlag') {
      const product = products?.find((p) => p.id === bucketArea?.product_id);
      if (hasProductTag(productTag, product?.detail?.tags)) {
        totalFace += bucketArea.count.x * bucketArea.count.y;
      } else {
        totalFace;
      }
    } else {
      totalFace += bucketArea.count.x * bucketArea.count.y;
    }
  }

  if (bucketArea.children) {
    for (const child of bucketArea.children) {
      totalFace += calcBucketsProductsTotalFaces(
        child,
        view,
        productTag,
        products
      );
    }
  }
  return totalFace;
};

export const hasDifferentSplitAxisDirection = (
  area: BucketArea,
  direction: 'compartment_x' | 'compartment_y'
): boolean => {
  let res = false;

  if (
    area.children?.some(
      (child) => child.split_axis && child.split_axis !== direction
    )
  ) {
    return true;
  }
  if (area?.children) {
    for (const child of area.children) {
      res = hasDifferentSplitAxisDirection(child, direction) ?? false;
    }
  }
  return res;
};

export const getCompartmentItems = (area: BucketArea): BucketArea[] => {
  let compartmentItems: BucketArea[] = [];

  if (area.type === 'compartment') {
    compartmentItems.push(area);
  }

  if (area.children) {
    for (const child of area.children) {
      compartmentItems = compartmentItems.concat(getCompartmentItems(child));
    }
  }

  return compartmentItems;
};
export const minAreaSize = 0.03;

export const getTopBottomBuckets = (
  buckets: Bucket[],
  bucketHalfContenZ: number
) => {
  const top = buckets.filter(
    (bucket) => bucket.detail.content_min.z < bucketHalfContenZ
  );
  const bottom = buckets.filter(
    (bucket) => bucket.detail.content_min.z >= bucketHalfContenZ
  );
  return {
    top,
    bottom,
  };
};

export const bucketPosition = (bucket: Bucket, bucketHalfContenZ: number) => {
  if (bucket.detail.content_min.z < bucketHalfContenZ) {
    return 'top';
  } else {
    return 'bottom';
  }
};

export const closeToShelfStepElement = (
  e: number | undefined,
  shelfSteps: {
    elevation: number;
  }[]
) => {
  if (!e) return -1;
  const param: { min: number; elevation: number | undefined } = {
    min: Number.MAX_VALUE,
    elevation: undefined,
  };
  shelfSteps.forEach(({ elevation }) => {
    const delta = Math.abs(elevation - e);
    if (param.min > delta) {
      param.min = delta;
      param.elevation = elevation;
    }
  });
  return param?.elevation ?? -1;
};

export const getBayPartControllerDisableState = (
  plan: PlanogramPlan,
  shelf?: PlanogramHookBarPart | PlanogramShelfBoardPart
) => {
  if (!shelf) return { isUpDisabled: false, isDownDisabled: false };
  const shelfSteps =
    'shelves_frame' in plan ? plan.shelves_frame.detail.shelf_steps : [];
  const steps = shelfSteps.map((el) => el.elevation);
  const shelfStepsIndexes = plan.shelves.map((item) => {
    const stepElevation = shelfElevationToStepElevation(item) ?? -1;
    const baseStepIndex = stepIndex(stepElevation, steps);
    return item.type === 'hook_bar'
      ? {
          indexTop: stepIndex(
            steps[baseStepIndex] + item.detail.exterior.height,
            steps
          ),
          indexBottom: baseStepIndex,
        }
      : {
          indexTop: baseStepIndex,
          indexBottom: stepIndex(
            steps[baseStepIndex] - item.detail.exterior.height,
            steps
          ),
        };
  });

  const shelfIndex = plan.shelves.indexOf(shelf);
  const topStepIndex = shelfStepsIndexes.at(shelfIndex)?.indexTop ?? 0;
  const bottomStepIndex = shelfStepsIndexes.at(shelfIndex)?.indexBottom ?? 0;
  const shelfHeight = shelf.detail.exterior.height ?? 0;
  // NOTE: this checks for bayParts that are in the middle
  const isBottomDisabled =
    shelfStepsIndexes[shelfIndex - 1]?.indexTop === bottomStepIndex - 1;
  const isTopDisabled =
    shelfStepsIndexes[shelfIndex + 1]?.indexBottom === topStepIndex + 1;
  const index = shelf.type === 'hook_bar' ? bottomStepIndex : topStepIndex;
  // NOTE: this checks for upper most top and bottom bayParts
  const isUpDisabled = steps[index] >= steps[steps.length - 1] || isTopDisabled;
  const isDownDisabled =
    topStepIndex === 0 ||
    steps[topStepIndex - 1] < shelfHeight ||
    isBottomDisabled;
  return { isUpDisabled, isDownDisabled };
};

export const getScope = (parentScope: PlanogramScope, isRoot?: boolean) => {
  if (isRoot || parentScope === 'private')
    return ['public', 'restricted', 'private'];
  else if (parentScope === 'restricted') return ['public', 'restricted'];
  else if (parentScope === 'public') return ['public'];
  return [];
};

const double = 2;
// 初期表示位置を真ん中より少しズらす
const misalignment = 0.5;

export const getFlatPlanogramAreaWidthAndCenter = (
  detail: BucketPlanogramPlan['frame']['detail'],
  factor: number = double
) => {
  const buckets = detail.buckets || [];

  // バケットからの左側のはみ出し計算
  const overLeftList =
    buckets.map(
      (bucket) =>
        bucket.detail.content_min.x -
        bucket.detail.padding.left +
        detail.padding.left
    ) ?? [];
  // バケットからの右側のはみ出し計算
  const overRightList =
    buckets.map(
      (bucket) =>
        bucket.detail.content_max.x +
        bucket.detail.padding.right -
        (detail.content_max.x + detail.padding.right)
    ) ?? [];

  // はみ出した分はプラスの値として計算されるので、最大値を取得する
  const left = -Math.min(...overLeftList);
  const right = Math.max(...overRightList);
  // はみ出しがない場合は0とする
  const overLeft = left > 0 ? left : 0;
  const overRight = right > 0 ? right : 0;

  // ゴンドラのサイズ取得
  const innerWidth = detail.content_max.x - detail.content_min.x;
  const sidePadding = detail.padding.left + detail.padding.right;
  const planogramWidth = innerWidth + sidePadding;

  // 表示可能領域
  const viewWidth = planogramWidth * factor + overLeft + overRight;

  // 描画時のデフォルト位置
  const displayPosition = planogramWidth / factor + overLeft - misalignment;

  return {
    areaWidth: convertMeterToPixel(viewWidth),
    displayPosition: convertMeterToPixel(displayPosition),
    right: convertMeterToPixel(overRight),
    left: convertMeterToPixel(overLeft),
  };
};

export const canMoveOrCreateFolder = (
  from: PlanogramScope,
  to?: PlanogramScope
) => {
  if (!to) return false;
  switch (to) {
    case 'public':
      return from === 'public';
    case 'restricted':
      return from === 'public' || from === 'restricted';
    case 'private':
      return true;
    default:
      return false;
  }
};
export const findProductInSales = (
  compartmentId: number,
  productSales?: ProductReportProduct[]
) => {
  return productSales?.find((product) => product.product_id === compartmentId);
};
