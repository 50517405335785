import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { updateIsShowBayPartDetail } from '@reducers/planogramEditor/reducer';
import { useGetBayPartQuery } from '@reducers/shelfAppsApi/injections/bayPartCategoriesApi';
import { useAppDispatch } from '@store/index';
import { CommonColors } from '@utils/colors';
import { convertMeterToMilliMeter } from '@utils/planogram';
import { FC } from 'react';

type Props = {
  selectedBayPartId: number;
  isCompareMode: boolean;
};

////TODO: Implement without the need for conditional branching
export const BayPartDetail: FC<Props> = ({
  selectedBayPartId,
  isCompareMode,
}) => {
  const dispatch = useAppDispatch();
  const { bayPart } = useGetBayPartQuery(
    { bay_part_id: selectedBayPartId },
    {
      selectFromResult: ({ data }) => ({ bayPart: data?.bay_part }),
    }
  );

  const width = () => {
    if (bayPart && bayPart.type === 'shelf_board') {
      return convertMeterToMilliMeter(bayPart.width ?? 0);
    } else if (bayPart && bayPart.type === 'buckets_bucket') {
      return convertMeterToMilliMeter(
        bayPart.detail.content_max.x - bayPart.detail.content_min.x
      );
    }
    return 0;
  };

  const height = () => {
    if (bayPart && bayPart.type === 'shelf_board') {
      return convertMeterToMilliMeter(bayPart.height ?? 0);
    } else if (bayPart && bayPart.type === 'buckets_bucket') {
      return convertMeterToMilliMeter(
        bayPart.detail.content_max.y - bayPart.detail.content_min.y
      );
    }
    return 0;
  };

  const depth = () => {
    if (bayPart && bayPart.type === 'shelf_board') {
      return convertMeterToMilliMeter(bayPart.depth ?? 0);
    } else if (bayPart && bayPart.type === 'buckets_bucket') {
      return convertMeterToMilliMeter(
        bayPart.detail.content_max.z - bayPart.detail.content_min.z
      );
    }
    return 0;
  };

  return (
    <Box component="div" paddingTop={isCompareMode ? '8px' : 0}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIos sx={{ color: CommonColors.appBlue }} />}
        onClick={() => {
          dispatch(updateIsShowBayPartDetail(false));
        }}
        sx={{
          mb: 3,
          color: CommonColors.appBlue,
          borderColor: CommonColors.appBlue,
        }}
      >
        戻る
      </Button>
      <Typography fontSize={20} fontWeight={500} letterSpacing="0.15px" mb={1}>
        {bayPart?.name}
      </Typography>
      <Typography mb={1} fontSize={16}>
        {`寸法 : W${width()} × H${height()} × D${depth()}`}
      </Typography>
    </Box>
  );
};
