import {
  ProductCandidate,
  RealogramCandidatesList,
  RealogramImageAndBbox,
} from '@components/organisms';
import { headerHeight } from '@components/organisms/header/header';
import { RealogramCandidateError } from '@components/organisms/realogramCandidateError/realogramCandidateError';
import { RealogramCandidateOverview } from '@components/organisms/realogramCandidateOverview/realogramCanditateOverview';
import { RealogramCandidateProcessing } from '@components/organisms/realogramCandidateProcessing/realogramCandidateProcessing';
import { ShelfDetailTabs } from '@components/organisms/shelfDetailTabs/shelfDetailTabs';
import { Box, Divider } from '@mui/material';
import { Refs } from '@utils/realogram';
import { FC, MutableRefObject } from 'react';
import { theme } from 'theme';
import {
  Product,
  ProductTag,
  ProfitTab,
  Rate,
  RateValue,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import {
  AnalyticsData,
  PrimaryCandidates,
  ProductCandidateWithName,
  RealogramCandidate,
  RealogramCandidateFace,
  RealogramSelectedItem,
  RealogramShelfBoard,
  UnknownProduct,
} from 'types/realogram';
import { ProductSalesReport } from 'types/products';
import { UnknownProductsReviseGuide } from './unknownProductsReviseMode/unknownProductsReviseGuide';
import { UnknownProductList } from './unknownProductsReviseMode/unknownProductList';
import { Statistic, ProductTagValue } from 'types/statistics';

type Props = {
  status?: 'error' | 'initialized' | 'processing' | 'processed';
  isProcessed: boolean;
  isBucketType: boolean;
  handleClickOutSideRealogramBbox: () => void;
  realogramCandidate?: RealogramCandidate;
  realogramDetailMode: ShelfDetailMode;
  realogramDetailView: ShelfDetailView;
  imageIsLoading: boolean;
  shelfBoards?: RealogramShelfBoard[];
  handleClickBbox: (item: RealogramSelectedItem) => void;
  createdAt: string;
  onClickNext: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickPrev: (e: React.MouseEvent<HTMLButtonElement>) => void;
  rateValue: Rate;
  profitTab: ProfitTab;
  analyticsData?: AnalyticsData;
  isUnknownProductsReviseMode: boolean;
  unknownProductsInfo: UnknownProduct[];
  setImageIsLoading: (imageIsLoading: boolean) => void;
  handleResetReviseMode: () => void;
  isEnableReviseButton: boolean;
  handleOnBulkRevise: () => void;
  isLarger: boolean;
  realogramImageElement: HTMLImageElement | null;
  isCheckedRecommendedRevise: boolean;
  handleCheckboxChange: (id: string) => void;
  handleToggleRecommendedRevise: () => void;
  hasRecommendProducts: boolean;
  productTag: ProductTag;
  handleChangeProductTag: (productTag: ProductTag) => void;
  handleChangeRateValue: (value: Rate) => void;
  selectedItem?: RealogramSelectedItem;
  rateValues: RateValue[];
  productTagValues: ProductTagValue[];
  score?: number;
  handleChangeProfitTabValueValue: (profitTab: ProfitTab) => void;
  handleUnknownProductsReviseMode: () => void;
  isCanNotEditProductAndCompartment?: boolean;
  primaryCandidates?: PrimaryCandidates;
  referenceImage: string;
  selectNext: () => void;
  selectPrevious: () => void;
  handleProductCandidatesDrawerOpen: () => void;
  setSelectedProductChoice: (selectedProductChoice: boolean) => void;
  shouldButtonBeDisabled: (value: 'next' | 'prev') => boolean;
  handleOpenReferenceImagePreview: () => void;
  setSelectedProductZoomIn: (product?: Product) => void;
  realogramCandidateId: number;
  isTenantSalesAnalytics?: boolean;
  handleChangeCandidate: () => void;
  listItemRefs: MutableRefObject<Refs>;
  shelfBoardsToDisplay?: RealogramShelfBoard[];
  handleClickFace: (item: RealogramSelectedItem) => void;
  handleClickDeleteShelfBoard: (shelfBoardId: string) => void;
  handleClickDeleteFace: (item: RealogramSelectedItem) => void;
  handleCorrectDrawerOpen: (item: RealogramSelectedItem) => void;
  handleDivisionModalOpen: (face: RealogramCandidateFace) => void;
  handleSort: () => void;
  isAscending: boolean;
  isCanEditFace?: boolean;
  isCanDeleteFace?: boolean;
  isCanDeleteBaypart?: boolean;
  setDeleteDialogOpen: (isDeleteDialogOpen: boolean) => void;
  setIsErrorComponent: (isErrorComponent: boolean) => void;
  handleClickReUpLoadScan: () => void;
  handleAddFaceMode: (shelfBoardIndex: number) => void;
  analyticsDataTerm: string;
  displayAnalyticsData?: ProductSalesReport;
  disableChangeToPreviousWeek: boolean;
  disableChangeToNextWeek: boolean;
  displayAnalyticsDataIndex: number;
  handleNextWeekTerm: () => void;
  handlePreviousWeekTerm: () => void;
  realogramStatistics?: Statistic;
  isLoadingAnalyticsData: boolean;
  setSelectedCandidate: (
    candidate: ProductCandidateWithName | undefined
  ) => void;
  isRealogramLoading?: boolean;
};

export const RealogramDetail: FC<Props> = ({
  status,
  isProcessed,
  isBucketType,
  handleClickOutSideRealogramBbox,
  realogramCandidate,
  realogramDetailMode,
  realogramDetailView,
  imageIsLoading,
  shelfBoards,
  handleClickBbox,
  createdAt,
  onClickNext,
  onClickPrev,
  rateValue,
  profitTab,
  analyticsData,
  isUnknownProductsReviseMode,
  unknownProductsInfo,
  setImageIsLoading,
  handleResetReviseMode,
  isEnableReviseButton,
  handleOnBulkRevise,
  isLarger,
  realogramImageElement,
  isCheckedRecommendedRevise,
  handleCheckboxChange,
  handleToggleRecommendedRevise,
  hasRecommendProducts,
  productTag,
  handleChangeProductTag,
  handleChangeRateValue,
  selectedItem,
  rateValues,
  productTagValues,
  score,
  handleChangeProfitTabValueValue,
  handleUnknownProductsReviseMode,
  isCanNotEditProductAndCompartment,
  primaryCandidates,
  referenceImage,
  selectNext,
  selectPrevious,
  handleProductCandidatesDrawerOpen,
  setSelectedProductChoice,
  shouldButtonBeDisabled,
  handleOpenReferenceImagePreview,
  setSelectedProductZoomIn,
  realogramCandidateId,
  isTenantSalesAnalytics,
  handleChangeCandidate,
  listItemRefs,
  shelfBoardsToDisplay,
  handleClickFace,
  handleClickDeleteShelfBoard,
  handleClickDeleteFace,
  handleCorrectDrawerOpen,
  handleDivisionModalOpen,
  handleSort,
  isAscending,
  isCanEditFace,
  isCanDeleteFace,
  isCanDeleteBaypart,
  setDeleteDialogOpen,
  setIsErrorComponent,
  handleClickReUpLoadScan,
  handleAddFaceMode,
  analyticsDataTerm,
  displayAnalyticsData,
  disableChangeToPreviousWeek,
  disableChangeToNextWeek,
  displayAnalyticsDataIndex,
  handleNextWeekTerm,
  handlePreviousWeekTerm,
  realogramStatistics,
  isLoadingAnalyticsData,
  setSelectedCandidate,
  isRealogramLoading = false,
}) => {
  const realogramInformation = () => {
    switch (status) {
      case 'error':
        return (
          <RealogramCandidateError
            handleOpenModal={() => {
              setDeleteDialogOpen(true);
              setIsErrorComponent(true);
            }}
            handleClickReUpLoadScan={handleClickReUpLoadScan}
          />
        );
      case 'processing':
      case 'initialized':
        return <RealogramCandidateProcessing />;
      case 'processed':
        return (
          <>
            {isUnknownProductsReviseMode ? (
              // 一括訂正表示
              <>
                <UnknownProductsReviseGuide
                  handleResetReviseMode={handleResetReviseMode}
                  isEnableReviseButton={isEnableReviseButton}
                  handleOnBulkRevise={handleOnBulkRevise}
                  isLarger={isLarger}
                />
                {isLarger && (
                  <UnknownProductList
                    realogramImageElement={realogramImageElement}
                    imageIsLoading={imageIsLoading}
                    unknownProductsInfo={unknownProductsInfo}
                    isCheckedRecommendedRevise={isCheckedRecommendedRevise}
                    handleCheckboxChange={handleCheckboxChange}
                    handleToggleRecommendedRevise={
                      handleToggleRecommendedRevise
                    }
                    hasRecommendProducts={hasRecommendProducts}
                  />
                )}
              </>
            ) : (
              <>
                <ShelfDetailTabs
                  productTag={productTag}
                  realogramDetailView={realogramDetailView}
                  handleChangeProductTag={handleChangeProductTag}
                  rateValue={rateValue}
                  handleChangeRateValue={handleChangeRateValue}
                  hasSelected={!!selectedItem}
                  rateValues={rateValues}
                  productTagValues={productTagValues}
                  score={score}
                />
                {shelfBoards && (
                  <RealogramCandidateOverview
                    type={realogramDetailView}
                    hasSelected={!!selectedItem}
                    profitTab={profitTab}
                    setProfitTab={(profitTab: ProfitTab) =>
                      handleChangeProfitTabValueValue(profitTab)
                    }
                    isBucketType={isBucketType}
                    setIsUnknownProductsReviseMode={
                      handleUnknownProductsReviseMode
                    }
                    analyticsDataTerm={analyticsDataTerm}
                    displayAnalyticsData={displayAnalyticsData}
                    disableChangeToPreviousWeek={disableChangeToPreviousWeek}
                    disableChangeToNextWeek={disableChangeToNextWeek}
                    handleNextWeekTerm={handleNextWeekTerm}
                    handlePreviousWeekTerm={handlePreviousWeekTerm}
                    realogramStatistics={realogramStatistics}
                    isLoadingAnalyticsData={isLoadingAnalyticsData}
                  />
                )}
                {/* 商品選択時 */}
                {selectedItem && (
                  <Box
                    component="div"
                    p={2}
                    height={{ xs: '100%', breakpoint: 'auto' }}
                  >
                    <ProductCandidate
                      isCanNotEditProductAndCompartment={
                        isCanNotEditProductAndCompartment
                      }
                      products={primaryCandidates?.products}
                      // TODO: reduxから取得する
                      selectedItem={selectedItem}
                      referenceImage={referenceImage}
                      selectNext={selectNext}
                      selectPrevious={selectPrevious}
                      handleModalOpen={handleProductCandidatesDrawerOpen}
                      handleOutOfStockModalOpen={() => {
                        performance.mark('start');
                        setSelectedCandidate(
                          selectedItem.item.primary_candidate
                        );
                        setSelectedProductChoice(true);
                      }}
                      shouldButtonBeDisabled={shouldButtonBeDisabled}
                      handleOpenReferenceImagePreview={
                        handleOpenReferenceImagePreview
                      }
                      realogramDetailView={realogramDetailView}
                      productSalesReport={analyticsData?.reports}
                      profitTab={profitTab}
                      setSelectedProductZoomIn={setSelectedProductZoomIn}
                      realogramCandidateId={realogramCandidateId}
                      isTenantSalesAnalytics={isTenantSalesAnalytics}
                      handleChangeCandidate={handleChangeCandidate}
                      isBucketType={isBucketType}
                      displayAnalyticsDataIndex={displayAnalyticsDataIndex}
                    />
                  </Box>
                )}
                {/* 商品一覧 */}
                {isLarger && (
                  <RealogramCandidatesList
                    refs={listItemRefs}
                    products={primaryCandidates?.products}
                    // TODO: reduxから取得する
                    selectedItem={selectedItem}
                    shelfBoards={shelfBoardsToDisplay}
                    handleClick={handleClickFace}
                    handleClickDeleteShelfBoard={handleClickDeleteShelfBoard}
                    handleClickDeleteFace={handleClickDeleteFace}
                    realogramDetailView={realogramDetailView}
                    handleCorrectDrawerOpen={handleCorrectDrawerOpen}
                    profitTab={profitTab}
                    productsSalesReport={displayAnalyticsData?.products}
                    handleDivisionModalOpen={handleDivisionModalOpen}
                    handleSort={handleSort}
                    isAscending={isAscending}
                    isCanEditFace={isCanEditFace}
                    isCanDeleteFace={isCanDeleteFace}
                    isCanDeleteBaypart={isCanDeleteBaypart}
                    isBucketType={isBucketType}
                    handleAddFaceMode={handleAddFaceMode}
                  />
                )}
              </>
            )}
          </>
        );
    }
  };

  return (
    <Box
      component="div"
      display="flex"
      flexDirection={{
        xs: isProcessed ? 'column' : 'row',
        breakpoint: 'row',
      }}
      flex={1}
      borderTop="1px solid #EDEDED"
      height={{
        xs: window.innerHeight - headerHeight - 1,
        breakpoint: 'auto',
      }}
    >
      <Box
        component="div"
        display="flex"
        width="100%"
        flexDirection={{
          xs: isBucketType || !isProcessed ? 'column' : 'row',
          breakpoint: 'row',
        }}
      >
        <Box
          component="div"
          flex={{ xs: 1, breakpoint: 4.5 }}
          onClick={handleClickOutSideRealogramBbox}
        >
          <Box
            component="div"
            minHeight={{ xs: '368px' }}
            height={{
              xs: isBucketType
                ? '100%'
                : realogramDetailView === 'profit'
                ? '569px'
                : '49.5vh',
              breakpoint: '100%',
            }}
            sx={{
              backgroundColor: theme.palette.backgroundBlack.black,
            }}
            display="flex"
          >
            {realogramCandidate && (
              <RealogramImageAndBbox
                mode={realogramDetailMode}
                view={realogramDetailView}
                imageIsLoading={imageIsLoading}
                realogramCandidateId={realogramCandidate.id}
                shelfBoards={shelfBoards}
                handleClickBbox={handleClickBbox}
                handleChangeLoading={() => setImageIsLoading(true)}
                createdAt={createdAt}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                rate={rateValue}
                profitTab={profitTab}
                productReports={displayAnalyticsData?.products}
                isBucketType={isBucketType}
                isUnknownProductsReviseMode={isUnknownProductsReviseMode}
                unknownProductsInfo={unknownProductsInfo}
                isNextDisabled={
                  !realogramCandidate.link?.next_created_realogram_candidate_id
                }
                isPrevDisabled={
                  !realogramCandidate.link?.prev_created_realogram_candidate_id
                }
                isRealogramLoading={isRealogramLoading}
              />
            )}
          </Box>
        </Box>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          flex={{ xs: 5, breakpoint: 5.5 }}
          borderLeft={`1px solid ${theme.palette.dividerBlack.light}`}
          height={{ breakpoint: window.innerHeight - headerHeight - 1 }}
          borderTop={{
            xs: !isProcessed
              ? `1px solid ${theme.palette.dividerBlack.medium}`
              : 'none',
            breakpoint: 'none',
          }}
          overflow="hidden"
        >
          {realogramInformation()}
        </Box>
      </Box>

      {/* 画面幅が小さい時はBoxの外 */}
      {!isLarger && isProcessed && (
        <>
          {isUnknownProductsReviseMode ? (
            <>
              <Divider />
              <UnknownProductList
                realogramImageElement={realogramImageElement}
                imageIsLoading={imageIsLoading}
                unknownProductsInfo={unknownProductsInfo}
                isCheckedRecommendedRevise={isCheckedRecommendedRevise}
                handleCheckboxChange={handleCheckboxChange}
                handleToggleRecommendedRevise={handleToggleRecommendedRevise}
                hasRecommendProducts={hasRecommendProducts}
              />
            </>
          ) : (
            <RealogramCandidatesList
              refs={listItemRefs}
              products={primaryCandidates?.products}
              // TODO: reduxから取得する
              selectedItem={selectedItem}
              shelfBoards={shelfBoardsToDisplay}
              handleClick={handleClickFace}
              handleClickDeleteShelfBoard={handleClickDeleteShelfBoard}
              handleClickDeleteFace={handleClickDeleteFace}
              realogramDetailView={realogramDetailView}
              handleCorrectDrawerOpen={handleCorrectDrawerOpen}
              profitTab={profitTab}
              productsSalesReport={displayAnalyticsData?.products}
              handleDivisionModalOpen={handleDivisionModalOpen}
              handleSort={handleSort}
              isAscending={isAscending}
              isCanEditFace={isCanEditFace}
              isCanDeleteFace={isCanDeleteFace}
              isCanDeleteBaypart={isCanDeleteBaypart}
              isBucketType={isBucketType}
              handleAddFaceMode={handleAddFaceMode}
            />
          )}
        </>
      )}
    </Box>
  );
};
