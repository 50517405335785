import { ProductImage } from '@components/organisms/productImage/productImage';
import { Box, Typography } from '@mui/material';
import { hasProductTag } from '@utils/product';
import Image from 'next/legacy/image';
import { FC, PropsWithChildren } from 'react';
import { theme } from 'theme';
import { FaceFrontId, Product } from 'types/common';
import { UploadedFile } from 'types/products';

type Props = {
  isSelected: boolean;
  title: string;
  handleClick?: () => void;
  uploadedFile?: UploadedFile;
  angle: number;
  size: number;
  isEditor: boolean;
  product: Product;
  faceFrontId: FaceFrontId;
};

export const GridItemBox: FC<PropsWithChildren<Props>> = ({
  children,
  title,
  isSelected,
  handleClick,
  uploadedFile,
  angle,
  size,
  isEditor,
  product,
  faceFrontId,
}) => {
  const hasFile = uploadedFile?.originalFile || uploadedFile?.file;
  const isNot3DScanned = hasProductTag('未3Dスキャン', product.detail?.tags);

  const renderImageComponent = () => {
    if (!isNot3DScanned) {
      return (
        <Box component="div" width="100%" height="100%" position="relative">
          <ProductImage
            product={product}
            imageSizeType="medium"
            fontSize={size}
            baseSize={size}
            layout="fill"
            objectFit="contain"
            faceFrontId={faceFrontId}
          />
        </Box>
      );
    }
    if (hasFile) {
      return (
        <Image
          src={uploadedFile?.file ?? uploadedFile?.originalFile ?? ''}
          width={size}
          height={size}
          alt={title}
          style={{ transform: `rotate(${angle}deg)` }}
          objectFit="contain"
        />
      );
    }
    return (
      <>
        {isEditor && (
          <Typography variant="body1" color={theme.palette.white.primary}>
            {title}
          </Typography>
        )}
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          {children}
        </Box>
      </>
    );
  };
  // todo:抽象化する
  return (
    <Box
      component="div"
      width={size}
      height={size}
      boxShadow={
        isSelected && isEditor ? `0 0 0 2px ${theme.palette.primary.main}` : ''
      }
      p={hasFile || !isNot3DScanned ? 0 : 1}
      sx={{
        backgroundColor: '#B5B5B5',
        cursor: isEditor ? 'pointer' : 'default',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      }}
      onClick={handleClick}
    >
      {renderImageComponent()}
    </Box>
  );
};
