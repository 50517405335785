import { ProductTitleAndImage } from '@components/molecules/productTitleAndImage/productTitleAndImage';
import { Box, Stack, Typography } from '@mui/material';
import { hasProductTag } from '@utils/product';
import { FC } from 'react';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductFaceDirection, UploadedFiles } from 'types/products';
import { ProductInfoDetailForm } from './productInfoDetailForm';
import { ProductUploadBox } from './productUploadBox';

type Props = {
  product: Product;
  directionTabValue: ProductFaceDirection;
  handleChangeDirectionTabValue: (tab: ProductFaceDirection) => void;
  handleClickFace: (face: ProductFaceDirection) => void;
  handleDeleteImage: () => void;
  selectedFace?: ProductFaceDirection;
  uploadedFiles: UploadedFiles;
  handleClickChange: () => void;
  handleClickRotate: () => void;
  isValidSizeFormat: {
    width: boolean;
    height: boolean;
    depth: boolean;
  };
};

export const ProductInfoDetailEditor: FC<Props> = ({
  product,
  directionTabValue,
  handleChangeDirectionTabValue,
  handleClickFace,
  handleDeleteImage,
  selectedFace,
  uploadedFiles,
  handleClickChange,
  handleClickRotate,
  isValidSizeFormat,
}) => {
  const isNot3DScanned = hasProductTag('未3Dスキャン', product.detail?.tags);
  return (
    <Box
      component="div"
      px={3}
      py={2}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Box component="div">
        <ProductTitleAndImage product={product} />
      </Box>
      <Box component="div" display="flex" gap={4}>
        <Stack gap={1} flex={6} maxWidth="576px">
          <Box
            component="div"
            display="flex"
            gap="9px"
            alignItems="center"
            color={theme.palette.textBlack.secondary}
          >
            <Typography variant="body1">商品画像</Typography>
            {/* variant="body3" */}
            <Typography fontSize="12px">
              {isNot3DScanned
                ? '画像をアップロードしたい面をクリックしてください。'
                : '3Dスキャン済みのため編集できません。'}
            </Typography>
          </Box>
          <ProductUploadBox
            directionTabValue={directionTabValue}
            handleChangeDirectionTabValue={handleChangeDirectionTabValue}
            handleClickFace={handleClickFace}
            handleDeleteImage={handleDeleteImage}
            selectedFace={selectedFace}
            uploadedFiles={uploadedFiles}
            handleClickChange={handleClickChange}
            handleClickRotate={handleClickRotate}
            product={product}
          />
        </Stack>
        <ProductInfoDetailForm
          product={product}
          isValidSizeFormat={isValidSizeFormat}
        />
      </Box>
    </Box>
  );
};
