import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import { ArrowDownward } from '@mui/icons-material';
import { CropSquare } from '@components/molecules/cropIcons/cropSquare';
import { CropRectangle } from '@components/molecules/cropIcons/cropRectangle';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const OperationModal: FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '500px',
          borderRadius: 2,
        },
      }}
    >
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 2,
          height: '534px',
          overflow: 'hidden',
        }}
      >
        <Typography variant="headingS">操作方法</Typography>
        <Typography variant="body2">
          左側の白いボックスの角や辺を動かして、追加するフェイスの大きさと位置を調整してください。
        </Typography>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          gap={1}
          alignItems="center"
        >
          <Box
            component="div"
            width="452px"
            height="176px"
            borderRadius="4px"
            position="relative"
            sx={{
              backgroundColor: theme.palette.primary.selected,
            }}
          >
            <CropSquare />
            <PanToolAltIcon
              sx={{
                color: theme.palette.icons.primary,
                width: '80px',
                height: '80px',
                position: 'absolute',
                top: '100px',
                left: '100px',
                transform: 'rotate(-45deg)',
              }}
            />
          </Box>
          <ArrowDownward />
          <Box
            component="div"
            width="452px"
            height="176px"
            borderRadius="4px"
            position="relative"
            sx={{
              backgroundColor: theme.palette.primary.selected,
            }}
          >
            <CropRectangle />
            <PanToolAltIcon
              sx={{
                color: theme.palette.icons.primary,
                opacity: 0.4,
                width: '80px',
                height: '80px',
                position: 'absolute',
                top: '100px',
                left: '100px',
                transform: 'rotate(-45deg)',
              }}
            />
            <PanToolAltIcon
              sx={{
                color: theme.palette.icons.primary,
                opacity: 0.4,
                width: '80px',
                height: '80px',
                position: 'absolute',
                top: '100px',
                left: '180px',
                transform: 'rotate(-45deg)',
              }}
            />
            <PanToolAltIcon
              sx={{
                color: theme.palette.icons.primary,
                opacity: 0.4,
                width: '80px',
                height: '80px',
                position: 'absolute',
                top: '100px',
                left: '260px',
                transform: 'rotate(-45deg)',
              }}
            />
            <PanToolAltIcon
              sx={{
                color: theme.palette.icons.primary,
                width: '80px',
                height: '80px',
                position: 'absolute',
                top: '100px',
                left: '340px',
                transform: 'rotate(-45deg)',
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{
            fontWeight: 'bold',
            m: 1,
            mr: 2,
            px: 2,
          }}
          onClick={onClose}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
