import { headerHeight } from '@components/organisms/header/header';
import { Add, Search } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { GetUsersQueryParams } from '@reducers/shelfAppsApi/injections/usersApi';
import { fullHeight, fullWidth } from '@utils/const';
import { FC, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from 'theme';
import { User, UserOrder } from 'types/user';
import {
  UserListControl,
  UserListFormData,
  UserListSubmit,
} from '../hooks/useListUsers';
import { UserConditions } from './userConditions';
import { UsersTable } from './usersTable';

type Props = {
  selectedUser?: User;
  selectUser: (user: User) => void;
  users?: User[];
  total?: number;
  control: UserListControl;
  handleSubmit: UserListSubmit;
  isFocused: boolean;
  isLoading: boolean;
  isFetching: boolean;
  selectedUserRoleTags: string[];
  selectedUserStatusTags: string[];
  handleChangeSelectedUserStatusTags: (name: string[]) => void;
  handleChangeSelectedUserRoleTags: (name: string[]) => void;
  condition: GetUsersQueryParams;
  offset: number;
  reset: () => void;
  resetFiltering: () => void;
  onSubmit: (data: UserListFormData) => void;
  handleFocus: () => void;
  handleEndReached: (index: number) => void;
  handleChangeOrder: (order: UserOrder) => void;
  handleCreateUser: () => void;
};

const tablePaddingR = 24;
const tablePaddingL = 24;

export const UsersList: FC<Props> = ({
  selectedUser,
  selectUser,
  users,
  total,
  control,
  handleSubmit,
  isFocused,
  isLoading,
  isFetching,
  selectedUserRoleTags,
  selectedUserStatusTags,
  condition,
  offset,
  reset,
  resetFiltering,
  onSubmit,
  handleFocus,
  handleEndReached,
  handleChangeOrder,
  handleChangeSelectedUserRoleTags,
  handleChangeSelectedUserStatusTags,
  handleCreateUser,
}) => {
  const titleRef = useRef<HTMLDivElement>();
  const filterRef = useRef<HTMLDivElement>();
  const titleHeight = titleRef.current?.clientHeight ?? 0;
  const filterHeight = filterRef.current?.clientHeight ?? 0;
  const tableHeight = useMemo(
    () =>
      `calc(${fullHeight} - ${headerHeight}px - ${titleHeight}px - ${filterHeight}px - 56px)`,
    [titleHeight, filterHeight]
  );

  const tableWidth = `calc(${fullWidth} - ${tablePaddingR}px - ${tablePaddingL}px)`;

  return (
    <Box component="div" display="flex" mx="auto">
      <Box
        component="div"
        px={3}
        width="100%"
        sx={{
          transition: 'all 0.1s linear',
        }}
      >
        <Box component="div" height="calc(100vh - 100px)">
          <Box
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            ref={titleRef}
            mt={3}
            marginTop="16px"
          >
            <Box component="div" display="flex" alignItems="center" gap={2}>
              <Typography
                variant="h5"
                fontWeight="bold"
                color={theme.palette.textBlack.primary}
              >
                ユーザー管理
              </Typography>
              <Typography color={theme.palette.textBlack.secondary}>
                {`全${total ?? 0}名`}
              </Typography>
            </Box>
            <Button
              color="primary"
              variant="contained"
              sx={{ gap: 1, display: 'flex', alignItems: 'center' }}
              onClick={() => handleCreateUser()}
            >
              <Add />
              <Typography variant="buttonText1">ユーザー追加</Typography>
            </Button>
          </Box>
          <Box component="div" my={2}>
            <Box
              component="div"
              ref={filterRef}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <UserConditions
                selectedUserRoleTags={selectedUserRoleTags}
                selectedUserStatusTags={selectedUserStatusTags}
                handleChangeSelectedUserRoleTags={
                  handleChangeSelectedUserRoleTags
                }
                handleChangeSelectedUserStatusTags={
                  handleChangeSelectedUserStatusTags
                }
                clearConditions={resetFiltering}
              />
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                display="flex"
              >
                <Controller
                  name="searchText"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="ユーザーを検索"
                      onFocus={handleFocus}
                      size="small"
                      sx={{
                        width: '254px',
                        'input::placeholder': {
                          fontSize: '14px',
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: '40px',
                          backgroundColor: '#F7F7F7',
                        },
                        startAdornment: (
                          <Search
                            sx={{
                              color: theme.palette.icons.primary,
                              mr: '4px',
                            }}
                          />
                        ),
                        endAdornment: isFocused && (
                          <IconButton
                            onClick={() => {
                              reset();
                            }}
                          >
                            <CloseIcon
                              sx={{
                                width: '18px',
                                height: '18px',
                                cursor: 'pointer',
                                color: theme.palette.icons.primary,
                              }}
                            />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <UsersTable
            tableHeight={tableHeight}
            handleClickRow={selectUser}
            handleEndReached={handleEndReached}
            isLoading={isLoading || isFetching}
            users={users}
            condition={condition}
            handleChangeOrder={handleChangeOrder}
            selectedUser={selectedUser}
            offset={offset}
            tableWidth={tableWidth}
          />
        </Box>
      </Box>
    </Box>
  );
};
