import { FC } from 'react';
import { Box, Chip } from '@mui/material';
import { Check } from '@mui/icons-material';
import { CommonColors } from '@utils/colors';
import { resetChipLabel } from '@components/organisms/productFlagChips/productFlagChips';

type Props = {
  label: string;
  handleClick: (tag?: string) => void;
  isSelected: boolean;
  isDisabled: boolean;
};

const selected = {
  backgroundColor: CommonColors.appBlueLight,
  color: CommonColors.appBlue,
  border: `1px solid ${CommonColors.appBlue}`,
  '&:hover ': {
    backgroundColor: '#CCE2FF',
  },
};

const notSelected = {
  border: '1px solid #CCCCCC',
  '&:hover ': {
    backgroundColor: '#EEEEEE',
  },
};

export const ProductFlagChip: FC<Props> = ({
  label,
  handleClick,
  isSelected,
  isDisabled,
}) => {
  return (
    <Box component="div">
      <Chip
        disabled={isDisabled}
        icon={
          isSelected ? (
            <Check sx={{ width: 16, height: 16, fill: CommonColors.appBlue }} />
          ) : undefined
        }
        label={label}
        sx={isSelected ? selected : notSelected}
        onClick={() =>
          handleClick(label === resetChipLabel ? undefined : label)
        }
      />
    </Box>
  );
};
