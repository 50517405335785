import { EmptyResult } from '@components/molecules/emptyResult/emptyResult';
import { GramTableFoot } from '@components/organisms';
import { GramTableFooter } from '@components/organisms/gramTableFooter/gramTableFooter';
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@mui/material';
import { useAppSelector } from '@store/index';
import { CommonColors } from '@utils/colors';
import { rowHeight, rowsPerPage } from '@utils/const';
import { FC, useEffect, useMemo, useRef } from 'react';
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso';
import { RealogramDirectory, RealogramListOrder } from 'types/realogram';
import { RealogramSharePermissionProps } from 'types/sharePermission';
import { theme } from '../../../../../theme';
import { TableHeader } from './tableHeader';
import { getColumns } from './directoryTableHeaderData';
import { TableCells } from './tableCells';
import { useQueryParameterPresence } from '@hooks/useQueryParameterPresence';
import { useGetRealogramPermission } from '@hooks/useGetRealogramPermission';
import { ShelvesViewMode } from 'types/common';

type Props = {
  isLoading: boolean;
  isRefetching: boolean;
  offset: number;
  realogramDirectories?: RealogramDirectory[];
  isFilteredByViewed: boolean;
  firstOrder?: RealogramListOrder;
  viewMode?: ShelvesViewMode;
  handleRowClick: (index: number, item: RealogramDirectory) => void;
  handleOpenDeleteDialog: (id: number) => void;
  handleChangeOrder: (order?: RealogramListOrder) => void;
  handleFavoriteClick: (id: string, isFavorite: boolean) => void;
  handleEndReached: (index: number) => void;
  handleSharePermission: (item: RealogramSharePermissionProps) => void;
};

const queryList = ['owner_id', 'name'];

export const RealogramsDirectoryTable: FC<Props> = ({
  isLoading,
  isRefetching,
  offset,
  realogramDirectories,
  isFilteredByViewed,
  firstOrder,
  viewMode,
  handleRowClick,
  handleOpenDeleteDialog,
  handleChangeOrder,
  handleFavoriteClick,
  handleEndReached,
  handleSharePermission,
}) => {
  const { hasQueryParameters } = useQueryParameterPresence();
  const ref = useRef<TableVirtuosoHandle>(null);
  const { selectedItemIndex } = useAppSelector((state) => state.Scanner);
  const isRealogramDataEmpty = !realogramDirectories?.length;
  const isEmpty = !isLoading && isRealogramDataEmpty;
  const hasQueries = hasQueryParameters(queryList);

  const getRowSx = () => {
    return {
      ':hover': {
        backgroundColor: CommonColors.lightGray,
      },
    };
  };

  const columns = useMemo(
    () => getColumns(isFilteredByViewed),
    [isFilteredByViewed]
  );

  useEffect(() => {
    if (!isRefetching) return;
    ref.current?.scrollToIndex({ index: 0 });
  });

  return (
    <TableVirtuoso
      style={{
        border: '1px solid #CCCCCC',
        overflow: isEmpty ? 'hidden' : 'auto',
      }}
      data={
        realogramDirectories?.length
          ? realogramDirectories
          : Array.from(
              { length: rowsPerPage },
              () => ({} as RealogramDirectory)
            )
      }
      endReached={(index) => {
        handleEndReached(index);
      }}
      initialTopMostItemIndex={selectedItemIndex}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        Table: (props: TableProps) => (
          <Table
            {...props}
            style={{
              borderCollapse: 'separate',
              height: isEmpty ? '100%' : '',
            }}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableHead,
        // Note: TableRowに一行に対するスタイルや動作を設定し、itemContentに行内の各カラムに対するスタイルや動作設定する。
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableRow: (props: {
          item: RealogramDirectory;
          // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the props's specification
          'data-index': number;
        }) => {
          const index = props['data-index'];
          const { isNotEnable: isNotCanRead } = useGetRealogramPermission({
            action: 'read',
            realogram: props.item,
            isCandidate: false,
            isCan: false,
          });

          return (
            <>
              {offset === 0 && (isLoading || isRefetching) ? (
                <TableRow {...props}>
                  {columns.map((_, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton animation="wave" width="75%" />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : (
                <TableRow
                  sx={{
                    ...getRowSx(),
                    cursor: isNotCanRead ? 'default' : 'pointer',
                    '.MuiTableCell-root': {
                      color: isNotCanRead
                        ? theme.palette.textBlack.disabled
                        : theme.palette.textBlack.table,
                    },
                    height: rowHeight,
                  }}
                  onClick={() => {
                    if (isNotCanRead) return;
                    handleRowClick(index, props.item);
                  }}
                  {...props}
                />
              )}
            </>
          );
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableBody:
          isRealogramDataEmpty && !isLoading && !isRefetching
            ? () => (
                <Box
                  component="div"
                  sx={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    width: 'calc(100vw - 233px)', // sidebar 183px, padding 48px, border 2px
                    height: '100%',
                  }}
                >
                  <EmptyResult
                    title="スキャン結果がありません"
                    message={
                      hasQueries
                        ? '条件を変更してください。'
                        : '左上の「新規スキャン」ボタンから追加してください。'
                    }
                    isErrorIcon
                  />
                </Box>
              )
            : TableBody,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        ScrollSeekPlaceholder: () => (
          <TableRow>
            {columns.map((_, index) => {
              return (
                <TableCell key={index}>
                  <Skeleton animation="wave" width="75%" />
                </TableCell>
              );
            })}
          </TableRow>
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- due to the library's specification
        TableFoot: GramTableFoot,
      }}
      fixedHeaderContent={() => (
        <TableHeader
          columns={columns}
          firstOrder={firstOrder}
          handleChangeOrder={(order?: RealogramListOrder) =>
            handleChangeOrder(order)
          }
        />
      )}
      fixedFooterContent={() => {
        if (isRefetching) return <GramTableFooter columns={columns} />;
        return <></>;
      }}
      itemContent={(_, realogramDirectory) => (
        <TableCells
          isFilteredByViewed={isFilteredByViewed}
          realogramDirectory={realogramDirectory}
          viewMode={viewMode}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          handleFavoriteClick={handleFavoriteClick}
          handleSharePermission={handleSharePermission}
        />
      )}
    />
  );
};
