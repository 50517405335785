import { FlatPlanogram } from '@components/organisms/flatPlanogram/flatPlanogram';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Box } from '@mui/material';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { BucketPlanogramPlan } from 'types/planogram';

type Props = {
  zoomScale: number;
  plan: BucketPlanogramPlan;
};

const zoomScaleMagnifier = 1.5;

export const TwoDFlatPlanogram: FC<Props> = ({ zoomScale, plan }) => {
  const { isLarger } = useBreakpoint();
  return (
    //NOTE: DnD判定を有効にするためにDndProviderでwrapする必要がある
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <Box
        component="div"
        sx={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: isLarger ? 'center' : 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Box
          component="div"
          sx={{
            pb: 6,
            willChange: 'transform',
            transform: `scale(${zoomScale * zoomScaleMagnifier})`,
            transformOrigin: isLarger ? 'top center' : 'top left',
            pointerEvents: 'none',
          }}
        >
          <FlatPlanogram
            plan={plan}
            zoomScale={zoomScale}
            setZoomScale={() => void 0}
          />
        </Box>
      </Box>
    </DndProvider>
  );
};
