import { createSelector } from '@reduxjs/toolkit';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { RootState } from 'store';

export const selectIsAuthenticated = createSelector(
  (state: RootState) => state.Auth.tokens,
  (tokens) => !!tokens
);

export const selectToken = createSelector(
  (state: RootState) => state.Auth.tokens,
  (tokens) => tokens?.idToken
);

export const selectAccessToken = createSelector(
  (state: RootState) => state.Auth.tokens,
  (tokens) => tokens?.accessToken ?? ''
);

export const selectRefreshToken = createSelector(
  (state: RootState) => state.Auth.tokens,
  (tokens) => tokens?.refreshToken ?? ''
);

export const selectEmail = createSelector(
  (state: RootState) => state.Auth.tokens?.idToken,
  (idToken) =>
    idToken
      ? jwtDecode<JwtPayload & { email: string }>(idToken).email
      : undefined
);

export const selectName = createSelector(
  (state: RootState) => state.Auth.user?.fullname,
  (fullname) => fullname
);

export const selectUserRole = createSelector(
  (state: RootState) => state.Auth.user?.role,
  (role) => role
);
