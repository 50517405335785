import { NotFound } from '@components/NotFound';
import { ZoomablePlanogram } from '@components/organisms';
import { NotPermittedModal } from '@components/organisms/NotPermittedModal/NotPermittedModal';
import { PlanogramHeader } from '@components/organisms/planogramHeader/planogramHeader';
import { StatisticsValueSelector } from '@components/organisms/statisticsValueSelector/statisticsValueSelector';
import { PlanButtonGroup } from '@components/pages/planogramDetail/fragments/planButtonGroup';
import { ComparisonPlanogram } from '@components/pages/planogramEditor/fragments/comparisonPlanogram';
import { RightSideArea } from '@components/pages/planogramEditor/fragments/rightSideArea';
import { useRerenderingDetails } from '@hooks/rerenderingComponents';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useBrowserOperate } from '@hooks/useBrowserOperate';
import { useEstimatedProfit } from '@hooks/useEstimatedProfit';
import { useGetPlanogramPermission } from '@hooks/useGetPlanogramPermission';
import { useInitSelectedPlanogram } from '@hooks/useInitSelectedPlanogram';
import { usePlanogramPlan } from '@hooks/usePlanogramPlan';
import { useUrlQueryParams } from '@hooks/useUrlQueryParams';
import { useZoomController } from '@hooks/useZoomController';
import { Box } from '@mui/material';
import { moveBayPart } from '@reducers/plan';
import {
  changeEditorMode,
  forget as forgetPlanogramEditorState,
  selectBayPartId,
  updateDetailMode,
  updateIsShowBayPartDetail,
  updateIsShowProductDetail,
  updateProductPosition,
  updateSelectedProductCompartment,
} from '@reducers/planogramEditor/reducer';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useListProductsBulkQuery } from '@reducers/shelfAppsApi';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  fullHeight,
  getDisplayValue,
  getProfitsMenu,
  planogramRightSideHeight,
  pointersDummy,
} from '@utils/const';
import { getTextDateStatistic } from '@utils/date';
import {
  closeToShelfStepElement,
  getBayPartControllerDisableState,
  getProductsLayout,
  isSameElevation,
} from '@utils/planogram';
import httpStatus from 'http-status';
import { t } from 'i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'theme';
import { Planogram } from 'types/planogram';
import { BayPartController } from '../fragments/bayPartController';
import { PreviewDragLayer } from '../fragments/previewDragLayer';
import { ThreeDPreview } from '../fragments/threeDPreview';
const defaultDelayTouchStart = 50; /* ms */

type Props = {
  planogram?: Planogram;
  planogramId: number;
  isLoading: boolean;
  isFetching: boolean;
  delay?: string;
  error?: FetchBaseQueryError | SerializedError;
  isForbidden?: boolean;
};

export const ShelfPlanogram: FC<Props> = ({
  planogram,
  planogramId,
  isLoading,
  isFetching,
  delay,
  error,
  isForbidden,
}) => {
  const dispatch = useAppDispatch();
  const { removeQueryParameter } = useUrlQueryParams();
  const [initRerenderingSelectedProduct, setInitRerenderingSelectedProduct] =
    useState(false);
  const [
    initRerenderingSelectedProductPosition,
    setInitRerenderingSelectedProductPosition,
  ] = useState(false);

  const {
    zoomScale,
    handleIncrementZoom,
    handleDecrementZoom,
    handleTriggerZoom,
  } = useZoomController();
  const { isEnable: isCanUpdate } = useGetPlanogramPermission({
    action: 'update',
    planogram: planogram,
    isPlanogram: true,
    isCan: true,
  });

  const [name, setName] = useState('');
  const [bayPlanCodeId, setBayPlanCodeId] = useState(
    planogram?.bay_plan_code_id
  );
  const [organizationStatusId, setOrganizationStatusId] = useState(
    planogram?.organization_status_id
  );
  const {
    plan,
    forget,
    isDirty,
    mark,
    selectBayPartPosition,
    bayPartPosition,
  } = usePlanogramPlan(planogram?.plan);

  const {
    mode,
    selectedProductCompartment,
    selectedBayPartId,
    productPosition,
    detailMode,
    isSwappingBayPartMode,
    detailView,
    productTag,
  } = useAppSelector(selectPlanogramEditorState);
  const {
    selector,
    operate,
    data: { isTenantSalesAnalytics },
  } = useRerenderingDetails();
  const productIds = plan.products_layout
    .flatMap(({ row }) => row.map(({ product_id }) => product_id))
    .sort()
    .join(',');

  const { data: productsBulk, isLoading: isLoadingProductsBulk } =
    useListProductsBulkQuery(
      { productIds: productIds, shape: true, detail: true },
      { skip: !productIds }
    );

  const { isEmpty, position, productsSelected } =
    operate.getSelectedItemOfPlanogram(
      productsBulk?.products,
      getProductsLayout(planogram?.plan)
    );

  const shelf = bayPartPosition && plan.shelves.at(bayPartPosition.indexY);
  const shelfSteps =
    'shelves_frame' in plan ? plan.shelves_frame.detail.shelf_steps : [];
  const {
    planogramEstimatedData,
    handleChangeProfit,
    storeAreaType,
    profit,
    handleRecalculate,
    isLoadingEstimate,
  } = useEstimatedProfit({ planogram, editedPlan: plan });
  const closestStepElevation = useMemo(
    () =>
      closeToShelfStepElement(
        shelf?.elevation,
        plan?.shelves_frame?.detail?.shelf_steps ?? []
      ),
    [shelf, plan]
  );
  const [bboxEnabled, setBboxEnabled] = useState(true);

  const stepIndex = shelf
    ? shelfSteps.findIndex(({ elevation }) => {
        return (
          closestStepElevation &&
          isSameElevation(elevation, closestStepElevation)
        );
      })
    : -1;

  const step = stepIndex !== -1 ? shelfSteps.length - stepIndex : undefined;
  const { isUpDisabled, isDownDisabled } = useMemo(
    () => getBayPartControllerDisableState(plan, shelf),
    [plan, shelf]
  );

  const isComparisonMode = detailMode === 'comparison';

  useEffect(() => {
    return () => {
      forget();
      dispatch(updateIsShowProductDetail(false));
      dispatch(updateIsShowBayPartDetail(false));
    };
  }, [forget, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(forgetPlanogramEditorState());
    };
  }, [dispatch]);

  const handleClickAwayPlanogram = () => {
    if ((bayPartPosition || selectedBayPartId) && !isSwappingBayPartMode) {
      selectBayPartPosition(undefined);
      dispatch(selectBayPartId(undefined));
      dispatch(updateIsShowBayPartDetail(false));
    }
    if (productPosition || selectedProductCompartment) {
      dispatch(updateProductPosition(undefined));
      dispatch(updateSelectedProductCompartment(undefined));
      dispatch(updateIsShowProductDetail(false));
      removeQueryParameter('item');
    }
  };

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleChangeBayPlanCodeId = (value?: number) => {
    setBayPlanCodeId(value);
  };

  const handleChangeOrganizationStatusId = (value?: number) => {
    setOrganizationStatusId(value);
  };

  const handleMoveUpBayPart = () => {
    // TODO: エラー処理が不十分
    if (!bayPartPosition) return;
    const elevation = plan.shelves_frame.detail.shelf_steps.at(
      stepIndex + 1
    )?.elevation;
    if (!elevation) return;

    dispatch(
      moveBayPart({
        from: bayPartPosition,
        to: { elevation },
      })
    );
  };

  const handleMoveDownBayPart = () => {
    // TODO: エラー処理が不十分
    if (!bayPartPosition) return;
    const elevation =
      plan.shelves_frame.detail.shelf_steps.at(stepIndex - 1)?.elevation ?? -1;
    if (!elevation) return;

    dispatch(
      moveBayPart({
        from: bayPartPosition,
        to: { elevation },
      })
    );
  };

  useEffect(() => {
    if (!planogram) return;
    setName(planogram.name);
    setBayPlanCodeId(planogram.bay_plan_code_id);
    setOrganizationStatusId(planogram.organization_status_id);
  }, [planogram]);

  useEffect(() => {
    // compare
    if (selector.modeQueryParams) {
      dispatch(updateDetailMode(selector.modeQueryParams));
    }
  }, [dispatch, selector]);

  useInitSelectedPlanogram({
    planogramPlan: plan,
    initSelectedData: {
      isSkipInit:
        !productsSelected?.length ||
        initRerenderingSelectedProduct ||
        isLoadingProductsBulk ||
        isComparisonMode,
      view: detailView,
      productTag,
      onInitCompleted: setInitRerenderingSelectedProduct,
    },
  });

  // 初回描画：選択中のアイテム位置情報
  useEffect(() => {
    if (
      initRerenderingSelectedProductPosition ||
      isLoadingProductsBulk ||
      isEmpty
    ) {
      return;
    }
    dispatch(
      updateProductPosition({
        indexX: 0,
        indexY: position.indexY as number,
        subPosition: {
          indexX: position.subIndexX as number,
          indexY: 0,
        },
      })
    );
    setInitRerenderingSelectedProductPosition(true);
  }, [
    dispatch,
    initRerenderingSelectedProductPosition,
    isEmpty,
    isLoadingProductsBulk,
    position.indexY,
    position.subIndexX,
  ]);

  // ブラウザバックなどの操作時に発火する
  const updateStateByBrowserOperated = () => {
    setInitRerenderingSelectedProduct(false);
    setInitRerenderingSelectedProductPosition(false);
  };

  useBrowserOperate(updateStateByBrowserOperated);
  const { isLarger } = useBreakpoint();

  // todo: flatと共通化できるか検討。（共通化すると表示不備が起こるため原因調査)
  if (!!error && 'status' in error && error.status === httpStatus.NOT_FOUND) {
    return <NotFound title="棚割計画（棚エディタ）" />;
  }
  if (mode === 'preview' && planogram) {
    return <ThreeDPreview planogram={planogram} plan={plan} />;
  }

  const { start_date: startDateFromAPI, end_date: endDateFromAPI } =
    planogramEstimatedData?.estimate.summary.aggregation_period || {};

  const term = getTextDateStatistic(startDateFromAPI, endDateFromAPI);

  return (
    <Box
      component="div"
      sx={{
        height: fullHeight,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PlanogramHeader
        name={name}
        bayPlanCodeId={bayPlanCodeId}
        organizationStatusId={organizationStatusId}
        planogram={planogram}
        planogramIsLoading={isLoading}
        handleChangeName={handleChangeName}
        handleChangeBayPlanCodeId={handleChangeBayPlanCodeId}
        handleChangeOrganizationStatusId={handleChangeOrganizationStatusId}
        planogramIsFetching={isFetching}
        plan={plan}
        isDirty={isDirty}
        mark={mark}
      />
      <DndProvider
        backend={isMobile ? TouchBackend : HTML5Backend}
        options={{ delayTouchStart: Number(delay ?? defaultDelayTouchStart) }}
      >
        {detailMode === 'comparison' ? (
          <ComparisonPlanogram
            step={step}
            handleMoveDownBayPart={handleMoveDownBayPart}
            handleMoveUpBayPart={handleMoveUpBayPart}
            planogram={planogram}
            plan={plan}
            handleClickAway={handleClickAwayPlanogram}
            planogramEstimatedData={planogramEstimatedData}
            handleRecalculate={handleRecalculate}
            isBucketType={false} // To make header style direction is always 'column'
            isTenantSalesAnalytics={isTenantSalesAnalytics}
            initRerenderingSelectedProduct={initRerenderingSelectedProduct}
            setInitRerenderingSelectedProduct={
              setInitRerenderingSelectedProduct
            }
            compareQueryParams={selector.compareQueryParams}
          />
        ) : (
          <>
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', breakpoint: 'row' },
                overflow: 'hidden',
                flex: 1,
              }}
            >
              <Box
                component="div"
                width="100%"
                display="flex"
                flexDirection="column"
                overflow="hidden"
                flex={1}
              >
                {planogram?.store_id && isTenantSalesAnalytics && (
                  <Box component="div" p="8px 16px">
                    <StatisticsValueSelector
                      value={getDisplayValue(
                        profit,
                        planogramEstimatedData?.estimate.summary,
                        t('gross_profit')
                      )}
                      profits={getProfitsMenu(t('gross_profit'))}
                      pointers={pointersDummy}
                      selectedProfitType={profit}
                      selectedPointerType={storeAreaType}
                      handleChangeProfitValue={handleChangeProfit}
                      handleRefreshValue={handleRecalculate}
                      hasRefreshButton
                      category="シミュレーション"
                      term={term}
                      disabled={isSwappingBayPartMode}
                      isLoading={isLoadingEstimate}
                      detailMode={detailMode}
                    />
                  </Box>
                )}
                <Box
                  component="div"
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  overflow="auto"
                  flex={1}
                >
                  <Box
                    component="div"
                    flex={1}
                    sx={{
                      backgroundColor: theme.palette.shelf.backgroundTana,
                    }}
                  >
                    <ZoomablePlanogram
                      plan={plan}
                      bboxEnabled={bboxEnabled}
                      scale={zoomScale}
                      handleClickAway={handleClickAwayPlanogram}
                    />
                  </Box>

                  {mode === 'BayEditor' && step && !isSwappingBayPartMode && (
                    <BayPartController
                      step={step}
                      onUpClick={() => handleMoveUpBayPart()}
                      onDownClick={() => handleMoveDownBayPart()}
                      isUpDisabled={isUpDisabled}
                      isDownDisabled={isDownDisabled}
                    />
                  )}
                </Box>
              </Box>
              <Box
                component="div"
                sx={{
                  maxWidth: { xs: '100%', breakpoint: '480px' },
                  width: '100%',
                  boxSizing: 'border-box',
                  padding: '8px',
                  background: '#FFF',
                  borderLeft: '1px solid #EDEDED',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  height: {
                    xs: planogramRightSideHeight,
                    breakpoint: '100%',
                  },
                }}
              >
                <RightSideArea
                  bayPlanId={planogram?.bay_plan_id ?? 0}
                  isProductDetailRow={!isLarger}
                />
              </Box>
            </Box>
            <PreviewDragLayer scale={zoomScale} />
          </>
        )}
      </DndProvider>
      {detailMode !== 'comparison' && !isSwappingBayPartMode && (
        <Box
          component="div"
          position="absolute"
          top={24}
          zIndex={5}
          sx={{ transform: `translate(0px, 24px)` }}
        >
          <PlanButtonGroup
            bboxEnabled={bboxEnabled}
            handleChangeBboxEnabled={() => setBboxEnabled(!bboxEnabled)}
            handleChangeView={() => dispatch(changeEditorMode('preview'))}
            isOrientationModalOpen={false}
            handleIncrementZoom={handleIncrementZoom}
            handleDecrementZoom={handleDecrementZoom}
            handleTriggerZoom={handleTriggerZoom}
            zoomScale={zoomScale}
            isEditor
          />
        </Box>
      )}
      <NotPermittedModal
        id={planogramId}
        open={!(isLoading || (planogram && isCanUpdate)) || !!isForbidden}
        errorMessage="この棚割の編集権限がありません"
        buttonMessage="棚割詳細へ戻る"
      />
    </Box>
  );
};
