import { useBreakpoint } from './useBreakpoint';
import { useWindowSize } from './useWindowSize';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { ShelfDetailMode } from '../types/common';
import { statisticsWidth } from '@components/pages/scannerDetails/fragments/comparison';

const shelfRealogramDisplayQty = 2;
const flatRealogramDisplayQty = 1;
const headerHeight = 64;
const faceAreaControllerBoxHeight = 56;
const bayHeaderHeight = 44;
const statisticValueSelectorHeight = 50;
const bordersHeight = 2;
const comparisonBayHeader = 50;
export const useRealogramImageRatio = (
  imgOnload: boolean,
  imageElement: HTMLImageElement | null,
  imageBoxRef: MutableRefObject<HTMLElement | undefined>,
  mode: ShelfDetailMode,
  isBucketType: boolean,
  isComparedBucketType = false,
  isCrop = false
) => {
  const [ratio, setRatio] = useState(0);
  const { width: windowInnerWidth, height: windowInnerHeight } =
    useWindowSize();
  const { isLarger } = useBreakpoint();

  const onPageLoad = useCallback(() => {
    if (!imageElement || !imageBoxRef.current) {
      return;
    }
    const imageRatio = calcImageRatio(
      imageElement,
      imageBoxRef.current,
      windowInnerWidth,
      isLarger,
      mode,
      isBucketType,
      isComparedBucketType,
      windowInnerHeight,
      isCrop
    );
    if (!isFinite(imageRatio)) {
      return;
    }
    setRatio(imageRatio);
  }, [
    imageElement,
    imageBoxRef,
    windowInnerWidth,
    isLarger,
    mode,
    isBucketType,
    isComparedBucketType,
    windowInnerHeight,
    isCrop,
  ]);

  useEffect(() => {
    if (imgOnload) {
      onPageLoad();
    }
  }, [imgOnload, onPageLoad]);
  return { ratio };
};

const calcImageRatio = (
  imageElement: HTMLImageElement,
  imageBoxElement: HTMLElement,
  windowInnerWidth: number,
  isLarger: boolean,
  mode: ShelfDetailMode,
  isBucketType: boolean,
  isComparedBucketType: boolean,
  windowInnerHeight: number,
  isCrop: boolean
) => {
  const { naturalHeight, naturalWidth } = imageElement;

  const imageBoxHeight = isLarger
    ? getImageBoxHeight({ isBucketType, windowInnerHeight, mode })
    : imageBoxElement.getBoundingClientRect().height; //NOTE: can't get image box width
  const comparisonItemsQuantity = isBucketType
    ? flatRealogramDisplayQty
    : shelfRealogramDisplayQty;
  const ratio =
    mode === 'comparison'
      ? (windowInnerWidth - statisticsWidth) /
        windowInnerWidth /
        comparisonItemsQuantity //get width percentage of comparison block and divide by comparison items count
      : isCrop
      ? 0.7
      : 0.45; //0.45 is flex value

  const imageBoxWidth = isLarger
    ? (windowInnerWidth * Math.trunc(ratio * 100)) / 100 //trim number after second decimal place
    : isBucketType || isCrop
    ? windowInnerWidth
    : windowInnerWidth / 2;
  const imageContainerWidth =
    isBucketType || isComparedBucketType ? imageBoxWidth / 2 : imageBoxWidth;
  const calcRatioWidth = imageContainerWidth / naturalWidth;
  const calcRatioHeight = imageBoxHeight / naturalHeight;

  //choosing biggest ratio for render image without paddings
  const imageRatio =
    calcRatioWidth > calcRatioHeight ? calcRatioHeight : calcRatioWidth;
  return Number(imageRatio);
};

const getImageBoxHeight = ({
  windowInnerHeight,
  isBucketType,
  mode,
}: {
  windowInnerHeight: number;
  isBucketType: boolean;
  mode: ShelfDetailMode;
}) => {
  if (mode !== 'comparison')
    return windowInnerHeight - headerHeight - faceAreaControllerBoxHeight - 1;
  if (!isBucketType) {
    return (
      windowInnerHeight -
      headerHeight -
      faceAreaControllerBoxHeight -
      bayHeaderHeight -
      statisticValueSelectorHeight -
      bordersHeight
    );
  }
  return (
    windowInnerHeight / 2 -
    headerHeight / 2 -
    comparisonBayHeader -
    faceAreaControllerBoxHeight -
    bordersHeight
  );
};
