import { datadogRum } from '@datadog/browser-rum';
import { setUser } from '@reducers/auth';
import { selectIsAuthenticated } from '@reducers/auth/selectors';
import { selectIsDemoOn } from '@reducers/demo/selector';
import { usersApi } from '@reducers/shelfAppsApi';
import {
  useGetTenantsLocaleQuery,
  useGetTenantsQuery,
} from '@reducers/shelfAppsApi/injections/tenantsApi';
import { AbilityContext, defineAbilityFor } from '@utils/abac';
import { embed, paths } from '@utils/const';
import { StorageHelper } from '@utils/storageHelper';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { setDemoState } from '@reducers/demo';

type JsonObject = {
  [key: string]: string;
};

export const Secured = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: RootState) => state.Auth);
  const isDemoOn = useAppSelector(selectIsDemoOn);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  // i18nextによるテナントの言語切り替え
  const { i18n } = useTranslation();
  const { data: tenantInfo, isLoading: isLoadingTenant } = useGetTenantsQuery();
  const { data: tenantLocaleInfo } = useGetTenantsLocaleQuery();

  const fetchJson = useCallback(async () => {
    if (process.env.NEXT_PUBLIC_IS_LOCAL_TENANT_JSON === 'true') {
      // 環境変数に何も指定しないとdefaultのja.jsonになる
      const tenantKey = process.env.NEXT_PUBLIC_LOCAL_TENANT_KEY ?? '';
      const language = process.env.NEXT_PUBLIC_LOCAL_TENANT_LANGUAGE ?? '';

      const res = (await import(
        `../../../i18n/${tenantKey}/${language}.json`
      )) as JsonObject;

      i18n.addResourceBundle(tenantKey, 'translation', res, true, true);
      await i18n.changeLanguage(tenantKey).catch((e) => console.log(e));
    } else {
      const tenantKey = tenantInfo?.tenant.tenant_key ?? '';

      i18n.addResourceBundle(
        tenantKey,
        'translation',
        tenantLocaleInfo ?? {},
        true,
        true
      );
      await i18n.changeLanguage(tenantKey).catch((e) => console.log(e));
    }
  }, [i18n, tenantInfo, tenantLocaleInfo]);

  useEffect(() => {
    fetchJson().catch((e) => console.log(e));
  }, [i18n, fetchJson]);

  useEffect(() => {
    // ログイン時にユーザー情報が取得されないので存在しない場合に明示的に取得を行う
    if (!user) {
      dispatch(
        usersApi.endpoints.getUser.initiate(
          { userId: 'me' },
          { forceRefetch: true } // do not use cache
        )
      )
        .unwrap()
        .then(({ user }) => dispatch(setUser(user)))
        .catch((error) => {
          console.error(error);
        });
    }
  });

  // reset demo mode if demo_mode is false
  useEffect(() => {
    if (
      isAuthenticated &&
      !tenantInfo?.features.demo_mode &&
      !isLoadingTenant
    ) {
      dispatch(setDemoState(false));
    }
  }, [
    dispatch,
    isAuthenticated,
    isLoadingTenant,
    tenantInfo?.features.demo_mode,
  ]);

  useEffect(() => {
    // 棚割計画の埋め込み対応
    const storageHelper = new StorageHelper();
    const params = new URLSearchParams(window.location.search);

    if (!isAuthenticated && params.get('embed') === embed) {
      storageHelper.set('path', window.location.pathname);
      storageHelper.set('embed', embed);
    } else {
      storageHelper.remove('path');
      storageHelper.remove('embed');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    datadogRum.setUser({
      id: String(user?.id),
      role: String(user?.role),
    });
  }, [user]);

  return isAuthenticated ? (
    <AbilityContext.Provider value={defineAbilityFor(user, isDemoOn)}>
      <Outlet />
    </AbilityContext.Provider>
  ) : (
    <Navigate to={paths.login} replace />
  );
};
