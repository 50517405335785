import { paths } from 'types/api';
import { baseApi, KeysToCamelcase, RealogramsTags } from '../baseApi';

export type GetProductsParams = KeysToCamelcase<
  paths['/api/v1/products']['get']['parameters']['query']
>;
type GetProductsResponse =
  paths['/api/v1/products']['get']['responses']['200']['content']['application/json'];
type GetProductParams = KeysToCamelcase<
  paths['/api/v1/products/{product_id}']['get']['parameters']['path']
>;

type GetProductResponse =
  paths['/api/v1/products/{product_id}']['get']['responses']['200']['content']['application/json'];

type GetProductsBulkParams = KeysToCamelcase<
  paths['/api/v1/products/bulk']['get']['parameters']['query']
>;
type GetProductsBulkResponse =
  paths['/api/v1/products/bulk']['get']['responses']['200']['content']['application/json'];

type DeleteProductMockupImageResponse =
  paths['/api/v1/products/{product_id}/mockup_images/{face_front}.png']['delete']['responses']['200']['content']['application/json'];
type DeleteProductMockupImageParams = KeysToCamelcase<
  paths['/api/v1/products/{product_id}/mockup_images/{face_front}.png']['delete']['parameters']['path']
>;

type GetProductAdjustersResponse =
  paths['/api/v1/products/{product_id}/adjusters']['get']['responses']['200']['content']['application/json'];
type GetProductAdjustersParams =
  paths['/api/v1/products/{product_id}/adjusters']['get']['parameters']['path'];
type UpdateProductAdjustersResponse =
  paths['/api/v1/products/{product_id}/adjusters']['put']['responses']['200']['content']['application/json'];
type UpdateProductAdjustersParams =
  paths['/api/v1/products/{product_id}/adjusters']['put']['parameters']['path'] &
    paths['/api/v1/products/{product_id}/adjusters']['put']['requestBody']['content']['application/json'];

// see) https://staging.shelf.pfn.dev/docs#/products
export const productsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listProducts: builder.query<GetProductsResponse, GetProductsParams>({
      query: (params) => ({
        url: 'products/',
        params: {
          offset: params?.offset,
          limit: params?.limit,
          shape: params?.shape,
          detail: params?.detail,
          category: params?.category,
          tags: params?.tags,
          first_order: params?.firstOrder,
          q: params?.q,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        // check is filters changed or not
        if (currentCache.pager.offset === newItems.pager.offset) {
          currentCache.pager = newItems.pager;
          currentCache.products = [...newItems.products];
        } else {
          currentCache.products.push(...newItems.products);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getProduct: builder.query<GetProductResponse, GetProductParams>({
      query: (params) => ({
        url: `products/${params.productId}`,
      }),
    }),
    listProductsBulk: builder.query<
      GetProductsBulkResponse,
      GetProductsBulkParams
    >({
      query: (params) => ({
        url: 'products/bulk',
        params: {
          // should prepare comma-separated character string
          // product_ids: "1" or "1,2,3,4,5"
          product_ids: params?.productIds,
          shape: params?.shape,
          detail: params?.detail,
        },
      }),
      providesTags: [
        RealogramsTags.UpdateFace,
        RealogramsTags.UpdateProductLists,
      ],
    }),
    deleteProductMockupImage: builder.mutation<
      DeleteProductMockupImageResponse,
      DeleteProductMockupImageParams
    >({
      query: (params) => ({
        url: `products/${params.productId}/mockup_images/${params.faceFront}.png`,
        method: 'DELETE',
      }),
    }),
    getProductAdjusters: builder.query<
      GetProductAdjustersResponse,
      GetProductAdjustersParams
    >({
      query: (params) => ({
        url: `products/${params.product_id}/adjusters`,
        method: 'GET',
      }),
    }),
    updateProductAdjusters: builder.mutation<
      UpdateProductAdjustersResponse,
      UpdateProductAdjustersParams
    >({
      query: (params) => ({
        url: `products/${params.product_id}/adjusters`,
        body: {
          width: params.width,
          height: params.height,
          depth: params.depth,
          face_direction: params.face_direction,
        },
        method: 'PUT',
      }),
      invalidatesTags: [RealogramsTags.UpdateProductLists],
    }),
  }),
});

export const {
  useListProductsQuery,
  useGetProductQuery,
  useListProductsBulkQuery,
  useDeleteProductMockupImageMutation,
  useGetProductAdjustersQuery,
  useUpdateProductAdjustersMutation,
} = productsApi;
