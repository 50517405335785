import { StyledToggleButtonGroup } from '@components/organisms/viewModeToggle/fragments';
import { Delete, RotateRight } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  ToggleButton,
  Typography,
} from '@mui/material';
import { CommonColors } from '@utils/colors';
import { mockupImageFace } from '@utils/const';
import { faceDirection, hasProductTag } from '@utils/product';
import { FC } from 'react';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductFaceDirection, UploadedFiles } from 'types/products';
import { Hexahedron } from './hexahedron';

type Props = {
  directionTabValue: ProductFaceDirection;
  handleChangeDirectionTabValue: (tab: ProductFaceDirection) => void;
  handleClickFace: (face: ProductFaceDirection) => void;
  handleDeleteImage: () => void;
  selectedFace?: ProductFaceDirection;
  uploadedFiles: UploadedFiles;
  handleClickChange: () => void;
  handleClickRotate: () => void;
  product: Product;
};

export const ProductUploadBox: FC<Props> = ({
  directionTabValue,
  handleChangeDirectionTabValue,
  handleClickFace,
  handleDeleteImage,
  selectedFace,
  uploadedFiles,
  handleClickChange,
  handleClickRotate,
  product,
}) => {
  const isButtonDisabled =
    !selectedFace ||
    (selectedFace &&
      !uploadedFiles[selectedFace]?.file &&
      !uploadedFiles[selectedFace]?.originalFile);

  const isNot3DScanned = hasProductTag('未3Dスキャン', product.detail?.tags);

  return (
    <Box
      component="div"
      border={`1px solid ${theme.palette.dividerBlack.dark}`}
      borderRadius="4px"
      position="relative"
      sx={{
        backgroundColor: theme.palette.backgroundBlack.primary,
      }}
      width="576px"
      height="424px"
    >
      {isNot3DScanned && (
        <Box
          component="div"
          display="flex"
          alignItems="center"
          gap={1}
          position="absolute"
          m={2}
          right={0}
          top={0}
        >
          <Typography variant="body1" color={theme.palette.text.secondary}>
            フェイス面
          </Typography>

          <StyledToggleButtonGroup
            value={directionTabValue}
            exclusive
            onChange={(_, value: ProductFaceDirection) => {
              if (value) {
                handleChangeDirectionTabValue(value);
              }
            }}
            sx={{ background: theme.palette.white.primary }}
          >
            {mockupImageFace.map((face) => (
              <ToggleButton key={face} value={face}>
                {faceDirection[face]}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </Box>
      )}
      <Box
        component="div"
        px={5.25}
        py={3.5}
        display="flex"
        flexDirection="column"
        sx={{ pointerEvents: isNot3DScanned ? 'all' : 'none' }}
      >
        <Hexahedron
          size={120}
          svgSize={80}
          handleClickFace={handleClickFace}
          uploadedFiles={uploadedFiles}
          directionTabValue={directionTabValue}
          selectedFace={selectedFace}
          isEditor
          product={product}
        />
      </Box>
      {isNot3DScanned && (
        <Box
          component="div"
          boxShadow={1}
          display="flex"
          gap={1}
          position="absolute"
          right={0}
          bottom={0}
          p={1}
          m={2}
          maxWidth={220}
          whiteSpace="nowrap"
          borderRadius="4px"
          sx={{
            backgroundColor: theme.palette.white.primary,
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleClickChange()}
            disabled={isButtonDisabled}
          >
            変更
          </Button>
          <Button
            startIcon={<RotateRight />}
            sx={{
              display: 'none',
              border: isButtonDisabled
                ? 'none'
                : `1px solid ${CommonColors.appBlue}`,
            }}
            disabled={isButtonDisabled}
            variant={isButtonDisabled ? 'contained' : 'outlined'}
            onClick={handleClickRotate}
          >
            回転
          </Button>
          <IconButton
            onClick={() => handleDeleteImage()}
            disabled={isButtonDisabled}
          >
            <Delete />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
