import { Search } from '@mui/icons-material/';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, TextField } from '@mui/material';
import { FC } from 'react';
import type { ControllerRenderProps } from 'react-hook-form/dist/types';
import { Asserts, object, string } from 'yup';
import { theme } from 'theme';

const schema = object({
  searchText: string().required(),
});

// Yupスキーマを使用して型を推論
type SchemaType = Asserts<typeof schema>;

type Props = {
  isFocused: boolean;
  field: ControllerRenderProps<SchemaType, 'searchText'>;
  handleFocus: () => void;
  reset: () => void;
};

export const ProductsSearchTextField: FC<Props> = ({
  isFocused,
  field,
  handleFocus,
  reset,
}) => {
  return isFocused ? (
    <TextField
      id="searchedTextId"
      key="searchedTextFullWidth"
      {...field}
      placeholder="全カテゴリから検索"
      size="small"
      sx={{
        width: '100%',
        'input::placeholder': {
          fontSize: '14px',
        },
      }}
      InputProps={{
        sx: {
          borderRadius: '40px',
          backgroundColor: '#F7F7F7',
        },
        startAdornment: (
          <Search
            sx={{
              color: theme.palette.icons.primary,
              mr: '4px',
            }}
          />
        ),
        endAdornment: isFocused && (
          <IconButton
            onClick={() => {
              reset();
            }}
          >
            <CloseIcon
              sx={{
                width: '18px',
                height: '18px',
                cursor: 'pointer',
                color: theme.palette.icons.primary,
              }}
            />
          </IconButton>
        ),
      }}
    />
  ) : (
    <TextField
      key="searchedTextCollapsed"
      {...field}
      placeholder="全カテゴリから検索"
      onFocus={handleFocus}
      size="small"
      sx={{
        width: '184px',
        'input::placeholder': {
          fontSize: '14px',
        },
      }}
      InputProps={{
        sx: {
          borderRadius: '40px',
          backgroundColor: '#F7F7F7',
        },
        startAdornment: (
          <Search
            sx={{
              color: theme.palette.icons.primary,
              mr: '4px',
            }}
          />
        ),
      }}
    />
  );
};
