import { ProductTag, ShelfDetailMode, ShelfDetailView } from 'types/common';

type QueryParamsObject = Record<string, string>;

export const filteringQueryParams = (
  allowKeys: string[],
  existingParams: QueryParamsObject
) => {
  return Object.fromEntries(
    Object.entries(existingParams).filter(([key]) => allowKeys.includes(key))
  );
};

export const getViewQueryParams = (
  searchParams: URLSearchParams
): ShelfDetailView => {
  const params = searchParams.get('view');

  switch (params) {
    case 'attribute':
      return 'productFlag';

    case 'analytics':
      return 'profit';

    case 'evaluation':
      return 'rate';

    case 'layout':
    default:
      return 'default';
  }
};
export const getModeQueryParams = (
  searchParams: URLSearchParams
): ShelfDetailMode => {
  const params = searchParams.has('compare');
  return params ? 'comparison' : 'default';
};
export const getAttributeQueryParams = (
  searchParams: URLSearchParams
): ProductTag => {
  const params = searchParams.get('attribute');
  switch (params) {
    case '新商品(1週目)':
    case '新商品(2週目)':
    case '基本商品':
    case '推奨取消':
    case '未3Dスキャン':
      return params;

    default:
      return '新商品(1週目)';
  }
};
