import { ProductDrawer } from '@components/organisms';
import { Box } from '@mui/material';
import { updateSelectedProductCompartment } from '@reducers/planogramEditor/reducer';
import {
  selectPlanogramEditorState,
  selectRecentProducts,
} from '@reducers/planogramEditor/selectors';
import { useAppDispatch, useAppSelector } from '@store/index';
import { FC, useRef, useState } from 'react';
import { theme } from 'theme';
import { Product } from 'types/common';
import { ProductCategory } from 'types/productCategories';
import { InferType, object, string } from 'yup';
import { useProductsSearch } from '../../../hooks/useProductsSearch';
import { CategoryProducts } from './fragments/categoryProducts';
import { ProductsHeader } from './fragments/productsHeader';
import { RecentProducts } from './fragments/recentProducts';

type Props = {
  categoryIndex: number;
  productCategoryHistory: ProductCategory[];
  categoryType: 'categories' | 'list';
  filteredCategories: ProductCategory[];
  isCompareMode?: boolean;
  displayRecentProducts: boolean;
  setDisplayRecentProducts: (displayRecentProducts: boolean) => void;
  handleBack: () => void;
  handleClick: (category: ProductCategory) => void;
  fetchFilteredCategories: (text: string) => void;
  handleClickProductCategory: (category: ProductCategory) => void;
};

const schema = object({
  searchText: string().required(),
});

type FormData = InferType<typeof schema>;

export const Products: FC<Props> = ({
  categoryIndex,
  productCategoryHistory,
  categoryType,
  filteredCategories,
  displayRecentProducts,
  handleBack,
  handleClick,
  fetchFilteredCategories,
  handleClickProductCategory,
  setDisplayRecentProducts,
}) => {
  const dispatch = useAppDispatch();
  const recentProducts = useAppSelector(selectRecentProducts);
  const { detailMode, selectedProductCompartment, isShowProductDetail } =
    useAppSelector(selectPlanogramEditorState);
  const isCompareMode = detailMode === 'comparison';
  const [searchedOffset, setSearchedOffset] = useState(0);
  const [offset, setOffset] = useState(0);
  const { isFocused, control, searchWord, focus, search, reset, handleSubmit } =
    useProductsSearch(fetchFilteredCategories);
  const handleClickProduct = (product: Product) => {
    if (selectedProductCompartment?.id === product.id) {
      dispatch(updateSelectedProductCompartment(undefined));
    } else {
      dispatch(updateSelectedProductCompartment(product));
    }
  };

  const productsRef = useRef<HTMLDivElement>(null);
  const onSubmit = (data: FormData) => {
    setSearchedOffset(0);
    search(data);
    productsRef.current?.scrollTo(0, 0);
  };

  const onClickRecentProducts = () => {
    setDisplayRecentProducts(!displayRecentProducts);
  };
  const onClearSearchClick = () => {
    reset();
    setOffset(0);
  };
  const onCategoryTitleClick = () => {
    handleBack();
    setOffset(0);
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      display="flex"
      flexDirection="column"
      height="100%"
      overflow="hidden"
    >
      <ProductsHeader
        displaySearchInput={isFocused}
        displayActionButtons={!isFocused && !displayRecentProducts}
        control={control}
        onClearSearchClick={onClearSearchClick}
        onCategoryTitleClick={onCategoryTitleClick}
        categoryIndex={categoryIndex}
        productCategoryHistory={productCategoryHistory}
        onClickSearch={focus}
        onClickRecentProducts={onClickRecentProducts}
        displayRecentProducts={displayRecentProducts}
      />
      {displayRecentProducts ? (
        <RecentProducts
          products={recentProducts}
          isCompareMode={isCompareMode}
          handleClickProduct={handleClickProduct}
          selectedItemId={selectedProductCompartment?.id ?? 0}
        />
      ) : (
        <CategoryProducts
          categoryType={categoryType}
          handleClick={handleClick}
          filteredCategories={filteredCategories}
          handleClickProductCategory={handleClickProductCategory}
          searchWord={searchWord}
          searchedOffset={searchedOffset}
          offset={offset}
          setOffset={setOffset}
          setSearchedOffset={setSearchedOffset}
          categoryIndex={categoryIndex}
          productCategoryHistory={productCategoryHistory}
          handleReset={() => {
            reset();
            setSearchedOffset(0);
          }}
          handleClickProduct={handleClickProduct}
          productsRef={productsRef}
          selectedItemId={selectedProductCompartment?.id ?? 0}
          isCompareMode={isCompareMode}
        />
      )}
      {selectedProductCompartment && !isShowProductDetail && (
        <Box
          component="div"
          sx={{
            p: 2,
            position: 'absolute',
            zIndex: 2,
            bottom: 0,
            width: '100%',
            boxShadow: '5px 2px 8px rgba(0, 0, 0, 0.25)',
            backgroundColor: theme.palette.white.primary,
            ...(isCompareMode && {
              right: 0,
              height: '80px',
            }),
            ...(!isCompareMode && {
              left: 0,
            }),
          }}
        >
          <ProductDrawer
            isCompareMode={isCompareMode}
            product={selectedProductCompartment}
            productsRef={productsRef}
          />
        </Box>
      )}
    </Box>
  );
};
