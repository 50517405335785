import { Categories } from '@components/organisms/categories/categories';
import { ProductsSearchResults } from '@components/organisms/productsSearchResults/productsSearchResults';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useListProductsQuery } from '@reducers/shelfAppsApi';
import { useAppSelector } from '@store/index';
import { itemsPerPage } from '@utils/product';
import { FC, MutableRefObject } from 'react';
import { Product } from 'types/common';
import { ProductCategory } from 'types/productCategories';
type Props = {
  searchWord: string;
  searchedOffset: number;
  offset: number;
  setOffset: (offset: number) => void;
  setSearchedOffset: (offset: number) => void;
  categoryIndex: number;
  productCategoryHistory: ProductCategory[];
  categoryType: 'categories' | 'list';
  handleClick: (category: ProductCategory) => void;
  filteredCategories: ProductCategory[];
  handleClickProductCategory: (category: ProductCategory) => void;
  isCompareMode?: boolean;
  handleReset: VoidFunction;
  handleClickProduct: (product: Product) => void;
  productsRef: MutableRefObject<HTMLDivElement | null>;
  selectedItemId: number;
};

export const CategoryProducts: FC<Props> = ({
  searchWord,
  searchedOffset,
  offset,
  setOffset,
  setSearchedOffset,
  categoryIndex,
  productCategoryHistory,
  categoryType,
  handleClick,
  filteredCategories,
  handleClickProductCategory,
  isCompareMode,
  handleReset,
  handleClickProduct,
  productsRef,
  selectedItemId,
}) => {
  const { tags } = useAppSelector(selectPlanogramEditorState);

  const {
    data: searchedData,
    isLoading: isSearchedLoading,
    isFetching: isSearchFetching,
  } = useListProductsQuery(
    {
      offset: searchedOffset,
      limit: itemsPerPage,
      detail: true,
      shape: true,
      q: searchWord,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !searchWord,
    }
  );

  const { data, isLoading, isFetching } = useListProductsQuery(
    {
      offset: offset,
      limit: itemsPerPage,
      detail: true,
      shape: true,
      category: [productCategoryHistory[categoryIndex]?.name],
      tags,
    },
    {
      skip: !!searchWord || categoryType === 'categories',
      refetchOnMountOrArgChange: true,
    }
  );

  const handleEndReached = (index: number) => {
    if (isLoading || isFetching) return;
    const offset = index + 1;
    if (data?.pager.total && offset >= data?.pager.total) return; // NOTE: 全件取得済みのときは何もしない
    setOffset(offset);
  };

  const handleSearchEndReached = (index: number) => {
    if (isSearchedLoading || isSearchFetching) return;
    const offset = index + 1;
    if (searchedData?.pager.total && offset >= searchedData?.pager.total)
      return; // NOTE: 全件取得済みのときは何もしない
    setSearchedOffset(offset);
  };
  return (
    <>
      {!searchWord ? (
        <Categories
          categoryName={productCategoryHistory[categoryIndex]?.name}
          categoryType={categoryType}
          productCategories={
            productCategoryHistory[categoryIndex]?.sub_categories
          }
          handleClick={handleClick}
          productsRef={productsRef}
          isCompareMode={isCompareMode}
          handleEndReached={handleEndReached}
          isLoading={isLoading || isFetching}
          products={data?.products}
          handleClickProduct={handleClickProduct}
          offset={offset}
          selectedItemId={selectedItemId}
          setOffset={setOffset}
        />
      ) : (
        <ProductsSearchResults
          products={searchedData?.products}
          reset={handleReset}
          offset={searchedOffset}
          filteredCategories={filteredCategories}
          handleClickProductCategory={(category: ProductCategory) => {
            handleClickProductCategory(category);
            setSearchedOffset(0);
          }}
          isLoading={isSearchedLoading || isSearchFetching}
          productsRef={productsRef}
          isCompareMode={isCompareMode}
          handleEndReached={handleSearchEndReached}
          total={searchedData?.pager.total}
          handleClickProduct={handleClickProduct}
          selectedItemId={selectedItemId}
        />
      )}
    </>
  );
};
