import { ViewModeToggle } from '@components/organisms/viewModeToggle/viewModeToggle';
import {
  Add,
  ArrowBack,
  Comment,
  Delete,
  InfoOutlined,
  MoreVert,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { featureFlags } from '@utils/featureFlags';
import { FC, useState } from 'react';
import { theme } from 'theme';
import { ShelfDetailMode, ShelfDetailView } from 'types/common';
import { RealogramCandidate } from 'types/realogram';
import { Header } from '../header/header';

type Props = {
  realogramCandidate?: RealogramCandidate;
  handleClickBackButton: () => void;
  handleClickMemoButton: () => void;
  handleClickDeleteButton: () => void;
  handleClickCreatePlanButton: () => void;
  handleClickMoreInfoButton: () => void;
  mode: ShelfDetailMode;
  view: ShelfDetailView;
  handleChangeMode: (value: ShelfDetailMode) => void;
  handleChangeView: (value: ShelfDetailView) => void;
  handleClickChangePermission: () => void;
  isDisabledAction?: boolean;
  isShowAnalyticsButton?: boolean;
  isNotCanDelete?: boolean;
  bayPlanName?: string;
};
export const RealogramDetailHeader: FC<Props> = ({
  realogramCandidate,
  handleClickBackButton,
  handleClickMemoButton,
  handleClickDeleteButton,
  handleClickCreatePlanButton,
  handleClickMoreInfoButton,
  mode,
  view,
  handleChangeMode,
  handleChangeView,
  handleClickChangePermission,
  isDisabledAction,
  isShowAnalyticsButton,
  isNotCanDelete,
  bayPlanName,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <Header
      sx={{
        p: 2,
      }}
    >
      <IconButton
        edge="start"
        sx={{
          width: 40,
          height: 40,
          border: `1px solid ${theme.palette.dividerBlack.dark}`,
          borderRadius: 1,
          ml: 0,
          mr: 2,
        }}
        onClick={handleClickBackButton}
      >
        <ArrowBack
          fontSize="small"
          sx={{ color: theme.palette.icons.primary }}
        />
      </IconButton>
      <Box
        component="div"
        display="flex"
        alignItems="center"
        flexGrow={1}
        gap={2}
      >
        <Box component="div" display="flex" flexDirection="column" gap={0.5}>
          <Typography
            variant="subtitle1"
            lineHeight="16px"
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
              WebkitLineClamp: 1,
              // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
              WebkitBoxOrient: 'vertical',
            }}
            textOverflow="ellipsis"
          >
            {realogramCandidate?.store_bay.name}
          </Typography>
          <Box component="div" display="flex" gap={1}>
            <Typography
              variant="body3"
              color={theme.palette.textBlack.secondary}
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                WebkitLineClamp: 1,
                // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                WebkitBoxOrient: 'vertical',
              }}
              textOverflow="ellipsis"
              lineHeight="15px"
            >
              {realogramCandidate?.store_bay.store.name}
            </Typography>
            <Typography
              variant="body3"
              color={theme.palette.textBlack.secondary}
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                WebkitLineClamp: 1,
                // eslint-disable-next-line @typescript-eslint/naming-convention -- Property should use UpperCase
                WebkitBoxOrient: 'vertical',
              }}
              textOverflow="ellipsis"
              lineHeight="12px"
            >
              {bayPlanName}
            </Typography>
          </Box>
        </Box>
        <IconButton
          onClick={handleClickMoreInfoButton}
          sx={{ p: 0, ml: '2px', mr: '18px' }}
        >
          <InfoOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
        </IconButton>
      </Box>
      <ViewModeToggle
        mode={mode}
        view={view}
        handleChangeMode={handleChangeMode}
        handleChangeView={handleChangeView}
        handleSharePermission={handleClickChangePermission}
        isActual
        disabled={isDisabledAction}
        isShowAnalyticsButton={isShowAnalyticsButton}
      />
      <IconButton
        sx={{ ml: 1 }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={isDisabledAction}
      >
        <MoreVert />
      </IconButton>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            minHeight: '92px',
            minWidth: '218px',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <MenuItem
          sx={{
            fontWeight: 'bold',
          }}
          onClick={handleClickMemoButton}
          disabled={!realogramCandidate?.custom_field?.memo_text}
        >
          <ListItemIcon>
            <Comment />
          </ListItemIcon>
          <ListItemText color={theme.palette.textBlack.primary}>
            メモ
          </ListItemText>
        </MenuItem>
        {featureFlags.planogramEditor && (
          <MenuItem onClick={handleClickCreatePlanButton}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText color={theme.palette.textBlack.primary}>
              スキャン結果から棚割を作成
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleClickDeleteButton} disabled={isNotCanDelete}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText color={theme.palette.textBlack.primary}>
            削除
          </ListItemText>
        </MenuItem>
      </Menu>
    </Header>
  );
};
