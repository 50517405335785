import { RealogramDirectory, RealogramCandidate } from 'types/realogram';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import {
  AbilityContext,
  AppAbility,
  RealogramAbilities,
  RealogramCandidateAbilities,
  RealogramDirectoryAbilities,
} from '@utils/abac';

export type PermissionConditions = {
  action:
    | RealogramAbilities[0]
    | RealogramDirectoryAbilities[0]
    | RealogramCandidateAbilities[0];
  realogram: RealogramDirectory | RealogramCandidate | undefined;
  isCandidate: boolean;
  isCan: boolean;
};

export const getRealogramPermission = (
  conditions: PermissionConditions,
  ability: AppAbility
) => {
  const { action, realogram, isCandidate, isCan } = conditions;

  const subjectType =
    (realogram as RealogramDirectory)?.type === 'file'
      ? 'Realogram'
      : (realogram as RealogramDirectory)?.type === 'directory'
      ? 'RealogramDirectory'
      : '';
  if (isCan) {
    return {
      isEnable: isCandidate
        ? ability.can(
            action as RealogramCandidateAbilities[0],
            subject('RealogramCandidate', {
              ...(realogram as RealogramCandidate),
            })
          )
        : ability.can(
            action as RealogramDirectoryAbilities[0],
            subject(subjectType, { ...(realogram as RealogramDirectory) })
          ),
    };
  } else {
    return {
      isNotEnable: isCandidate
        ? ability.cannot(
            action as RealogramCandidateAbilities[0],
            subject('RealogramCandidate', {
              ...(realogram as RealogramCandidate),
            })
          )
        : ability.cannot(
            action as RealogramDirectoryAbilities[0],
            subject(subjectType, { ...(realogram as RealogramDirectory) })
          ),
    };
  }
};

export const useGetRealogramPermission = (conditions: PermissionConditions) => {
  const ability = useAbility(AbilityContext);
  return getRealogramPermission(conditions, ability);
};
