/* eslint-disable @typescript-eslint/naming-convention -- ネストしたフィールドにアクセスするために . を利用するため */
import {
  AbilityBuilder,
  MongoAbility,
  createMongoAbility,
} from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { createContext } from 'react';
import { components } from 'types/api';
import { ShareRole } from 'types/sharePermission';
import { User } from 'types/user';

type Planogram = components['schemas']['Planogram'];
type PlanogramDirectory = components['schemas']['PlanogramDirectory'];
type Realogram = components['schemas']['RealogramDirectory'];
type RealogramCandidate = components['schemas']['RealogramCandidate'];

// 棚割計画
export type PlanogramAbilities = [
  // ファイル
  (
    | 'read'
    | 'copy'
    | 'update'
    | 'move'
    | 'delete'
    // スター
    | 'create_favorite'
    | 'delete_favorite'
    // 権限
    | 'read_role'
    | 'create_role'
    | 'delete_role'
    | 'update_role'
    | 'request_role'
    // 公開範囲
    | 'read_scope'
    | 'update_scope'
  ),
  'Planogram' | Planogram
];
export type PlanogramDirectoryAbilities = [
  // ディレクトリ
  (
    | 'read'
    | 'create'
    | 'update'
    | 'move'
    | 'delete'
    // ファイル
    | 'create_file'
    // スター
    | 'create_favorite'
    | 'delete_favorite'
    // 権限
    | 'read_role'
    | 'create_role'
    | 'delete_role'
    | 'update_role'
    | 'request_role'
    // 公開範囲
    | 'read_scope'
    | 'update_scope'
  ),
  'PlanogramDirectory' | PlanogramDirectory
];

// 棚割実績
export type RealogramAbilities = [
  // ファイル
  (
    | 'read'
    | 'update'
    | 'move'
    | 'delete'
    // スター
    | 'create_favorite'
    | 'delete_favorite'
    // 棚割操作
    | 'revise_face'
    | 'delete_face'
    | 'delete_bay_part'
    // 権限
    | 'read_role'
    | 'create_role'
    | 'delete_role'
    | 'update_role'
    | 'request_role'
  ),
  'Realogram' | Realogram
];
export type RealogramDirectoryAbilities = [
  // ディレクトリ
  (
    | 'read'
    | 'update'
    | 'move'
    | 'delete'
    // ファイル
    | 'create_file'
    // スター
    | 'create_favorite'
    | 'delete_favorite'
    // 権限
    | 'read_role'
    | 'create_role'
    | 'delete_role'
    | 'update_role'
    | 'request_role'
  ),
  'RealogramDirectory' | Realogram
];
export type RealogramCandidateAbilities = [
  // ディレクトリ
  (
    | 'read'
    | 'update'
    | 'move'
    | 'delete'
    // ファイル
    | 'create_file'
    // スター
    | 'create_favorite'
    | 'delete_favorite'
    // 棚割操作
    | 'revise_face'
    | 'delete_face'
    | 'delete_bay_part'
    // 権限
    | 'read_role'
    | 'create_role'
    | 'delete_role'
    | 'update_role'
    | 'request_role'
  ),
  'RealogramCandidate' | RealogramCandidate
];

type DemoAbilities = ['read', 'Demo'];

type Abilities =
  | PlanogramAbilities
  | PlanogramDirectoryAbilities
  | RealogramAbilities
  | RealogramDirectoryAbilities
  | RealogramCandidateAbilities
  | DemoAbilities;

export type AppAbility = MongoAbility<Abilities>;

type PlanogramType = Planogram & {
  'current_user_role.role': ShareRole | null;
};

type PlanogramDirectoryType = Planogram & {
  'current_user_role.role': ShareRole | null;
};

type RealogramType = Realogram & {
  'current_user_role.role': ShareRole | null;
};

type RealogramDirectoryType = Realogram & {
  'current_user_role.role': ShareRole | null;
};
type RealogramCandidateType = RealogramCandidate & {
  'current_user_role.role': ShareRole | null;
};

export function defineAbilityFor(user?: User, isDemoOn?: boolean) {
  const { can, build } = new AbilityBuilder<AppAbility>(createMongoAbility);

  // DEMO MODE
  if (isDemoOn) {
    can('read', 'Demo');
  }

  // NOTE : https://docs.google.com/spreadsheets/d/1MNyhPFphIZIYmmYkFVJcM1Top1A4nefh5SDTLDPOCno/edit#gid=351623232
  if (user?.role === 'admin') {
    // 棚割計画
    // ファイル
    can<PlanogramType>('read', 'Planogram');
    can<PlanogramType>('copy', 'Planogram');
    can<PlanogramType>('update', 'Planogram');
    can<PlanogramType>('move', 'Planogram');
    can<PlanogramType>('delete', 'Planogram');
    can<PlanogramType>('create_favorite', 'Planogram');
    can<PlanogramType>('delete_favorite', 'Planogram');
    can<PlanogramType>('read_role', 'Planogram');
    can<PlanogramType>('create_role', 'Planogram');
    can<PlanogramType>('delete_role', 'Planogram');
    can<PlanogramType>('update_role', 'Planogram');
    can<PlanogramType>('read_scope', 'Planogram');
    can<PlanogramType>('update_scope', 'Planogram');
    // ディレクトリ
    can<PlanogramDirectoryType>('read', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('create', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('update', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('move', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('delete', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('create_file', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('create_favorite', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('delete_favorite', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('read_role', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('create_role', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('delete_role', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('update_role', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('read_scope', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('update_scope', 'PlanogramDirectory');

    // 棚割実績
    // ファイル
    can<RealogramType>('read', 'Realogram');
    can<RealogramType>('update', 'Realogram');
    can<RealogramType>('revise_face', 'Realogram');
    can<RealogramType>('delete_face', 'Realogram');
    can<RealogramType>('delete_bay_part', 'Realogram');
    can<RealogramType>('move', 'Realogram');
    can<RealogramType>('delete', 'Realogram');
    can<RealogramType>('create_favorite', 'Realogram');
    can<RealogramType>('delete_favorite', 'Realogram');
    can<RealogramType>('read_role', 'Realogram');
    can<RealogramType>('create_role', 'Realogram');
    can<RealogramType>('delete_role', 'Realogram');
    can<RealogramType>('update_role', 'Realogram');
    can<RealogramCandidateType>('read', 'RealogramCandidate');
    can<RealogramCandidateType>('update', 'RealogramCandidate');
    can<RealogramCandidateType>('revise_face', 'RealogramCandidate');
    can<RealogramCandidateType>('delete_face', 'RealogramCandidate');
    can<RealogramCandidateType>('delete_bay_part', 'RealogramCandidate');
    can<RealogramCandidateType>('move', 'RealogramCandidate');
    can<RealogramCandidateType>('delete', 'RealogramCandidate');
    can<RealogramCandidateType>('create_favorite', 'RealogramCandidate');
    can<RealogramCandidateType>('delete_favorite', 'RealogramCandidate');
    can<RealogramCandidateType>('read_role', 'RealogramCandidate');
    can<RealogramCandidateType>('create_role', 'RealogramCandidate');
    can<RealogramCandidateType>('delete_role', 'RealogramCandidate');
    can<RealogramCandidateType>('update_role', 'RealogramCandidate');
    // ディレクトリ
    can<RealogramCandidateType>('create_file', 'RealogramCandidate');
    can<RealogramDirectoryType>('read', 'RealogramDirectory');
    can<RealogramDirectoryType>('update', 'RealogramDirectory');
    can<RealogramDirectoryType>('move', 'RealogramDirectory');
    can<RealogramDirectoryType>('delete', 'RealogramDirectory');
    can<RealogramDirectoryType>('create_file', 'RealogramDirectory');
    can<RealogramDirectoryType>('create_favorite', 'RealogramDirectory');
    can<RealogramDirectoryType>('delete_favorite', 'RealogramDirectory');
    can<RealogramDirectoryType>('read_role', 'RealogramDirectory');
    can<RealogramDirectoryType>('create_role', 'RealogramDirectory');
    can<RealogramDirectoryType>('delete_role', 'RealogramDirectory');
    can<RealogramDirectoryType>('update_role', 'RealogramDirectory');
  } else if (user?.role === 'user') {
    // 棚割計画
    // ファイル
    can<PlanogramType>('read', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<PlanogramType>('copy', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<PlanogramType>('update', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramType>('move', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramType>('delete', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramType>('create_favorite', 'Planogram');
    can<PlanogramType>('delete_favorite', 'Planogram');
    can<PlanogramType>('read_role', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<PlanogramType>('create_role', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramType>('delete_role', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramType>('update_role', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramType>('request_role', 'Planogram', {
      'current_user_role.role': { $in: ['viewer', null] },
    });
    can<PlanogramType>('read_scope', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<PlanogramType>('update_scope', 'Planogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    // ディレクトリ
    can<PlanogramDirectoryType>('read', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('create', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramDirectoryType>('update', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramDirectoryType>('move', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramDirectoryType>('delete', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramDirectoryType>('create_file', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramDirectoryType>('create_favorite', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('delete_favorite', 'PlanogramDirectory');
    can<PlanogramDirectoryType>('read_role', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<PlanogramDirectoryType>('create_role', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramDirectoryType>('delete_role', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramDirectoryType>('update_role', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<PlanogramDirectoryType>('request_role', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['viewer', null] },
    });
    can<PlanogramDirectoryType>('read_scope', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<PlanogramDirectoryType>('update_scope', 'PlanogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });

    // 棚割実績
    // ファイル
    can<RealogramType>('read', 'Realogram', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<RealogramType>('update', 'Realogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramType>('revise_face', 'Realogram', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<RealogramType>('delete_face', 'Realogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramType>('delete_bay_part', 'Realogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramType>('move', 'Realogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramType>('delete', 'Realogram', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramType>('create_favorite', 'Realogram');
    can<RealogramType>('delete_favorite', 'Realogram');
    can<RealogramType>('read_role', 'Realogram', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<RealogramType>('request_role', 'Realogram', {
      'current_user_role.role': { $in: ['viewer', null] },
    });
    can<RealogramCandidateType>('read', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<RealogramCandidateType>('update', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramCandidateType>('revise_face', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<RealogramCandidateType>('delete_face', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramCandidateType>('delete_bay_part', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramCandidateType>('move', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramCandidateType>('delete', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramCandidateType>('create_favorite', 'RealogramCandidate');
    can<RealogramCandidateType>('delete_favorite', 'RealogramCandidate');
    can<RealogramCandidateType>('read_role', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<RealogramCandidateType>('create_role', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramCandidateType>('delete_role', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramCandidateType>('update_role', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramCandidateType>('request_role', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['viewer', null] },
    });
    can<RealogramCandidateType>('create_file', 'RealogramCandidate', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });

    // ディレクトリ
    can<RealogramDirectoryType>('read', 'RealogramDirectory');
    can<RealogramDirectoryType>('create_file', 'RealogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramDirectoryType>('create_favorite', 'RealogramDirectory');
    can<RealogramDirectoryType>('delete_favorite', 'RealogramDirectory');
    can<RealogramDirectoryType>('read_role', 'RealogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor', 'viewer'] },
    });
    can<RealogramDirectoryType>('create_role', 'RealogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramDirectoryType>('delete_role', 'RealogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramDirectoryType>('update_role', 'RealogramDirectory', {
      'current_user_role.role': { $in: ['owner', 'editor'] },
    });
    can<RealogramDirectoryType>('request_role', 'RealogramDirectory', {
      'current_user_role.role': { $in: ['viewer', null] },
    });
  }

  return build();
}

export const AbilityContext = createContext({} as AppAbility);
export const Can = createContextualCan(AbilityContext.Consumer);
