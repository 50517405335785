import { ArrowUpwardDownwardIcon } from '@components/molecules/arrowUpwardDownwardIcon/arrowUpwardDowneardIcon';
import {
  Box,
  Divider,
  SxProps,
  TableCell,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { RealogramListOrder, RealogramOrderBy } from 'types/realogram';

type Props = {
  columns: ColumnProps[];
  // TODO: RealogramsTableにsort機能を実装後,optional解除
  handleChangeOrder?: (order?: RealogramListOrder) => void;
  isLatestTab?: boolean;
  firstOrder?: RealogramListOrder;
};
export type ColumnProps = {
  headerName: string;
  orderBy?: RealogramOrderBy;
  headerSx?: SxProps;
  hasDivider?: boolean;
};

export const TableHeader: FC<Props> = ({
  columns,
  handleChangeOrder = () => {
    return;
  },
  isLatestTab = false,
  firstOrder,
}) => {
  return (
    <TableRow
      sx={{
        backgroundColor: '#F6FAFF',
        width: '100%',
        height: '48px',
        whiteSpace: 'nowrap',
      }}
    >
      {columns.map((item, index) => {
        const orderBy = item.orderBy;
        const isCurrentOrderBy = firstOrder?.startsWith(orderBy ?? '');

        // a sequence: undefined -> desc -> asc
        const getCurrentOrder = (order: RealogramListOrder | undefined) => {
          if (!order) return undefined;
          if (order.endsWith('desc')) return 'desc';
          if (order.endsWith('asc')) return 'asc';
          return undefined;
        };

        const getNextOrder = (order: RealogramListOrder | undefined) => {
          if (!order) return 'desc';
          if (order.endsWith('desc')) return 'asc';
          if (order.endsWith('asc')) return undefined;
          return undefined;
        };

        const currentOrder = isCurrentOrderBy
          ? getCurrentOrder(firstOrder)
          : undefined;
        const nextOrder = isCurrentOrderBy ? getNextOrder(firstOrder) : 'desc';

        const newFirstOrder =
          orderBy && nextOrder
            ? (`${orderBy}_${nextOrder}` as RealogramListOrder)
            : undefined;

        return (
          <TableCell
            key={index}
            sx={item.headerSx}
            sortDirection={currentOrder}
          >
            <Box component="div" display="flex" gap="16px">
              {item.hasDivider && <Divider orientation="vertical" flexItem />}
              {orderBy ? (
                <TableSortLabel
                  hideSortIcon={!isCurrentOrderBy}
                  active={isCurrentOrderBy}
                  direction={currentOrder}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    '& .MuiTableSortLabel-icon': {
                      width: 20,
                      height: 20,
                    },
                  }}
                  onClick={() => {
                    handleChangeOrder(newFirstOrder);
                  }}
                >
                  <Typography color="gray" fontSize="14px">
                    {item.headerName}
                  </Typography>
                  {!isCurrentOrderBy && (
                    <Box component="div" width={20} height={20}>
                      <ArrowUpwardDownwardIcon />
                    </Box>
                  )}
                </TableSortLabel>
              ) : (
                <Typography color="gray" fontSize="14px">
                  {item.headerName}
                </Typography>
              )}
              {item.headerName === '権限' && (
                <Divider orientation="vertical" flexItem />
              )}
              {isLatestTab && item.headerName === 'ステータス' && (
                <Divider orientation="vertical" flexItem />
              )}
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
